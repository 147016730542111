<template>
  <panel
    :back-route="{ name: 'mon-compte_payment-methods' }"
    title="Titres restaurant">
    <div class="panel-section">
      <p
        v-if="!getCouponsLuncheon.length"
        class="panel-section__p">
        Aucun titre restaurant n’est encore associé à votre compte.
        Pour commencer, vous devez soumettre un lot.
        <potager-link :to="{ name: 'mon-compte_luncheons_howto' }">
          En savoir plus
        </potager-link>

        <potager-button
          :to="{ name: 'mon-compte_luncheons_add' }"
          class="mt-4"
          full-width>
          Soumettre un lot
        </potager-button>
      </p>
    </div>

    <div
      v-if="getCouponsLuncheon.length"
      class="panel-section">
      <p class="panel-section__subtitle">
        {{ pluralize(getCouponsLuncheon.length, 'Titre restaurant enregistré', 'Titres restaurant enregistrés') }}
      </p>
      <coupon-collapsible
        v-for="coupon in getCouponsLuncheon"
        :key="coupon.id"
        :coupon="coupon" />
      <potager-navigator
        :to="{ name: 'mon-compte_luncheons_add' }"
        class="border-t-0">
        Soumettre un lot de titres restaurant
      </potager-navigator>
    </div>

    <div
      v-if="getCouponLuncheonsBatch.length > 0"
      ref="couponsluncheonbatchlist"
      class="panel-section">
      <p class="panel-section__subtitle">
        {{ pluralize(getCouponLuncheonsBatch.length, 'lot') }} de titres restaurant
        <span class="text-warning block">En attente de validation</span>
      </p>
      <coupon-collapsible
        v-for="batch in getCouponLuncheonsBatch"
        :key="batch.id">
        <template #title>
          Lot crée le {{ tmsToFormat(batch.creationDate, 'dd/MM/yyyy') }}
        </template>
        <template #value>
          -{{ priceFilter(batch.total) }}€
        </template>
        <template #subtitle>
          Nombre de {{ batch.tickets.length > 1 ? 'titres' : 'titre' }}: {{ batch.tickets.length }}
          <span class="block text-warning text-xs.5 normal-case">
            En attente de validation
          </span>
        </template>
        <template #actions>
          <potager-button
            size="small"
            theme="stroke"
            @onClick="printBatch(batch)">
            Imprimer le bordereau
          </potager-button>
          <potager-button
            :is-loading="isBatchDeleting"
            size="small"
            theme="stroke-grey"
            @onClick="deleteBatch(batch)">
            Supprimer
          </potager-button>
        </template>
      </coupon-collapsible>
    </div>

    <div
      v-if="getCouponsLuncheon.length"
      class="panel-section">
      <potager-info size="small">
        Vous avez {{ pluralize(getCouponsLuncheon.length, 'titre') }} restaurant sur votre compte.
        Vous pouvez en utiliser maximum 2 par commande.
      </potager-info>
    </div>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  UPDATE_COUPON_LUNCHEON_BATCH_ACTION,
  REMOVE_COUPON_LUNCHEON_BATCH_ACTION
} from 'Stores/types/userActionsTypes';

import { pluralize, priceFilter, tmsToFormat } from 'Filters';

import Panel from 'Components/panel/Panel';
import PotagerButton from 'UI/PotagerButton';
import PotagerNavigator from 'UI/PotagerNavigator';
import PotagerInfo from 'UI/PotagerInfo';
import CouponCollapsible from 'Components/coupons/CouponCollapsible';
import ModalInfo from 'Modals/ModalInfo';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { downloadFile } from 'Classes/Tools';

export default {
  components: {
    Panel,
    CouponCollapsible,
    PotagerButton,
    PotagerNavigator,
    PotagerInfo,
  },

  computed: {
    ...mapGetters('user', [
      'getCouponsLuncheon',
      'getCouponLuncheonsBatch',
    ]),
    isBatchDeleting() {
      return this.$wait.is(REMOVE_COUPON_LUNCHEON_BATCH_ACTION);
    },
  },

  methods: {
    pluralize,
    priceFilter,
    tmsToFormat,
    printBatch({ id }) {
      this.$api.user.downloadCouponLuncheonBatch(id)
        .then(({ data }) => {
          downloadFile(data, `Bordereau-ticket-restaurants-${id}.pdf`);
        })
        .catch(() => {
          this.$modal.open(ModalInfo, {
            title: 'Oups !',
            text: 'Désolé, une erreur inattendue est survenue, veuillez réessayer plus tard. Si le problème persiste, vous pouvez contacter <b><a href="https://www.potagercity.fr/contact" target="_blank">notre service client</a></b>.',
          });
        });
    },
    deleteBatch({ id }) {
      this.$modal.open(ModalInfo, {
        title: 'Avertissement',
        text: 'En validant cette action, votre lot de titres restaurant sera supprimé et ne sera plus accessible. Êtes-vous sûr de supprimer ?',
        closeBtnText: 'Supprimer',
        showCancel: true,
        onClose: () => {
          this.$store.dispatch(`user/${REMOVE_COUPON_LUNCHEON_BATCH_ACTION}`, { id });
        },
      });
    },
  },

  mounted() {
    this.$store.dispatch(`user/${UPDATE_COUPON_LUNCHEON_BATCH_ACTION}`);
  },

  head: MetaInfosService.generate({
    title: 'Titres restaurant',
  }),

};
</script>
