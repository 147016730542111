<template>
  <div
    :class="[
      'dlp-resume',
      { 'dlp-resume--condensed': condensed },
      { 'panel-section': !condensed },
    ]">
    <slot />

    <component
      :is="condensed ? 'potager-button' : 'potager-navigator'"
      :allow-multiline="!condensed"
      :class="[
        'dlp-resume__delivery-point',
        {
          'rounded-r-none pr-0 max-w-[66%] bp375:max-w-[55%] dlp-resume__delivery-point--selected': condensed && computedDeliveryPoint,
        }]"
      :readonly="!getDeliveryPointRedirect || readonly"
      :theme="computedTheme"
      :to="getDeliveryPointRedirect"
      is-rounded>
      <template #icon>
        <icon-home
          v-if="computedDeliveryPoint?.isHomeDelivery"
          class="text-british-racing" />
        <icon-two-pin-marker
          v-else
          class="text-british-racing" />
      </template>
      <span>{{ deliveryDisplayedName }}</span><span
        v-if="deliveryDisplayedAddress && !condensed"
        class="text-xs.5 line-clamp-1">
        {{ deliveryDisplayedAddress }}
      </span>
    </component>

    <component
      :is="condensed ? 'potager-button' : 'potager-navigator'"
      v-if="computedDeliveryPoint || !condensed"
      :allow-multiline="!condensed"
      :readonly="!getDeliveryPointRedirect || readonly"
      :theme="computedTheme"
      :to="getDeliveryDateRedirect"
      :tooltip="!this.computedGDD?.timeSlot ? 'Votre précédent jour de retrait n\'est plus disponible' : undefined"
      class="rounded-l-none"
      is-rounded
      tooltip-position="bottom-left">
      <template #icon>
        <icon-calendar class="text-british-racing" />
      </template>

      <span
        class="text-xs.5"
        v-html="ucfirst(getDeliveryDateMsg)" />
    </component>

    <potager-navigator
      v-if="getNote && !condensed"
      readonly>
      Remarque

      <template #icon>
        <icon-information-circle class="text-british-racing" />
      </template>

      <potager-info>
        <collapsible-ellipsis>
          <span v-html="getNote" />
        </collapsible-ellipsis>
      </potager-info>
    </potager-navigator>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { ucfirst } from 'Filters';

import { getDlpDetailsRoute, getDlpSearchRoute } from 'Classes/DlpRouteTools';
import { CONTEXT_SUBSCRIPTION, CONTEXT_USER } from 'Classes/Constants';
import {
  DELIVERY_HOME_TYPE,
  DELIVERY_PRIVATE_TYPE,
  DELIVERY_PUBLIC_TYPE,
} from 'Classes/workflow/TunnelTypesConstants';
import { getFormattedDay } from 'Classes/DlpTools';

import PotagerNavigator from 'UI/PotagerNavigator';
import PotagerInfo from 'UI/PotagerInfo';
import PotagerButton from 'UI/PotagerButton';

import CollapsibleEllipsis from 'Components/collapsible/CollapsibleEllipsis';

import IconCalendar from 'Icons/bold/IconCalendar';
import IconInformationCircle from 'Icons/bold/IconInformationCircle';
import IconHome from 'Icons/bold/IconHome';
import IconTwoPinMarker from 'Icons/bold/IconTwoPinMarker';

export default {
  components: {
    CollapsibleEllipsis,
    IconCalendar,
    IconHome,
    IconInformationCircle,
    IconTwoPinMarker,
    PotagerButton,
    PotagerInfo,
    PotagerNavigator,
  },

  props: {
    condensed: {
      type: Boolean,
      required: false,
      default: false,
    },
    context: {
      type: String,
      required: false,
      default: CONTEXT_USER,
    },
    deliveryPoint: {
      type: Object,
      default: undefined,
    },
    groupDeliveryDay: {
      type: Object,
      required: false,
      default: undefined,
    },
    order: {
      type: Object,
      required: false,
      default: undefined,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    theme: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  computed: {
    ...mapGetters('session', [
      'getAddress',
    ]),
    ...mapGetters('basket', [
      'getDeliveryPoint',
      'getSelectedGdd',
    ]),
    ...mapGetters('user', [
      'getSubscriptionDeliveryPoint',
      'getSubscriptionGroupDeliveryDay',
    ]),
    computedTheme() {
      if (this.condensed) {
        return this.computedDeliveryPoint ? (this.theme || 'bianca') : 'go-green';
      } else {
        return this.theme || 'white';
      }
    },
    getDeliveryPointRedirect() {
      return getDlpSearchRoute({
        context: this.context,
        orderId: this.order?.id
      }, this.$potagerRoute);
    },
    getType() {
      if (!this.computedDeliveryPoint) return undefined;

      const {
        isHomeDelivery,
        private: isPrivate,
      } = this.computedDeliveryPoint;

      if (isHomeDelivery) {
        return DELIVERY_HOME_TYPE;
      }
      if (isPrivate) {
        return DELIVERY_PRIVATE_TYPE;
      }
      return DELIVERY_PUBLIC_TYPE;
    },
    getDeliveryDateRedirect() {
      return getDlpDetailsRoute({
        context: this.context,
        type: this.getType,
        deliveryPoint: this.computedDeliveryPoint,
        orderId: this.order?.id,
        query: {
          onlyGDD: true,
        },
      }, this.$potagerRoute);
    },
    getDeliveryDateMsg() {
      if (!this.computedGDD?.timeSlot) return this.condensed ? 'Indisponible' : 'Jour de retrait indisponible';
      return getFormattedDay(this.computedGDD, this.context, this.condensed, !this.condensed);
    },
    getNote() {
      const prNote = this.computedDeliveryPoint?.note || '';
      const tsNote = this.computedGDD?.timeSlot?.note || this.computedGDD?.note || '';
      return `${prNote}${prNote && tsNote ? '<br>' : ''}${tsNote}`;
    },
    isHomeDelivery() {
      return this.computedDeliveryPoint?.isHomeDelivery;
    },
    homeDeliveryAddress() {
      return this.getAddress(this.context);
    },
    deliveryDisplayedName() {
      if (!this.computedDeliveryPoint) return 'Choisir un point de livraison';
      const {
        isHomeDelivery,
        groupName
      } = this.computedDeliveryPoint;
      return isHomeDelivery ? 'Livraison à domicile' : groupName;
    },
    deliveryDisplayedAddress() {
      if (!this.computedDeliveryPoint) return undefined;

      const {
        isHomeDelivery,
        groupDeliveryAddress
      } = this.computedDeliveryPoint;

      if (isHomeDelivery) {
        if (this.homeDeliveryAddress) {
          return `${this.homeDeliveryAddress.streetNumber || ''} ${this.homeDeliveryAddress.streetName} - ${this.homeDeliveryAddress.postalCode} ${this.homeDeliveryAddress.city}`;
        }
        return 'Veuillez compléter votre adresse';
      }

      return groupDeliveryAddress;
    },
    computedDeliveryPoint() {
      const deliveryPoint = this.context === CONTEXT_SUBSCRIPTION ? this.getSubscriptionDeliveryPoint : this.getDeliveryPoint;
      return this.deliveryPoint || this.order?.deliveryPoint || deliveryPoint;
    },
    computedGDD() {
      // in some cases (mainly public holidays), the timeslot of the gdd is not good
      // so we must only use the timeSlot at the root
      const orderGdd = this.order ? {
        timeSlot: this.order?.timeSlot,
      } : undefined;
      const groupDeliveryDay = this.context === CONTEXT_SUBSCRIPTION ? this.getSubscriptionGroupDeliveryDay : this.getSelectedGdd;

      return this.groupDeliveryDay || orderGdd || groupDeliveryDay;
    },
  },

  methods: {
    ucfirst,
  },
};
</script>

<style lang="scss">
.dlp-resume {
  &--condensed {
    @apply flex items-center mx-auto justify-center;
    @apply whitespace-nowrap;
    max-width: min(100%, 360px);

    > * {
      flex: 0;

      .potager-button__text {
        @apply text-xs.5;
      }

      &.dlp-resume__delivery-point--selected {
        @apply w-full;

        .potager-button__text {
          @apply pr-2 border-r border-white-rock;
        }

        + * {
          @apply pl-2;
        }
      }
    }
  }
}
</style>
