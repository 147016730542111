<template>
  <div :class="['home-page-dashboard-closed']">
    <potager-container
      v-if="isClosedStoreAndRegion"
      class="pt-16 mb-18">
      <h2
        class="dashboard__section__title max-w-6/12 mx-auto block"
        v-html="getClosedRegionInfos.title" />

      <p
        class="dashboard__section__subtitle max-w-6/12 mx-auto font-bold"
        v-html="getClosedRegionInfos.subtitle" />

      <p
        v-for="(content, index) of getClosedRegionInfos.description"
        :key="`closed-region-description-${index}`"
        class="dashboard__section__subtitle max-w-6/12 mx-auto"
        v-html="content" />

      <p
        v-for="(content, index) of getClosedRegionInfos.suffix"
        :key="`closed-region-suffix-${index}`"
        class="dashboard__section__subtitle max-w-6/12 mx-auto"
        v-html="content" />
    </potager-container>

    <potager-container
      v-else
      :class="['text-center pt-16 mb-18']">
      <template v-if="getClosedRegionInfos">
        <h2
          class="dashboard__section__title mb-2"
          v-html="getClosedRegionInfos.title" />

        <div class="dashboard__section__subtitle max-w-10/12 mx-auto">
          <p
            class="mb-6"
            v-html="getClosedRegionInfos.subtitle" />

          <p
            v-for="(content, index) of getClosedRegionInfos.description"
            :key="`closed-region-description-${index}`"
            class="mb-6"
            v-html="content" />

          <p
            v-for="(content, index) of getClosedRegionInfos.suffix"
            :key="`closed-region-suffix-${index}`"
            class="mb-2"
            v-html="content" />
        </div>
      </template>

      <template v-else>
        <h2 class="dashboard__section__title">
          Notre boutique est fermée, connectez-vous la semaine prochaine&nbsp;<span class="font-normal">😊</span>
        </h2>

        <div class="dashboard__section__subtitle max-w-10/12 mx-auto">
          Votre prochaine commande ne sera pas livrée dans votre point de retrait.
          Les livraisons reprendront normalement dès la semaine prochaine. Vous pourrez retrouver&nbsp;:
        </div>
      </template>

      <concept-how-it-works-dashboard
        v-if="!onlyMessage"
        class="mt-20" />
    </potager-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerContainer from 'UI/PotagerContainer';

import COLORS from 'Settings/colors';

import ConceptHowItWorksDashboard from 'Components/concept/ConceptHowItWorksDashboard';

const { bianca } = COLORS;

export default {
  name: 'DashboardClosedPage',

  components: {
    ConceptHowItWorksDashboard,
    PotagerContainer
  },

  props: {
    onlyMessage: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    colors: { bianca },
  }),

  computed: {
    ...mapGetters('user', ['getRegion']),
    ...mapGetters('dashboard', ['isClosedStoreAndRegion']),
    ...mapGetters('session', ['getRegionId', 'getClosedRegionInfos']),
  },
};
</script>
