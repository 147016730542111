export const getUrlAttributes = (url) => {
  try {
    return new URL(url);
  } catch (_) {
    return false;
  }
};

export const isHttpUrl = (url) => {
  const urlObj = getUrlAttributes(url);
  return urlObj?.protocol === 'http:' || urlObj?.protocol === 'https:';
};

export const getUrlDomain = (url) => {
  const urlObj = getUrlAttributes(url);
  return urlObj?.hostname.replace('www.', '');
};

export const isSameDomain = (url) => url && (!isHttpUrl(url) || getUrlDomain(window.location.href) === getUrlDomain(url));

export const isExternalLink = (url) => isHttpUrl(url) && !isSameDomain(url);
