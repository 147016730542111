<template>
  <tunnel-body class="text-center">
    <h1 class="text-xl font-extrabold mb-3 mx-10">
      L’abonnement
    </h1>

    <p class="mb-3">
      Votre panier de fruits & légumes pour la semaine&nbsp;! Récoltez les fruits de votre fidélité avec l’abonnement.
    </p>

    <potager-button
      :to="getNextStepRoute()"
      class="mb-10"
      theme="mango-tango">
      C'est parti&nbsp;!
    </potager-button>

    <div class="text-left p-12 rounded-xl bg-sandy-beach">
      <subscriber-benefits-list />
    </div>
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';

import MetaInfosService from 'Classes/services/MetaInfoService';

import GtmMixin from 'Mixins/GtmMixin';

import PotagerButton from 'UI/PotagerButton';

import TunnelBody from 'Components/tunnel/TunnelBody';
import SubscriberBenefitsList from 'Components/subscriberBenefits/SubscriberBenefitsList';

export default {
  mixins: [
    GtmMixin,
  ],

  components: {
    SubscriberBenefitsList,
    PotagerButton,
    TunnelBody,
  },

  computed: {
    ...mapGetters('tunnel', [
      'getNextStepRoute',
    ]),
  },

  head: MetaInfosService.generate({
    title: 'Introduction au tunnel d\'inscription',
  }),
};
</script>
