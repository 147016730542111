import { CONTEXT_BASKET, POPIN_ERROR_EVENT } from 'Classes/Constants';
import WordingTools from 'Classes/WordingTools';
import { getDlpSearchRoute } from 'Classes/DlpRouteTools';

import store from 'Stores';

export const BT005_BASKET_ERROR = {
  eventType: POPIN_ERROR_EVENT,
  title: 'Avertissement',
  text: 'Il n\'est pas encore possible de commander sur ce jour de livraison.',
  closeBtnText: 'Modifier mon point de retrait',
  onCloseRedirect: getDlpSearchRoute(),
  showCancel: true,
  handle: (app, response, error) => {
    const gdd = store.getters['basket/getGroupDeliveryDay'];
    const text = WordingTools.gddError(CONTEXT_BASKET, gdd);
    if (text) error.text = text;
  },
};

export default {
  default: {
    text: 'Le jour de livraison pour cette commande n\'existe pas.',
  },
  'TemporaryCustomerApi.addBox': BT005_BASKET_ERROR,
  'UserApi.addBox': BT005_BASKET_ERROR,
  'TemporaryCustomerApi.setBasketDeliveryPoint': BT005_BASKET_ERROR,
  'UserApi.setBasketDeliveryPoint': BT005_BASKET_ERROR,
  'PaymentApi.payBasketByPaiementMethods': BT005_BASKET_ERROR,
  'PaymentApi.tryFreePaymentBasket': BT005_BASKET_ERROR,
};
