<template>
  <potager-form
    v-if="!isStreetListLoading"
    id="dlp-form-home"
    ref="form"
    @onSubmit="onSubmit">
    <potager-row>
      <potager-col
        :bp1280="cols"
        bp768="12"
        class="mb-1">
        <potager-input
          v-model="lastname"
          :constraints="[
            { constraint: 'required', field: 'address-last-name' },
          ]"
          :disabled="isLoading"
          autocomplete="family-name"
          external-label
          label="Nom*"
          name="address-last-name"
          no-placeholder />
      </potager-col>

      <potager-col
        :bp1280="cols"
        bp768="12"
        class="mb-1">
        <potager-input
          v-model="firstname"
          :constraints="[
            { constraint: 'required', field: 'address-first-name' },
          ]"
          :disabled="isLoading"
          autocomplete="given-name"
          external-label
          label="Prénom*"
          name="address-first-name"
          no-placeholder />
      </potager-col>
    </potager-row>

    <potager-row>
      <potager-col
        :bp1280="cols"
        bp768="12"
        class="mb-1">
        <potager-input
          v-model="company"
          :disabled="isLoading"
          autocomplete="organization"
          external-label
          label="Société (facultatif)"
          name="address-company"
          no-placeholder />
      </potager-col>

      <potager-col
        :bp1280="cols"
        bp768="12"
        class="mb-1">
        <potager-input
          v-model="phone"
          :constraints="[
            { constraint: 'phone', field: 'phone' },
            { constraint: 'required', field: 'phone'},
          ]"
          :disabled="isLoading"
          autocomplete="tel"
          external-label
          label="Téléphone portable*"
          name="phone"
          no-placeholder
          type="tel" />
      </potager-col>
    </potager-row>

    <potager-row>
      <potager-col
        bp768="4"
        class="mb-1"
        col="3">
        <potager-input
          v-model="streetNumber"
          :constraints="houseNumberConstraint"
          :disabled="isLoading"
          external-label
          label="N° de rue"
          name="address-house-number"
          no-placeholder />
      </potager-col>

      <potager-col
        bp768="8"
        class="mb-1"
        col="9">
        <potager-input
          v-model="streetName"
          :constraints="streetNameConstraint"
          :disabled="isLoading"
          :options="getStreetList?.map(({ street }) => capitalize(street.toLowerCase()))"
          external-label
          label="Nom de rue*"
          name="address-street-name"
          no-placeholder />
      </potager-col>
    </potager-row>

    <potager-row>
      <potager-col
        :bp1280="colsPostalcode"
        bp768="12"
        class="mb-1">
        <potager-input
          v-model="postalCode"
          :constraints="[
            { constraint: 'required', field: 'postal-code' },
          ]"
          :disabled="isLoading"
          autocomplete="postal-code"
          external-label
          label="Code postal*"
          name="address-postal-code"
          no-placeholder
          readonly />
      </potager-col>

      <potager-col
        :bp1280="colscity"
        bp768="12"
        class="mb-1">
        <potager-input
          v-model="city"
          :constraints="[
            { constraint: 'required', field: 'city' },
          ]"
          :disabled="isLoading"
          autocomplete="home city"
          external-label
          label="Ville*"
          name="city"
          placeholder=""
          readonly
          truncate
          @click.stop="$router.push(backRoute)">
          <template #action>
            <potager-button
              :to="backRoute"
              is-square
              size="small"
              theme="white-rock"
              type="button">
              <template #icon>
                <icon-pencil2 class="text-sm" />
              </template>
            </potager-button>
          </template>
        </potager-input>
      </potager-col>
    </potager-row>

    <potager-row>
      <potager-col
        bp1280="12"
        bp768="12"
        class="mb-1">
        <potager-input
          v-model="comment"
          :disabled="isLoading"
          external-label
          label="Instructions de livraison"
          name="address-comment"
          placeholder="Bâtiment, étage, code, porte…"
          rows="3"
          textarea />
      </potager-col>
    </potager-row>

    <potager-row>
      <potager-col
        bp1280="12"
        bp768="12"
        class="mb-1">
        <potager-input
          v-model="addressNote"
          :disabled="isLoading"
          external-label
          label="Recommandation en cas d’absence"
          name="address-note"
          placeholder="Déposer chez Mme Rihannanas…"
          rows="3"
          textarea />
      </potager-col>
    </potager-row>

    <potager-row>
      <potager-col
        class="mb-1 text-center"
        col="12">
        <potager-button
          :is-loading="isLoading"
          class="potager-button--tunnel"
          full-width
          theme="go-green"
          type="submit">
          Continuer
        </potager-button>
      </potager-col>
    </potager-row>
  </potager-form>

  <div v-else>
    <icon-ptcy-loader-spinner class="text-4xl mx-auto block" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LOAD_STREET_LIST_ACTION, FETCH_ADDRESS_ELIGIBILITY_LAD } from 'Stores/types/homeDeliveryActionsTypes';

import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerForm from 'UI/Form/PotagerForm';
import PotagerInput from 'UI/Form/PotagerInput';
import PotagerButton from 'UI/PotagerButton';

import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';
import IconPencil2 from 'Icons/bold/IconPencil2';

import WordingTools from 'Classes/WordingTools';

import ModalInfo from 'Modals/ModalInfo';

export default {
  components: {
    PotagerButton,
    PotagerRow,
    PotagerCol,
    PotagerForm,
    PotagerInput,
    IconPtcyLoaderSpinner,
    IconPencil2,
  },

  emits: ['onSubmit'],

  data: () => ({
    lastname: '',
    firstname: '',
    company: '',
    phone: '',
    postalCode: '',
    streetNumber: '',
    streetName: '',
    city: '',
    comment: '',
    addressNote: '',
  }),

  props: {
    inPanel: {
      type: Boolean,
      required: false,
      default: false,
    },
    backRoute: {
      type: [String, Object],
      required: false,
      default: undefined,
    },
  },

  computed: {
    ...mapGetters('user', [
      'getFirstName',
      'getLastName',
      'getPhone',
      'getSubscriptionAddress',
      'getOrderById',
      'getAddresses',
    ]),
    ...mapGetters('session', [
      'isLoggedIn',
      'getAddress',
    ]),
    ...mapGetters('temporaryCustomer', {
      getTemporaryFirstName: 'getFirstName',
      getTemporaryLastName: 'getLastName',
      getTemporaryPhone: 'getPhone',
    }),
    ...mapGetters('homeDelivery', [
      'getPostalCode',
      'getStreetList',
    ]),
    isLoading() {
      return this.$wait.is([FETCH_ADDRESS_ELIGIBILITY_LAD]);
    },
    isStreetListLoading() {
      return !this.getStreetList && this.$wait.is([
        LOAD_STREET_LIST_ACTION,
      ]);
    },
    cols() {
      return this.inPanel ? '12' : '6';
    },
    colsPostalcode() {
      return this.inPanel ? '12' : '3';
    },
    colscity() {
      return this.inPanel ? '12' : '9';
    },
    houseNumberConstraint() {
      const constraint = [
        // { constraint: 'required', field: 'address-house-number' },
      ];

      if (this.streetName) {
        if (this.getStreetList && this.getStreetList.length > 0) {
          const street = this.getStreetList.find((str) => str.street.toLowerCase() === this.streetName.toLowerCase());

          if (street?.numberStreetMax) {
            constraint.push({
              constraint: 'number',
              field: 'address-house-number',
              options: {
                min: 1,
                max: street.numberStreetMax,
                message: 'Nous ne livrons pas à ce numéro.'
              }
            });
          }
        }
      }

      return constraint;
    },
    streetNameConstraint() {
      const constraint = [
        {
          constraint: 'required',
          field: 'address-street-name'
        },
      ];

      if (this.getStreetList && this.getStreetList.length > 0) {
        constraint.push(
          {
            constraint: 'datalist',
            field: 'address-street-name',
            options: {
              values: this.getStreetList.map(({ street }) => street),
              message: 'Ce nom de rue n\'est pas valide.'
            }
          },
        );
      }

      return constraint;
    },
    addressFormData() {
      const {
        firstname,
        lastname,
        phone,
        company,
        postalCode,
        streetNumber,
        streetName,
        city,
        comment,
        addressNote,
      } = this;

      return {
        firstname,
        lastname,
        company,
        phone,
        postalCode,
        streetName: `${streetNumber} ${this.capitalize(streetName)}`, // need BO improvement to split street number and street name
        city,
        comment,
        addressNote,
      };
    },
  },

  watch: {
    addressFormData(addressFormData) {
      this.$emit('onChange', addressFormData);
    },
  },

  methods: {
    ...mapActions('homeDelivery', [
      LOAD_STREET_LIST_ACTION,
      FETCH_ADDRESS_ELIGIBILITY_LAD,
    ]),
    capitalize(str) {
      return WordingTools.capitalize(str);
    },
    onSubmit() {
      this.FETCH_ADDRESS_ELIGIBILITY_LAD({
        address: this.addressFormData,
      })
        .then((resp) => {
          this.$emit('onSubmit', resp);
        })
        .catch((error) => {
          this.$modal.open(ModalInfo, {
            title: 'Oops !',
            text: error.message,
          });
        });
    },
  },

  created() {
    // address autocomplete restriction
    if (this.getPostalCode) {
      this.postalCode = this.getPostalCode.postalCode;
      this.city = this.getPostalCode.city;

      this.LOAD_STREET_LIST_ACTION(this.getPostalCode.postalCode);
    } else {
      this.$router.push(this.backRoute);
    }

    // form prefill
    const address = this.getAddress(this.context)
      || this.getAddresses.findLast((addr) => addr.postalCode === this.postalCode.toString());

    if (!address) {
      this.firstname = this.getFirstName || this.getTemporaryFirstName;
      this.lastname = this.getLastName || this.getTemporaryLastName;
      this.phone = this.getPhone || this.getTemporaryPhone;
    } else {
      this.firstname = address.firstname;
      this.lastname = address.lastname;
      this.phone = address.phone;

      this.company = address.company;
      this.addressNote = address.note;

      const comment = address.comment?.split('//');
      if (comment) {
        if (comment.length > 1) {
          this.comment = comment[0];
          this.addressNote = comment[1];
        } else {
          this.comment = comment[0];
        }
      }

      const street = address.streetName?.split(' ');
      if (street) {
        // if street[0] contains a number, it's the street number
        // it can be a number with a letter (ex: 12B)
        if (!street[0].match(/^\d/)) {
          this.streetName = address.streetName;
          this.streetNumber = address.streetNumber;
        } else {
          this.streetNumber = street[0];
          this.streetName = street.slice(1)
            .join(' ');
        }
      }
    }
  },
};
</script>
