import Filters from 'Filters';
import PriceTools from 'Classes/PricesTools';

import ModalUnusualDeliveryPoint from 'Modals/ModalUnusualDeliveryPoint';
import { couponUnit } from 'Classes/CouponsMapping';

export const getOrderLine = (order) => (order ? {
  label: 'Votre commande',
  value: `${Filters.priceFilter(PriceTools.getSubTotalOrder(order))}€`,
} : null);

export const getCompletionOrderLine = (order) => (order ? {
  label: 'Votre panier en cours',
  value: `${Filters.priceFilter(PriceTools.getSubTotalOrder(order))}€`,
} : null);

export const getPreviousOrderLine = (order) => (order ? {
  label: 'Votre commande en cours',
  value: `${Filters.priceFilter(PriceTools.getSubTotalOrder(order))}€`,
} : null);

export const getShippingCostLine = (order) => {
  const cost = PriceTools.getShippingCostOrder(order);
  const isFree = !cost && order?.deliveryPoint?.hasShippingCost;

  return {
    label: 'Livraison',
    value: isFree ? 'Offerte' : (cost ? `${Filters.priceFilter(cost)}€` : 'Offerte'),
    type: isFree || !cost ? 'discount' : undefined,
  };
};

export const getEntrepriseDiscountLine = (order) => {
  const discount = PriceTools.getSubscriptionEnterpriseDiscount(order);

  return discount && discount !== '0.00' ? {
    label: 'Remise entreprise',
    value: `-${Filters.priceFilter(discount)}€`,
    type: 'discount',
  } : null;
};

export const getCouponsListLines = (order) => {
  const { coupons } = order;
  return coupons
    .map((coupon) => ({
      label: coupon.couponCode,
      value: couponUnit(coupon),
      type: 'discount',
    }));
};

export const getCouponsTotalLine = (order) => {
  const coupons = PriceTools.getCouponsTotalOrder(order);

  return coupons ? {
    label: Filters.pluralize(order.coupons.length, 'Coupon', null, false),
    value: `-${Filters.priceFilter(coupons)}€`,
    type: 'discount',
  } : null;
};

export const getTotalLine = (order) => {
  const total = PriceTools.getTotalOrder(order);

  return {
    label: 'Total',
    value: `${Filters.priceFilter(total)}€`,
    type: 'total',
  };
};

export const getReceiptLines = (order, previousOrder, completionOrder) => {
  // To display the details of an order, only the 'order' attribute is required.
  // For completing an order, 'previousOrder' and 'completionOrder' are also needed,
  // where 'order' is the combination of 'previousOrder' + 'completionOrder'.

  let lines = [];
  if (previousOrder && completionOrder) {
    lines = [getCompletionOrderLine(completionOrder), getPreviousOrderLine(previousOrder),];
  } else {
    lines = [getOrderLine(order),];
  }

  return [...lines, getShippingCostLine(order), getEntrepriseDiscountLine(order)].filter((line) => line);
};

export default {
  getReceiptLines,
};
