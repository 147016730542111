import store from 'Stores';

import { SET_TYPE, SET_CURRENT_INDEX } from 'Stores/types/tunnelMutationsTypes';

import { ORDER_ALC_TYPE, ORDER_TYPE } from 'Classes/workflow/TunnelTypesConstants';

export default async function (to, from, next) {
  if (!store.getters['tunnel/getFlattenSteps']) await store.dispatch('tunnel/init');

  /* Define variables */
  const routeBasedStep = store.getters['tunnel/getFlattenSteps']
    .find((step) => step.route === to.name);
  const routeBasedStepIndex = routeBasedStep ? routeBasedStep.index : null;
  const filteredRouteBasedStep = store.getters['tunnel/getAccessibleFlattenSteps']()
    .find((step) => step.route === to.name);
  let filteredRouteBasedStepIndex = filteredRouteBasedStep?.index;

  const fromRouteBasedStep = store.getters['tunnel/getFlattenSteps']
    .find((step) => step.route === from.name);
  const fromRouteBasedStepIndex = fromRouteBasedStep ? fromRouteBasedStep.index : null;
  // set the current step index based on the current route
  store.commit(`tunnel/${SET_CURRENT_INDEX}`, routeBasedStepIndex);

  // set debug mode
  const debugMode = routeBasedStep?.debugGuard || fromRouteBasedStep?.debugGuard;

  /* START Define type */
  // If we go forward in the tunnel,
  // Or if the 'from' step is not a tunnel step (like we come from the dashboard)
  if (fromRouteBasedStepIndex < routeBasedStepIndex
    || (!fromRouteBasedStep || !fromRouteBasedStep.name.includes('tunnel'))) {
    // If the new step needs a specific type (so isn't in the filtered steps),
    // we set the type to the type of the new step
    if (routeBasedStepIndex && !filteredRouteBasedStepIndex) {
      routeBasedStep.constraints
        .filter((constraint) => constraint.type === 'type')
        .forEach((constraint) => {
          store.commit(`tunnel/${SET_TYPE}`, {
            name: constraint.name,
            value: constraint.value
          });
        });
    }
  }

  if (to.params.types?.length) {
    to.params.types
      .map((type) => ({
        name: Object.entries(type)[0][0],
        value: Object.entries(type)[0][1]
      }))
      .forEach((type) => {
        store.commit(`tunnel/${SET_TYPE}`, {
          name: type.name,
          value: type.value
        });
      });
  }
  /* END Define type */

  // If orderType ALC : we change the route with the /sans-abonnement alias
  let newRoute;

  const isOrderTypeAlc = store.getters['tunnel/getTypeValue'](ORDER_TYPE) === ORDER_ALC_TYPE;
  // currently, we're doing it only on success page for tracking
  // TODO : do it on all steps
  const alcRoutes = ['tunnel-subscription-success'];

  if (isOrderTypeAlc && alcRoutes.includes(to.name)) {
    if (!to.path.includes('/sans-abonnement')) {
      newRoute = to.fullPath.replace(to.matched[0].path, to.matched[0].alias);
    }
  } else if (to.path.includes('/sans-abonnement') && alcRoutes.includes(from.name) && !alcRoutes.includes(to.name)) {
    newRoute = to.fullPath.replace(to.matched[0].alias, to.matched[0].path);
  }

  // reload the filtered steps after setting the type
  filteredRouteBasedStepIndex = store.getters['tunnel/getAccessibleFlattenSteps']()
    .find((step) => step.route === to.name)?.index;
  let prevAccessibleStepRoute = store.getters['tunnel/getPrevStepRoute']();

  // if deliveryPoint saved, pass params to the next/prev step
  const deliveryPoint = store.getters['subscriptionBasket/getDeliveryPoint'];
  if (deliveryPoint) {
    const dlpParams = {
      deliveryPointRegionId: deliveryPoint?.regionId,
      deliveryPointId: deliveryPoint?.id,
    };
    if (newRoute) newRoute.params = { ...dlpParams, ...newRoute.params };
    if (prevAccessibleStepRoute) prevAccessibleStepRoute.params = { ...dlpParams, ...prevAccessibleStepRoute.params };
  }

  /* next */
  // !== undefined because 0 is a valid index
  if (filteredRouteBasedStepIndex !== undefined) {
    // if the step is accessible, we set it as the current step
    if (debugMode) {
      console.info('Tunnel: Accessible step');
      console.info('Tunnel: Next step is', to.name, 'from', from.name);
      console.info('^GUARD^', '-'.repeat(80));
    }

    if (newRoute) {
      console.info('TunnelWorkflowGuard newRoute > Redirecting to', newRoute);
      next(newRoute);
    } else {
      console.info('TunnelWorkflowGuard > next');
      next();
    }
  } else if (prevAccessibleStepRoute) {
    // if the step is not accessible, we redirect to the previous accessible step
    if (debugMode) {
      console.info('Tunnel: Inaccessible step');
      console.info('Tunnel: Redirecting to', prevAccessibleStepRoute.name, 'from', from.name, from);
      console.info('Tunnel: wanted step was', to.name, to);
      console.info('^GUARD^', '-'.repeat(80));
    }

    console.info('TunnelWorkflowGuard prevAccessibleStepRoute > Redirecting to', prevAccessibleStepRoute);
    next(prevAccessibleStepRoute);
  } else {
    // if the step is not found, we redirect to the first step
    if (debugMode) {
      console.info('Tunnel: Inaccessible step');
      console.info('Tunnel: Redirecting to first step', store.getters['tunnel/getFirstStepRoute'](), 'from', from.name);
      console.info('Tunnel: wanted step was', to);
      console.info('^GUARD^', '-'.repeat(80));
    }

    console.info('TunnelWorkflowGuard getFirstStepRoute > Redirecting to', store.getters['tunnel/getFirstStepRoute']());
    next(store.getters['tunnel/getFirstStepRoute']());
  }
}
