<template>
  <tunnel-body class="text-center">
    <h1 class="border-white-rock border-0 border-b border-solid mb-6 text-xl font-extrabold">
      Félicitations
    </h1>

    <p class="mb-10">
      <template v-if="isSubscriptionActive">
        <p class="font-bold">
          👍&nbsp;Votre abonnement est activé&nbsp;!
        </p>

        <p class="mb-3">
          Votre première commande a été créée et sera renouvelée chaque semaine.
        </p>
      </template>

      <template v-else>
        <p class="font-bold">
          👍&nbsp;Votre compte a bien été créé&nbsp;!
        </p>

        <p class="mb-3">
          Vous pouvez accéder à notre boutique.
        </p>
      </template>

      <potager-button
        :to="getNextStepRoute()"
        class="mx-auto"
        theme="go-green">
        Découvrir la boutique
      </potager-button>
    </p>

    <template v-if="!isInAppWebView">
      <div class="mb-6 text-center">
        <p :class="['text-lg font-bold mb-2']">
          Testez notre application
        </p>

        <p class="mb-6">
          Créez, complétez puis suivez l’état de vos commandes depuis votre smartphone.
        </p>

        <potager-app-button
          theme="stroke"
          class="inline-block"
          store="apple" />

        <potager-app-button
          theme="stroke"
          class="inline-block"
          store="google" />
      </div>
    </template>

    <potager-picture
      src="phone-dashboard.png"
      class="tunnel-success__phone w-full mx-auto -mb-8" />
  </tunnel-body>
</template>

<script>
import MobileAppMixin from 'Mixins/MobileAppMixin';
import GtmMixin from 'Mixins/GtmMixin';

import PotagerButton from 'UI/PotagerButton';
import PotagerAppButton from 'UI/PotagerAppButton';
import PotagerPicture from 'UI/PotagerPicture';

import TunnelBody from 'Components/tunnel/TunnelBody';

import MetaInfosService from 'Classes/services/MetaInfoService';

import { mapGetters } from 'vuex';
import { CONTEXT_SUBSCRIPTION_BASKET } from 'Classes/Constants';
import { inAppWebView } from 'Classes/Tools';

export default {
  props: {
    context: {
      type: String,
      default: CONTEXT_SUBSCRIPTION_BASKET,
    },
  },

  mixins: [
    MobileAppMixin,
    GtmMixin,
  ],

  components: {
    PotagerPicture,
    PotagerButton,
    PotagerAppButton,
    TunnelBody,
  },

  computed: {
    ...mapGetters('tunnel', [
      'getNextStepRoute',
    ]),
    ...mapGetters('subscriptionBasket', [
      'getOrderCardId',
    ]),
    ...mapGetters('user', [
      'getUser',
      'getSubscription',
      'getMySubscriptionOrder',
      'isSubscriptionActive',
    ]),
    isInAppWebView() {
      return inAppWebView();
    },
  },

  mounted() {
    this.trackPurchaseSuccess(this.getMySubscriptionOrder, this.getUser, this.getSubscription, this.getOrderCardId);
  },

  head: MetaInfosService.generate({
    title: 'Votre abonnement a été activé',
  }),
};
</script>

<style lang="scss" scoped>
  .tunnel-success {
    &__phone {
      max-width: 375px;
    }
  }
</style>
