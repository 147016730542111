
import FrontErrors from 'Classes/ErrorHandler/ErrorsMapper';

class ApiErrorService {

  overrideErrorsMessages(errors) {
    return errors.filter((error) => {
      // Filter error where front set message at null.
      return (!FrontErrors.hasOwnProperty(error.code) || FrontErrors[error.code] !== null);
    }).map((error) => {
      if (FrontErrors.hasOwnProperty(error.code) && typeof FrontErrors[error.code] === 'string') {
        error.message = FrontErrors[error.code];
      }

      return error;
    });
  }
}

export default new ApiErrorService();
