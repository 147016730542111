<template>
  <potager-modal>
    <template #title>
      <h2 class="text-center">
        <span v-if="getSituation === BASKET_HAVE_LINKED_ORDER">
          Panier lié à une commande en cours
        </span>
        <span v-else-if="getSituation === BASKET_HAVE_DIFFERENT_SUB_GDD">
          Panier avec un lieu de livraison différent
        </span>
      </h2>
    </template>

    <template #body>
      <p v-if="getSituation === BASKET_HAVE_LINKED_ORDER">
        Votre commande en cours (n°{{ getLinkedOrder.number }}) contient
        {{ pluralize(getLinkedOrder.boxes.length, 'élément') }} et sera livrée le
        <b>{{ tmsToFormat(getLinkedOrder.timeSlot.date, ('dd MMMM yyyy')) }}</b>
        à <b>{{ linkedOrderDeliveryName }}</b>.

        <br>
        Lorsque vous validerez ce panier, son contenu sera
        ajouté à votre commande en cours. Ainsi, si vous ajoutez un coupon
        sur ce panier, la réduction s’appliquera également sur la commande.
      </p>

      <p v-else-if="getSituation === BASKET_HAVE_DIFFERENT_SUB_GDD">
        Le lieu et jour de livraison de votre panier sont
        différents de ceux de votre abonnement. Si vous
        validez ce panier, cela génèrera une commande qui
        sera livrée le <b>{{ transformToTextDate(getSelectedGdd) }}</b>
        à <b>{{ deliveryName }}</b>.
      </p>
    </template>
  </potager-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { pluralize, tmsToFormat } from 'Filters';

import PotagerModal from 'UI/PotagerModal';

import { BASKET_HAVE_LINKED_ORDER, BASKET_HAVE_DIFFERENT_SUB_GDD } from 'Classes/Constants';

export default {
  components: {
    PotagerModal,
  },

  data: () => ({
    BASKET_HAVE_LINKED_ORDER,
    BASKET_HAVE_DIFFERENT_SUB_GDD,
  }),

  computed: {
    ...mapGetters('basket', [
      'getLinkedOrder',
      'getSituation',
      'getDeliveryPoint',
      'getSelectedGdd',
      'getBasketAddress',
    ]),
    linkedOrderDeliveryName() {
      const {
        isHomeDelivery,
        groupName
      } = this.getLinkedOrder.deliveryPoint;

      if (isHomeDelivery) {
        const {
          firstname,
          lastname,
          streetAddress,
          postalCode,
          city,
        } = this.getLinkedOrder.address;

        return [
          firstname,
          lastname,
          streetAddress,
          postalCode,
          city,
        ].join(' ');
      }
      return groupName;
    },
    deliveryName() {
      const {
        isHomeDelivery,
        groupName
      } = this.getDeliveryPoint;

      if (isHomeDelivery) {
        const {
          streetName,
          streetAddress,
          postalCode,
          city,
        } = this.getBasketAddress;

        return [
          streetName,
          streetAddress,
          postalCode,
          city,
        ].join(' ');
      }
      return groupName;
    },
  },

  methods: {
    tmsToFormat,
    pluralize,
    transformToTextDate(gdd) {
      return tmsToFormat(gdd.timeSlot.date, 'iiii dd MMMM');
    },
  },

};
</script>
