<template>
  <div :class="['card-v2__body z-10 max-w-full', {'w-1/2': landscape}]">
    <header class="card-v2__body__header">
      <slot />
    </header>

    <footer
      v-if="$slots.footer"
      class="card-v2__body__footer relative">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
export default {
  props: {
    landscape: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-v2__body {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: opacity .3s;

  .card-v2--small & {
    padding: .375rem;
  }

  &__footer {
    @apply mt-2;
  }
}
</style>
