import { POPIN_ERROR_EVENT } from 'Classes/Constants';
import { CONTEXT_BASKET, DELIVERY_FUNCTIONAL_TYPE_HOME } from 'Classes/Constants';
import { getDlpSearchRoute } from 'Classes/DlpRouteTools.js';

export default {
  default: {
    eventType: POPIN_ERROR_EVENT,
    title: 'Avertissement',
    text: 'Vous devez avoir une adresse pour une livraison à domicile.',
    closeBtnText: 'Saisir mon adresse',
    onCloseRedirect: getDlpSearchRoute({
      context: CONTEXT_BASKET,
      type: DELIVERY_FUNCTIONAL_TYPE_HOME,
    }),
    cancelBtnText: 'Annuler',
    showCancel: true,
  }
};

