import COLORS from 'Settings/colors';

const {
  bimpli,
  bimpliAlt,
  bimpliAlt2,
  edenred,
  edenredAlt,
  edenredAlt2,
  pluxee,
  pluxeeAlt,
  pluxeeAlt2,
  swile,
  swileAlt,
  swileAlt2,
  up,
  upAlt,
  upAlt2,
  visa,
  visaAlt,
  visaAlt2,
} = COLORS;

export const PAYMENT_METHODS_TYPES = {
  BANK_CARD: 'bank_card',
  RESTAURANT_CARD: 'restaurant_card',
};

export const PAYMENT_METHODS_INTEGRATIONS = {
  PAYGREEN: 'paygreen',
  STRIPE: 'stripe',
  DIRECT_ACCOUNT: 'direct_account',
};

export default {
  BIMPLI: {
    name: 'bimpli',
    type: PAYMENT_METHODS_TYPES.RESTAURANT_CARD,
    integration: PAYMENT_METHODS_INTEGRATIONS.PAYGREEN,
    issuer: 'natixis-intertitres',
    colors: {
      primary: bimpli,
      secondary: bimpliAlt,
      tertiary: bimpliAlt2,
    },
  },
  EDENRED: {
    name: 'edenred',
    type: PAYMENT_METHODS_TYPES.RESTAURANT_CARD,
    integration: PAYMENT_METHODS_INTEGRATIONS.DIRECT_ACCOUNT,
    label: 'carte Ticket Restaurant® Edenred',
    colors: {
      primary: edenred,
      secondary: edenredAlt,
      tertiary: edenredAlt2,
    },
  },
  PAYGREEN_BANK_CARD: {
    name: 'paygreen_bank_card',
    type: PAYMENT_METHODS_TYPES.BANK_CARD,
    integration: PAYMENT_METHODS_INTEGRATIONS.PAYGREEN,
    issuer: '',
    colors: {
      primary: visa,
      secondary: visaAlt,
      tertiary: visaAlt2,
    },
  },
  PLUXEE: {
    name: 'pluxee',
    alias: 'sodexo',
    type: PAYMENT_METHODS_TYPES.RESTAURANT_CARD,
    integration: PAYMENT_METHODS_INTEGRATIONS.PAYGREEN,
    issuer: 'sodexo-pass-france',
    colors: {
      primary: pluxee,
      secondary: pluxeeAlt,
      tertiary: pluxeeAlt2,
    },
  },
  STRIPE: {
    name: 'stripe',
    type: PAYMENT_METHODS_TYPES.BANK_CARD,
    integration: PAYMENT_METHODS_INTEGRATIONS.STRIPE,
    colors: {
      primary: visa,
      secondary: visaAlt,
      tertiary: visaAlt2,
    },
  },
  SWILE: {
    name: 'swile',
    type: PAYMENT_METHODS_TYPES.RESTAURANT_CARD,
    integration: PAYMENT_METHODS_INTEGRATIONS.DIRECT_ACCOUNT,
    colors: {
      primary: swile,
      secondary: swileAlt,
      tertiary: swileAlt2,
    },
  },
  UP: {
    name: 'up',
    type: PAYMENT_METHODS_TYPES.RESTAURANT_CARD,
    integration: PAYMENT_METHODS_INTEGRATIONS.PAYGREEN,
    issuer: 'up-france-cheque-dejeuner',
    colors: {
      primary: up,
      secondary: upAlt,
      tertiary: upAlt2,
    },
  },
};
