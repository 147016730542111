<template>
  <footer
    v-if="isVisible"
    class="main-footer">
    <newsletter-section
      v-if="!$route?.meta?.hideNewsletter"
      source="Footer" />

    <div class="main-footer__main">
      <potager-container class="main-footer__assurance">
        <potager-row
          class="main-footer__assurance__list"
          tag="ul">
          <potager-col
            bp1024="4"
            bp640="12"
            class="main-footer__assurance__item"
            col="auto"
            tag="li">
            <icon-ecology-plant-hand class="main-footer__assurance__item__icon" />

            <span class="main-footer__assurance__item__text">
              Produits
              <br>
              extra-frais
            </span>
          </potager-col>

          <potager-col
            bp1024="4"
            bp640="12"
            class="main-footer__assurance__item"
            col="auto"
            tag="li">
            <icon-delivery-truck2 class="main-footer__assurance__item__icon" />

            <span class="main-footer__assurance__item__text">
              Livraison
              <br>
              incluse
            </span>
          </potager-col>

          <potager-col
            bp1024="4"
            bp640="12"
            class="main-footer__assurance__item"
            col="auto"
            tag="li">
            <icon-headphones-customer-support-human class="main-footer__assurance__item__icon" />

            <span class="main-footer__assurance__item__text">
              Un service client
              <br>
              à votre écoute
            </span>
          </potager-col>

          <potager-col
            bp1024="4"
            bp640="12"
            class="main-footer__assurance__item"
            col="auto"
            tag="li">
            <icon-credit-card-lock class="main-footer__assurance__item__icon" />

            <span class="main-footer__assurance__item__text">
              Paiement
              <br>
              par CB sécurisé
            </span>
          </potager-col>

          <potager-col
            bp1024="4"
            bp640="12"
            class="main-footer__assurance__item"
            col="auto"
            tag="li">
            <icon-ticket class="main-footer__assurance__item__icon" />

            <span class="main-footer__assurance__item__text">
              Titres restaurants
              <br>
              acceptés
            </span>
          </potager-col>
        </potager-row>
      </potager-container>

      <potager-container class="main-footer__alllinks">
        <potager-row class="main-footer__alllinks__row">
          <potager-col
            bp640="6"
            class="main-footer__alllinks__col"
            col="auto">
            <p class="main-footer__alllinks__title">
              Notre concept
            </p>

            <ul class="main-footer__alllinks__list">
              <li class="main-footer__alllinks__list__item">
                <potager-link
                  :to="{ name: 'who-we-are' }"
                  class="main-footer__alllinks__list__item__link">
                  Notre histoire
                </potager-link>
              </li>

              <li class="main-footer__alllinks__list__item">
                <potager-link
                  :to="{ name: 'manifesto' }"
                  class="main-footer__alllinks__list__item__link">
                  Nos valeurs
                </potager-link>
              </li>

              <li class="main-footer__alllinks__list__item">
                <potager-link
                  :to="{ name: 'delivery-points' }"
                  class="main-footer__alllinks__list__item__link">
                  Nos points relais
                </potager-link>
              </li>
            </ul>
          </potager-col>

          <potager-col
            bp640="6"
            class="main-footer__alllinks__col"
            col="auto">
            <p class="main-footer__alllinks__title">
              Contenus
            </p>

            <ul class="main-footer__alllinks__list">
              <li class="main-footer__alllinks__list__item">
                <potager-link
                  :to="{name: 'content-list'}"
                  class="main-footer__alllinks__list__item__link">
                  Tous les contenus
                </potager-link>
              </li>

              <li class="main-footer__alllinks__list__item">
                <potager-link
                  :to="{name: 'recettes-list'}"
                  class="main-footer__alllinks__list__item__link">
                  Nos recettes
                </potager-link>
              </li>

              <li class="main-footer__alllinks__list__item">
                <potager-link
                  :to="{name: 'suppliers-list'}"
                  class="main-footer__alllinks__list__item__link">
                  Nos producteurs
                </potager-link>
              </li>

              <li class="main-footer__alllinks__list__item">
                <potager-link
                  :to="{name: 'reports-list'}"
                  class="main-footer__alllinks__list__item__link">
                  Nos articles
                </potager-link>
              </li>

              <li class="main-footer__alllinks__list__item">
                <potager-link
                  :to="{name: 'products-list'}"
                  class="main-footer__alllinks__list__item__link">
                  Nos variétés
                </potager-link>
              </li>
            </ul>
          </potager-col>

          <potager-col
            bp640="6"
            class="main-footer__alllinks__col"
            col="auto">
            <div class="main-footer__alllinks__col__sub">
              <p class="main-footer__alllinks__title">
                Offres entreprise
              </p>

              <ul class="main-footer__alllinks__list">
                <li class="main-footer__alllinks__list__item">
                  <potager-link
                    :to="{ name: 'b2b-offers' }"
                    class="main-footer__alllinks__list__item__link">
                    Livraison de corbeilles de fruits en entreprise
                  </potager-link>
                </li>

                <li class="main-footer__alllinks__list__item">
                  <potager-link
                    :to="{ name: 'b2b-box' }"
                    class="main-footer__alllinks__list__item__link">
                    Paniers de fruits et légumes livrés au bureau
                  </potager-link>
                </li>
              </ul>
            </div>
          </potager-col>

          <potager-col
            bp640="6"
            class="main-footer__alllinks__col"
            col="auto">
            <div class="main-footer__alllinks__col__sub">
              <p class="main-footer__alllinks__title">
                Nous rejoindre
              </p>

              <ul class="main-footer__alllinks__list">
                <li class="main-footer__alllinks__list__item">
                  <potager-link
                    :to="{ name: 'devenir-point-de-retrait' }"
                    class="main-footer__alllinks__list__item__link">
                    Devenir point relais
                  </potager-link>
                </li>

                <li class="main-footer__alllinks__list__item">
                  <a
                    class="main-footer__alllinks__list__item__link"
                    href="https://recrutement-potagercity.talentview.io/?source=site_entreprise"
                    target="_blank">Nos métiers</a>
                </li>

                <li class="main-footer__alllinks__list__item">
                  <a
                    class="main-footer__alllinks__list__item__link"
                    href="https://careers.werecruit.io/fr/potager-city"
                    target="_blank">On recrute !</a>
                </li>
              </ul>
            </div>

            <div class="main-footer__alllinks__col__sub">
              <p class="main-footer__alllinks__title">
                Service client
              </p>

              <ul class="main-footer__alllinks__list">
                <li class="main-footer__alllinks__list__item">
                  <a
                    class="main-footer__alllinks__list__item__link"
                    href="https://potagercity.force.com/serviceclient/"
                    target="_blank">Foire aux questions
                  </a>
                </li>

                <li class="main-footer__alllinks__list__item">
                  <a
                    class="main-footer__alllinks__list__item__link"
                    href="https://potagercity.force.com/serviceclient/s/contactsupport"
                    target="_blank">Nous contacter
                  </a>
                </li>
              </ul>
            </div>
          </potager-col>

          <potager-col
            bp1024="12"
            bp1280="auto"
            class="main-footer__alllinks__col main-footer__alllinks__col--centered"
            col="6">
            <p class="main-footer__alllinks__title main-footer__alllinks__title--nomobile">
              Suivez-nous sur
            </p>

            <potager-row
              class="main-footer__social"
              tag="ul">
              <potager-col
                class="main-footer__social__item"
                col="auto"
                tag="li">
                <potager-link
                  aria-label="Facebook"
                  class="main-footer__social__item__link"
                  target="_blank"
                  to="https://www.facebook.com/potagercity/">
                  <icon-social-media-facebook />
                </potager-link>
              </potager-col>

              <potager-col
                class="main-footer__social__item"
                col="auto"
                tag="li">
                <potager-link
                  aria-label="Twitter"
                  class="main-footer__social__item__link"
                  target="_blank"
                  to="https://twitter.com/potagercity">
                  <icon-social-media-twitter />
                </potager-link>
              </potager-col>

              <potager-col
                class="main-footer__social__item"
                col="auto"
                tag="li">
                <potager-link
                  aria-label="Instagram"
                  class="main-footer__social__item__link"
                  target="_blank"
                  to="https://www.instagram.com/potagercity/">
                  <icon-social-instagram />
                </potager-link>
              </potager-col>
            </potager-row>
          </potager-col>

          <potager-col
            bp1024="12"
            bp1280="auto"
            class="main-footer__alllinks__col main-footer__alllinks__col--centered"
            col="6">
            <p class="main-footer__alllinks__title main-footer__alllinks__title--nomobile">
              Téléchargez l’app
            </p>

            <ul class="main-footer__alllinks__list">
              <li class="main-footer__alllinks__list__item main-footer__alllinks__list__item--app">
                <potager-app-button store="google" />
              </li>

              <li class="main-footer__alllinks__list__item main-footer__alllinks__list__item--app">
                <potager-app-button store="apple" />
              </li>
            </ul>
          </potager-col>
        </potager-row>
      </potager-container>

      <potager-container
        class="main-footer__ml-links"
        tag="ul">
        <li
          v-for="link in settingsLinks"
          :key="link.label"
          class="main-footer__ml-links__item">
          <potager-link
            :to="link.route"
            class="main-footer__ml-links__item__link">
            {{ link.shortLabel }}
          </potager-link>
        </li>

        <li class="main-footer__ml-links__item">
          <a
            class="main-footer__ml-links__item__link"
            href="#"
            @click.prevent="toggleCookiesSettings">
            Paramétrer mes cookies
          </a>
        </li>

        <li class="main-footer__ml-links__item main-footer__ml-links__item__link">
          Potager City v{{ version }}
        </li>
      </potager-container>

      <api-switcher v-if="!isProd" />
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

import IconEcologyPlantHand from 'Icons/regular/IconEcologyPlantHand';
import IconDeliveryTruck2 from 'Icons/regular/IconDeliveryTruck2';
import IconHeadphonesCustomerSupportHuman from 'Icons/regular/IconHeadphonesCustomerSupportHuman';
import IconCreditCardLock from 'Icons/regular/IconCreditCardLock';
import IconTicket from 'Icons/regular/IconTicket';

import IconSocialMediaFacebook from 'Icons/bold/IconSocialMediaFacebook';
import IconSocialMediaTwitter from 'Icons/bold/IconSocialMediaTwitter';
import IconSocialInstagram from 'Icons/bold/IconSocialInstagram';

import PotagerContainer from 'UI/PotagerContainer';
import PotagerAppButton from 'UI/PotagerAppButton';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';

import COLORS from 'Settings/colors';

import { settingsLinks } from 'Pages/account/AccountSettingsPage';

import NewsletterSection from 'Components/newsletter/NewsletterSection';
import ApiSwitcher from 'Components/_dev/ApiSwitcher';

import { version } from '../../../package.json';

const { charcoal } = COLORS;

export default {
  components: {
    NewsletterSection,
    ApiSwitcher,
    IconEcologyPlantHand,
    IconDeliveryTruck2,
    IconHeadphonesCustomerSupportHuman,
    IconCreditCardLock,
    IconTicket,
    IconSocialMediaFacebook,
    IconSocialMediaTwitter,
    IconSocialInstagram,
    PotagerContainer,
    PotagerAppButton,
    PotagerRow,
    PotagerCol,
  },

  data: () => ({
    colors: { charcoal },
    email: null,
    name: 'email',
    version,
    settingsLinks,
  }),

  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    isProd() {
      return import.meta.env.VITE_WEB_ENV === 'production';
    },
    isVisible() {
      return this.$route?.meta?.footer !== false;
    },
  },

  methods: {
    toggleCookiesSettings() {
      if (typeof window.OneTrust?.ToggleInfoDisplay === 'function') {
        window.OneTrust.ToggleInfoDisplay();
      }
    },
  },
};
</script>
