<template>
  <div
    v-if="highlightedProducts?.length"
    class="blog__section">
    <h2 class="blog__subtitle">
      {{ !isLoggedIn ? 'Découvrez notre potager' : 'Quoi de bon cette semaine ?' }}
    </h2>

    <potager-row class="content-list__content mb-4">
      <potager-col
        v-for="(product, index) in highlightedProducts"
        :key="index"
        bp1024="4"
        bp640="12"
        class="content-list-page__item bp640:mb-0"
        col="2">
        <card-product
          :box="product"
          :landscape="$mq.bp640" />
      </potager-col>
    </potager-row>

    <p class="cross-selling__see-more">
      <potager-button
        :full-width="$mq.bp1024"
        :to="{ name: 'dashboard'}"
        label="Voir la boutique"
        theme="stroke" />
    </p>
  </div>
</template>

<script>
import { toRaw } from 'vue';

import { mapGetters } from 'vuex';
import { FETCH_DASHBOARD_ACTION } from 'Stores/types/dashboardActionTypes';

import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerButton from 'UI/PotagerButton';
import CardProduct from 'Components/cards/CardProduct';

export default {

  components: {
    CardProduct,
    PotagerButton,
    PotagerRow,
    PotagerCol,
  },

  computed: {
    ...mapGetters('app', [
      'getHighlightedProducts',
    ]),
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    ...mapGetters('dashboard', [
      'getDashboardSections',
      'isLoaded',
    ]),
    highlightedProducts() {
      let products = this.getHighlightedProducts;

      if (this.isLoggedIn) {
        products = this.getDashboardSections[0]?.items
          .filter((item) => item.itemType !== 'thumbnail');
      }

      return toRaw(products) // Avoids reactivity issues (max stack size exceeded)
        .sort(() => Math.random() - 0.5)
        .slice(0, 6);
    },
  },

  created() {
    if (this.isLoggedIn && !this.isLoaded) {
      this.$store.dispatch(`dashboard/${FETCH_DASHBOARD_ACTION}`);
    }
  },
};
</script>
