<template>
  <transition name="fade">
    <div
      v-if="isMaintenance"
      class="maintenance">
      <potager-logo class="mb-4" />

      <p class="text-2xl font-bold">
        Site en cours de maintenance
      </p>

      <div class="text-lg mb-12">
        Nous procédons actuellement à une mise à jour de notre site pour lui
        apporter quelques améliorations et nouveautés. Nous serons de retour
        dans quelques instants.
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
import { FETCH_PUBLIC_ACTION } from 'Stores/types/appActionTypes';
import { stuckPage, unstuckPage } from 'Classes/Tools';

import PotagerLogo from 'UI/PotagerLogo';

export default {

  components: {
    PotagerLogo,
  },

  computed: {
    ...mapGetters('app', [
      'isMaintenance',
    ]),
  },

  watch: {
    isMaintenance: {
      handler(val, oldVal) {
        if (val && !oldVal) {
          this.$nextTick(() => {
            stuckPage();
            this.tryAgain();
          });
        }
        if (!val) {
          this.$nextTick(() => {
            unstuckPage();
          });
        }
      },
      immediate: true,
    },
  },

  methods: {
    tryAgain() {
      setTimeout(() => {
        this.$store.dispatch(`app/${FETCH_PUBLIC_ACTION}`)
          .then(() => {
            document.location.reload();
          })
          .catch(({ response }) => {
            if (response?.status === 503) this.tryAgain();
          });
      }, 60 * 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.maintenance {
  @apply fixed inset-0 flex flex-col items-center justify-center text-center;
  @apply bg-white-rock/80 backdrop-blur p-8;
  z-index: 9999;
}
</style>
