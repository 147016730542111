import { inAppWebView } from 'Classes/Tools';

// Test if a user is logged or not, redirect to home page if not connected
export default function (to, from, next) {
  if (!inAppWebView() && import.meta.env.NODE_ENV === 'production') {
    console.info('WebviewGuard > Redirecting to home page');
    next(to.meta.outsideWebviewRedirect || '/');
  } else {
    next();
  }
}
