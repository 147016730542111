<template>
  <div class="subscription-detail__footer">
    <div class="subscription-detail__footer__container flex items-center justify-center gap-4">
      <template v-if="isOrderCanceledStatus(getSubscriptionOrder)">
        <potager-button
          v-if="getSubscriptionOrder?.isEditable"
          :is-loading="isLoading"
          @onClick="resumeSubscriptionOrder()">
          Reprendre la commande
        </potager-button>

        <p
          v-else
          class="subscription-detail__footer__message">
          Vous avez annulé la commande contenant votre panier en abonnement
        </p>
      </template>

      <p
        v-else-if="getSubscription?.temporaryOrder || isNotYetCreated"
        class="subscription-detail__footer__message">
        Rendez-vous jeudi pour découvrir la compositon de la semaine prochaine
      </p>

      <template v-else-if="getSubscriptionOrder?.isEditable">
        <potager-button
          v-if="!getSubscriptionOrder?.containsSubscriptionBox"
          full-width
          theme="stroke"
          @onClick="tryToDeleteOrderSubscription(getSubscriptionOrder)">
          Annuler <span v-if="!$mq.bp768">ma commande</span>
        </potager-button>

        <potager-button
          :full-width="!getSubscriptionOrder?.containsSubscriptionBox"
          :to="{ name: 'mon-compte_subscription_replace' }"
          theme="stroke">
          Remplacer <span v-if="!$mq.bp768">
            mon panier
          </span>
        </potager-button>
      </template>

      <p
        v-else
        class="subscription-detail__footer__message">
        Votre commande a été validée puis payée et n’est plus modifiable
      </p>
    </div>
  </div>
</template>

<script>
import SubscriptionMixin from 'Mixins/SubscriptionMixin';

import PotagerButton from 'UI/PotagerButton';
import { isOrderCanceledStatus } from 'potagerlogic/dist/Order/Front';

export default {
  name: 'SubscriptionDetailPageFooter',
  methods: { isOrderCanceledStatus },
  mixins: [
    SubscriptionMixin,
  ],
  components: {
    PotagerButton,
  },
  computed: {
    isLoading() {
      return this.$wait.is([
        'CANCEL_ORDER_ACTION',
        `RESUME_ORDER_ACTION_${this.order.id}`,
      ]);
    },
  },
};
</script>
