import { CONTEXT_SUBSCRIPTION, CONTEXT_SUBSCRIPTION_BASKET } from 'Classes/Constants';

export const test = ({
  rootGetters,
  expectedValue
}) => {
  const subscriptionBasketDeliveryPoint = rootGetters['session/getDeliveryPoint'](CONTEXT_SUBSCRIPTION_BASKET);
  const subscriptionDeliveryPoint = rootGetters['session/getDeliveryPoint'](CONTEXT_SUBSCRIPTION);
  const deliveryPoint = subscriptionBasketDeliveryPoint || subscriptionDeliveryPoint;

  if (deliveryPoint?.isHomeDelivery) {
    const address = subscriptionBasketDeliveryPoint
      ? rootGetters['session/getAddress'](CONTEXT_SUBSCRIPTION_BASKET)
      : rootGetters['session/getAddress'](CONTEXT_SUBSCRIPTION);
    return !!deliveryPoint && !!address === expectedValue;
  }

  return !!deliveryPoint === expectedValue;
};

export default {
  type: 'hasDeliveryPoint',
  values: [true, false],
  test,
};
