import replace from 'lodash/replace';
import { LUNCHEON, PERCENT } from 'Classes/CouponsMapping';

import { fromUnixTime, subDays, subMonths, subYears, subHours, subMinutes } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import { timeZone } from 'Classes/Dates';

export const ucfirst = (value) => {
  if (!value) return '';
  const v = value.toString();
  return v.charAt(0)
    .toUpperCase() + v.slice(1);
};
export const uppercase = (value) => {
  return value?.toUpperCase();
};

export const indexToDay = (index) => {
  if (index && index >= 0 && index < 7) {
    const days = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi',];
    return days[index];
  }
  return '';
};

export const tmsToHour = (tms, short = false) => {
  const HH = tmsToFormat(tms, 'HH'); // Obtenir les heures
  const mm = tmsToFormat(tms, 'mm'); // Obtenir les minutes

  // Retourner l'heure formatée, sans les minutes si elles sont '00' et que 'short' est true
  return `${HH}h${short && mm === '00' ? '' : mm}`;
};

export const tmsToFormat = (tms, formatString) => {
  if (!tms) return undefined;
  const date = tms instanceof Date ? tms : fromUnixTime(tms);
  return formatInTimeZone(date, timeZone, formatString);
};

export const tmsToFormatMinus = (tms, formatString, quantity, letterFormat = 'd') => {
  const date = fromUnixTime(tms); // Convertir le timestamp Unix en objet Date

  // Déterminez quel type de soustraction utiliser basé sur 'letterFormat'
  let subtractedDate;
  switch (letterFormat) {
    case 'd': // Jours
      subtractedDate = subDays(date, quantity);
      break;
    case 'M': // Mois
      subtractedDate = subMonths(date, quantity);
      break;
    case 'y': // Années
      subtractedDate = subYears(date, quantity);
      break;
    case 'h': // Heures
      subtractedDate = subHours(date, quantity);
      break;
    case 'm': // Minutes
      subtractedDate = subMinutes(date, quantity);
      break;
    default:
      throw new Error('Unsupported time unit');
  }

  // Formater et retourner la date
  return formatInTimeZone(subtractedDate, timeZone, formatString);
};

export const tmsToDate = (tms) => {
  const date = fromUnixTime(tms); // Convertir le timestamp Unix en objet Date
  return formatInTimeZone(date, timeZone, 'eeee dd MMMM', { timeZone });
};

export const devisePercent = (value) => {
  return value === PERCENT ? '%' : '€';
};

export const typeOfCoupon = (type) => {
  return type !== LUNCHEON ? 'de remise' : 'Titre Restaurant*';
};

/**
 * It takes a price, and returns a string
 * @param price - the price to be formatted
 * @param [options] - overwrite : convert0toString ; unit ; freeString ; fractionDigits
 * @returns A String
 */
export const priceFilter = (price, options) => {
  if (!price) return null;
  const opt = {
    convert0toString: false,
    unit: '€',
    freeString: 'OFFERT !',
    fractionDigits: 2, ...options,
  };

  const numericalPrice = String(Number
    .parseFloat(price)
    .toFixed(opt.fractionDigits))
    .replace('.', ',');
  if (numericalPrice === '0,00') {
    return opt.convert0toString ? opt.freeString : numericalPrice;
  }
  return opt.convert0toString ? `${numericalPrice}${opt.unit}` : numericalPrice;
};

export const weightFilter = (weight, unit = 'kg') => {
  if (!weight) return null;
  return `${String(weight)
    .replace('.', ',')}${unit || ''}`;
};

export const shortPriceFilter = (price) => {
  const val = priceFilter(price);
  if (val.slice(-2) === '00') return val.substring(0, val.length - 3);
  return val;
};

export const distanceFilter = (distance) => {
  if (distance) {
    if (distance > 1000) {
      return `${(distance / 1000).toFixed(distance > 10000 ? 0 : 2)
        .replace('.', ',')}km`;
    }
    return `${parseInt(distance, 10)}m`;
  }
  return 0;
};

export const removeBio = (str) => {
  return replace(str, new RegExp('bio', 'gi'), '');
};

export const stripHtml = (content) => {
  const element = document.createElement('div');
  element.innerHtml = content;
  return element.innerHtml;
};

export const stripHtmlEntities = (content) => {
  return content?.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec));
};

export const decodeHtmlEntities = (str) => {
  if (str && typeof str === 'string') {
    const element = document.createElement('div');
    element.innerHTML = str;

    str = element.textContent;
    element.textContent = '';
  }
  return str;
};

// Suppression des doubles espaces
export const cleanDoubleSpaces = (str) => {
  return str?.replace(/(&nbsp;| )(&nbsp;| )+/g, ' ');
};

// Ajout d'espace insécable avant la ponctuation
export const cleanSpaces = (str) => {
  return str?.replace(/[ ]([?|!|;|:])/g, '&nbsp;$1');
};

// Suppression des espaces insécables unicode
export const cleanUnicodeSpaces = (str) => {
  return str?.replace(/\u2028/g, ' ');
};

// Suppression des classes
export const cleanClasses = (str) => {
  return str?.replace(/class="[a-zA-Z0-9:;.\s()\-,_]*"/g, '');
};

// Suppression des styles
export const cleanStyles = (str) => {
  return str?.replace(/style="[a-zA-Z0-9:;.\s()\-,]*"/g, '');
};

// Suppression des <br>
export const cleanBr = (str) => {
  return str?.replace(/<br>/g, '');
};

// Suppression des balises avec juste &nbsp;
// Suppression des balises vides
export const cleanEmptyTags = (str) => {
  return str
    ?.replace(/<[^/>][^>]*>(&nbsp;)<\/[^>]+>/g, '')
    .replace(/<[^/>(iframe)][^>]*><\/[^>]+>/g, '');
};

export const cleanImg = (str) => {
  return str?.replace(/<p>\s*(<a .*>)?\s*(<img .* \/>)\s*(<\/a>)?\s*<\/p>/g, '$1$2');
};

// Suppression des iframes "sauvages" pour éviter la dépose de cookies
export const cleanIframes = (str) => {
  return str?.replace(/<iframe.+?<\/iframe>/g, '');
};

export const cleanWordpress = (str) => {
  let text = str;

  if (text) {
    text = cleanSpaces(text);
    text = cleanUnicodeSpaces(text);
    text = cleanDoubleSpaces(text);
    text = cleanIframes(text);
    text = cleanEmptyTags(text);
  }

  return text;
};

export const pluralize = (value, singular, plural, showValue = true) => {
  const v = parseFloat(value);
  let str = showValue ? `${v} ` : '';
  str += v > 1 ? (plural || `${singular}s`) : singular;
  return str;
};

export default {
  cleanBr,
  cleanClasses,
  cleanDoubleSpaces,
  cleanEmptyTags,
  cleanIframes,
  cleanImg,
  cleanSpaces,
  cleanStyles,
  cleanUnicodeSpaces,
  cleanWordpress,
  decodeHtmlEntities,
  devisePercent,
  distanceFilter,
  indexToDay,
  pluralize,
  priceFilter,
  removeBio,
  shortPriceFilter,
  stripHtml,
  stripHtmlEntities,
  tmsToDate,
  tmsToFormat,
  tmsToFormatMinus,
  tmsToHour,
  typeOfCoupon,
  ucfirst,
  uppercase,
  weightFilter,
};
