import {
  FETCH_PUBLIC_ACTION,
  SUBSCRIBE_NEWSLETTER,
  FETCH_HP_SLIDES,
  SET_HAS_SHOWN_ALERTS_ACTION,
} from 'Stores/types/appActionTypes';

import {
  SET_IS_READY,
  SET_MAINTENANCE,
  UPDATE_PUBLIC,
  SET_HP_SLIDES,
  SET_HAS_SHOWN_ALERTS,
} from 'Stores/types/appMutationsTypes';

import Filters from 'Filters';

import Cookies from 'js-cookie';

import { api } from 'Plugins/potagerApiClient';

export default {

  namespaced: true,

  state: {
    isReady: false,
    alerts: [],
    hasShownAlerts: false,
    maintenance: false,
    sponsorship: {
      refererValue: null,
      refereeValue: null,
      minRefereeValue: null,
      minRefererValue: null,
      refereeType: null,
      refererType: null,
      maxRefereeValue: null,
      maxRefererValue: null,
      refereeDescriptionValue: null,
      refereeTitleValue: null,
    },
    basketLimit: {
      abonnement: null,
      oneShot: null,
    },
    promotedCoupons: null,
    homeDelivery: {
      regions: [],
    },
    bottomBarVisible: false,
    highlightedProducts: [],
    luncheons: null,
    oneShotMinimumAmount: null,
    paygreenPublicKey: null,
    hpSlides: [],
  },

  getters: {
    getAlerts: (state, getters, rootState, rootGetters) => {
      const alerts = [
        ...getters.getAlertsByType('global'),
        ...rootGetters['session/isLoggedIn'] ? getters.getAlertsByType('loggedIn') : getters.getAlertsByType('loggedOut'),
      ];
      return alerts.filter((alert) => !Cookies.get(alert.id));
    },
    hasShownAlerts: (state) => state.hasShownAlerts,
    getAlertsByType: (state) => (type) => state.alerts.filter((alert) => alert.type === type),
    getSponsorshipGodFatherValue: (state) => {
      const unit = state.sponsorship.refererType ? '%' : '€';
      return state.sponsorship.refererValue ? `${Filters.shortPriceFilter(state.sponsorship.refererValue)}${unit}` : '...';
    },
    getSponsorshipGodSonValue: (state) => {
      const unit = state.sponsorship.refereeType ? '%' : '€';
      return state.sponsorship.refereeValue ? `${Filters.shortPriceFilter(state.sponsorship.refereeValue)}${unit}` : '...';
    },
    getSponsorshipMinGodSonValue: (state) => (state.sponsorship.minRefereeValue ? `${Filters.shortPriceFilter(state.sponsorship.minRefereeValue)}€` : '...'),
    getSponsorshipMinGodFatherValue: (state) => (state.sponsorship.minRefererValue ? `${Filters.shortPriceFilter(state.sponsorship.minRefererValue)}€` : '...'),
    getSponsorshipPromotedText: (state) => ({
      promotedDescription: state.sponsorship.refereeDescriptionValue,
      promotedTitle: state.sponsorship.refereeTitleValue,
    }),
    getSubscriptionBasketLimit: (state) => state.basketLimit.abonnement,
    getOneShotBasketLimit: (state) => state.basketLimit.oneShot,
    getPromotedCoupons: (state) => state.promotedCoupons,
    isMaintenance: (state) => state.maintenance,
    getHomeDeliveryRegions: (state) => state.homeDelivery.regions,
    getHomeDeliveryByRegionId: (state) => (regionId) => state.homeDelivery.regions
      .find(({ id }) => id === Number(regionId)),
    getHomeDeliveryRegion: (state, getters, rootState, rootGetters) => getters.getHomeDeliveryByRegionId(rootGetters['session/getRegionId']),
    getHomeDeliveryCost: (state, getters) => getters.getHomeDeliveryRegion?.cost,
    getHomeDeliveryLimit: (state, getters) => getters.getHomeDeliveryRegion?.limit,
    getHomeDeliveryRegionsIds: (state) => state.homeDelivery.regions.map(({ id }) => id),
    getHighlightedProducts: (state) => state.highlightedProducts,
    getLuncheons: (state) => state.luncheons,
    getOneShotMinimumAmount: (state) => state.oneShotMinimumAmount,
    getPaygreenPublicKey: (state) => state.paygreenPublicKey,
    isReady: (state) => state.isReady,
    getHpSlides: (state) => state.hpSlides,
  },

  mutations: {
    [UPDATE_PUBLIC](state, data) {
      state.alerts = data.alerts;
      state.sponsorship = data.sponsorship;
      state.basketLimit = data.basketLimit;
      state.homeDelivery = data.homeDelivery;
      state.maintenance = false;
      state.highlightedProducts = data.highlightedProducts;
      state.luncheons = data.luncheons;
      state.oneShotMinimumAmount = data.oneShotMinimumAmount;
      state.paygreenPublicKey = data.paygreenPublicKey;

      state.promotedCoupons = data.promotedCoupons.map((coupon) => {
        coupon.label = coupon.giftedProductName || `Code : ${coupon.couponCode}`;
        coupon.promotedTitleHome = Filters.cleanBr(coupon.promotedTitleHome || '');

        const couponModifier = `-${coupon.couponCode}`.toLowerCase();
        // importer dans potagerasset une image >
        // bandeau-homepage-<nom_du_coupon_en_minuscule>
        coupon.bannerPicture = `bandeau-homepage${couponModifier}`;

        // importer dans potagerasset deux images (mobile/desktop) >
        // bandeau-concept-<nom_du_coupon_en_minuscule>
        // bandeau-concept-<nom_du_coupon_en_minuscule>-mobile
        coupon.conceptPictureDesktop = `bandeau-concept${couponModifier}`;
        coupon.conceptPictureDesktop = `bandeau-concept${couponModifier}-mobile`;

        const liPromotedDescriptionTrim = coupon.promotedDescription
          // clean html & catch li content
          .replace(/[\n\r]/g, '')
          .trim()
          .match(/<li>(.*?)<\/li>/g)
          // clean unused html tags
          ?.map((rule) => rule.replace('<li>', '')
            .replace('</li>', '')
            .replace('<i></i>', '')
            .replace('<b></b>', '')
            .trim())[0];

        coupon.promotedDescription = liPromotedDescriptionTrim || coupon.promotedDescription;
        return coupon;
      });
    },
    [SET_MAINTENANCE](state, value = true) {
      state.maintenance = value;
    },
    [SET_IS_READY](state, value) {
      state.isReady = value;
    },
    [SET_HP_SLIDES](state, value) {
      state.hpSlides = value;
    },
    [SET_HAS_SHOWN_ALERTS](state, value) {
      state.hasShownAlerts = value;
    }
  },

  actions: {
    [FETCH_PUBLIC_ACTION]({ commit }) {
      return new Promise((resolve, reject) => {
        api.app.fetchPublicInformation()
          .then((resp) => {
            commit(UPDATE_PUBLIC, resp.data);
            resolve(resp);
          })
          .catch((error) => reject(error));
      });
    },
    [SUBSCRIBE_NEWSLETTER]({ dispatch }, params) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', SUBSCRIBE_NEWSLETTER, { root: true });
        api.app.newsletter(params)
          .then((resp) => resolve(resp))
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', SUBSCRIBE_NEWSLETTER, { root: true }));
      });
    },
    [FETCH_HP_SLIDES]({
      commit,
      dispatch,
      rootGetters
    }) {
      return new Promise((resolve, reject) => {
        dispatch('wait/start', FETCH_HP_SLIDES, { root: true });
        api.app.fetchSlides(rootGetters['session/getTestModeDate'])
          .then((resp) => {
            commit(SET_HP_SLIDES, resp.data.data.results);
            resolve(resp);
          })
          .catch((err) => reject(err))
          .finally(() => dispatch('wait/end', FETCH_HP_SLIDES, { root: true }));
      });
    },
    [SET_HAS_SHOWN_ALERTS_ACTION]({ commit }, value) {
      commit(SET_HAS_SHOWN_ALERTS, value);
    }
  },
};
