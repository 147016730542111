import COLORS from 'Settings/colors';
import TYPOS from 'Settings/typos';

export const fonts = [{
  cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:400',
}];

export const classes = {
  focus: 'stripe__input--focus',
  invalid: 'stripe__input--invalid',
};

export const style = {
  base: {
    backgroundColor: COLORS.white,
    color: COLORS.charcoal,
    fontFamily: TYPOS.fontBase,
    fontSize: `${TYPOS.baseFs}px`,
    fontSmoothing: 'antialiased',
    fontWeight: TYPOS.weightNormal,
    lineHeight: '2.625em',
    letterSpacing: TYPOS.baseLs,
    '::placeholder': {
      color: COLORS.placeholder,
    },
  },
  invalid: {
    color: COLORS.charcoal,
  },
};
