export default {
  baseFs: 16,

  // iso tailwind https://tailwindcss.com/docs/font-weight
  weightThin: 100,
  weightExtralight: 200,
  weightLight: 300,
  weightNormal: 400,
  weightMedium: 500,
  weightSemibold: 600,
  weightBold: 700,
  weightExtrabold: 800,
  weightBlack: 900,
};
