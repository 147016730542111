<template>
  <potager-modal>
    <template #title>
      Oups !
    </template>

    <template #body>
      <span v-html="message" />

      <p
        v-if="errorMsg"
        class="text-warm-grey text-xs.5 font-semibold mt-4"
        v-html="errorMsg" />
    </template>

    <template #footer>
      <div class="flex flex-col">
        <potager-button
          full-width
          label="J'ai compris"
          @onClick="retry" />
        <potager-button
          :to="{ name: 'contact' }"
          size="small"
          target="_blank"
          theme="link">
          Si le problème persiste, contactez-nous
        </potager-button>
      </div>
    </template>
  </potager-modal>
</template>

<script>
import PotagerModal from 'UI/PotagerModal';
import PotagerButton from 'UI/PotagerButton';

import { getPaymentErrorMessage } from 'Classes/PaymentMethodTools';

export default {

  components: {
    PotagerModal,
    PotagerButton,
  },

  props: {
    error: {
      type: [Error, Object],
      required: false,
      default: () => ({}),
    },
  },

  computed: {
    message() {
      return getPaymentErrorMessage(this.error);
    },
    errorMsg() {
      const getErrorCode = (obj) => {
        const {
          code,
          errorCode,
          stripePayment
        } = obj;
        return code || errorCode || (stripePayment && stripePayment.errorCode) || obj.code;
      };

      const errorCode = getErrorCode(this.error) || getErrorCode(this.item);
      const declineCode = this.error.decline_code;

      if (errorCode && declineCode) {
        return `Code erreur : ${errorCode} - ${declineCode}`;
      }

      if (errorCode) {
        return `Code erreur : ${errorCode}`;
      }

      if (declineCode) {
        return `Code erreur (decline) : ${declineCode}`;
      }

      return null;
    },

  },

  methods: {
    retry() {
      this.$modal.close();
    },
  },
};
</script>
