<template>
  <panel
    :back-route="{ name: 'mon-compte_luncheons' }"
    title="Soumettre un lot">
    <div class="panel-section panel-section__line">
      <potager-button
        v-if="!showForm"
        full-width
        theme="stroke"
        @onClick="showForm = true">
        Ajouter un titre restaurant
      </potager-button>

      <potager-form
        v-else
        id="add-coupon-luncheon"
        @onSubmit="onSubmit">
        <potager-input
          v-model="couponValue"
          :constraints="[{ constraint: 'required', field: 'lvalue' }]"
          label="Valeur"
          min="0"
          name="lvalue"
          placeholder="Ex : 7,50"
          step="0.01"
          suffix="€"
          type="number" />
        <potager-input
          v-model="couponCode"
          :constraints="[
            { constraint: 'required', field: 'lcode' },
            { constraint: 'length', field: 'lcode', options: { exact: 9 } },
            {
              constraint: 'regex',
              field: 'lcode',
              regex: /^[0-9]{9}$/,
              message: 'Le code doit être composé de 9 chiffres'
            },
          ]"
          autocomplete="off"
          label="Code à 9 chiffres"
          maxlength="9"
          name="lcode"
          placeholder="123456789" />
        <potager-button
          :is-loading="$wait.is(`${ADD_COUPON_LUNCHEON_BATCH_ACTION}_SIMULATION`)"
          full-width
          label="Ajouter"
          type="submit" />
      </potager-form>

      <potager-button
        :to="{ name: 'mon-compte_luncheons_howto' }"
        size="small"
        theme="link">
        Comment ça marche ?
      </potager-button>
    </div>

    <div
      v-if="couponLuncheons.length"
      class="panel-section">
      <p class="panel-section__subtitle">
        {{ pluralize(couponLuncheons.length, 'titre') }} restaurant
        {{ pluralize(couponLuncheons.length, 'ajouté', null, false) }}
      </p>

      <coupon-collapsible
        v-for="(coupon, index) in couponLuncheons"
        :key="coupon.code"
        class="panel-section__line">
        <template #title>
          Titre restaurant n°{{ index + 1 }}
        </template>
        <template #value>
          -{{ priceFilter(coupon.value) }}€
        </template>
        <template #subtitle>
          {{ priceFilter(coupon.value) }}€ de remise<br>
          <span class="text-xs.5">
            Code : <b>{{ coupon.code }}</b>
          </span>
        </template>
        <template #actions>
          <potager-button
            size="small"
            theme="stroke-grey"
            @onClick="onDelete(coupon)">
            Supprimer
          </potager-button>
        </template>
      </coupon-collapsible>
    </div>

    <template
      v-if="couponLuncheons.length"
      #footer>
      <potager-button
        :is-loading="$wait.is(ADD_COUPON_LUNCHEON_BATCH_ACTION)"
        full-width
        label="Soumettre"
        @onClick="sendAll" />
    </template>
  </panel>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { DELETE_LOCAL_COUPON_LUNCHEON_BATCH } from 'Stores/types/userMutationsTypes';
import { ADD_COUPON_LUNCHEON_BATCH_ACTION } from 'Stores/types/userActionsTypes';

import { pluralize, priceFilter } from 'Filters';

import PotagerButton from 'UI/PotagerButton';
import PotagerForm from 'UI/Form/PotagerForm';
import PotagerInput from 'UI/Form/PotagerInput';

import Panel from 'Components/panel/Panel';
import CouponCollapsible from 'Components/coupons/CouponCollapsible';

import MetaInfosService from 'Classes/services/MetaInfoService';

import findIndex from 'lodash/findIndex';

export default {
  components: {
    Panel,
    PotagerForm,
    PotagerInput,
    PotagerButton,
    CouponCollapsible,
  },

  data: () => ({
    ADD_COUPON_LUNCHEON_BATCH_ACTION,
    couponCode: '',
    couponValue: '',
    showForm: true,
  }),

  computed: {
    ...mapGetters('user', {
      couponLuncheons: 'getLocalCouponLuncheonsBatch',
    }),
  },

  methods: {
    priceFilter,
    pluralize,
    ...mapActions('user', {
      addCouponLuncheonBatchAction: ADD_COUPON_LUNCHEON_BATCH_ACTION,
    }),
    ...mapMutations('user', {
      onDelete: DELETE_LOCAL_COUPON_LUNCHEON_BATCH,
    }),
    onSubmit() {
      const code = this.couponCode.toString();
      const value = parseFloat(this.couponValue);

      return new Promise((resolve, reject) => {
        if (this.getCouponIndexByCode(code) === -1) {
          // On n'utise ici pas de dispatch pour pouvoir gérer l'erreur manuellement
          // sans déclencher l'ouverture de la modal
          this.$wait.start(`${ADD_COUPON_LUNCHEON_BATCH_ACTION}_SIMULATION`);

          this.addCouponLuncheonBatchAction({
            tickets: [{
              code,
              value
            }],
            simulation: true,
          })
            .then(() => {
              this.showForm = false;
              this.couponValue = '';
              this.couponCode = '';
              resolve();
            })
            .catch((err) => {
              if (err && err.globals) {
                const error = err.globals.filter((e) => e.code === 'TR001');

                if (error && error[0] && error[0].metadata && error[0].metadata[0]) {
                  const message = `Le coupon "${error[0].metadata[0].barcode}" n\'est pas valide.`;
                  this.handleInputError(message);
                }
              }
            })
            .finally(() => {
              this.$wait.end(`${ADD_COUPON_LUNCHEON_BATCH_ACTION}_SIMULATION`);
            });
        } else {
          const message = `Le coupon "${code}" existe déjà`;
          this.handleInputError(message);
          reject(new Error(message));
        }
      });
    },
    sendAll() {
      const tickets = this.couponLuncheons.map((ticket) => ({
        code: ticket.code,
        value: ticket.value,
      }));
      this.$store.dispatch(`user/${ADD_COUPON_LUNCHEON_BATCH_ACTION}`, { tickets })
        .then(({ data }) => {
          const batchId = data.data.id;
          this.$notify({
            type: 'success',
            title: 'Lot soumis',
            text: 'Votre lot de titres restaurant a bien été soumis',
          });

          this.$router.push({
            name: 'mon-compte_luncheons_send',
            params: { batchId },
          })
            .catch(() => {
            });
        })
        .catch((err) => {
          if (err && err.globals) this.handleError(err.globals);
        });
    },
    getCouponIndexByCode(code) {
      return findIndex(this.couponLuncheons, (coupon) => coupon.code === code);
    },
    handleError([error]) {
      const tickets = error.code === 'TR001' ? error.metadata : null;
      if (tickets) {
        tickets.forEach((ticket) => {
          this.onDelete(ticket);
        });
      }
    },
    handleInputError(message) {
      this.$events.emit(`request:inputError:lcode`, message);
    },
  },

  head: MetaInfosService.generate({
    title: 'Ajouter des titres restaurant',
  }),

};
</script>
