<template>
  <potager-container size="small">
    <div class="enterprise__cta">
      <potager-button
        :label="label"
        :size="$mq.bp1024 ? 'small' : undefined"
        :full-width="$mq.bp1024"
        @onClick="$emit('openSellsy')" />
    </div>
  </potager-container>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerButton from 'UI/PotagerButton';

export default {

  components: {
    PotagerContainer,
    PotagerButton,
  },

  props: {
    label: {
      type: String,
      required: false,
      default: 'Contactez-nous',
    },
  },

};
</script>
