<template>
  <ul
    v-if="sortedBankCards.length"
    class="bank-cards-selection-list">
    <li
      v-for="blueCard in sortedBankCards"
      :key="blueCard.id"
      class="bank-cards-selection-list__item">
      <payment-card
        :card="blueCard"
        :is-selected="isSelected(blueCard)"
        radio
        @onChange="onChange" />
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import { PRIMARY } from 'Classes/BlueCards';
import PaymentCard from 'Components/paymentCard/PaymentCard';

export default {
  components: {
    PaymentCard,
  },

  props: {
    selectedCardId: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },

  emits: ['onChange'],

  computed: {
    ...mapGetters('user', [
      'getBankCards',
    ]),
    sortedBankCards() {
      return [...this.getBankCards].sort((a) => (a.priority === PRIMARY ? -1 : 0));
    },
  },

  methods: {
    onChange(value) {
      this.$emit('onChange', value);
    },
    isSelected(card) {
      return this.selectedCardId
        ? card.id.toString() === this.selectedCardId.toString()
        : false;
    },
  },

};

</script>
