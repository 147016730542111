export const convertAddressToParameter = (address) => (
  address ? {
    addressCity: address.city || address.addressCity,
    addressComment: address.comment || address.addressComment,
    addressCompany: address.company || address.addressCompany,
    addressFirstname: address.firstname || address.addressFirstname,
    addressLastname: address.lastname || address.addressLastname,
    addressNote: address.note || address.addressNote,
    phone: address.phone || address.phone,
    addressPostcode: address.postalCode || address.addressPostcode,
    // addressStreetNumber: address.streetNumber || address.addressStreetNumber,  <--- will be used after BO update to split street and number
    addressStreetName: address.streetName || address.addressStreetName,
  } : undefined);
