<template>
  <component
    :is="tag"
    v-if="!isDisable"
    :class="[
      'potager-tooltip select-none',
      `potager-tooltip--${theme}`,
      `potager-tooltip--${computedPosition}`,
      {
        'potager-tooltip--shadow': shadow,
        'potager-tooltip--visible': isVisible,
        'potager-tooltip--closable': closable,
      },
    ]"
    @click="$emit('click')"
    @mouseleave="isHover = false"
    @mouseover="isHover = true">
    <div
      ref="tooltip"
      :class="[
        'potager-tooltip__body',
        { 'opacity-0': hideBeforeCheck}
      ]"
      :style="getStyle">
      <div class="potager-tooltip__content normal-case">
        <button
          v-if="closable"
          class="potager-tooltip__close"
          @click="dismiss">
          <icon-close />
        </button>

        <slot name="message">
          <span v-html="message" />
        </slot>
      </div>
    </div>

    <slot />
  </component>

  <component
    :is="tag"
    v-else>
    <slot />
  </component>
</template>

<script>
import IconClose from 'Icons/regular/IconClose';

import { propsBuilder } from 'UI/Tools';

export const tooltipPositions = ['top', 'right', 'bottom', 'bottom-left', 'left', 'top-left'];

export const props = {
  tag: {
    type: String,
    required: false,
    default: 'span',
  },
  message: {
    type: String,
    required: false,
    default: null,
  },
  theme: {
    type: String,
    required: false,
    default: 'default',
    values: ['default', 'dark', 'error'],
  },
  position: {
    type: String,
    required: false,
    default: null,
    values: tooltipPositions,
  },
  always: {
    type: Boolean,
    required: false,
    default: false,
  },
  closable: {
    type: Boolean,
    required: false,
    default: false,
  },
  shadow: {
    type: Boolean,
    required: false,
    default: false,
  },
  disable: {
    type: Boolean,
    required: false,
    default: false,
  },
  width: {
    type: [String, Number],
    required: false,
    default: null,
  },
  spacing: {
    type: [String, Number],
    required: false,
    default: null,
  },
  root: {
    type: Object,
    required: false,
    default: undefined,
  },
};

export default {
  name: 'PotagerTooltip',

  components: {
    IconClose,
  },

  data: () => ({
    visible: true,
    calculatedPosition: undefined,
    previousIntersecting: 0,
    parents: [],
    observer: undefined,
    isHover: false,
    indexPositionChecking: undefined,
    hideBeforeCheck: true,
  }),

  props: propsBuilder(props),

  mounted() {
    this.getParents(this);
  },

  computed: {
    isVisible() {
      return (this.always || this.closable) && this.visible && !this.isDisable;
    },
    isDisable() {
      return this.disable || !this.visible;
    },
    getStyle() {
      return {
        ...this.getWidth,
        ...this.getMargin,
      };
    },
    getWidth() {
      if (!this.width) return null;
      return { width: this.sanitizeValue(this.width) };
    },
    getMargin() {
      if (!this.spacing) return null;
      let direction = 'top';
      if (['top', 'top-left'].includes(this.computedPosition)) direction = 'bottom';
      if (this.computedPosition === 'right') direction = 'left';
      if (this.computedPosition === 'left') direction = 'right';
      return { [`margin-${direction}`]: this.sanitizeValue(this.spacing) };
    },
    computedPosition() {
      return this.position || this.calculatedPosition;
    },
    tooltipWrapper() {
      // On récupère le wrapper le plus proche afin de calculer la position de la tooltip par rapport à lui
      const excludeComponents = ['potager-tooltip', 'potager-input', 'transition'];
      const excludeElements = ['span', 'button', 'span', 'a', 'svg', 'path', 'i', 'icon', 'ul', 'li'];
      // on filtre les parents pour ne pas prendre en compte les composants qui ne sont pas des wrappers
      const validParents = this.parents.filter((parent) => {
        const element = parent.component.$vnode?.elm.localName || parent.component.$el.localName;
        const component = parent.component.$options._componentTag;
        return !excludeComponents.includes(component) && !excludeElements.includes(element);
      });
      return validParents.length ? validParents[0].component.$el : document;
    },
  },

  methods: {
    getParents(elmt) {
      if (elmt.$parent) {

        this.parents.push({
          name: elmt.$parent.$options._componentTag,
          component: elmt.$parent
        });
        this.getParents(elmt.$parent);
      }
    },
    dismiss() {
      this.visible = false;
      this.$emit('onDismiss');
    },
    sanitizeValue(val) {
      return typeof val === 'string' ? val : `${val}px`;
    },
    checkPositions() {
      const positionPromises = tooltipPositions.map((position) => new Promise((resolve) => {
        // Use requestAnimationFrame for better performance
        requestAnimationFrame(() => {
          this.calculatedPosition = position;

          this.$nextTick(() => {
            const rootRect = this.tooltipWrapper?.getBoundingClientRect();
            const tooltipRect = this.$refs.tooltip.getBoundingClientRect();

            const intersectionRect = {
              top: Math.max(rootRect.top, tooltipRect.top),
              right: Math.min(rootRect.right, tooltipRect.right),
              bottom: Math.min(rootRect.bottom, tooltipRect.bottom),
              left: Math.max(rootRect.left, tooltipRect.left),
            };

            const intersectionArea = Math.max(0, intersectionRect.right - intersectionRect.left) * Math.max(0, intersectionRect.bottom - intersectionRect.top);
            const tooltipArea = (tooltipRect.right - tooltipRect.left) * (tooltipRect.bottom - tooltipRect.top);

            const intersectingRatio = intersectionArea / tooltipArea;

            if (intersectingRatio > this.previousIntersecting) {
              this.previousIntersecting = intersectingRatio;
            }

            resolve(intersectingRatio);
          });
        });
      }));

      Promise.all(positionPromises)
        .then((results) => {
          let maxRatio = -Infinity;
          let maxIndex = -1;

          results.forEach((ratio, index) => {
            if (ratio > maxRatio) {
              maxRatio = ratio;
              maxIndex = index;
            }
          });

          this.calculatedPosition = tooltipPositions[maxIndex];
          this.hideBeforeCheck = false;
        });
    },

  },

  watch: {
    // on hover
    isHover: {
      handler(val) {
        if (val) {
          if ((val || this.always) && !this.calculatedPosition) {
            this.checkPositions();
          }
        }
      },
      immediate: true,
    },
  },
};
</script>
