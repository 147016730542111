<template>
  <component
    :is="tag"
    :class="[
      'card-v2 min-w-[min(100%, 180px)]',
      {
        'card-v2--small': small,
        'card-v2--landscape': landscape,
        'card-v2--hover': redirect && !$mq.bp768 && !landscape,
      }
    ]"
    :href="isHref ? redirect : undefined"
    :target="isDocument ? '_blank' : undefined"
    :to="isHref ? undefined : redirect">
    <slot />
  </component>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  props: {
    redirect: {
      type: [Object, String],
      required: false,
      default: null,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    landscape: {
      type: Boolean,
      required: false,
      default: false,
    },
    box: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    ...mapGetters('dashboard', [
      'getDashboardSections',
    ]),
    isHref() {
      return this.redirect && typeof this.redirect === 'string';
    },
    isDocument() {
      return !!(this.isHref && this.redirect.match(/\.[0-9a-z]{1,5}$/i));
    },
    tag() {
      return this.redirect ? (this.isHref ? 'a' : 'potager-link') : 'span'; // eslint-disable-line no-nested-ternary
    },
  },
};
</script>

<style lang="scss" scoped>
.card-v2 {
  $this: &;

  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  text-align: left;
  border-radius: .625rem;

  &--landscape {
    flex-direction: row;
    height: auto;
    align-items: center;
  }
}
</style>
