// Colors names generated with
// http://chir.ag/projects/name-that-color/
// https://www.color-blindness.com/color-name-hue/

const COLORS = {
  // Greys
  white: '#ffffff',
  whiteSmoke: '#f7f7f7',
  seashell: '#f1f1f1',
  porcelain: '#e2e6e9',
  warmGrey: '#9b9b9b',
  charcoal: '#353f47',
  black: '#000000',
  // Colors
  goGreen: '#00AF66',
  forestGreen: '#169a3b',
  sushi: '#8CC63F',
  wildWillow: '#a1cd6d',
  feijoa: '#BFE398',
  berylGreen: '#BAE9B9',
  monza: '#ba0118',
  maximumRed: '#C92413',
  crimson: '#df2935',
  bittersweet: '#F9705B',
  zest: '#e4792e',
  atomicTangerine: '#FF9D57',
  sunglow: '#fecb39',
  goldenDream: '#eee82c',
  yellowCrayola: '#FEEB87',
  springWood: '#fcfbf8',
  bianca: '#F9F5ED',
  cosmicLatte: '#FFFBE4',
  champagne: '#FAE3CF',
  whiteRock: '#EEE8D8',
  easternBlue: '#1387af',
  summerSky: '#26b6e8',
  charlotte: '#c7ecf9',
  lavender: '#F5D8FF',
  lavenderBlush: '#F0E2E2',
  byzantine: '#cc33aa',
  purpleElectric: '#C516FF',
  lavenderIndigo: '#9949FF',
  purpleHeart: '#552BD5',
  chocolate: '#5A3218',
  britishRacing: '#005229',
  como: '#4d866a',
  peach: '#FFCDAA',
  carnation: '#F15A4B',
  cinnabar: '#EA3D2C',
  lightRed: '#FACFCF',
  rossabel: '#F4EDC8',
  tequila: '#F3CFA2',
  sandyBeach: '#FFE3B9',
  mangoTango: '#F2651F',

  // Misc
  edenred: '#E30613',
  edenredAlt: '#28C0D4',
  edenredAlt2: '#162056',
  swile: '#1e1147',
  swileAlt: '#fac6c1',
  swileAlt2: '#1e1147',
  up: '#FF960F',
  upAlt: '#FF960F',
  upAlt2: '#FF960F',
  pluxee: '#00EB5E',
  pluxeeAlt: '#221C46',
  pluxeeAlt2: '#221C46',
  bimpli: '#FFAA4D',
  bimpliAlt: '#0085F6',
  bimpliAlt2: '#000000',
  visaAlt: '#1434CB',
  visaAlt2: '#1434CB',
  // Socials
  facebook: '#1259B7',
  twitter: '#1D9BF0',
  whatsapp: '#00b85e',
};

const ALIAS = {
  // Context
  bio: COLORS.forestGreen,
  promo: COLORS.goldenDream,
  basket: COLORS.forestGreen,
  subscription: COLORS.summerSky,
  favorite: COLORS.crimson,
  sponsorship: COLORS.britishRacing,
  sponsorshipAlt: COLORS.sushi,
  new: COLORS.carnation,
  local: COLORS.sushi,
  // Status
  success: COLORS.goGreen,
  warning: COLORS.zest,
  error: COLORS.cinnabar,
  danger: COLORS.cinnabar,
  // Misc
  visa: COLORS.charcoal,
};

export default {
  ...COLORS,
  ...ALIAS,
};
