import { reactive } from 'vue';
import { MediaQueriesCalculator } from 'Classes/MediaQueriesTools';

export const useMediaQuery = () => {
  const mqw = reactive(MediaQueriesCalculator());
  const mq = reactive({
    ...mqw,
    width: undefined
  }); // to avoid watch to trigger on each resize

  // Mise à jour des valeurs réactives sur le redimensionnement de la fenêtre
  window.addEventListener('resize', () => {
    Object.assign(mqw, MediaQueriesCalculator());
    Object.assign(mq, {
      ...mqw,
      width: undefined
    });
  });

  return { mq, mqw };
};

export default {
  install(app) {
    const mqw = reactive(MediaQueriesCalculator());
    const mq = reactive({
      ...mqw,
      width: undefined
    }); // to avoid watch to trigger on each resize

    // Maintenant, assignez les références réactives directement
    app.config.globalProperties.$mq = mq;
    app.config.globalProperties.$mqw = mqw;

    // Mise à jour des valeurs réactives sur le redimensionnement de la fenêtre
    window.addEventListener('resize', () => {
      Object.assign(mqw, MediaQueriesCalculator());
      Object.assign(mq, {
        ...mqw,
        width: undefined
      });
    });
  },
};
