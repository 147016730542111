import AccountHubPage from 'Pages/account/AccountHubPage';
import AccountInfoPersoPage from 'Pages/account/userInfo/AccountInfoPersoPage';
import AccountIdentityPage from 'Pages/account/userInfo/AccountIdentityPage';
import AccountMailPage from 'Pages/account/userInfo/AccountMailPage';
import AccountConfirmMail from 'Pages/account/userInfo/AccountConfirmMail';
import AccountResendMail from 'Pages/account/userInfo/AccountResendMail';
import AccountPasswordPage from 'Pages/account/userInfo/AccountPasswordPage';
import AccountDeletePage from 'Pages/account/userInfo/AccountDeletePage';
import AccountOrdersPage from 'Pages/account/orders/AccountOrdersPage';
import AccountOrdersDetailPage from 'Pages/account/orders/AccountOrdersDetailPage';
import AccountCreditCardsSelectPage from 'Pages/account/paymentMethods/creditCards/AccountCreditCardsSelectPage';
import AccountOrdersCouponsPage from 'Pages/account/orders/AccountOrdersCouponsPage';
import AccountOrdersGazettePage from 'Pages/account/orders/AccountOrdersGazettePage';
import AccountGazettesPages from 'Pages/account/AccountGazettesPages';
import AccountSubscriptionPage from 'Pages/account/subscription/AccountSubscriptionPage';
import AccountSubscriptionSuspendPage from 'Pages/account/subscription/AccountSubscriptionSuspendPage';
import AccountSubscriptionReplacePage from 'Pages/account/subscription/AccountSubscriptionReplacePage';
import AccountSubscriptionSuspendFormPage from 'Pages/account/subscription/AccountSubscriptionSuspendFormPage';
import AccountPaymentMethodsHubPage from 'Pages/account/paymentMethods/AccountPaymentMethodsHubPage';
import AccountCardsPage from 'Pages/account/paymentMethods/AccountCardsPage';
import AccountCreditCardsAddPage from 'Pages/account/paymentMethods/creditCards/AccountCreditCardsAddPage';
import AccountCreditCardsSetPrimaryPage
  from 'Pages/account/paymentMethods/creditCards/AccountCreditCardsSetPrimaryPage';
import AccountLoginPaymentMethodPage from 'Pages/account/paymentMethods/login/AccountLoginPaymentMethodPage';
import AccountPaygreenCardsAddPage from 'Pages/account/paymentMethods/paygreen/AccountPaygreenCardsAddPage';
import AccountNotificationsPage from 'Pages/account/AccountNotificationsPage';
import AccountRecipesPage from 'Pages/account/AccountRecipesPage';
import AccountCouponsPage from 'Pages/account/coupons/AccountCouponsPage';
import AccountCouponsAddPage from 'Pages/account/coupons/AccountCouponsAddPage';
import AccountCouponsAutomatedPage from 'Pages/account/coupons/AccountCouponsAutomatedPage';
import AccountLuncheonPage from 'Pages/account/coupons/AccountLuncheonPage';
import AccountLuncheonHowToPage from 'Pages/account/coupons/AccountLuncheonHowToPage';
import AccountLuncheonAddPage from 'Pages/account/coupons/AccountLuncheonAddPage';
import AccountLuncheonSendPage from 'Pages/account/coupons/AccountLuncheonSendPage';
import AccountSponsorshipPage from 'Pages/account/AccountSponsorshipPage';
import AccountSettingsPage from 'Pages/account/AccountSettingsPage';
import AccountTestMode from 'Pages/account/AccountTestMode';
import AccountOrderSuspendFormPage from 'Pages/account/orders/AccountOrderSuspendFormPage';

import { LOGIN_PAYMENT_METHODS } from 'Classes/paymentMethods';

import paygreenPlatformGuard from 'Router/guards/paygreenPlatformGuard';
import testModeGuard from 'Router/guards/testModeGuard';

const meta = {
  isPanel: true,
  requiresAuth: true,
};

export default [
  {
    name: 'mon-compte',
    path: '/mon-compte/',
    component: AccountHubPage,
    meta: {
      ...meta,
      bottomBar: true,
    },
  },

  /*
   * Informations personnelles
   */
  {
    name: 'mon-compte_info_perso',
    path: '/mon-compte/mes-informations-personnelles/',
    component: AccountInfoPersoPage,
    meta,
  },
  {
    name: 'mon-compte_identity',
    path: '/mon-compte/mes-informations-personnelles/identite',
    component: AccountIdentityPage,
    meta,
  },
  {
    name: 'mon-compte_email',
    path: '/mon-compte/mes-informations-personnelles/email',
    component: AccountMailPage,
    meta,
  },
  {
    name: 'mon-compte_confirm-email',
    path: '/mon-compte/mes-informations-personnelles/email/confirmer/',
    component: AccountConfirmMail,
    meta: {
      ...meta,
      cantBeUsedForLoginRedirect: true,
    },
  },
  {
    name: 'mon-compte_renvoyer',
    path: '/mon-compte/mes-informations-personnelles/email/renvoyer/',
    component: AccountResendMail,
    meta: {
      ...meta,
      cantBeUsedForLoginRedirect: true,
    },
  },
  {
    name: 'mon-compte_password',
    path: '/mon-compte/mes-informations-personnelles/mot-de-passe',
    component: AccountPasswordPage,
    meta,
  },
  {
    name: 'mon-compte_supprimer',
    path: '/mon-compte/supprimer-mon-compte',
    component: AccountDeletePage,
    meta,
  },

  /*
   * Mes commandes
   */
  {
    name: 'mon-compte_orders',
    path: '/mon-compte/mes-commandes/:status(en-cours|historique)?',
    component: AccountOrdersPage,
    meta: {
      ...meta,
      bottomBar: true,
    },
    beforeEnter: (to, from, next) => {
      if (!to.params.status) {
        next({
          name: 'mon-compte_orders',
          params: { status: 'en-cours' }
        });
      } else {
        next();
      }
    },
  },
  {
    name: 'mon-compte_orders_detail',
    path: '/mon-compte/commande/:orderId',
    alias: '/mon-compte/mes-commandes/en-cours/:orderId',
    component: AccountOrdersDetailPage,
    meta,
  },
  {
    name: 'mon-compte_orders_detail_select-card',
    path: '/mon-compte/mes-commandes/en-cours/:orderId/selectionner-une-carte/:cardId?',
    component: AccountCreditCardsSelectPage,
    meta,
  },
  {
    name: 'mon-compte_orders_detail_add-card',
    path: '/mon-compte/mes-commandes/en-cours/:orderId/ajouter-une-carte',
    component: AccountCreditCardsAddPage,
    meta,
    props: {
      autoSetPrimary: false
    },
  },
  {
    name: 'mon-compte_orders_coupons',
    path: '/mon-compte/mes-commandes/en-cours/:orderId/coupons',
    component: AccountOrdersCouponsPage,
    meta,
  },
  {
    name: 'mon-compte_orders_detail_replace',
    path: '/mon-compte/mes-commandes/en-cours/:orderId/remplacer/',
    component: AccountSubscriptionReplacePage,
    meta,
  },
  {
    name: 'mon-compte_orders_gazette',
    path: '/mon-compte/mes-commandes/:status(en-cours|livrees)/:orderId/gazette',
    component: AccountOrdersGazettePage,
    meta,
  },
  {
    name: 'mon-compte_order_suspend_form',
    path: '/mon-compte/mes-commandes/en-cours/:orderId/cause-de-votre-annulation',
    component: AccountOrderSuspendFormPage,
    meta,
  },

  /*
   * Gazette
   */
  {
    name: 'mon-compte_gazettes',
    path: '/mon-compte/gazettes',
    component: AccountGazettesPages,
    meta,
  },

  /*
   * Mon abonnement
   */
  {
    name: 'mon-compte_subscription',
    path: '/mon-compte/mon-abonnement/',
    component: AccountSubscriptionPage,
    meta: {
      ...meta,
      bottomBar: true,
    },
  },
  {
    name: 'mon-compte_subscription_suspend',
    path: '/mon-compte/mon-abonnement/suspendre',
    component: AccountSubscriptionSuspendPage,
    meta,
  },
  {
    name: 'mon-compte_subscription_replace',
    path: '/mon-compte/mon-abonnement/remplacer/',
    component: AccountSubscriptionReplacePage,
    meta,
  },
  {
    name: 'mon-compte_subscription_suspend_form',
    path: '/mon-compte/mon-abonnement/cause-de-votre-desabonnement',
    component: AccountSubscriptionSuspendFormPage,
    meta,
  },

  /*
   * Mes cartes de paiement
   */
  {
    name: 'mon-compte_payment-methods',
    path: '/mon-compte/mes-moyens-de-paiements',
    alias: '/mon-compte/mes-cartes-de-paiement',
    component: AccountPaymentMethodsHubPage,
    meta: {
      ...meta,
      keepQuery: true,
    },
  },
  /* Cartes de crédits */
  {
    name: 'mon-compte_payment-methods_cards',
    path: '/mon-compte/mes-moyens-de-paiements/:name',
    component: AccountCardsPage,
    meta: {
      ...meta,
      keepQuery: true,
    },
  },
  {
    name: 'mon-compte_payment-methods_credit-card_add',
    path: '/mon-compte/mes-moyens-de-paiements/cartes-bancaires/ajouter',
    alias: '/mon-compte/mes-cartes-de-paiement/ajouter',
    component: AccountCreditCardsAddPage,
    meta: {
      ...meta,
      keepQuery: true,
    },
    props: {
      setPrimaryRoute: { name: 'mon-compte_payment-methods_credit-card_set-primary' },
    },
  },
  {
    name: 'mon-compte_payment-methods_credit-card_add-and-set-primary',
    path: '/mon-compte/mes-moyens-de-paiements/cartes-bancaires/ajouter/definir-carte-principal',
    alias: '/mon-compte/mes-cartes-de-paiement/ajouter/definir-carte-principal',
    component: AccountCreditCardsAddPage,
    meta: {
      ...meta,
      keepQuery: true,
    },
    props: {
      autoSetPrimary: true,
    },
  },
  {
    name: 'mon-compte_payment-methods_credit-card_set-primary',
    path: '/mon-compte/mes-moyens-de-paiements/cartes-bancaires/definir-carte-principal/:cardId',
    alias: '/mon-compte/mes-cartes-de-paiement/definir-carte-principal/:cardId',
    component: AccountCreditCardsSetPrimaryPage,
    meta: {
      ...meta,
      keepQuery: true,
    },
  },
  /*
   * Méthodes de paiements secondaires (Edenred, Swile, etc)
   */
  ...(LOGIN_PAYMENT_METHODS
    .map((pm) => ({
      name: pm.route.name,
      path: `/mon-compte/mes-moyens-de-paiements/${pm.name}`,
      component: AccountLoginPaymentMethodPage,
      meta: {
        ...meta,
        keepQuery: true,
      },
      props: {
        name: pm.name,
      },
    }))),
  {
    name: 'mon-compte_payment-methods_paygreen_add',
    path: '/mon-compte/mes-moyens-de-paiements/:name/ajouter',
    component: AccountPaygreenCardsAddPage,
    beforeEnter: paygreenPlatformGuard, // TODO don't work yet because we're in a panel
    meta: {
      ...meta,
      keepQuery: true,
    },
  },

  /*
   * Mes notifications
   */
  {
    name: 'mon-compte_notif',
    path: '/mon-compte/mes-notifications/',
    component: AccountNotificationsPage,
    meta,
  },

  /*
   * Mon carnet de recettes
   */
  {
    name: 'mon-compte_recipes',
    path: '/mon-compte/mon-carnet-de-recettes/',
    component: AccountRecipesPage,
    meta,
  },

  /*
   * Mes coupons
   */
  {
    name: 'mon-compte_coupons',
    path: '/mon-compte/mes-coupons/',
    component: AccountCouponsPage,
    meta,
  },
  {
    name: 'mon-compte_coupons_add',
    path: '/mon-compte/mes-coupons/ajouter',
    component: AccountCouponsAddPage,
    meta,
  },
  {
    name: 'mon-compte_coupons_automated',
    path: '/mon-compte/mes-coupons/application-automatique',
    component: AccountCouponsAutomatedPage,
    meta,
  },
  {
    name: 'mon-compte_luncheons',
    path: '/mon-compte/mes-titres-restaurant',
    component: AccountLuncheonPage,
    meta,
  },
  {
    name: 'mon-compte_luncheons_howto',
    path: '/mon-compte/mes-titres-restaurant/comment-ca-marche',
    component: AccountLuncheonHowToPage,
    meta,
  },
  {
    name: 'mon-compte_luncheons_add',
    path: '/mon-compte/mes-titres-restaurant/ajouter',
    component: AccountLuncheonAddPage,
    meta,
  },
  {
    name: 'mon-compte_luncheons_send',
    path: '/mon-compte/mes-titres-restaurant/envoyer/:batchId?',
    component: AccountLuncheonSendPage,
    meta,
  },

  /*
   * Parrainage
   */
  {
    name: 'mon-compte-sponsorship-my-code',
    path: '/mon-compte/parrainage/mon-code',
    component: AccountSponsorshipPage,
    meta,
  },

  /*
    * Divers
   */
  {
    name: 'mon-compte_settings',
    path: '/mon-compte/parametres',
    component: AccountSettingsPage,
    meta,
  },

  /*
   * Test modes
   */
  {
    name: 'test-mode',
    path: '/mon-compte/mode-test',
    component: AccountTestMode,
    meta: {
      ...meta,
      webview: true,
    },
    beforeEnter: testModeGuard,
  },
];
