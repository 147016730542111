<template>
  <potager-container class="relative z-10 -mb-20 bp1024:mb-0 bp1024:px-0 pt-24">
    <potager-picture
      background
      class="
        rounded-xl bp1024:rounded-none bp1024:pt-20 bp768:pt-12 bp1024:px-6 bp768:px-4
        flex justify-center bg-beryl-green overflow-hidden
      "
      src="b2b-en-situation">
      <div class="max-w-6/12 bp1024:max-w-full py-24 bp1024:py-0 relative z-10">
        <template v-if="!$mq.bp1024">
          <span class="absolute block top-1/2 -left-20 z-10 transform -translate-y-1/2 -translate-x-1/2">
            <potager-button
              class="js__b2b-slider-reviews__prev"
              is-circle
              theme="beryl-green">
              <template #icon>
                <icon-arrow-left />
              </template>
            </potager-button>
          </span>
          <span class="absolute block top-1/2 -right-20 z-10 transform -translate-y-1/2 translate-x-1/2 transition">
            <potager-button
              class="js__b2b-slider-reviews__next"
              is-circle
              theme="beryl-green">
              <template #icon>
                <icon-arrow-right />
              </template>
            </potager-button>
          </span>
        </template>

        <potager-slider
          key="b2b-slider-reviews"
          :items="quotes"
          :settings="getSettings"
          :slides-per-view="{
            default: 'auto',
            768: 1,
          }"
          name="b2b-slider-reviews"
          overflow-visible>
          <template #default="{ item }">
            <div
              :style="`max-width: ${getItemsMaxWidth}`"
              class="rounded-3x1 flex justify-center items-center h-full">
              <div class="flex flex-col bg-bianca h-full rounded-xl p-12 bp768:py-8 bp768:px-5">
                <div class="flex flex-row mb-6">
                  <highlights-icon
                    :direction="getLogoDirection"
                    :size="getLogoSize"
                    extraclass="shadow-sm"
                    theme="white">
                    <potager-picture
                      :src="item.logo"
                      class="w-full" />
                  </highlights-icon>

                  <div class="flex flex-col justify-center pl-2 rounded-lg">
                    <b class="text-xl">
                      {{ item.author }}
                    </b>
                    {{ item.from }}
                  </div>
                </div>

                <p
                  class="text-lg tracking-normal"
                  v-html="item.description" />
              </div>
            </div>
          </template>
        </potager-slider>
      </div>
      <div
        v-if="$mq.bp1024"
        class="bg-british-racing absolute h-16 bottom-0 left-0 w-full z-0" />
    </potager-picture>
  </potager-container>
</template>

<script>

import PotagerContainer from 'UI/PotagerContainer';
import PotagerSlider from 'UI/PotagerSlider';
import PotagerPicture from 'UI/PotagerPicture';
import PotagerButton from 'UI/PotagerButton';
import HighlightsIcon from 'Components/highlightsSlider/HighlightsIcon';
import IconArrowLeft from 'Icons/bold/IconArrowLeft';
import IconArrowRight from 'Icons/bold/IconArrowRight';

const BOLD_CLASSES = 'bg-champagne font-normal bp768:font-semibold';

export default {

  components: {
    PotagerContainer,
    PotagerSlider,
    PotagerPicture,
    PotagerButton,
    HighlightsIcon,
    IconArrowLeft,
    IconArrowRight,
  },

  data: () => ({
    quotes: [
      {
        logo: 'logo-blacksheep',
        description: `
          Excellent ! Chez Blacksheep, on adore les tomates cerises et les graines de tournesol.
          <b class="${BOLD_CLASSES}">Super pour connaître les fruits et légumes de saison et répondre
          à des petits creux</b> tout au long de notre journée!
        `,
        author: 'Eva',
        from: 'de Black Sheep',
      },
      {
        logo: 'logo-pall',
        description: `
          <b class="${BOLD_CLASSES}">Vos corbeilles nous plaisent depuis longtemps</b> et nous avons
          de plus en plus de personnes qui reviennent sur le site actuellement.
        `,
        author: 'Annie',
        from: 'de Pall France',
      },
      {
        logo: 'logo-mews',
        description: `
          <b class="${BOLD_CLASSES}">Les fruits sont très bons, divers et frais 🙂</b>.<br>
          Les clémentines vertes ont beaucoup intrigué nos collaborateurs et maintenant sont
          devenues les fruits préférés de la semaine !
        `,
        author: 'Adina',
        from: 'de Mews Partners',
      },
      {
        logo: 'logo-acritec',
        description: `
          Nous sommes livrés chaque semaine. <b class="${BOLD_CLASSES}">Les fruits sont très bons
          et de bonne qualité.</b>
        `,
        author: 'Gaëlle',
        from: 'd’Acritec',
      },
    ],
  }),

  computed: {
    getLogoSize() {
      return this.$mq.bp768 ? 'medium' : 'large';
    },
    getLogoDirection() {
      return this.$mq.bp768 ? '' : 'reverse';
    },
    getSettings() {
      const getCreativeEffect = (mode = 'prev') => ({
        translate: [`${150 * (mode === 'prev' ? -1 : 1)}%`, 0, 0],
        opacity: 0,
      });
      return {
        ...(!this.$mq.bp1024 ? {
          effect: 'creative',
          creativeEffect: {
            limitProgress: 2,
            prev: getCreativeEffect('prev'),
            next: getCreativeEffect('next'),
          },
        } : {}),
        navigation: {
          nextEl: '.js__b2b-slider-reviews__next',
          prevEl: '.js__b2b-slider-reviews__prev',
          disabledClass: 'opacity-0 pointer-events-none',
        },
        freeMode: this.$mq.bp1024,
      };
    },
    getItemsMaxWidth() {
      if (this.$mq.bp768) return '18.75rem';
      if (this.$mq.bp1024) return '29.5rem';
      return undefined;
    },
  },
};
</script>
