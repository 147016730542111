import RecetteDetailPage from 'Pages/flyingPages/RecetteDetailPage';
import ReplaceProductDetailPage from 'Pages/flyingPages/ReplaceProductDetailPage';
import ProductDetailPage from 'Pages/flyingPages/ProductDetailPage';
import SubscriptionDetailPage from 'Pages/flyingPages/subscriptionDetails/SubscriptionDetailPage';
import ArticleDetailPage from 'Pages/flyingPages/ArticleDetailPage';
import SupplierDetailPage from 'Pages/flyingPages/SupplierDetailPage';
import VarietyDetailPage from 'Pages/flyingPages/VarietyDetailPage';

const meta = {
  isFlying: true,
  scrollToTop: true,
};

export default [

  {
    name: 'recette-detail-item',
    path: '/fiche-recette/:slug/:id',
    component: RecetteDetailPage,
    meta,
  },

  {
    path: '/fiche-produit/historique/:slug/:id/:idRegion/:orderId',
    redirect: '/fiche-produit/:slug/:id/:idRegion/:orderId',
  },
  {
    name: 'replace-detail-product-item',
    path: '/fiche-produit/remplacer/:slug/:id/:idRegion/:orderId?',
    component: ReplaceProductDetailPage,
    meta: {
      ...meta,
      requiresAuth: true,
    },
  },
  {
    name: 'detail-product-item',
    path: '/fiche-produit/:slug/:id/:idRegion/:orderId?',
    component: ProductDetailPage,
    meta,
  },

  {
    name: 'detail-user-subscription-item',
    path: '/fiche-abonnement',
    component: SubscriptionDetailPage,
    meta: {
      ...meta,
      requiresAuth: true,
    },
  },

  {
    name: 'article-detail-item',
    path: '/fiche-article/:slug/:id',
    component: ArticleDetailPage,
    meta,
  },

  {
    name: 'supplier-detail-item',
    path: '/fiche-producteur/:slug/:id',
    component: SupplierDetailPage,
    meta,
  },

  {
    name: 'variety-detail-item',
    path: '/fiche-variete/:slug/:id',
    component: VarietyDetailPage,
    meta,
  },
];
