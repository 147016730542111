<template>
  <potager-button
    v-bind="$props"
    class="potager-social-button font-semibold mb-4"
    full-width
    @onClick="$emit('onClick')"
    @mouseover="isHover = true"
    @mouseout="isHover = false">
    <span
      class="potager-social-button__icon"
      :style="getIconStyle">
      <slot name="icon" />
    </span>
    <slot />
  </potager-button>
</template>

<script>
import PotagerButton, { props as potagerButtonProps } from 'UI/PotagerButton';
import { propsBuilder } from './Tools';

export default {

  components: {
    PotagerButton,
  },

  data: () => ({
    isHover: false,
  }),

  props: propsBuilder({
    ...potagerButtonProps,
    color: {
      type: String,
      required: false,
      default: null,
    },
  }),

  computed: {
    getIconStyle() {
      return this.color ? { color: this.color } : {};
    },
  },

};
</script>

<style lang="scss">
.potager-social-button {
  padding-left: 2.625rem;
  box-shadow: $boxshadow;

  &__icon {
    @apply text-xl;
    position: absolute;
    left: 2px;
    top: 2px;
    bottom: 2px;
    width: 2.625rem;
    border-radius: .25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: visible;
    background: $whiteSmoke;
    transition: background .3s ease;
  }

  @include hover() {
    box-shadow: none;

    .potager-social-button__icon {
      background: darken($white, 5%);
    }
  }

}
</style>
