import { mapGetters } from 'vuex';
import { isNextWeek } from 'Classes/Dates';
import OrderActionMixin from 'Mixins/OrderActionMixin';

export default {
  mixins: [
    OrderActionMixin,
  ],

  computed: {
    ...mapGetters('user', [
      'getSubscription',
      'getSubscriptionOrder',
      'isUserLoaded',
      'isSubscriptionActive',
      'getNextGenerationDate',
      'getNextDeliveredTimeslot',
      'isSubscriptionSuspended',
    ]),
    subscriptionOrderOrSubscription() {
      return this.getSubscriptionOrder || this.getSubscription;
    },
    isNotYetCreated() {
      return this.getNextGenerationDate && !this.getSubscriptionOrder;
    },
    getNextDeliveryDate() {
      const orderTimeSlot = isNextWeek(this.getSubscriptionOrder?.date)
        ? this.getSubscriptionOrder?.groupDeliveryDay?.timeSlot
        : this.getSubscriptionOrder?.groupDeliveryDay?.currentWeekTimeSlot;
      let timeSlot = orderTimeSlot || this.getNextDeliveredTimeslot;
      return timeSlot?.date;
    },
  },
};
