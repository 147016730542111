<template>
  <div class="blog blog--list">
    <potager-container>
      <heading
        :background="null"
        modifiers="blog-list">
        <template #title>
          Tous les
          <potager-spotlight
            inline-block
            type="scribble"
            :fill="wildWillow">
            contenus
          </potager-spotlight>
          <br>
          de Potager City
        </template>
      </heading>
    </potager-container>

    <blog-body>
      <content-list
        :key="`${$route.name}-${search}`"
        :items="items"
        :subtitle="false">
        <template v-if="search && categorizedItems.length">
          <div
            v-for="(category, i) in categorizedItems"
            class="content-list content-list__categorized"
            :key="i">
            <p class="content-list__categorized__content__title">
              {{ pluralize(category.nbResults, category.label) }}
              avec
              «&nbsp;<span class="content-list__categorized__content__title__query">{{ search }}</span>&nbsp;»
            </p>

            <potager-row class="content-list__categorized__content">
              <potager-col
                v-for="(item, j) in category.items"
                class="content-list-page__item bp640:mb-0"
                :key="j"
                col="4"
                bp1024="6"
                bp640="12">
                <card-content
                  :content="item"
                  class="bp640:pb-4 bp640:mb-4" />
              </potager-col>

              <potager-col class="content-list__categorized__content__button-wrapper">
                <potager-button
                  :to="{
                    name: category.button.redirect.name,
                    params: { search },
                  }"
                  theme="stroke"
                  :full-width="$mq.bp768"
                  :label="category.button.label" />
              </potager-col>
            </potager-row>
          </div>
        </template>
      </content-list>
    </blog-body>

    <push-subscription class="mb-10 bp768:my-6" />
  </div>
</template>

<script>
import ToolsMixin from 'Mixins/ToolsMixin';

import PotagerContainer from 'UI/PotagerContainer';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerButton from 'UI/PotagerButton';
import PotagerSpotlight from 'UI/PotagerSpotlight';

import ContentList from 'Components/content/ContentList';
import CardContent from 'Components/cards/CardContent';
import Heading from 'Components/heading/Heading';
import BlogBody from 'Components/blog/BlogBody';
import PushSubscription from 'Components/pushs/PushSubscription';

import COLORS from 'Settings/colors';

import { PLACEHOLDER_BONCOING_ITEMS } from 'Classes/Placeholders';
import { pluralize } from 'Filters';

const { wildWillow } = COLORS;

export default {

  mixins: [
    ToolsMixin,
  ],

  components: {
    PushSubscription,
    PotagerContainer,
    PotagerRow,
    PotagerCol,
    PotagerButton,
    PotagerSpotlight,
    Heading,
    BlogBody,
    ContentList,
    CardContent,
  },

  data: () => ({
    wildWillow,
    hub: [{
      title: 'Recettes',
      image: 'content-recipes',
      redirect: { name: 'recettes-list' },
      type: 'hub',
    }, {
      title: 'Produits',
      image: 'content-products',
      redirect: { name: 'products-list' },
      type: 'hub',
    }, {
      title: 'Producteurs',
      image: 'content-suppliers',
      redirect: { name: 'suppliers-list' },
      type: 'hub',
    }, {
      title: 'Articles',
      image: 'content-reports',
      redirect: { name: 'reports-list' },
      type: 'hub',
    }],
    items: [],
    categorizedItems: [],
  }),

  props: {
    search: {
      type: String,
      required: false,
      default: null,
    },
  },

  watch: {
    search() {
      this.fetchData();
    },
  },

  head() {
    return this.generateMetaIfPanelClosed({
      title: 'Les contenus de Potager City',
    });
  },

  methods: {
    pluralize,
    fetchData() {
      if (!this.search) {
        this.items = this.hub;
        this.categorizedItems = [];
      } else {
        const { search: research } = this;
        const page = 1;
        const maxPerPage = 6;

        this.items = PLACEHOLDER_BONCOING_ITEMS;
        this.categorizedItems = [];
        this.$wait.start('FETCH_CONTENT_LIST');

        Promise.all([
          this.$api.recipe.findAll({ research, page, maxPerPage }),
          this.$api.supplierProducts.findAll({ research, page, maxPerPage }),
          this.$api.supplier.findAll({ research, page, maxPerPage }),
          this.$api.report.findAll({ research, page, maxPerPage }),
        ]).then((responses) => {
          this.items = [];
          this.categorizedItems = [];
          responses.forEach((response) => {
            if (response.data.data && response.data.data.results) {
              const items = response.data.data.results;
              this.items = [...this.items, ...items];

              if (items.length) {
                const { type } = items[0];
                const label = this.getLabel(type);
                const button = {
                  label: this.getButtonLabel(type),
                  redirect: this.getButtonRedirect(type),
                };
                const nbResults = parseInt(response.data.data.nbResults, 10);
                this.categorizedItems.push({
                  type,
                  label,
                  button,
                  items,
                  nbResults,
                });
              }
            }
          });
        }).finally(() => {
          this.$wait.end('FETCH_CONTENT_LIST');
        });
      }
    },
    getLabel(type) {
      if (type === 'recipe') return 'recette';
      if (type === 'supplier-product') return 'produit';
      if (type === 'supplier') return 'producteur';
      if (type === 'report') return 'article';
      return 'résultat';
    },
    getButtonLabel(type) {
      if (type === 'recipe') return 'Toutes nos recettes';
      if (type === 'supplier-product') return 'Tous nos produits';
      if (type === 'supplier') return 'Tous nos producteurs';
      if (type === 'report') return 'Tous nos articles';
      return 'Tous les résultats';
    },
    getButtonRedirect(type) {
      if (type === 'recipe') return { name: 'recettes-list' };
      if (type === 'supplier-product') return { name: 'products-list' };
      if (type === 'supplier') return { name: 'suppliers-list' };
      if (type === 'report') return { name: 'reports-list' };
      return { name: 'content-list' };
    },
  },

  mounted() {
    this.fetchData();
  },

};
</script>
