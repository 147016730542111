<template>
  <panel
    :back-route="$route.query.from ? { name: $route.query.from } : undefined"
    back-btn
    class="basket-subscription"
    title="⚡ Avantages abonné">
    <template #header>
      <p class="text-center">
        Chaque semaine, découvrez le contenu de votre panier et profitez d’avantages (parce que la fidélité, ça se
        récompense !).
      </p>
    </template>

    <div class="panel-section bg-transparent p-0">
      <div class="panel-section__separator" />

      <subscriber-benefits-list separators />

      <potager-picture
        class="w-full rounded-xl overflow-hidden mt-6"
        src="concept-1" />
    </div>

    <template
      v-if="!isSubscriptionActive"
      #footer>
      <potager-button
        :to="{ name: 'tunnel' }"
        class="w-full"
        label="Découvrir les paniers"
        theme="go-green" />
    </template>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerButton from 'UI/PotagerButton';
import PotagerPicture from 'UI/PotagerPicture';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { CONTEXT_BASKET } from 'Classes/Constants';

import Panel from 'Components/panel/Panel';

import SubscriberBenefitsList from 'Components/subscriberBenefits/SubscriberBenefitsList';

export default {
  components: {
    SubscriberBenefitsList,
    PotagerPicture,
    Panel,
    PotagerButton,
  },

  data() {
    return {
      CONTEXT_BASKET,
    };
  },

  computed: {
    ...mapGetters('user', [
      'isSubscriptionActive',
    ]),
  },

  metaInfo: MetaInfosService.generate({
    title: 'Avantages abonné',
  }),
};
</script>
