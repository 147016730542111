<template>
  <flying-page
    ref="flyingPage"
    :next="next"
    :order="order"
    :previous="previous">
    <div class="product-detail">
      <product-detail-tags :box="box" />

      <div class="product-detail__poster relative">
        <transition name="fade">
          <div
            v-if="headPictureLoaded"
            :class="[
              'absolute -inset-4 bg-charcoal',
            ]">
            <div
              :style="{ backgroundImage: `url(${headPictureLoaded})` }"
              class="absolute inset-0 bg-cover bg-center opacity-70 blur-sm" />
          </div>
        </transition>

        <potager-picture
          v-if="box.image"
          :src="box.image"
          :title="box.name"
          class="absolute inset-0 shadow"
          extra-img-class="mx-auto"
          no-lazy-load
          placeholder
          ratio="1/1"
          @onLoaded="headPictureLoaded = $event?.src" />

        <template v-if="!readonly">
          <transition name="fade">
            <div
              v-if="box.deliveryDate && !box.deliveryDate.isCurrentWeek"
              class="product-detail__poster__message">
              Non disponible à la vente pour le moment
            </div>

            <div
              v-else-if="box.isSoldOut"
              class="product-detail__poster__message product-detail__poster__message--success">
              Victime de son succès
            </div>

            <div
              v-else-if="nbItems"
              class="product-detail__poster__message product-detail__poster__message--success">
              {{ pluralize(nbItems, 'élément ajouté', 'éléments ajoutés') }} au panier
            </div>
          </transition>
        </template>

        <div class="product-detail__poster__nav">
          <button
            v-if="previous"
            class="product-detail__poster__nav__btn product-detail__poster__nav__btn--prev"
            @click="nav('prev')">
            <icon-arrow-left1 />
          </button>

          <button
            v-if="next"
            class="product-detail__poster__nav__btn product-detail__poster__nav__btn--next"
            @click="nav('next')">
            <icon-arrow-right1 />
          </button>
        </div>
      </div>

      <div class="product-detail__content">
        <product-detail-heading
          :box="box"
          @scrollTo="scrollTo" />

        <product-detail-products-list
          :box="box" />

        <template v-if="box && !box.loading">
          <section
            v-if="!readonly"
            class="product-detail__section">
            <potager-info
              v-if="tooLateMessage && !box.deliveryDate.isCurrentWeek"
              status="warning">
              <template #icon>
                <icon-road-sign-warning />
              </template>
              {{ tooLateMessage }}
            </potager-info>

            <potager-info v-else>
              <template #icon>
                <icon-information-circle />
              </template>
              {{ disponibilityMessage }}
            </potager-info>
          </section>

          <upselling-slider
            v-if="!readonly"
            :exclude="idProduit"
            flying />

          <product-detail-associated-contents
            ref="associatedContents"
            :box="box" />

          <section
            v-if="boxesRelated && !readonly"
            class="product-detail__section print:hidden">
            <h3 class="product-detail__subtitle">
              Disponible actuellement
            </h3>

            <potager-row>
              <potager-col
                v-for="(boxRelated, index) in boxesRelated"
                :key="index"
                col="12">
                <card-product
                  :box="boxRelated"
                  landscape />
              </potager-col>
            </potager-row>
          </section>

          <order-summary :order="order" />

          <section
            v-if="!isSubscriptionActive"
            class="product-detail__section">
            <push-subscription condensed />
          </section>
        </template>
      </div>
    </div>

    <template
      v-if="!readonly"
      #footer>
      <product-tools
        :box="box"
        :nb-items="nbItems" />
    </template>

    <template
      v-else-if="canReplaceSubscriptionBox"
      #footer>
      <div class="subscription-detail__footer">
        <div class="subscription-detail__footer__container">
          <potager-row>
            <potager-col>
              <potager-button
                :to="{ name: 'mon-compte_subscription_replace' }"
                full-width
                theme="stroke">
                Remplacer mon panier
              </potager-button>
            </potager-col>
          </potager-row>
        </div>
      </div>
    </template>

    <micro-datas
      v-if="!extendedBox.loading"
      :content="extendedBox"
      context="oneshot" />
  </flying-page>
</template>

<script>
import { mapGetters } from 'vuex';

import FlyingPageMixin from 'Mixins/FlyingPageMixin';
import GtmMixin from 'Mixins/GtmMixin';

import IconArrowLeft1 from 'Icons/regular/IconArrowLeft1';
import IconArrowRight1 from 'Icons/regular/IconArrowRight1';
import IconRoadSignWarning from 'Icons/regular/IconRoadSignWarning';
import IconInformationCircle from 'Icons/regular/IconInformationCircle';

import ProductDetailTags from 'Pages/flyingPages/ProductDetailComponents/ProductDetailTags';
import ProductDetailHeading from 'Pages/flyingPages/ProductDetailComponents/ProductDetailHeading';
import ProductDetailProductsList from 'Pages/flyingPages/ProductDetailComponents/ProductDetailProductsList';
import ProductDetailAssociatedContents from 'Pages/flyingPages/ProductDetailComponents/ProductDetailAssociatedContents';

import PotagerButton from 'UI/PotagerButton';
import PotagerPicture from 'UI/PotagerPicture';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerInfo from 'UI/PotagerInfo';

import ProductTools from 'Components/productTools/ProductTools';
import OrderSummary from 'Components/order/OrderSummary';

import { calcSlug } from 'Classes/Tools';
import { pluralize, tmsToFormat } from 'Filters';

import MetaInfosService from 'Classes/services/MetaInfoService';
import filter from 'lodash/filter';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';

export default {

  mixins: [
    FlyingPageMixin,
    GtmMixin,
  ],

  components: {
    IconRoadSignWarning,
    IconInformationCircle,
    IconArrowLeft1,
    IconArrowRight1,
    PotagerRow,
    PotagerCol,
    PotagerButton,
    PotagerPicture,
    PotagerInfo,
    ProductDetailTags,
    ProductDetailHeading,
    ProductDetailAssociatedContents,
    ProductDetailProductsList,
    ProductTools,
    OrderSummary,
  },

  data: () => ({
    box: { loading: true },
    boxesRelated: [],
    headPictureLoaded: false,
  }),

  watch: {
    $potagerRoute() {
      this.fetchData();
    },
  },

  computed: {
    ...mapGetters('basket', [
      'getProducts',
    ]),
    ...mapGetters('dashboard', [
      'getMiniBoxes',
    ]),
    ...mapGetters('user', [
      'getOrderById',
    ]),
    ...mapGetters('session', [
      'getTestModeDate',
    ]),
    isLoading() {
      return !this.box || this.box.loading;
    },
    idProduit() {
      return parseInt(this.$potagerRoute.params.id, 10);
    },
    idRegion() {
      return parseInt(this.$potagerRoute.params.idRegion, 10);
    },
    orderId() {
      return this.$potagerRoute.params.orderId ? parseInt(this.$potagerRoute.params.orderId, 10) : null;
    },
    extendedBox() {
      const box = { ...this.box };
      if (this.boxesRelated.length) box.related = this.boxesRelated;
      return box;
    },
    nbItems() {
      if (this.box.loading) return 0;
      return filter(this.getProducts, ['id', this.box.id]).length;
    },
    isComplementary() {
      return this.box.isComplementary;
    },
    getProductsPool() {
      return this.isComplementary ? this.getMiniBoxes : this.getDashboardProducts;
    },
    previous() {
      if (this.currentDashboardIndex >= 0 && !this.box.loading && !this.readonly) {
        const index = this.currentDashboardIndex === 0 ? this.getProductsPool.length - 1 : this.currentDashboardIndex - 1;
        const item = this.getProductsPool[index];
        if (item) return this.calcBoxRoute(item);
      }
      return null;
    },
    next() {
      if (this.currentDashboardIndex >= 0 && !this.box.loading && !this.readonly) {
        const index = this.currentDashboardIndex === this.getProductsPool.length - 1 ? 0 : this.currentDashboardIndex + 1;
        const item = this.getProductsPool[index];
        if (item) return this.calcBoxRoute(item);
      }
      return null;
    },
    currentDashboardIndex() {
      if (this.getProductsPool && this.box.id) {
        return findIndex(this.getProductsPool, (item) => item.id === this.box.id);
      }
      return null;
    },
    disponibilityDates() {
      const {
        startDate,
        endDate
      } = this.box.deliveryDate;
      if (!startDate || !endDate) {
        return {
          from: null,
          to: null
        };
      }
      return {
        from: tmsToFormat(startDate, 'iiii dd MMMM'),
        to: tmsToFormat(endDate, 'iiii dd MMMM'),
      };
    },
    disponibilityMessage() {
      const {
        from,
        to
      } = this.disponibilityDates;
      if (!from || !to) return null;
      return `Disponible pour tous les retraits du ${from} au ${to} (${this.box.region.shortName}).`;
    },
    tooLateMessage() {
      const {
        from,
        to
      } = this.disponibilityDates;
      if (!from || !to) return null;
      return `Ce panier n’est actuellement pas disponible à la vente : sa dernière période de vente
        remonte aux livraisons du ${from} au ${to}.`;
    },
    order() {
      return this.getOrderById(this.orderId);
    },
    canReplaceSubscriptionBox() {
      if (this.order && this.order.containsSubscriptionBox && this.order.isEditable && this.box.isTypeFormule) {
        return findIndex(this.order.boxes, (box) => box.id === this.box.id) !== -1;
      }
      return false;
    },
    readonly() {
      return !!this.orderId;
    },
  },

  methods: {
    pluralize,
    resetData() {
      this.box = { loading: true };
      this.headPictureLoaded = false;
      this.boxesRelated = [];
    },
    fetchData() {
      this.resetData();

      this.getBox()
        .then(() => {
          if (!this.readonly) this.getBoxesRelated();
          if (!this.isLoaded) this.fetchDashboard();
        });
    },
    getBox() {
      return new Promise((resolve) => {
        if (this.orderId) {
          this.getBoxFromOrder()
            .then((resolve));
        } else {
          const promise = this.isLoggedIn
            ? this.$api.box.findOneWithHistoryByBasket(this.idRegion, this.idProduit, 'basket', this.getTestModeDate)
            : this.$api.box.findOneWithHistory(this.idRegion, this.idProduit);

          this.$wait.start('GET_BOX');
          promise
            .then(({ data }) => {
              this.box = data;
              this.trackBoxImpression(this.box);
              resolve();
            })
            .catch(() => this.$events.emit('404'))
            .finally(() => this.$wait.end('GET_BOX'));
        }
      });
    },
    getBoxFromOrder() {
      return new Promise((resolve) => {
        this.getOrder()
          .then((order) => {
            this.findBox(order.boxes)
              .then((box) => {
                this.box = box;
                this.trackBoxImpression(this.box);
                resolve();
              })
              .catch(() => this.$events.emit('404'));
          });
      });
    },
    getOrder() {
      return new Promise((resolve) => {
        if (this.order) {
          resolve(this.order);
        } else {
          this.$api.order.getOneById(this.orderId)
            .then((resp) => resolve(resp.data.data))
            .catch(() => this.$events.emit('404'));
        }
      });
    },
    findBox(boxes) {
      return new Promise((resolve, reject) => {
        const box = boxes.filter((b) => b.id === this.idProduit);
        if (box?.[0]) {
          resolve(box[0]);
        } else {
          reject(new Error('Une erreur est survenue'));
        }
      });
    },
    getBoxesRelated() {
      this.$wait.start('GET_BOXES_RELATED');
      this.$api.box.findAllRelated(this.idRegion, this.idProduit)
        .then(({ data }) => {
          this.boxesRelated = data;
        })
        .finally(() => this.$wait.end('GET_BOXES_RELATED'));
    },
    calcBoxRoute(box) {
      if (box.itemType === 'product' || box.isComplementary) {
        return {
          name: 'detail-product-item',
          params: {
            id: box.id,
            idRegion: box.region.id,
            slug: calcSlug(box.name),
          },
        };
      }
      return null;
    },
    scrollTo(ref) {
      if (this.$refs.associatedContents && this.$refs.associatedContents.$refs[ref]) {
        this.$events.emit('request:scrollToElement', this.$refs.associatedContents.$refs[ref].$el);
      }
    },
    nav(direction) {
      if (this.$refs.flyingPage) {
        this.$refs.flyingPage.nav(direction);
      }
    },
  },

  mounted() {
    this.fetchData();
  },

  head() {
    let metaInfo = {};
    if (this.box.id) {
      metaInfo = {
        title: `${this.box.name} (${this.box.region.shortName})`,
        description: this.box.descriptionOfWeek,
        image: this.box.image ? this.box.image.url : null,
        meta: [],
      };

      // #PTCY-3330
      if (this.box.isAssociableToVouchers) {
        metaInfo.meta.push(
          {
            name: 'robots',
            content: 'noindex, nofollow'
          },
        );
      }
    }

    if (!isEmpty(metaInfo)) {
      return MetaInfosService.generate(metaInfo);
    }

    return metaInfo;
  },
};
</script>
