import { getPaymentErrorMessage } from 'Classes/PaymentMethodTools';
import { generic_decline } from 'Components/stripe/StripeErrors';

export default {
  default: {
    text: generic_decline,
    handle(response, error) {
      if (response.errors && response.errors.globals && response.errors.globals[0]) {
        error.text = getPaymentErrorMessage(response.errors.globals[0].metadata);
      }
    },
  },
};
