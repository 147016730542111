<template>
  <div class="">
    <div class="mb-2 last:mb-0">
      <p
        ref="content"
        :class="{
          'line-clamp-2': ellipsis,
        }">
        <slot />
      </p>
    </div>
    <button
      v-if="isEllipsis"
      class="font-bold underline bg-transparent p-0 border-0 text-charcoal"
      @click="onClick">
      {{ ellipsis ? 'Afficher la suite' : 'Masquer' }}
    </button>
  </div>
</template>

<script>
import ModalInfo from 'Modals/ModalInfo';

export default {

  data: () => ({
    ellipsis: true,
    isEllipsis: false,
  }),

  props: {
    modal: {
      type: Boolean,
      required: false,
      default: false,
    },
    modalTitle: {
      type: String,
      required: false,
      default: 'Informations',
    },
  },

  methods: {
    onClick() {
      if (this.modal) {
        this.$modal.open(ModalInfo, {
          title: this.modalTitle,
          text: this.$slots.default?.[0]?.text,
        });
      } else {
        this.ellipsis = !this.ellipsis;
      }
    },
    checkIfEllipsis() {
      const { content } = this.$refs;

      if (content) {
        const css = window.getComputedStyle(content);
        const rect = content.getBoundingClientRect();
        const clone = document.createElement('div');
        const text = document.createTextNode(content.textContent);
        clone.appendChild(text);

        clone.style.fontSize = css.getPropertyValue('font-size');
        clone.style.lineHeight = css.getPropertyValue('line-height');
        clone.style.fontWeight = css.getPropertyValue('font-weight');
        clone.style.width = `${rect.width}px`;
        clone.style.position = 'absolute';
        clone.style.visibility = 'hidden';
        clone.style.top = 0;
        clone.style.left = 0;
        clone.style.pointerEvents = 'none';

        document.body.appendChild(clone);

        const cloneRect = clone.getBoundingClientRect();
        this.isEllipsis = cloneRect.height > rect.height;

        clone.parentNode.removeChild(clone);
      } else {
        this.isEllipsis = false;
      }
    },
  },

  mounted() {
    this.checkIfEllipsis();
  },

};
</script>
