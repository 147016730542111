export const subscriptionBenefits = [
  {
    title: 'Moins cher',
    list: [
      '<b>1€ de réduction</b> sur les paniers composés',
      '<b>10% de réduction</b> sur les produits à l’unité',
      '<b>0€ de minimum d’achat</b> (non abonné : 13,50€)',
    ],
  },
  {
    title: 'Plus pratique',
    list: [
      '<b>Arrêtez votre abo</b> quand vous le souhaitez',
      '<b>Sautez vos livraisons</b> en 1 clic',
      '<b>Remplacez/composez votre panier</b> s’il ne vous convient pas',
    ],
  },
];

export default {
  subscriptionBenefits,
};
