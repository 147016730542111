/* eslint-disable object-curly-newline */
import BREAKPOINTS from 'Settings/breakpoints';

export const test = ({ expectedValue }) => window.innerWidth < BREAKPOINTS[expectedValue];

export default {
  type: 'screen',
  values: Object.keys(BREAKPOINTS),
  test,
};
