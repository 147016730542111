import { CONTEXT_SUBSCRIPTION, CONTEXT_SUBSCRIPTION_BASKET } from 'Classes/Constants';

export const test = ({ rootGetters, expectedValue }) => {
  const subscriptionBasketAddress = rootGetters['session/getAddress'](CONTEXT_SUBSCRIPTION_BASKET);
  const subscriptionAddress = rootGetters['session/getAddress'](CONTEXT_SUBSCRIPTION);
  const address = subscriptionBasketAddress || subscriptionAddress;

  return !!address === expectedValue;
};

export default {
  type: 'hasHomeDeliveryAddress',
  values: [true, false],
  test,
};
