// get a string and return formatted string with usefull keywords
export const getResearchWords = (str) => {
  const entry = str?.toLowerCase();

  if (entry) {
    const entrySplit = entry.split(' ');
    const specialCasesIncludes = [
      'pomme de terre',
      'fruit de la passion',
      'jeune pousse',
      'noix de coco',
      'petit pois',
      'fruits secs',
      { name: 'trompette de la mort', research: 'champignon' },
      { name: 'petit épeautre', research: 'graine' },
    ];

    let research = entrySplit[0];

    // if the name contain one of the special cases, we return the whole special case
    // else we return the first word
    specialCasesIncludes
      .forEach((specialCase) => {
        const specialCaseName = typeof specialCase === 'object' ? specialCase.name : specialCase;
        const specialCaseResearch = typeof specialCase === 'object' ? specialCase.research : specialCase;
        if (entry.includes(specialCaseName)) {
          research = specialCaseResearch;
        }
      });

    return research;
  }

  return null;
};
