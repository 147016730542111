export const ONE_SHOT_ENTERPRISE = 'priceOneShotEnterprise';
export const ONE_SHOT = 'priceOneShot';
export const SUBSCRIPTION = 'priceSubscription';
export const SUBSCRIPTION_ENTERPRISE = 'priceSubscriptionEnterprise';

export const isSubscription = (priceCategory) => {
  return priceCategory === SUBSCRIPTION || priceCategory === SUBSCRIPTION_ENTERPRISE;
};

export const getBlockedOutPrice = (priceCategory, price) => {
  switch (priceCategory) {
    case SUBSCRIPTION:
    case SUBSCRIPTION_ENTERPRISE:
      return price.blockedOutSubscription;
    case ONE_SHOT:
    case ONE_SHOT_ENTERPRISE:
    default:
      return price.blockedOutOneShot;
  }
};

export const getPriceBox = (priceCategory, product) => {
  switch (priceCategory) {
    case SUBSCRIPTION_ENTERPRISE:
    case SUBSCRIPTION:
      return product.price.subscription;
    case ONE_SHOT_ENTERPRISE:
    case ONE_SHOT:
    default:
      return product.price.oneShot;
  }
};

export const getPriceOrder = (priceCategory, price) => {
  return price[priceCategory] || price.priceOneShot;
};

export const getSubscriptionPriceOrder = (priceCategory, order) => {
  switch (priceCategory) {
    case SUBSCRIPTION_ENTERPRISE:
    case ONE_SHOT_ENTERPRISE:
      return getPriceOrder(SUBSCRIPTION_ENTERPRISE, order);
    case SUBSCRIPTION:
    case ONE_SHOT:
    default:
      return getPriceOrder(SUBSCRIPTION, order);
  }
};

export const getPublicPriceOrder = (priceCategory, order) => {
  return getPriceOrder(ONE_SHOT, order);
};

export const getSubTotalOrder = (order) => {
  switch (order.priceCategory) {
    case SUBSCRIPTION:
    case SUBSCRIPTION_ENTERPRISE:
      return order.priceSubscription.subTotal;
    case ONE_SHOT_ENTERPRISE:
    case ONE_SHOT:
    default:
      return order.priceOneShot.subTotal;
  }
};

export const getSubscriptionEnterpriseDiscount = (order) => {
  switch (order.priceCategory) {
    case SUBSCRIPTION_ENTERPRISE:
      return order.priceSubscriptionEnterprise?.enterpriseDiscount;
    default:
      return '0.00';
  }
};

export const getTotalOrder = (order) => {
  if (order && order.priceCategory) {
    return order[order.priceCategory]?.total || order.priceOneShot?.total;
  }

  return '0.00';
};

export const getCouponsTotalOrder = (order) => {
  return Number(order[order.priceCategory]?.totalCoupons || order.priceOneShot?.totalCoupons);
};

export const getShippingCostOrder = (order) => {
  return Number(order[order.priceCategory]?.shippingCost || order.priceOneShot?.shippingCost);
};

export const getSubscriberReduction = (order) => {
  const publicPrice = getPublicPriceOrder(order.priceCategory, order).subTotal;
  const subscriberPrice = getSubscriptionPriceOrder(order.priceCategory, order).subTotal;
  return publicPrice - subscriberPrice;
};

export default {
  ONE_SHOT_ENTERPRISE,
  ONE_SHOT,
  SUBSCRIPTION,
  SUBSCRIPTION_ENTERPRISE,
  isSubscription,
  getBlockedOutPrice,
  getPriceBox,
  getPriceOrder,
  getSubscriptionPriceOrder,
  getPublicPriceOrder,
  getSubTotalOrder,
  getSubscriptionEnterpriseDiscount,
  getTotalOrder,
  getCouponsTotalOrder,
  getShippingCostOrder,
  getSubscriberReduction,
};
