<template>
  <panel
    :back-route="backRoute"
    title="Moyens de paiement">
    <div
      v-for="pmSection in paymentMethods"
      :key="pmSection.type"
      class="panel-section">
      <div
        v-if="pmSection.title && pmSection.pm.length > 1"
        class="panel-section__subtitle">
        {{ pmSection.title }}
      </div>

      <potager-navigator
        v-for="PM in pmSection.pm"
        :key="PM.name"
        v-gtm
        :to="PM.route"
        class="items-center">
        <template #icon>
          <span class="relative">
            <component
              :is="PM.defaultIcon"
              :style="{ color: PM.colors?.primary }" />

            <potager-badge
              v-if="getPaymentMethodCount(PM) > 0"
              class="absolute -top-1 -right-3"
              rounded
              size="mini"
              theme="british-racing">
              {{ getPaymentMethodCount(PM) }}
            </potager-badge>
          </span>
        </template>

        <div class="mr-1 first-letter:uppercase inline-block">
          {{ PM.label }}
        </div>

        <div
          v-if="PM.description"
          class="text-xs.5 text-gray-500">
          {{ PM.description }}
        </div>
      </potager-navigator>
    </div>

    <div class="panel-section">
      <potager-navigator
        v-gtm
        :to="{ name: 'mon-compte_luncheons' }">
        Titres restaurant
        <potager-badge
          v-if="getCouponsLuncheon.length"
          size="small">
          {{ getCouponsLuncheon.length }}
        </potager-badge>
      </potager-navigator>
    </div>
  </panel>
</template>

<script>
import { mapGetters } from 'vuex';

import Panel from 'Components/panel/Panel';
import PotagerNavigator from 'UI/PotagerNavigator';
import PotagerBadge from 'UI/PotagerBadge';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { PAYMENT_METHODS, PRIMARY_PAYMENT_METHODS, SECONDARY_PAYMENT_METHODS } from 'Classes/paymentMethods';
import { PAYMENT_METHODS_TYPES, PAYMENT_METHODS_INTEGRATIONS } from 'Classes/paymentMethods/Constants';

export default {
  data: () => ({
    PAYMENT_METHODS,
  }),

  computed: {
    ...mapGetters('user', [
      'getPaymentAccountByName',
      'getBankCards',
      'getAllCards',
      'getPaymentGateway',
      'getCouponsLuncheon',
    ]),
    backRoute() {
      return this.$potagerRoute.query.back
        ? JSON.parse(decodeURIComponent(this.$potagerRoute.query.back))
        : { name: 'mon-compte' };
    },
    primaryPM() {
      const currentIntegration = this.getPaymentGateway === PAYMENT_METHODS_INTEGRATIONS.PAYGREEN
        ? PAYMENT_METHODS_INTEGRATIONS.PAYGREEN
        : PAYMENT_METHODS_INTEGRATIONS.STRIPE;
      return PRIMARY_PAYMENT_METHODS
        .filter((PM) => PM.integration === currentIntegration);
    },
    secondaryPM() {
      return SECONDARY_PAYMENT_METHODS;
    },
    paymentMethods() {
      return [
        {
          title: 'Cartes bancaires',
          type: 'primary',
          pm: this.primaryPM,
        },
        {
          title: 'Cartes restaurant',
          type: 'secondary',
          pm: this.secondaryPM,
        }
      ];
    },
  },

  components: {
    PotagerBadge,
    Panel,
    PotagerNavigator,
  },

  methods: {
    getPaymentMethodCount(pm) {
      // edenred and swile (restaurant payment accounts)
      if (pm.integration === PAYMENT_METHODS_INTEGRATIONS.DIRECT_ACCOUNT) {
        return this.getPaymentAccountByName(pm.name) ? 1 : 0;
      }
      // bank cards
      if (pm.type === PAYMENT_METHODS_TYPES.BANK_CARD) {
        return this.getBankCards.length;
      }
      // restaurant payment cards
      return this.getAllCards.filter((card) => card.issuer === pm.issuer).length;
    },
  },

  head: MetaInfosService.generate({
    title: 'Moyens de paiements',
  }),
};
</script>
