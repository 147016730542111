<template>
  <div
    class="main-header-actions flex justify-end w-3/12">
    <potager-button
      :to="isLoggedIn ? { name: 'mon-compte' } : { name: 'login' }"
      class="mr-2 rounded-full font-semibold text-center flex justify-center mx-auto max-w-[160px]"
      theme="bianca">
      <template #icon>
        <icon-single-neutral />
      </template>

      {{ isLoggedIn ? name : 'Connexion' }}
    </potager-button>

    <basket-button />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerButton from 'UI/PotagerButton';

import IconSingleNeutral from 'Icons/bold/IconSingleNeutral';

import BasketButton from 'Components/basket/BasketButton';

export default {
  name: 'MainHeaderActions',

  components: {
    PotagerButton,
    IconSingleNeutral,
    BasketButton,
  },

  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    ...mapGetters('user', [
      'getFirstName',
      'getLastName',
    ]),
    name() {
      if (this.$mq.bp640) {
        return `${this.getFirstName.charAt(0)}${this.getLastName.charAt(0)}`;
      }

      return this.getFirstName;
    },
  },
};
</script>
