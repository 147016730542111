<template>
  <card-subscription-loading
    v-if="!getEntity || getEntity.loading"
    :is-white="isWhite" />

  <div
    v-else
    :class="{
      'card-subscription': true,
      'card-subscription--white': isWhite,
    }"
    @click="onCardClick">
    <potager-background
      v-if="getRegionPreview"
      background-size="100% 100%"
      class="card-subscription__label"
      src="background-card-label.svg">
      En avant-première en {{ getRegionPreview.shortName }}
      <icon-ptcy-france
        :style="{ color: getCardColor }"
        class="card-subscription__label__icon" />
    </potager-background>

    <potager-picture
      :src="getImg"
      :title="getName"
      background
      class="card-subscription__header"
      placeholder />

    <div class="card-subscription__body">
      <h3 class="card-subscription__name">
        <potager-spotlight
          :color="colors.white"
          :fill="getCardColor"
          block
          type="brush">
          {{ getName }}
          <icon-ptcy-bio
            v-if="isBio"
            class="card-subscription__name__icon" />
        </potager-spotlight>
      </h3>

      <p class="card-subscription__desc">
        {{ getDesc }}
      </p>

      <ul
        v-if="getFormats"
        class="card-subscription__formats">
        <li
          v-for="format in getFormats"
          :key="format.name"
          class="card-subscription__formats__item">
          <div class="card-subscription__formats__item__left">
            <p class="card-subscription__formats__item__quantity">
              {{ getQuantityMessage(format.minPeople, format.maxPeople, 'pers.', 'pers.') }}
            </p>
            <p class="card-subscription__formats__item__weight">
              {{ getPackagingWeightMinimalMessage(format.productPackaging) }}
            </p>
          </div>
          <div
            :style="{ color: getCardColor }"
            class="card-subscription__formats__item__right">
            <p class="card-subscription__formats__item__price">
              {{ priceFilter(getFormatPrice(format)) }}€
            </p>
            <p class="card-subscription__formats__item__price-per-week">
              /semaine
            </p>
          </div>
        </li>
      </ul>

      <slot name="footer">
        <potager-button
          :to="getRedirect"
          full-width
          size="small"
          theme="go-green">
          Commander
        </potager-button>
      </slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { priceFilter } from 'Filters';

import PricesTools from 'Classes/PricesTools';
import { getQuantityMessage, getPackagingWeightMinimalMessage } from 'Classes/BoxesTools';

import IconPtcyFrance from 'Icons/bold/IconPtcyFrance';
import IconPtcyBio from 'Icons/regular/IconPtcyBio';

import PotagerSpotlight from 'UI/PotagerSpotlight';
import PotagerButton from 'UI/PotagerButton';
import PotagerPicture from 'UI/PotagerPicture';
import PotagerBackground from 'UI/PotagerBackground';

import CardSubscriptionLoading from 'Components/cards/CardSubscriptionLoading';

import COLORS from 'Settings/colors';

const {
  white,
  sunglow,
  sushi,
  bittersweet,
  forestGreen,
  atomicTangerine,
  black,
} = COLORS;

export default {

  components: {
    IconPtcyFrance,
    IconPtcyBio,
    PotagerSpotlight,
    PotagerButton,
    PotagerPicture,
    PotagerBackground,
    CardSubscriptionLoading,
  },

  data: () => ({
    colors: { white },
  }),

  props: {
    box: {
      type: Object,
      required: false,
      default: null,
    },
    productSelector: {
      type: Object,
      required: false,
      default: null,
    },
    isWhite: {
      type: Boolean,
      required: false,
      default: false,
    },
    redirect: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    ...mapGetters('user', [
      'getSubscription',
    ]),
    getEntity() {
      return this.box || this.productSelector;
    },
    getBox() {
      return this.box || this.productSelector?.box;
    },
    getName() {
      return this.getEntity?.name;
    },
    getDesc() {
      return this.getEntity?.description;
    },
    getImg() {
      return this.getBox?.image;
    },
    getFormats() {
      return this.box ? [this.box] : this.productSelector?.formats;
    },
    getCardColor() {
      const mapping = {
        FRU: sunglow,
        PLEG: sushi,
        PTMEL: bittersweet,
        PTMEO: bittersweet,
        PMEL: bittersweet,
        PMEFS: bittersweet,
        PMELO: bittersweet,
        GMEL: bittersweet,
        GMEFS: bittersweet,
        GMELO: bittersweet,
        MELBIO: forestGreen,
        SPMEL: atomicTangerine,
      };
      return mapping[this.getBox?.productReference] || forestGreen;
    },
    getRedirect() {
      if (this.redirect) return this.redirect;

      return this.getBox ? {
        name: 'tunnel-box-slider',
        params: {
          regionId: this.getBox.region.id,
          ref: this.getBox.productReference,
        },
      } : {};
    },
    isBio() {
      return this.getEntity?.isBio;
    },
    getRegionPreview() {
      return this.getEntity?.regionsAvailability?.length === 1 ? this.getEntity.regionsAvailability[0] : null;
    },
  },

  methods: {
    priceFilter,
    getQuantityMessage,
    getPackagingWeightMinimalMessage,
    getFormatPrice(format) {
      // Box.price MAIS productSelector.formats[].prices
      return (format.prices || format.price)?.subscription;
    },
    onCardClick() {
      this.$router.push(this.getRedirect)
        .catch(() => {
        });
    },
  },

};
</script>

<style lang="scss">
.card-subscription {
  text-align: center;
  position: relative;
  border-radius: 1.25rem;
  cursor: pointer;
  transition: transform .3s, box-shadow .3s;

  @include hover() {
    transform: translate(0, -.5rem);
  }

  &__label {
    width: 12rem;
    padding: .5rem .875rem;
    position: absolute;
    top: -1rem;
    left: -.5rem;
    z-index: 2;
    text-align: left;
    display: flex;
    align-items: center;
    font-weight: $weightExtrabold;
    @apply text-3xs;
    text-transform: uppercase;
    line-height: $baseLh;
    filter: drop-shadow($boxshadow);

    &__icon {
      @apply text-3xl.5;
    }

    @include bp768() {
      top: -.5rem;
    }
  }

  &__header {
    border-radius: 1.25rem 1.25rem 0 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    margin-bottom: -1.25rem;
    line-height: 0;
    position: relative;
    background: $whiteSmoke;
  }

  &__body {
    padding: 0 1.25rem 1.25rem 1.25rem;
    border-radius: 1.25rem;
    background: $bianca;
    position: relative;
  }

  &__name {
    font-weight: $weightExtrabold;
    @apply text-sm.5;
    line-height: 1em;
    position: relative;
    z-index: 1;
    padding: 0 1.25rem;
    transform: translate(0, -1rem);
    max-width: 14rem;
    margin: 0 auto .625rem auto;

    &__icon {
      @apply text-xs;
    }
  }

  &__desc {
    @apply text-xxs;
    line-height: $baseLh;
    margin-bottom: .875rem;

    &:last-child {
      margin-bottom: .0
    }
  }

  &__formats {
    @apply text-xxs;
    letter-spacing: 0;
    line-height: $baseLh;
    margin-bottom: .875rem;

    &:last-child {
      margin-bottom: .0
    }

    &__item {
      $item: &;
      padding: .5rem .25rem;
      border-top: solid 1px $white;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:last-child {
        border-bottom: solid 1px $white;
      }

      &__left {
        text-align: left;
      }

      &__right {
        text-align: right;
      }

      &__quantity {
        font-weight: $weightExtrabold;
      }

      &__price {
        @apply text-base;
        font-weight: $weightExtrabold;
        line-height: 1em;
      }

      &__price-per-week {
        @apply text-3xs;
        font-weight: $weightExtrabold;
        line-height: 1em;
      }

    }
  }

  &--white {

    @include hover() {
      box-shadow: 0 1rem 2rem rgba($black, .1);
    }

    .card-subscription__body {
      background: $white;
    }

    .card-subscription__formats__item {
      border-color: $bianca;
    }
  }

  &--loading {
    pointer-events: none;
  }
}
</style>
