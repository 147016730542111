import abTest from './constraints/abTestConstraint';
import hasCustomerSubscriptionProduct from './constraints/hasCustomerSubscriptionProductConstraint';
import hasCustomerSubscriptionProductOptions from './constraints/hasCustomerSubscriptionProductOptionsConstraint';
import hasDeliveryPoint from './constraints/hasDeliveryPointConstraint';
import hasEmail from './constraints/hasEmailConstraint';
import hasGroupDeliveryDay from './constraints/hasGroupDeliveryDayConstraint';
import hasHomeDeliveryAddress from './constraints/hasHomeDeliveryAddressConstraint';
import hasHomeDeliveryPostalCode from './constraints/hasHomeDeliveryPostalCodeConstraint';
import hasRegion from './constraints/hasRegionConstraint';
import hasSubscriptionBasket from './constraints/hasSubscriptionBasketConstraint';
import hasSubscriptionOrder from './constraints/hasSubscriptionOrderConstraint';
import isLoggedIn from './constraints/isLoggedInConstraint';
import isSubscriptionActive from './constraints/isSubscriptionActiveConstraint';
import mediaQueries from './constraints/mediaQueriesConstraint';
import showSuccessPage from './constraints/showSuccessPageConstraint';
import typeConstraints from './constraints/typeConstraints';

export default {
  abTest,
  hasCustomerSubscriptionProduct,
  hasCustomerSubscriptionProductOptions,
  hasDeliveryPoint,
  hasEmail,
  hasGroupDeliveryDay,
  hasHomeDeliveryAddress,
  hasHomeDeliveryPostalCode,
  hasRegion,
  hasSubscriptionBasket,
  hasSubscriptionOrder,
  isLoggedIn,
  isSubscriptionActive,
  mediaQueries,
  showSuccessPage,
  ...typeConstraints,
};
