<template>
  <div
    v-if="hasError"
    class="text-xs.5 bg-rossabel text-cinnabar font-semibold rounded-lg p-3">
    <span class="font-normal">
      ⚠️
    </span>

    <template v-if="isInAnotherRegion">
      Vous ne pouvez pas choisir ce point car il est en dehors de votre région.<br><br>
      <potager-link
        :to="{ name: 'contact' }"
        class="text-current underline"
        target="_blank">
        Contactez le service client<br>
      </potager-link>
      si vous souhaitez changer de région.
    </template>

    <template v-else>
      Désolé, ce point de retrait est actuellement indisponible pour une durée indéterminée.<br>Veuillez
      sélectionner un autre point de retrait parmi ceux disponibles.
    </template>
  </div>

  <div v-else>
    <div
      v-for="(gdd, index) in groupDeliveryDays"
      :key="index"
      class="mb-3 last:mb-0 relative text-left bg-white rounded p-3">
      <div class="flex justify-between">
        <div class="leading-normal font-semibold flex flex-col gap-1">
          <p
            class="text-sm"
            v-html="getGDDContent(gdd).day" />

          <potager-tooltip
            v-if="gdd.timeSlot.hasSubstitute"
            :message="gdd.timeSlot.note"
            class="font-bold text-zest text-xs"
            position="bottom"
            theme="error">
            <icon-information-circle />
            Les commandes seront livrées dans un point relais remplaçant ce jour.
          </potager-tooltip>

          <span
            v-if="getType === DELIVERY_HOME_TYPE"
            class="font-normal text-xs">Livraison <b
              class="font-semibold">entre {{ getGDDContent(gdd).from }} et&nbsp;{{ getGDDContent(gdd).to }}</b>.
          </span>

          <span
            v-else
            class="font-normal text-xs">Commande déposée <b
              class="font-semibold">avant&nbsp;{{ getGDDContent(gdd).from }}</b><br>et disponible <b
              class="font-semibold">jusqu’à {{ getGDDContent(gdd).to }}</b>.
          </span>
        </div>

        <potager-button
          :is-disabled="!isEnabled(gdd)"
          :is-loading="isLoading"
          :theme="isEnabled(gdd) ? 'go-green' : 'charcoal'"
          :tooltip="!isEnabled(gdd) ? getGddErrorMessage({ gdd, context }) : undefined"
          class="self-end font-bold text-xs.5"
          size="small"
          @onClick="onSubmit(gdd)">
          {{
            getGddLabel({
              gdd,
              context
            })
          }}
        </potager-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  DELIVERY_HOME_TYPE,
  DELIVERY_PRIVATE_TYPE,
  DELIVERY_PUBLIC_TYPE,
} from 'Classes/workflow/TunnelTypesConstants';

import {
  getTimeSlot,
  isGDDFull,
  isGDDDisabled,
  isInAnotherRegion,
  getGddErrorMessage,
  getGddLabel,
  getFormattedDay,
  getNote,
  getNoteTitle,
} from 'Classes/DlpTools';

import PotagerButton from 'UI/PotagerButton';
import PotagerTooltip from 'UI/PotagerTooltip';

import Filters from 'Filters';
import ModalList from 'Modals/ModalList';

import { UPDATE_DELIVERY_POINT_LOADERS } from 'Classes/Loaders';

import IconInformationCircle from 'Icons/bold/IconInformationCircle';

export default {
  components: {
    PotagerTooltip,
    PotagerButton,
    IconInformationCircle,
  },

  props: {
    context: {
      type: String,
      default: undefined,
    },
    deliveryPoint: {
      type: Object,
      required: true,
    },
  },

  emits: ['onSubmit'],

  data() {
    return {
      DELIVERY_HOME_TYPE,
    };
  },

  computed: {
    ...mapGetters('tunnel', [
      'getPrevStepRoute',
      'getStepRouteByName',
    ]),
    ...mapGetters('map', [
      'getUserSearch',
    ]),
    isInAnotherRegion() {
      return isInAnotherRegion({ item: this.deliveryPoint });
    },
    groupDeliveryDays() {
      const gdds = this.deliveryPoint?.groupDeliveryDay || [];
      return gdds
        .sort((a, b) => {
          const aDate = getTimeSlot(a, this.context).date;
          const bDate = getTimeSlot(b, this.context).date;
          return aDate - bDate;
        });
    },
    hasError() {
      return this.isInAnotherRegion || !this.groupDeliveryDays.length;
    },
    getType() {
      if (this.deliveryPoint?.private) {
        return DELIVERY_PRIVATE_TYPE;
      }
      if (this.deliveryPoint?.isHomeDelivery) {
        return DELIVERY_HOME_TYPE;
      }
      return DELIVERY_PUBLIC_TYPE;
    },
    isLoading() {
      return this.$wait.is(UPDATE_DELIVERY_POINT_LOADERS);
    },
  },

  methods: {
    isGDDFull,
    isGDDDisabled,
    getGddLabel,
    getGddErrorMessage,
    isEnabled(gdd) {
      const { context } = this;
      return !this.isGDDDisabled({
        gdd,
        context
      }) && !this.isGDDFull({
        gdd,
        context
      });
    },
    getNote(gdd) {
      return getNote(gdd, this.deliveryPoint, this.context);
    },
    getNoteTitle(gdd) {
      return getNoteTitle(gdd, this.deliveryPoint, this.context);
    },
    getGDDContent(gdd) {
      const timeslot = getTimeSlot(gdd, this.context);
      const day = Filters.ucfirst(getFormattedDay(gdd, this.context));

      const from = Filters.tmsToHour(timeslot.startHour, true);
      const to = Filters.tmsToHour(timeslot.endHour, true);
      return {
        day,
        from,
        to,
      };
    },
    onSubmit(gdd) {
      const note = this.getNote(gdd);
      if (note) {
        this.$modal.open(ModalList, {
          title: this.getNoteTitle(gdd),
          items: [
            {
              title: this.deliveryPoint.groupName,
              description: note,
              icon: 'ℹ️',
            },
          ],
          btnLabel: 'C’est noté !',
          btnTheme: 'go-green',
          btnCallback: () => this.$emit('onSubmit', gdd),
        });
      } else {
        this.$emit('onSubmit', gdd);
      }
    },
  },
};
</script>
