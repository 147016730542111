<template>
  <panel
    :back-route="{ name: 'mon-compte' }"
    :is-loading="!getUserId"
    title="Gazettes">
    <template v-if="filteredOrders && filteredOrders.length">
      <div class="panel-section">
        <potager-navigator
          v-for="order in filteredOrders"
          :key="order.id"
          :to="{
            name: 'mon-compte_orders_gazette',
            params: {
              status: order.isHistorical ? 'livrees' : 'en-cours',
              orderId: order.id,
            },
          }">
          Gazette n°{{ order.number }}
          <p
            v-if="getDeliveryDate"
            class="text-xs.5 leading-tight text-warm-grey mt-1">
            Commande livrée le {{ getDeliveryDate(order) }}
          </p>
        </potager-navigator>
      </div>

      <div
        v-if="hasHistoricalOrdersNextPage || isLoading"
        class="panel-section--transparent">
        <potager-button
          :is-loading="isLoading"
          full-width
          theme="stroke"
          @onClick="loadHistoricalOrders('more')">
          Voir plus
        </potager-button>
      </div>
    </template>

    <template v-else>
      <div class="panel-section">
        <p class="panel-section__p">
          Aucune gazette pour le moment. Effectuez une première commande pour recevoir votre première gazette.
        </p>
        <potager-button
          :to="{ name: 'dashboard' }"
          full-width
          theme="stroke">
          Aller sur la boutique
        </potager-button>
      </div>
    </template>
  </panel>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { LOAD_HISTORICAL_ORDERS, SWITCH_HISTORICAL_ORDERS_FILTER } from 'Stores/types/userActionsTypes';

import Filters from 'Filters';
import MetaInfosService from 'Classes/services/MetaInfoService';
import Panel from 'Components/panel/Panel';
import PotagerButton from 'UI/PotagerButton';
import PotagerNavigator from 'UI/PotagerNavigator';

import {
  VALIDATED,
  PAYED,
  PREPARATION,
  DELIVERED,
} from 'Classes/OrderStatus';

export default {

  components: {
    Panel,
    PotagerButton,
    PotagerNavigator,
  },

  computed: {
    ...mapGetters('user', [
      'getUserId',
      'getOrders',
      'getHistoricalOrders',
      'isHistoricalOrdersOnlyShipped',
      'hasHistoricalOrdersNextPage',
    ]),
    allOrders() {
      return [
        ...this.getOrders,
        ...this.getHistoricalOrders,
      ];
    },
    filteredOrders() {
      return this.allOrders.filter((order) => [
        VALIDATED,
        PAYED,
        PREPARATION,
        DELIVERED,
      ].indexOf(order.frontStatus) !== -1);
    },
    isLoading() {
      return this.$wait.is(LOAD_HISTORICAL_ORDERS);
    },
  },

  methods: {
    ...mapActions('user', [
      SWITCH_HISTORICAL_ORDERS_FILTER,
    ]),
    loadHistoricalOrders(page = 1) {
      if (!this.isHistoricalOrdersOnlyShipped) {
        this.SWITCH_HISTORICAL_ORDERS_FILTER();
      } else {
        this.$store.dispatch(`user/${LOAD_HISTORICAL_ORDERS}`, { page });
      }
    },
    getDeliveryDate(order) {
      if (!order || !order.timeSlot || !order.timeSlot.date) return null;
      return Filters.tmsToFormat(order.timeSlot.date, 'iiii d MMMM yyyy');
    },
  },

  mounted() {
    if (!this.getHistoricalOrders || !this.getHistoricalOrders.length) {
      this.loadHistoricalOrders();
    }
  },

  head: MetaInfosService.generate({
    title: 'Gazettes',
  }),

};
</script>
