<template>
  <div class="flex items-center justify-between">
    <span class="text-xs font-semibold mr-2">Filtres&nbsp;:</span>
    <template v-if="condensed">
      <potager-input
        :model-value="[]"
        :options="filters"
        class="ml-1"
        full-width
        multiple
        type="select"
        @update:modelValue="onInput" />
    </template>

    <template v-else>
      <potager-button
        v-for="filter in getFilters"
        :key="filter.dayIndex"
        :class="['capitalize mr-1 last:mr-0 min-w-[65px]', {'text-xs': filter.value}]"
        :label="filter.label"
        :theme="filter.value ? 'charcoal' : 'white'"
        is-rounded
        size="small"
        @onClick="UPDATE_FILTERS({ dayIndex: filter.dayIndex, value: !filter.value })">
        <template
          v-if="filter.value"
          #icon>
          <icon-delete2
            class="-mr-1 text-xs" />
        </template>
      </potager-button>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { UPDATE_FILTERS } from 'Stores/types/mapMutationsTypes';

import { capitalize } from 'Classes/WordingTools';

import PotagerButton from 'UI/PotagerButton';
import PotagerInput from 'UI/Form/PotagerInput';

import IconDelete2 from 'Icons/bold/IconDelete2';

export default {
  props: {
    condensed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    PotagerInput,
    PotagerButton,
    IconDelete2,
  },
  computed: {
    ...mapGetters('map', ['getFilters']),
    filters() {
      const filters = this.getFilters.map((filter) => ({
        label: capitalize(filter.label),
        value: filter.dayIndex,
      }));

      return [
        {
          value: null,
          label: 'Jour',
          disabled: true,
        },
        ...filters,
      ];
    },
  },

  methods: {
    ...mapMutations('map', [UPDATE_FILTERS]),
    onInput(value) {
      this.getFilters.forEach((filter) => {
        this.UPDATE_FILTERS({
          dayIndex: filter.dayIndex,
          value: value.includes(filter.dayIndex)
        });
      });
    },
  },
};
</script>
