import { createStore } from 'vuex';

import AppStore from 'Stores/AppStore';
import SessionStore from 'Stores/SessionStore';
import UserStore from 'Stores/UserStore';
import RouteHistoryStore from 'Stores/RouteHistoryStore';
import BasketStore from 'Stores/BasketStore';
import SubscriptionBasketStore from 'Stores/SubscriptionBasketStore';
import TemporaryCustomer from 'Stores/TemporaryCustomerStore';
import DashboardStore from 'Stores/DashboardStore';
import ShadowBasketStore from 'Stores/ShadowBasketStore';
import ABTestStore from 'Stores/ABTestStore';
import MapStore from 'Stores/MapStore';
import HomeDeliveryStore from 'Stores/HomeDeliveryStore';
import TunnelStore from 'Stores/TunnelStore';
import PopupsStore from 'Stores/popupsStore';
import WaitStore from 'Stores/WaitStore';

import gtmPlugin from 'Stores/plugins/gtmPlugin';

export default createStore({
  devtools: process.env.NODE_ENV === 'development',
  modules: {
    ABTest: ABTestStore,
    app: AppStore,
    basket: BasketStore,
    dashboard: DashboardStore,
    homeDelivery: HomeDeliveryStore,
    map: MapStore,
    popups: PopupsStore,
    routeHistory: RouteHistoryStore,
    session: SessionStore,
    shadowBasket: ShadowBasketStore,
    subscriptionBasket: SubscriptionBasketStore,
    temporaryCustomer: TemporaryCustomer,
    tunnel: TunnelStore,
    user: UserStore,
    wait: WaitStore,
  },

  plugins: [
    gtmPlugin,
  ],
});
