import { PLACEHOLDER_DASHBOARD_SECTIONS } from 'Classes/Placeholders';
import { RESET_DASHBOARD, UPDATE_DASHBOARD, UPDATE_DASHBOARD_SLIDES } from 'Stores/types/dashboardMutationsTypes';
import { FETCH_DASHBOARD_ACTION, FETCH_DASHBOARD_SLIDES_ACTION } from 'Stores/types/dashboardActionTypes';

import { api } from 'Plugins/potagerApiClient';

export default {
  namespaced: true,
  state: {
    deliveryDate: {},
    header: null,
    region: null,
    sections: PLACEHOLDER_DASHBOARD_SECTIONS,
    miniBoxes: [],
    slides: [],
  },

  getters: {
    isSlidesLoaded: (state, getters, rootState, rootGetters) => {
      const isLoading = rootGetters['wait/is'](FETCH_DASHBOARD_SLIDES_ACTION) || rootGetters['wait/is'](FETCH_DASHBOARD_ACTION);
      return state.slides?.length > 0 && !isLoading;
    },
    isLoaded: (state, getters, rootState, rootGetters) => {
      const isLoading = rootGetters['wait/is'](FETCH_DASHBOARD_ACTION);
      return !isLoading && state.sections?.some((section) => !section.loading);
    },
    getDashboardSections: (state) => state.sections
      .filter((section) => section.items.length > 0)
      .filter((s) => s.type !== 'homepage')
      .filter((s) => s.items.some((item) => item.itemType !== 'thumbnail')),
    getHomepageSections: (state) => state.sections
      .filter((s) => s.type === 'homepage'),
    getDashboardProducts: (state) => {
      const products = [];

      state.sections
        .forEach((section) => {
          if (!section.loading) {
            section.items
              .forEach((item) => {
                if (item.itemType !== 'thumbnail') {
                  products.push(item);
                }
              });
          }
        });

      return products;
    },
    getDashboardRegion: (state) => state.region,
    getMiniBoxes: (state) => state.miniBoxes,
    getSlides: (state) => state.slides,
    getDeliveryDate: (state) => state.deliveryDate,
    isClosedStore: (state, getters) => (getters.getDashboardSections?.length <= 0),
    isClosedStoreAndRegion: (state, getters, rootState, rootGetters) => getters.isClosedStore && rootGetters['session/isClosedRegion'],
  },

  mutations: {
    [UPDATE_DASHBOARD](state, data) {
      state.deliveryDate = data.deliveryDate;
      state.header = data.header;
      state.region = data.region;
      state.sections = data.sections;
      state.miniBoxes = data.miniBoxes;
      state.slides = data.slides;
    },
    [UPDATE_DASHBOARD_SLIDES](state, slides) {
      state.slides = slides;
    },
    [RESET_DASHBOARD](state) {
      state.deliveryDate = {};
      state.header = null;
      state.region = null;
      state.sections = PLACEHOLDER_DASHBOARD_SECTIONS;
      state.miniBoxes = [];
      state.slides = [];
    }
  },

  actions: {
    [FETCH_DASHBOARD_ACTION]({
      commit,
      dispatch,
      rootGetters
    }, force = false) {
      dispatch('wait/start', FETCH_DASHBOARD_ACTION, { root: true });
      if (force) commit(RESET_DASHBOARD);

      return new Promise((resolve) => {
        const apiCall = rootGetters['session/isLoggedIn']
          ? api.dashboard.findOneByBasket(undefined, rootGetters['session/getTestModeDate'])
          : api.dashboard.findOneByRegion(rootGetters['session/getRegionId']);

        apiCall
          .then((resp) => {
            commit(UPDATE_DASHBOARD, resp.data);
            resolve(resp);
          })
          .finally(() => dispatch('wait/end', FETCH_DASHBOARD_ACTION, { root: true }));
      });
    },
    [FETCH_DASHBOARD_SLIDES_ACTION]({
      commit,
      dispatch,
      rootGetters
    }) {
      dispatch('wait/start', FETCH_DASHBOARD_SLIDES_ACTION, { root: true });
      return new Promise((resolve) => {
        api.dashboard.getSlides(rootGetters['session/getTestModeDate'])
          .then((resp) => {
            commit(UPDATE_DASHBOARD_SLIDES, resp.data.data.results);
            resolve(resp.data.data.results);
          })
          .finally(() => dispatch('wait/end', FETCH_DASHBOARD_SLIDES_ACTION, { root: true }));
      });
    },
  },
};
