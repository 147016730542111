<template>
  <potager-link
    :class="[
      'bg-beryl-green rounded-md z-10 h-full flex flex-col overflow-hidden text-center',
      'bp768:min-h-[85px] bp768:text-left bp768:w-full',
      { 'skeleton': skeleton },
    ]"
    :to="getDeliveryPointRedirect">
    <template v-if="!skeleton">
      <div
        v-if="!$mq.bp768"
        class="flex-1 relative">
        <potager-picture
          alt="Illustration de la carte de France"
          background-size="contain"
          class="pt-4 absolute inset-0 w-full object-contain px-12"
          no-lazy-load
          src="PR-map" />
      </div>

      <div
        :class="[
          'text-british-racing pb-6 pt-3 flex flex-col items-center text-center justify-center',
          'bp768:pt-6'
        ]">
        <div class="px-6 mb-4 bp768:mb-4">
          <h3 class="font-bold text-base mb-1 bp768:mb-0">
            Mon point de livraison
          </h3>

          <p class="text-xs.5">
            La boutique change en fonction de votre région.
          </p>
        </div>

        <potager-button
          :to="getDeliveryPointRedirect"
          class="mx-auto block bp768:mx-0 z-10"
          size="small"
          theme="british-racing">
          Choisir mon point
        </potager-button>
      </div>
    </template>
  </potager-link>
</template>

<script>
import { getDlpSearchRoute } from 'Classes/DlpRouteTools';
import PotagerButton from 'UI/PotagerButton';

import PotagerPicture from 'UI/PotagerPicture';

export default {
  components: {
    PotagerPicture,
    PotagerButton,
  },

  props: {
    skeleton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    getDeliveryPointRedirect() {
      return getDlpSearchRoute();
    },
  },
};
</script>
