import { mapGetters } from 'vuex';
import { INIT_PAYGREEN_BUYER, ADD_PAYGREEN_CARD_ACTION } from 'Stores/types/userActionsTypes';
import { PAYGREEN_LOADERS, VARIOUS_PAYMENT_LOADERS } from 'Classes/Loaders';

import { isProd } from 'Classes/Tools';
import { POPIN_ERROR_EVENT } from 'Classes/Constants';
import { capitalize } from 'Classes/WordingTools';
import { getPaymentMethod, getPMTypeByName } from 'Classes/paymentMethods';
import { PAYMENT_METHODS_TYPES, PAYMENT_METHODS_INTEGRATIONS } from 'Classes/paymentMethods/Constants';

import { style } from 'Components/stripe/StripeStyles';

import GtmMixin from 'Mixins/GtmMixin';

export const PAYGREEN_PROCESSING_LOADER = 'PAYGREEN_PROCESSING_LOADER';

export default {
  mixins: [GtmMixin],

  data() {
    return {
      ready: false,
      paygreenStyle: {
        input: style,
      },
      validCard: false,
      reusable: false,
      paygreenEvents: undefined,
    };
  },

  computed: {
    ...mapGetters('app', [
      'getPaygreenPublicKey',
    ]),
    ...mapGetters('user', [
      'getPaygreenBuyerId',
      'getPaymentGateway',
    ]),
    isPaygreenLoading() {
      return this.$wait.is([
        PAYGREEN_PROCESSING_LOADER,
        PAYGREEN_LOADERS,
        VARIOUS_PAYMENT_LOADERS,
      ]) || !this.ready;
    },
  },

  watch: {
    paygreenEvents(events) {
      if (events) {
        this.logPaygreenEvents();
      }
    },
  },

  methods: {
    // Pose le Script Paygreen s'il n'est pas déjà présent
    addPaygreenScript() {
      return new Promise((resolve, reject) => {
        if (!window.paygreenjs) {
          const scriptSrc = isProd ? 'https://pgjs.paygreen.fr/latest/paygreen.min.js' : 'https://sb-pgjs.paygreen.fr/latest/paygreen.min.js';
          const paygreenScript = document.createElement('script');
          paygreenScript.setAttribute('src', scriptSrc);
          paygreenScript.setAttribute('id', 'paygreenjs');
          document.head.appendChild(paygreenScript);

          paygreenScript.addEventListener('load', () => {
            if (window.paygreenjs) {
              resolve();
            } else {
              reject(new Error('Une erreur est survenue'));
            }
          });
        } else {
          resolve();
        }
      });
    },
    initPaygreen(order, context, paymentMode = 'instrument') {
      this.$wait.start(PAYGREEN_PROCESSING_LOADER);

      return new Promise((resolve, reject) => {
        this.unmountPaygreen()
          .finally(() => {
            this.addPaygreenScript()
              .then(() => {
                this.addPaygreenEventListener(paymentMode)
                  .then((resp) => {
                    resolve(resp);
                  })
                  .catch((error) => {
                    reject(error);
                  })
                  .finally(() => {
                    this.$wait.end(PAYGREEN_PROCESSING_LOADER);
                  });

                this.initBuyerId()
                  .then(() => {
                    const params = {
                      mode: paymentMode,
                      publicKey: this.getPaygreenPublicKey,
                      buyer: this.getPaygreenBuyerId,
                      paymentMethod: getPMTypeByName(this.name) === PAYMENT_METHODS_TYPES.BANK_CARD ? PAYMENT_METHODS_TYPES.BANK_CARD : 'conecs',
                      style: this.paygreenStyle,
                      displayAuthentication: 'modal',
                      lang: 'fr',
                    };

                    if (paymentMode === 'payment') {
                      const paygreenData = order?.stripePayment?.currentStripePayment?.paygreenData;
                      params.paymentOrderID = paygreenData.paymentOrderId;
                      params.objectSecret = paygreenData.objectSecret;
                      params.instrument = paygreenData.instrumentId;
                    } else {
                      params.modeOptions = {
                        // on peut mettre un authorizedInstrument : false
                        // afin de simuler une banque qui va déclencher une demande 3DS
                        // à la demande de paiement automatique d'une commande d'abonnement
                        // authorizedInstrument: false,
                        authorizedInstrument: getPMTypeByName(this.name) === PAYMENT_METHODS_TYPES.BANK_CARD,
                      };
                    }

                    window.paygreenjs.init(params);
                  })
                  .catch((error) => {
                    reject(error);
                  })
                  .finally(() => {
                    this.$wait.end(PAYGREEN_PROCESSING_LOADER);
                  });
              })
              .catch((error) => {
                reject(error);
              })
              .finally(() => {
                this.$wait.end(PAYGREEN_PROCESSING_LOADER);
              });
          });
      });
    },
    initBuyerId() {
      return new Promise((resolve, reject) => {
        if (!this.getPaygreenBuyerId) {
          this.$store.dispatch(`user/${INIT_PAYGREEN_BUYER}`)
            .then((buyerId) => {
              resolve(buyerId);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve(this.getPaygreenBuyerId);
        }
      });
    },
    addPaygreenEventListener(paymentMode = 'instrument') {
      return new Promise((resolve, reject) => {
        this.paygreenEvents = Object.keys(window.paygreenjs.Events);

        // check when is ready
        window.paygreenjs.attachEventListener(
          window.paygreenjs.Events.PAYMENT_FLOW_ONCHANGE,
          () => {
            if (window.paygreenjs.status().paymentOrder) {
              this.ready = true;
            }
          },
        );

        // on CARD_ONCHANGE, update validCard
        window.paygreenjs.attachEventListener(
          window.paygreenjs.Events.CARD_ONCHANGE,
          (data) => {
            this.validCard = data.detail.valid;
          },
        );

        // loading on REQUEST_SUBMIT_TOKENIZE_FORM
        // stop loading when TOKEN_READY
        window.paygreenjs.attachEventListener(
          window.paygreenjs.Events.REQUEST_SUBMIT_TOKENIZE_FORM,
          () => {
            this.$wait.start(PAYGREEN_PROCESSING_LOADER);
          },
        );

        // on INSTRUMENT_READY, submit payment (= add card)
        // api call /api/private/paygreen/add/card
        window.paygreenjs.attachEventListener(
          window.paygreenjs.Events.INSTRUMENT_READY,
          (resp) => {
            this.$wait.start(PAYGREEN_PROCESSING_LOADER);
            const { id } = resp.detail.instrument;
            this.$store.dispatch(`user/${ADD_PAYGREEN_CARD_ACTION}`, id)
              .then((card) => {
                this.trackPaymentInfo({
                  payment_type: PAYMENT_METHODS_INTEGRATIONS.PAYGREEN,
                });

                this.onPaygreenSuccess(card, paymentMode);
                this.$wait.end(PAYGREEN_PROCESSING_LOADER);
                resolve(card);
              })
              .catch((error) => {
                this.$wait.end(PAYGREEN_PROCESSING_LOADER);
                this.onPaygreenError(error, error.message);
                reject(error);
              });
          },
        );

        // on ACTUAL_FLOW_PAYMENT_DONE, success payment
        window.paygreenjs.attachEventListener(
          window.paygreenjs.Events.ACTUAL_FLOW_PAYMENT_DONE,
          (data) => {
            this.$wait.end(PAYGREEN_PROCESSING_LOADER);
            resolve(data);
          },
        );

        // on REUSABLE_ALLOWED_CHANGE, update reusable
        window.paygreenjs.attachEventListener(
          window.paygreenjs.Events.REUSABLE_ALLOWED_CHANGE,
          (data) => {
            this.reusable = data.detail.reusable_allowed;
          },
        );

        // on ERROR, stop loading & show error
        window.paygreenjs.attachEventListener(
          window.paygreenjs.Events.ERROR,
          (data) => {
            this.$wait.end(PAYGREEN_PROCESSING_LOADER);
            this.onPaygreenError(data.detail, data.detail.message, paymentMode);
            reject(data);
          },
        );

        // on PAYMENT_FAIL, stop loading & show error
        window.paygreenjs.attachEventListener(
          window.paygreenjs.Events.PAYMENT_FAIL,
          (err) => {
            const error = err.detail || 'Payment fail (no detail)';
            this.$wait.end(PAYGREEN_PROCESSING_LOADER);
            this.onPaygreenError(error, 'Payment fail', paymentMode);
            reject(new Error(error));
          },
        );

        // on TOKEN_FAIL, stop loading & show error
        window.paygreenjs.attachEventListener(
          window.paygreenjs.Events.TOKEN_FAIL,
          (data) => {
            this.$wait.end(PAYGREEN_PROCESSING_LOADER);
            this.onPaygreenError(data.detail, data.detail.error, paymentMode);
            reject(data);
          },
        );
      });
    },
    paygreenPayment() {
      window.paygreenjs.submitPayment();
      // déclenche le paiement
      // au succès, on a l'event INSTRUMENT_READY
    },
    onPaygreenSuccess(card) {
      this.unmountPaygreen()
        .then(() => {
          if (card) {
            const PM = getPaymentMethod(card);
            // event is "Modal" type to simplify tracking with modal tutorial
            this.trackAction({
              event: 'Modal',
              name: `Modal${capitalize(this.name)}`,
              value: 'success',
            });

            const last4 = card.number.slice(-4);
            this.$notify({
              type: 'success',
              title: 'Carte ajoutée',
              text: `Votre ${PM.label}&nbsp;<span class="whitespace-nowrap">**** **** **** ${last4}</span> a bien été ajoutée.`,
            });

            this.$emit('success', card);
          } else {
            this.onPaygreenError('No card', 'No card');
          }
        });
    },
    onPaygreenError(error, codeError) {
      console.error(error);

      // if error is "Missing paygreen-container"
      // it's mostly because we try to unmount
      // after route change
      if (codeError !== 'Missing paygreen-container') {
        this.trackAction({
          event: 'Modal',
          name: `Modal${capitalize(this.name || 'Paygreen')}`,
          value: 'error',
          params: {
            error: codeError,
          },
        });

        // event is "Modal" type to simplify tracking with modal tutorial
        this.$events.emit(POPIN_ERROR_EVENT, {
          title: 'On dirait qu\'il y a un pépin',
          text: 'Une erreur est survenue dans le processus d\'ajout de carte. Veuillez réessayer en vérifiant les informations saisies ou <a href="https://www.potagercity.fr/contact" class="underline">contacter le service client</a> si le problème persiste.',
          code: codeError,
        });
      }
    },
    launchPaygreenOrder3DSAction(order, context) {
      const paymentIntentId = order?.stripePayment?.currentStripePayment?.paygreenData?.paymentOrderId;

      return new Promise((resolve, reject) => {
        this.initPaygreen(order, context, 'payment')
          .then(() => {
            resolve(paymentIntentId);
          })
          .catch(() => {
            reject(paymentIntentId);
          })
          .finally(() => {
            this.$wait.end(PAYGREEN_PROCESSING_LOADER);
          });
      });
    },
    logPaygreenEvents() {
      this.paygreenEvents
        .forEach((event) => {
          window.paygreenjs.attachEventListener(
            window.paygreenjs.Events[event],
            (data) => {
              console.info(event, data);
            },
          );
        });
    },
    unmountPaygreen() {
      return new Promise((resolve) => {
        try {
          window.paygreenjs?.unmount(true);
          this.$emit('unmount');
          this.$events.emit('paygreen:unmount');
          this.paygreenEvents?.forEach((event) => {
            window.paygreenjs.detachEventListener(window.paygreenjs.Events[event]);
          });
          resolve();
        } catch (error) {
          resolve();
        }
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.unmountPaygreen()
      .finally(() => {
        next();
      });
  },

  beforeUnmount() {
    this.unmountPaygreen();
  },
};
