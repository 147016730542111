<template>
  <panel
    :back-route="getBackRoute"
    header-extra-classes="border-solid border-0 border-b border-white-rock">
    <template #header>
      <div class="pt-10">
        <dlp-search-private :context="context" />
      </div>
    </template>

    <div class="h-full bg-bianca px-6 pt-6">
      <dlp-private-list
        :context="context"
        show-private-form
        @onSelect="onSelect" />
    </div>
  </panel>
</template>

<script>
import DlpSearchPrivate from 'Components/deliveryPoint/step2/DlpSearchPrivate';
import DlpPrivateList from 'Components/deliveryPoint/step3/DlpPrivateList';
import Panel from 'Components/panel/Panel';

import { getDlpDetailsRoute, getDlpSearchRoute } from 'Classes/DlpRouteTools';
import { DELIVERY_PRIVATE_TYPE } from 'Classes/workflow/TunnelTypesConstants';
import { DELIVERY_FUNCTIONAL_TYPE_PRIVATE_PATH } from 'Classes/Constants';

import MobileAppMixin from 'Mixins/MobileAppMixin';

export default {
  mixins: [
    MobileAppMixin
  ],

  components: {
    Panel,
    DlpSearchPrivate,
    DlpPrivateList,
  },

  props: {
    context: {
      type: String,
      required: true,
    },
  },

  computed: {
    getBackRoute() {
      return getDlpSearchRoute({
        context: this.context,
        params: { type: DELIVERY_FUNCTIONAL_TYPE_PRIVATE_PATH },
      }, this.$potagerRoute);
    },
  },

  methods: {
    onSelect(deliveryPoint) {
      if (this.isInAppWebView) {
        this.redirectWebApp({
          dlpId: deliveryPoint.id,
          dlpName: deliveryPoint.groupName,
          dlpGroupCode: deliveryPoint.groupCode,
          regionId: deliveryPoint.regionId,
        });
      } else {
        this.$router.push(
          getDlpDetailsRoute({
            context: this.context,
            type: DELIVERY_PRIVATE_TYPE,
            deliveryPoint,
          }, this.$potagerRoute),
        );
      }
    },
  },
};
</script>
