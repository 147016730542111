<template>
  <div
    :class="{
      'recipe-steps': true,
      'recipe-steps--large': isLarge,
    }">
    <youtube-frame
      v-if="recipe.youtube"
      class="recipe-steps__video"
      :url="recipe.youtube" />

    <ol>
      <li
        class="recipe-steps__step"
        v-for="(step, i) in recipe.steps"
        :key="`step-${i}`">
        <template v-if="step.step">
          {{ step.step }}

          <potager-picture
            v-if="step.image"
            class="recipe-steps__step__img"
            :src="step.image"
            :title="step.step" />
        </template>

        <div
          v-else
          class="skeleton h-6 w-1/3" />
      </li>
    </ol>

    <div
      v-if="recipe.conclusion"
      class="recipe-steps__ending"
      v-html="cleanWP(recipe.conclusion)" />
  </div>
</template>

<script>
import YoutubeFrame from 'Components/videoFrame/YoutubeFrame';
import PotagerPicture from 'UI/PotagerPicture';

import Filters from 'Filters';

export default {

  components: {
    YoutubeFrame,
    PotagerPicture,
  },

  props: {
    recipe: {
      type: Object,
      required: false,
      default: null,
    },
    isLarge: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    cleanWP(str) {
      return Filters.cleanWordpress(str);
    },
  },
};
</script>
