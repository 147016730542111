import Questions from 'Classes/OAC/Questions';
import { FRU, PLEG, MEL, PTMEL, GMELN, MELBIO, SPMEL } from 'Classes/Constants'; // eslint-disable-line object-curly-newline

const aggregator = (values) => values.reduce((acc, { value }) => (typeof value === 'number' && acc === '' ? 0 : acc) + value, '');

// Voir https://potagercity.atlassian.net/wiki/spaces/FRONTEND/pages/2884304922/Classes
export default [

  {
    id: 'A',
    ...Questions.family,
    allowSkip: true,
    resolver: (values) => {
      switch (aggregator(values)) {
        case 1:
          return 'B';
        case 2:
        case 3:
          return 'C';
        default:
          return 'D';
      }
    },
  },

  {
    id: 'B',
    ...Questions.fruitsAndVegetables,
    resolver: (values) => {
      switch (aggregator(values)) {
        case 'f':
          return { ref: FRU };
        case 'l':
          return { ref: PLEG };
        case 'fl':
        default:
          return { ref: PTMEL };
      }
    },
  },

  {
    id: 'C',
    ...Questions.fruitsAndVegetables,
    resolver: (values) => {
      switch (aggregator(values)) {
        case 'f':
          return { ref: FRU, isNotEnough: true };
        case 'l':
          return { ref: PLEG };
        case 'fl':
        default:
          return 'E';
      }
    },
  },

  {
    id: 'D',
    ...Questions.fruitsAndVegetables,
    resolver: (values) => {
      switch (aggregator(values)) {
        case 'f':
          return { ref: FRU, isNotEnough: true };
        case 'l':
          return { ref: PLEG, isNotEnough: true };
        case 'fl':
        default:
          return 'F';
      }
    },
  },

  {
    id: 'E',
    ...Questions.bio,
    resolver: (values) => {
      switch (aggregator(values)) {
        case 'y':
          return { ref: MELBIO };
        case 'n':
        default:
          return 'G';
      }
    },
  },

  {
    id: 'F',
    ...Questions.bio,
    resolver: (values) => {
      switch (aggregator(values)) {
        case 'y':
          return { ref: MELBIO, isNotEnough: true };
        case 'n':
        default:
          return 'H';
      }
    },
  },

  {
    id: 'G',
    ...Questions.exclusivities,
    resolver: (values) => {
      switch (aggregator(values)) {
        case 'y':
          return { ref: SPMEL };
        case 'n':
        default:
          return { ref: MEL };
      }
    },
  },

  {
    id: 'H',
    ...Questions.exclusivities,
    resolver: (values) => {
      switch (aggregator(values)) {
        case 'y':
          return { ref: SPMEL, isNotEnough: true };
        case 'n':
        default:
          return { ref: GMELN };
      }
    },
  },

];
