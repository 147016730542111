export default class ConfigurationManager {
  static getByName(name) {
    switch (name) {
      case 'isMobile':
        return navigator.userAgent.match(/(iPhone)|(iPod)|(android)|(webOS)/i);
      case 'isMobileTablet':
        return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
      case 'isIE':
        return (navigator.userAgent.match(/.net/i));
      case 'retina':
        return (window.devicePixelRatio > 1 || (window.matchMedia && window.matchMedia('(-webkit-min-device-pixel-ratio: 1.5),(-moz-min-device-pixel-ratio: 1.5),(min-device-pixel-ratio: 1.5)').matches));
      case 'isApple':
        return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)/i);
      case 'isAndroid':
        return navigator.userAgent.match(/(android)/i);
      default:
        return null;
    }
  }
}
