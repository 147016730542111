/**
 * "Create an array of numbers in a range, with a given step size."
 *
 * The function takes three arguments:
 *
 * start: The first number in the range.
 * end: The last number in the range.
 * step: The step size between each number in the range.
 * The function returns an array of numbers in the range
 * @param [start=0] - The starting number of the range.
 * @param [end=100] - The end of the range.
 * @param [step=10] - The step size between each number in the array.
 * @returns An array of numbers in a range.
 */
const arrayOfNumberInRange = (start = 0, end = 100, step = 10) => {
  const arrayLength = Math.floor(((end - start) / step)) + 1;
  return [...Array(arrayLength)
    .keys()].map((x) => (x * step) + start);
};

const isEmpty = (value) => {
  if (value == null) {
    return true;
  }
  if (Array.isArray(value) || typeof value === 'string') {
    return !value.length;
  }
  if (typeof value === 'object') {
    return !Object.keys(value).length;
  }
  return false;
};

export {
  arrayOfNumberInRange,
  isEmpty,
};
