<template>
  <transition
    appear
    name="slide-panel">
    <stack-panels class="panel-delivery-point pointer-events-none">
      <stack-panels-layer>
        <div class="stack-panels-layer-section">
          <router-view
            :back-route="backRoute"
            :context="context"
            :route="$potagerRoute"
            @onDlpSelect="onDlpSelect"
            @onGddSubmit="onGddSubmit"
            @onHomeDeliveryRedirect="onHomeDeliveryRedirect" />
        </div>
      </stack-panels-layer>

      <stack-panels-layer
        v-if="!onlyGDD"
        secondary>
        <stack-panels-layer-section
          :has-header="false"
          full-height
          is-large>
          <div
            :class="[
              'account-dlp-map-layout__map h-full flex items-center justify-center',
              {
                'account-dlp-map-layout__map--has-header': showPublicMap,
              }
            ]">
            <dlp-map-private
              v-if="isPrivateDlp || isHomeDlp"
              :context="context" />

            <dlp-map-public
              v-else-if="showPublicMap"
              :context="context"
              @onDlpSelect="onDlpSelect"
              @onSearch="onSearch" />

            <IconPtcyLoaderSpinner
              v-else
              class="text-4xl" />
          </div>
        </stack-panels-layer-section>
      </stack-panels-layer>
    </stack-panels>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

import GtmMixin from 'Mixins/GtmMixin';

import StackPanels from 'Components/panel/StackPanels';
import StackPanelsLayer from 'Components/panel/StackPanelsLayer';
import StackPanelsLayerSection from 'Components/panel/StackPanelsLayerSection';
import DlpMapPublic from 'Components/deliveryPoint/map/DlpMapPublic';
import DlpMapPrivate from 'Components/deliveryPoint/map/DlpMapPrivate';

import MetaInfoService from 'Classes/services/MetaInfoService';
import { CONTEXT_USER } from 'Classes/Constants';
import {
  getDlpBackRoute,
  getDlpUpdateRoute,
  getDlpSearchRoute,
  getDlpListRoute,
  getDlpDetailsRoute,
  getOnlyGDD,
} from 'Classes/DlpRouteTools';
import {
  DELIVERY_PUBLIC_TYPE,
  DELIVERY_HOME_TYPE,
  DELIVERY_PRIVATE_TYPE
} from 'Classes/workflow/TunnelTypesConstants';

import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';

import MobileAppMixin from 'Mixins/MobileAppMixin';

export default {
  mixins: [
    GtmMixin,
    MobileAppMixin,
  ],

  components: {
    StackPanels,
    StackPanelsLayer,
    StackPanelsLayerSection,
    DlpMapPublic,
    DlpMapPrivate,
    IconPtcyLoaderSpinner,
  },

  props: {
    context: {
      type: String,
      required: false,
      default: CONTEXT_USER,
    },
  },

  computed: {
    ...mapGetters('map', [
      'getSelectedDeliveryPoint',
    ]),
    getParams() {
      return this.$potagerRoute?.params || this.$route.params;
    },
    getRegionId() {
      return this.getParams.deliveryPointRegionId;
    },
    getDlpId() {
      return this.getParams.deliveryPointId;
    },
    isPrivateDlp() {
      return this.getSelectedDeliveryPoint?.private;
    },
    showPublicMap() {
      return this.$potagerRoute.name.includes('account-dlp-list')
        || (this.$potagerRoute.name.includes('account-dlp-details') && !this.isPrivateDlp && !this.isHomeDlp);
    },
    isHomeDlp() {
      return this.getSelectedDeliveryPoint?.isHomeDelivery;
    },
    onlyGDD() {
      return getOnlyGDD(this.$route);
    },
    backRoute() {
      // is DlpMapDetails
      if (this.getDlpId) {
        if (this.onlyGDD) {
          return this.context !== CONTEXT_USER
            ? getDlpBackRoute({ context: this.context }, this.$potagerRoute)
            : undefined;
        }

        return this.isHomeDlp
          ? getDlpSearchRoute({
            context: this.context,
            type: DELIVERY_HOME_TYPE
          }, this.$potagerRoute)
          : getDlpListRoute({
            context: this.context,
            type: this.isPrivateDlp ? DELIVERY_PRIVATE_TYPE : DELIVERY_PUBLIC_TYPE,
          }, this.$potagerRoute);
      }

      // is DlpMapList
      return getDlpSearchRoute({ context: this.context }, this.$potagerRoute);
    },
  },

  watch: {
    // if is HomeDlp and no address, redirect to getDlpSearchRoute
    isHomeDlp: {
      handler(isHomeDlp) {
        if (isHomeDlp && !this.$store.getters['session/getAddress'](this.context)) {
          this.$router.push(getDlpSearchRoute({
            context: this.context,
            type: DELIVERY_HOME_TYPE
          }, this.$potagerRoute));
        }
      },
      immediate: true,
    },
  },

  methods: {
    onSearch() {
      if (this.$potagerRoute.params.deliveryPointId) {
        this.$router.push(getDlpListRoute({
          context: this.context,
          type: DELIVERY_PUBLIC_TYPE
        }, this.$potagerRoute));
      }
    },
    onDlpSelect(deliveryPoint) {
      if (deliveryPoint) {
        if (this.isInAppWebView) {
          this.redirectWebApp({
            dlpId: deliveryPoint.id,
            dlpName: deliveryPoint.groupName,
            dlpGroupCode: deliveryPoint.groupCode,
            regionId: deliveryPoint.regionId,
          }, false);
        } else {
          this.$router.push(
            getDlpDetailsRoute({
              context: this.context,
              type: DELIVERY_PUBLIC_TYPE,
              deliveryPoint,
            }, this.$potagerRoute),
          );
        }
      } else {
        this.onSearch();
      }
    },

    onGddSubmit({
      updateOthersDlps,
      dlpId,
      gddId
    }) {
      this.$notify({
        type: 'success',
        title: 'Choix de livraison enregistrés',
        text: 'Vous pouvez commander et faire votre marché !',
      });

      const { context } = this;
      if (updateOthersDlps) {
        this.$router.push(getDlpUpdateRoute({
          context,
          dlpId,
          gddId
        }, this.$potagerRoute));
      } else {
        this.$router.push(getDlpBackRoute({ context }, this.$potagerRoute));
      }
    },
    onHomeDeliveryRedirect(regionId) {
      this.$router.push(getDlpSearchRoute({
        context: this.context,
        type: DELIVERY_HOME_TYPE,
        params: { regionId }
      }, this.$potagerRoute));
    },
  },

  head: MetaInfoService.generate({
    title: 'Choisir un point de retrait',
  }),
};
</script>
