<template>
  <section class="blog__section">

    <template v-if="!isLoading">
      <h2
          v-if="title || $slots.title"
          class="blog__subtitle">
        <slot name="title">
          {{ title }}
        </slot>
      </h2>
      <slot/>
    </template>

    <template v-else>
      <p
          :style="{
            width: randomize(40, 80, '%'),
            height: '1.25em',
          }"
          class="skeleton blog__subtitle"/>

      <div class="cms">
        <p
            v-for="i in randomize(1, 3)"
            :key="`skeleton-${i}`"
            :style="{
                width: '100%',
                height: `${randomize(3, 7) * 1.5}rem`,
              }"
            class="skeleton"/>
      </div>
    </template>

  </section>
</template>

<script>
import { randomize } from 'Classes/Tools';

export default {

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    randomize,
  },
};
</script>
