<template>
  <div
    v-if="substituteMessage"
    class="panel-section panel-section--align-center bg-champagne">
    <div class="panel-section__title text-cinnabar">
      Remarque
    </div>

    <div class="panel-section__p">
      {{ substituteMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountOrdersSubstituteSection',
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    substituteMessage() {
      return this.order.timeSlot.hasSubstitute ?
        this.order.timeSlot.note
        : undefined;
    }
  },
};
</script>
