<template>
  <div class="not-found-page">
    <div class="not-found-page__content">
      <div class="not-found-page__content__wrapper">
        <div class="not-found-page__content__text__wrapper">
          <potager-picture
            class="not-found-page__content__img"
            src="erreur-404-message"
            title="erreur" />
        </div>

        <potager-picture
          class="not-found-page__content__img"
          src="erreur-404-kiwi"
          title="404" />
      </div>

      <div class="not-found-page__content__details">
        <p>
          Vous allez être redirigé(e) vers l'accueil de notre boutique dans...
        </p>
        <p class="not-found-page__content__timer">
          {{ timing }} seconde{{ timing > 1 ? 's' : '' }}
        </p>
        <potager-button
          class="not-found-page__content__btn"
          @onClick="goHome">
          Aller à l'accueil
        </potager-button>
      </div>
    </div>
  </div>
</template>

<script>
import PotagerButton from 'UI/PotagerButton';
import PotagerPicture from 'UI/PotagerPicture';
import MetaInfosService from 'Classes/services/MetaInfoService.js';

let timer;

export default {

  components: {
    PotagerButton,
    PotagerPicture,
  },

  data: () => ({
    timing: 5,
  }),

  mounted() {
    timer = setInterval(() => {
      this.timing -= 1;

      if (this.timing === 0) {
        this.goHome();
        clearInterval(timer);
      }
    }, 1000);
  },

  methods: {
    goHome() {
      this.$router.push({ name: 'home' })
        .catch(() => {
        });
    },
  },

  unmounted() {
    clearInterval(timer);
  },

  beforeRouteEnter(to, from, next) {
    next();
  },

  head: MetaInfosService.generate({
    title: 'Page introuvable',
    meta: [
      {
        name: 'prerender-status-code',
        content: '404'
      },
    ],
  }),
};
</script>

<style lang="scss">
.not-found-page {
  background: $whiteSmoke;
  min-height: 100vh;

  &__content {
    min-height: calc(100vh - 6.0625rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: relative;
    table-layout: fixed;
    padding: 3em 0;
    margin-bottom: -25px; // Footer margin-top
    overflow: hidden;

    &__wrapper {
      position: relative;
      font-size: 0;
      line-height: 0;
      width: 100%;
    }

    &__img,
    &__text {
      max-width: 830px;
    }

    &__img {
      position: relative;
      margin: 0 auto;
    }

    &__text {
      width: 100%;

      &__wrapper {
        position: absolute;
        width: 100%;
        z-index: 2;
        top: 50%;
        left: 0;
        transform: translate(0, -100%);
      }
    }

    &__details {
      text-align: center;
      @apply text-xs.5;
      font-weight: $weightSemibold;
      line-height: $baseLh;
    }

    &__timer {
      @apply text-lg;
      line-height: $baseLh;
      margin-top: .5rem;
    }

    &__btn {
      margin-top: 3.5rem;

      @include bp640() {
        margin-top: 1.5rem;
      }
    }

  }

}
</style>
