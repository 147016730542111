<template>
  <div>
    <div :class="`skeleton ${context}-detail__poster`"/>

    <section :class="`${context}-detail__section`">
      <p
          :class="`${context}-detail__title skeleton`"
          :style="{
              width: randomize(60, 100, '%'),
              height: $mq.bp1024 ? '1.875rem' : '2.5rem',
            }"/>

      <p
          v-if="context === 'recipe'"
          :class="[
            'preparation skeleton',
            `${context}-detail__preparation`,
          ]"
          :style="{
              width: randomize(20, 40, '%'),
              height: $mq.bp1024 ? '1.25rem' : '1.5625rem',
            }"/>

      <p
          v-else-if="context !== 'variety'"
          :class="[
            'skeleton mb-2',
            `${context}-detail__baseline`,
            `${context}-detail__associated-content-wrapper`,
          ]"
          :style="{
              width: $mq.bp1024 ? '100%' : randomize(30, 70, '%'),
              height: $mq.bp1024 ? '3.25rem' : '1.875rem',
            }"/>

      <p
          v-if="context !== 'variety'"
          :class="`skeleton ${context}-detail__description`"
          :style="{
              width: context === 'supplier'
                ? '100%'
                : ($mq.bp1024
                  ? '100%'
                  : randomize(30, 70, '%')
                ),
              height: context === 'supplier'
                ? '4.5rem'
                : ($mq.bp1024
                  ? '3rem'
                  : '1.5rem'
                ),
            }"/>
    </section>

    <section
        v-if="context === 'recipe'"
        :class="`${context}-detail__section`">
      <p
          :class="`${context}-detail__subtitle skeleton`"
          :style="{
              width: randomize(30, 70, '%'),
              height: $mq.bp1024 ? '1.25rem' : '1.6875rem',
            }"/>

      <potager-row>
        <potager-col
            v-for="i in randomize(1, 2)"
            :key="i"
            col="6">
          <card-product/>
        </potager-col>
      </potager-row>
    </section>

    <section
        v-if="context !== 'variety' && context !== 'supplier'"
        :class="`${context}-detail__section`">
      <p
          :class="`skeleton ${context}-detail__subtitle`"
          :style="{
              width: randomize(30, 70, '%'),
              height: $mq.bp1024 ? '1.25rem' : '1.6875rem',
            }"/>

      <div
          :class="[
            'product-list',
            `${context}-detail__product-list`,
          ]">
        <ul class="product-list__wrapper">
          <li
              v-for="i in randomize(2, 5)"
              :key="i"
              class="product-list__item">
            <p
                :style="{
                  width: randomize(20, 80, '%'),
                  height: '1.3125rem',
                }"
                class="skeleton"/>
          </li>
        </ul>
      </div>
    </section>

    <section
        v-if="context === 'product'"
        :class="`${context}-detail__section`">
      <p
          :style="{
            width: '100%',
            height: '2.5rem',
          }"
          class="skeleton"/>
    </section>

    <template v-if="context === 'variety'">
      <section
          v-for="i in 2"
          :key="i"
          :class="`${context}-detail__section`">
        <p
            :class="`${context}-detail__subtitle`"
            :style="{
                width: randomize(30, 70, '%'),
                height: $mq.bp1024 ? '1.25rem' : '1.6875rem',
              }"/>

        <p
            :style="{
              width: '100%',
              height: randomize(4, 8, 'rem'),
            }"
            class="skeleton"/>
      </section>
    </template>

    <template v-if="context === 'supplier'">
      <section
          v-for="i in 3"
          :key="i"
          :class="`${context}-detail__section`">
        <p
            :class="`skeleton ${context}-detail__subtitle`"
            :style="{
                width: randomize(30, 70, '%'),
                height: $mq.bp1024 ? '1.25rem' : '1.6875rem',
              }"/>

        <p
            :style="{
              width: '100%',
              height: randomize(8, 16, 'rem'),
            }"
            class="skeleton"/>
      </section>
    </template>

    <section :class="`${context}-detail__section`">
      <p
          :class="`${context}-detail__subtitle`"
          :style="{
              width: randomize(30, 70, '%'),
              height: $mq.bp1024 ? '1.25rem' : '1.6875rem',
            }"/>
    </section>
  </div>
</template>

<script>
import { randomize } from 'Classes/Tools';

import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';

import CardProduct from 'Components/cards/CardProduct';

export default {

  components: {
    PotagerRow,
    PotagerCol,
    CardProduct,
  },

  props: {
    context: {
      type: String,
      required: false,
      default: 'product',
    },
  },

  methods: {
    randomize,
  },

};
</script>
