import Stripe from 'Classes/paymentMethods/Stripe';
import Edenred from 'Classes/paymentMethods/Edenred';
import Swile from 'Classes/paymentMethods/Swile';
import Up from 'Classes/paymentMethods/Up';
import Pluxee from 'Classes/paymentMethods/Pluxee';
import Bimpli from 'Classes/paymentMethods/Bimpli';
import PaygreenBankCard from 'Classes/paymentMethods/PaygreenBankCard';

import IconPtcyVisa from 'Icons/color/IconPtcyVisa';
import IconPtcyMastercard from 'Icons/color/IconPtcyMastercard';
import IconPtcyAmex from 'Icons/color/IconPtcyAmex';

import { PAYMENT_METHODS_TYPES } from 'Classes/paymentMethods/Constants';

export const PRIMARY_PAYMENT_METHODS = [
  Stripe,
  PaygreenBankCard,
];

export const LOGIN_PAYMENT_METHODS = [
  Edenred,
  Swile,
];

export const PAYGREEN_PAYMENT_METHODS = [
  Bimpli,
  Pluxee,
  Up,
  PaygreenBankCard,
];

// trier dans l'ordre alphabétique de l'attribut name
export const SECONDARY_PAYMENT_METHODS = [
  ...LOGIN_PAYMENT_METHODS,
  Bimpli,
  Pluxee,
  Up,
].sort((a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
});

export const PAYMENT_METHODS = [
  ...PRIMARY_PAYMENT_METHODS,
  ...SECONDARY_PAYMENT_METHODS,
];

export function getPMsByType(type) {
  return PAYMENT_METHODS?.filter((e) => e.type === type);
}

export function getPMByName(name) {
  return PAYMENT_METHODS?.filter((e) => e.name === name || e.alias === name || e.issuer === name)?.[0];
}

export function getPaymentMethod(PM) {
  if (!PM) return null;
  const brand = PM.brand?.toLowerCase();

  const stripeBrands = ['visa', 'mastercard', 'amex'];
  const paygreenBrands = ['bank_card'];

  // we check first if we get a pm with the brand
  // const isStripe = PM.stripe_id;
  // const isPaygreen = PM.issuer === '';
  const matchingStripeBrand = stripeBrands.includes(brand) && Stripe;
  const matchingPaygreenBrand = paygreenBrands.includes(brand) && PaygreenBankCard;

  if (matchingStripeBrand || matchingPaygreenBrand) {
    return matchingStripeBrand || matchingPaygreenBrand;
  }

  // else we check if we get a pm with the name / issuer
  return PAYMENT_METHODS?.find((paymentMethod) => {
    const matchingIssuer = paymentMethod.issuer && paymentMethod.issuer === PM.issuer;
    const matchingName = paymentMethod.name && paymentMethod.name === PM.name;
    return matchingIssuer || matchingName;
  });
}

export function getPMTypeByName(name) {
  const paymentMethod = getPMByName(name);
  return paymentMethod?.type;
}

export function getCardIcon(card, alternative = false) {
  const paymentMethod = getPaymentMethod(card);
  if (paymentMethod.type === PAYMENT_METHODS_TYPES.BANK_CARD) {
    const mapping = {
      visa: IconPtcyVisa,
      mastercard: IconPtcyMastercard,
      amex: IconPtcyAmex,
    };
    return mapping[card.brand] || null;
  }

  return alternative ? paymentMethod.alternativeIcons[0] : paymentMethod.defaultIcon;
}
