<template>
  <card-content-loading
    v-if="content.loading"
    :inline="inline" />

  <div
    v-else
    :class="[
      'card-content',
      `card-content--${content.type}`,
      { 'card-content--inline': inline }
    ]">
    <potager-link
      class="card-content__content"
      :to="redirect"
      v-gtm>

      <potager-picture
        v-wave
        :src="content.image"
        :title="title"
        background
        class="card-content__pic"
        placeholder>
        <favori
          v-if="content.type === 'recipe' && !$mq.bp768 && !condensed"
          class="carnet--condensed"
          :id="typeof content.id === 'string' ? content.contentId : content.id" />
      </potager-picture>

      <div class="card-content__text">
        <header class="card-content__header">
          <h3 class="card-content__title">
            {{ title }}
          </h3>
        </header>

        <preparation
          v-if="content.type === 'recipe' && !condensed"
          :class="{
            'preparation--fat': !$mq.bp768,
            'card-content__preparation': true,
          }"
          :recipe="content" />

      </div>
    </potager-link>
  </div>
</template>

<script>
import { getContentLink } from 'Classes/Tools';

import PotagerPicture from 'UI/PotagerPicture';

import CardContentLoading from 'Components/cards/CardContentLoading';
import Preparation from 'Components/recette/Preparation';
import Favori from 'Components/recette/Favori';

export default {

  components: {
    PotagerPicture,
    CardContentLoading,
    Preparation,
    Favori,
  },

  props: {
    content: {
      type: Object,
      required: false,
      default: null,
    },
    openInFlyingPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    condensed: {
      type: Boolean,
      required: false,
      default: false,
    },
    inline: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    redirect() {
      const redirect = getContentLink(this.content, null, this.openInFlyingPage);
      return this.content.redirect || redirect;
    },
    title() {
      return this.content.type === 'supplier-product' ? this.content.name : this.content.title;
    },
  },

};
</script>
