import { CONTEXT_SUBSCRIPTION, CONTEXT_SUBSCRIPTION_BASKET } from 'Classes/Constants';

export const test = ({
  rootGetters,
  expectedValue
}) => {
  const subscriptionBasketGroupDeliveryDay = rootGetters['session/getGroupDeliveryDay'](CONTEXT_SUBSCRIPTION_BASKET);
  const subscriptionGroupDeliveryDay = rootGetters['session/getGroupDeliveryDay'](CONTEXT_SUBSCRIPTION);
  const groupDeliveryDay = subscriptionBasketGroupDeliveryDay || subscriptionGroupDeliveryDay;

  return (!!groupDeliveryDay?.timeSlot) === expectedValue;
};
export default {
  type: 'hasGroupDeliveryDay',
  values: [true, false],
  test,
};
