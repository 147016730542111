import IconBimpli from 'Icons/bold/IconBimpli';
import IconBimpliFull from 'Icons/bold/IconBimpliFull';

import ModalPedagogyTicketResto from 'Components/modals/ModalPedagogyTicketResto';

import { SET_DEFAULT_CARD_ACTION } from 'Stores/types/userActionsTypes';

import PAYMENT_METHODS_CONSTANTS from 'Classes/paymentMethods/Constants';

import { openModal } from 'Plugins/potagerModals';

import store from 'Stores';
import router from 'Router';

export const {
  type,
  integration,
  issuer,
  colors,
} = PAYMENT_METHODS_CONSTANTS.BIMPLI;

export default {
  name: PAYMENT_METHODS_CONSTANTS.BIMPLI.name,
  type,
  integration,
  issuer,
  label: 'carte Bimpli (ex Apetiz)',
  title: 'Carte Bimpli (ex Apetiz)',
  readmoreLink: undefined,
  route: {
    name: 'mon-compte_payment-methods_cards',
    params: { name: PAYMENT_METHODS_CONSTANTS.BIMPLI.name },
  },

  defaultIcon: IconBimpli,
  icons: [IconBimpli],
  alternativeIcons: [IconBimpliFull],
  colors,

  loaders: {
    editSettings: SET_DEFAULT_CARD_ACTION,
    editSettingsByCardId: (cardId) => `${SET_DEFAULT_CARD_ACTION}_${cardId}`,
  },

  actions: {
    openAddModal(app, onSuccess, onClose) {
      openModal(ModalPedagogyTicketResto,
        {
          name: PAYMENT_METHODS_CONSTANTS.BIMPLI.name,
          cardIconComponent: IconBimpli,
          cardIconFullComponent: IconBimpliFull,
          cardIconColor: colors.primary,
          cardIconBgColor: colors.tertiary,
        },
        undefined,
        (e) => {
          if (typeof onClose === 'function') onClose();
          if (e.params?.type === 'success' && typeof onSuccess === 'function') onSuccess();
        });
    },

    redirect() {
      router.push({
        name: 'mon-compte_payment-methods_paygreen_add',
        params: { name: PAYMENT_METHODS_CONSTANTS.BIMPLI.name },
      });
    },

    setDefault(cardId, isDefault) {
      store.dispatch(`user/${SET_DEFAULT_CARD_ACTION}`, {
        cardId,
        isDefault
      });
    },
  },
};
