const isUndefined = (value) => value === undefined;

const evaluateGuards = (guards, to, from, next) => {
  const guardsLeft = guards.slice(0); // clone the array so we do not accidentally modify it
  const nextGuard = guardsLeft.shift();

  if (isUndefined(nextGuard)) {
    next();
    return;
  }

  nextGuard(to, from, (nextArg) => {
    if (isUndefined(nextArg)) {
      evaluateGuards(guardsLeft, to, from, next);
      return;
    }
    console.info('multipleGuard > Redirecting to', nextArg);
    next(nextArg);
  });
};

export default (guards) => {
  if (!Array.isArray(guards)) {
    throw new Error('You must specify an array of guards');
  }

  return (to, from, next) => evaluateGuards(guards, to, from, next);
};
