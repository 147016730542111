<template>
  <transition :name="transition">
    <div
      v-if="isVisible"
      class="potager-alert-wrapper flex print:hidden relative min-h-[40px] bp425:min-h-[56px] font-semibold">
      <div
        ref="collapsibleBody"
        :class="[
          'potager-alert w-full h-auto',
          `potager-alert--${theme}`,
          {
            'potager-alert--closable': closable,
          }
        ]">
        <potager-container
          :class="['potager-alert__container max-w-full relative',
                   {
                     'px-12 bp425:pl-4': closable,
                     'px-4': !closable,
                   }
          ]"
          size="wide">
          <potager-button
            :class="[
              'potager-alert__message text-center mx-auto pl-2 py-4 bp1280:py-2 text-xs.5',
              { 'potager-alert__message--redirect': redirect },
              { 'potager-button--reset font-semibold': clickable },
            ]"
            :to="redirect"
            theme="reset"
            @onClick="onClick">
            <slot />
          </potager-button>

          <potager-button
            v-if="closable"
            :theme="theme === 'default' ? 'go-green' : theme"
            aria-label="Fermer l'alerte"
            class="absolute top-1/2 right-4 transform -translate-y-1/2 bp425:right-2"
            is-square
            name="close"
            size="small"
            @onClick="dismiss">
            <template #icon>
              <icon-close />
            </template>
          </potager-button>

          <div
            v-if="$slots.action"
            class="potager-alert__action h-full flex items-center">
            <slot name="action" />
          </div>
        </potager-container>
      </div>
    </div>
  </transition>
</template>

<script>
import Cookies from 'js-cookie';

import IconClose from 'Icons/bold/IconClose';
import PotagerContainer from 'UI/PotagerContainer';
import PotagerButton from 'UI/PotagerButton';

import { propsBuilder } from 'UI/Tools';
import { DEFAULT_THEMES } from 'Classes/Constants';

export const props = {
  theme: {
    type: String,
    required: false,
    default: 'default',
    values: [
      'default', // go-green #00AF66
      ...DEFAULT_THEMES,
    ],
  },
  id: {
    type: [String, Number],
    required: false,
    default: null,
  },
  showOnce: {
    type: Boolean,
    required: false,
    default: false,
  },
  redirect: {
    type: [Object, String],
    required: false,
    default: null,
  },
  closable: {
    type: Boolean,
    required: false,
    default: false,
  },
  clickable: {
    type: Boolean,
    required: false,
    default: false,
  },
  transition: {
    type: String,
    required: false,
    default: 'collapse',
  },
};

export default {
  components: {
    IconClose,
    PotagerContainer,
    PotagerButton,
  },

  emits: ['onDismiss', 'onClick'],

  data: () => ({
    isVisible: false,
  }),

  props: propsBuilder(props),

  watch: {
    id: {
      handler(id) {
        this.isVisible = id && !Cookies.get(`alert-${id}`);
      },
      immediate: true,
    },
  },

  methods: {
    dismiss() {
      this.$emit('onDismiss', this.id);

      this.$nextTick(() => {
        if (this.id) {
          Cookies.set(`alert-${this.id}`, true, {
            ...(this.showOnce ? { expires: 365 } : {}),
            sameSite: 'Lax'
          });
        }
        this.isVisible = false;
      });
    },
    onClick() {
      this.$emit('onClick');
    },
  },
};
</script>
