<template>
  <div class="secondary-payment-methods-list">
    <h3 class="text-xs.5 font-extrabold mb-2">
      Ajouter une carte titres restaurant
    </h3>

    <potager-button
        v-for="PM in SECONDARY_PAYMENT_METHODS"
        :key="PM.name"
        class="mb-4 last:mb-0"
        full-width
        theme="beryl-light"
        @onClick="$emit('onClick', PM)">
      <div
          :class="[
          'text-left text-xs.5',
          'flex items-center justify-between',
          'transition',
          {
            'filter grayscale opacity-30 cursor-default hover:cursor-normal' : isAlreadyAdded(PM),
            'cursor-pointer hover:border-charcoal' : !isAlreadyAdded(PM),
          }
        ]">
        <div
            :class="[
            'flex flex-col',
            {
              'py-2': !PM.description
            }
          ]">
          <span class="font-semibold">
            {{ PM.title }}
          </span>

          <span class="text-xs.5">
            {{ PM.description }}
          </span>
        </div>

        <span class="text-sm.5 leading-0">
            <component
                :is="icon"
                v-for="(icon, index) in PM.icons"
                :key="`${PM.name}-${index}`"
                :class="`ml-1 text-${PM.name}`"
                :style="{color: PM.colors.primary}"/>
        </span>
      </div>
    </potager-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SECONDARY_PAYMENT_METHODS } from 'Classes/paymentMethods';
import PotagerButton from 'UI/PotagerButton';

export default {
  name: 'SecondaryPaymentMethodsList',
  components: { PotagerButton },

  computed: {
    ...mapGetters('user', [
      'getPaymentAccountByName',
    ]),
  },

  data() {
    return {
      SECONDARY_PAYMENT_METHODS,
    };
  },

  methods: {
    isAlreadyAdded(PM) {
      return !PM.type === 'login' || this.getPaymentAccountByName(PM.name);
    },
    open(PM) {
      if (!this.isAlreadyAdded(PM)) {
        PM.actions.openAddModal(this, () => {
          this.$modal.close();
        });
      }
    },
  },
};
</script>
