<template>
  <section class="py-8">
    <div class="overflow-hidden bg-sandy-beach py-16 bp425:py-10">
      <potager-container
        :class="[
          'flex justify-center gap-10 px-12 max-w-[1168px]',
          'bp840:flex-col bp840:items-center bp425:px-6 bp425:gap-6',
        ]">
        <div
          :class="[
            'max-w-[480px] w-full',
            'bp1024:min-w-[410px] bp1024:w-[410px]',
            'bp840:min-w-0 bp840:w-full'
          ]">
          <h2 class="home-page__title text-left mb-2 text-mango-tango bp1024:text-2xl">
            <strong>
              Votre panier de fruits & légumes pour la semaine&nbsp;!
            </strong>
          </h2>

          <p class="text-sm mb-6 font-bold">
            Récoltez les fruits de votre fidélité avec l’abonnement.
          </p>

          <subscriber-benefits-list />

          <potager-button
            :to="{ name: 'tunnel' }"
            class="mt-6"
            theme="mango-tango">
            Découvrir l’abonnement
          </potager-button>
        </div>

        <potager-picture
          src="concept-1"
          :class="[
            'w-full max-h-[414px] rounded-xl overflow-hidden bp840:order-first',
            'bp840:max-w-[480px] bp840:max-h-[365px]'
          ]" />
      </potager-container>
    </div>
  </section>
</template>

<script>
import PotagerContainer from 'UI/PotagerContainer';
import PotagerButton from 'UI/PotagerButton';
import PotagerPicture from 'UI/PotagerPicture';

import SubscriberBenefitsList from 'Components/subscriberBenefits/SubscriberBenefitsList';

export default {
  components: {
    SubscriberBenefitsList,
    PotagerPicture,
    PotagerButton,
    PotagerContainer,
  },
};
</script>
