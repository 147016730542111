<template>
  <div class="product-tools">
    <transition name="fade">
      <div
        v-if="!box.loading"
        class="product-tools__container">
        <div class="product-tools__price">
          <p>
            <span
              v-if="isPromotion && getBlockedOutPrice"
              class="product-tools__price__blocked-out">
              {{ priceFilter(getBlockedOutPrice) }}€
            </span>
            <span
              :class="{
                'product-tools__price__value': true,
                'product-tools__price__value--has-blocked-out-price': getBlockedOutPrice,
                'product-tools__price__value--promo': isPromotion
              }">
              {{ priceFilter(getPrice, { convert0toString: true }) }}
            </span>
          </p>
          <p class="product-tools__price__details">
            <span class="product-tools__price__details__packaging">
              {{ getPackaging }}
            </span>
            <span
              v-if="!box.isAssociableToVouchers"
              class="product-tools__price__details__price-by-weight">
              {{ getPriceByWeight }}
            </span>
          </p>
        </div>

        <slot name="action">
          <card-box-tools
            :box="box"
            :disabled="box.isAssociableToVouchers"
            :is-loading="isLoading"
            :quantity="nbItems"
            can-edit-quantity
            @decrement="decrement"
            @edit="edit"
            @increment="increment" />
        </slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Filters from 'Filters';

import BasketActionMixin from 'Mixins/BasketActionMixin';

import PriceTools from 'Classes/PricesTools';
import { getPackagingWeightMessage, getPackagingPriceByWeight } from 'Classes/BoxesTools';
import { getDlpSearchRoute } from 'Classes/DlpRouteTools';

import CardBoxTools from 'Components/cards/UI/CardBoxTools';

export default {

  mixins: [
    BasketActionMixin,
  ],

  components: {
    CardBoxTools,
  },

  props: {
    box: {
      type: Object,
      required: true,
    },
    nbItems: {
      type: Number,
      required: false,
      default: null,
    },
  },

  computed: {
    ...mapGetters('basket', [
      'getPriceCategory',
      'isSubscription',
      'canContainMiniBox',
      'getDeliveryPoint',
    ]),
    subPrice() {
      return this.box?.price?.subscription;
    },
    publicPrice() {
      return this.box.price?.oneShot;
    },
    getPrice() {
      return this.isSubscription ? this.subPrice : this.publicPrice;
    },
    getPackaging() {
      return getPackagingWeightMessage(
        this.box.productPackaging,
        false,
        this.box.categoryMentionWeight,
      );
    },
    getPriceByWeight() {
      return getPackagingPriceByWeight(this.box.productPackaging, this.getPriceCategory);
    },
    getBlockedOutPrice() {
      return PriceTools.getBlockedOutPrice(this.getPriceCategory, this.box.price);
    },
    isLoading() {
      const { id } = this.box;
      return this.$wait.is([
        `ADD_BOX_BASKET_ACTION_${id}`,
        `DELETE_BOX_BASKET_ACTION_${id}`,
        'EMPTY_BASKET_ACTION',
      ]);
    },
    isDisabled() {
      return this.box.isSoldOut
        || !this.box.deliveryDate.isCurrentWeek
        || this.box.isTypeFormule;
    },
    isCouponProduct() {
      return this.box.isAssociableToVouchers;
    },
    isPromotion() {
      return this.box.isInPromotion || this.isCouponProduct;
    },
    isIncreaseLock() {
      return this.box.isComplementary && !this.canContainMiniBox;
    },
    isFullyLock() {
      return this.isCouponProduct;
    },
  },

  methods: {
    priceFilter: Filters.priceFilter,
    increment() {
      if (this.getDeliveryPoint) {
        this.addBox(this.box);
      } else {
        this.$router.push(getDlpSearchRoute());
      }
    },
    edit(newQuantity) {
      this.editBoxQuantity(this.box, newQuantity);
    },
    decrement() {
      this.removeBox(this.box);
    },
  },

};
</script>
