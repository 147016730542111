import { EDIT_SWILE_SETTINGS, LOGOUT_SWILE, GET_SWILE_LOGIN_URL } from 'Stores/types/userActionsTypes';
import IconPtcySwile from 'Icons/regular/IconPtcySwile';
import IconPtcySwileApp from 'Icons/bold/IconPtcySwileApp';
import IconPtcySwileGradient from 'Icons/color/IconPtcySwileGradient';

import { getResolvedUrl, inAppWebView } from 'Classes/Tools';

import PAYMENT_METHODS_CONSTANTS from 'Classes/paymentMethods/Constants';
import ModalPedagogyTicketResto from 'Modals/ModalPedagogyTicketResto';

import { openModal } from 'Plugins/potagerModals';

import store from 'Stores';

export const {
  type,
  integration,
  colors,
} = PAYMENT_METHODS_CONSTANTS.SWILE;

export default {
  name: PAYMENT_METHODS_CONSTANTS.SWILE.name,
  type,
  integration,
  label: 'carte Swile',
  title: 'Carte Swile',
  readmoreLink: 'https://potagercity.force.com/serviceclient/s/article/comment-utiliser-ma-carte-swile-comme-moyen-de-paiement',

  route: { name: 'mon-compte_payment-methods_swile' },

  helpers: {
    login: `
      En cliquant sur le bouton "Connecter", vous allez être redirigé vers le site de Swile.<br>
      Saisissez votre email et votre mot de passe, puis validez les étapes suivantes.<br>
      Une fois toutes ces étapes validées, vous serez connecté à votre carte Swile et pourrez commencer à l'utiliser.
    `,
    logout: `
      Votre carte Swile sera déconnectée et ne sera plus débitée.
      Vous devrez vous reconnecter pour l'utiliser à nouveau.
    `,
    setDefault: `
      J'utilise automatiquement le solde de ma carte Swile pour toutes mes commandes à venir.
      Pour chaque commande en mode Abonnement, j'autorise Potager City à prélever le
      solde au moment de la génération de la commande (la veille de la livraison).
    `,
    subscriptionOrder: `
      Au moment du paiement de votre commande, nous prélèverons en priorité sur votre carte Swile.
      Si le solde est insuffisant ou le plafond dépassé, nous prélèverons la différence sur votre carte bancaire.
      Dans cette éventualité, nous avons effectué une autorisation sur votre carte bancaire du montant total de
      votre commande.
    `,
  },

  defaultIcon: IconPtcySwileApp,
  icons: [IconPtcySwileGradient],
  alternativeIcons: [IconPtcySwile],

  colors,

  loaders: {
    login: GET_SWILE_LOGIN_URL,
    logout: LOGOUT_SWILE,
    editSettings: EDIT_SWILE_SETTINGS,
  },

  actions: {
    openAddModal(app, onSuccess, onClose) {
      openModal(ModalPedagogyTicketResto,
        {
          name: PAYMENT_METHODS_CONSTANTS.SWILE.name,
          cardIconComponent: IconPtcySwileGradient,
          cardIconFullComponent: IconPtcySwile,
        },
        undefined,
        (e) => {
          if (typeof onClose === 'function') onClose();
          if (e.params?.type === 'success' && typeof onSuccess === 'function') onSuccess();
        });
    },
    redirect: (routeName = 'mon-compte_payment-methods_swile') => {
      const redirectUrl = `${getResolvedUrl(routeName)}?from=swile${inAppWebView() ? `&utm_source=app&token=${localStorage.getItem('token')}` : ''}`;
      store.dispatch(`user/${GET_SWILE_LOGIN_URL}`, redirectUrl)
        .then(({ data }) => {
          document.location.href = data;
        });
    },
    logout: () => {
      store.dispatch(`user/${LOGOUT_SWILE}`);
    },
    setDefault: (value) => {
      store.dispatch(`user/${EDIT_SWILE_SETTINGS}`, {
        usableForSubscription: !!value,
      });
    },
  },
};
