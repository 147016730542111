<template>
  <status-banner
    v-if="getSituation || errorMessage"
    :type="type">
    <span
      v-if="errorMessage"
      v-html="errorMessage" />

    <span v-else-if="haveLinkedOrder">
      Remplissez et validez votre panier.<br>
      Il sera ajouté à votre
      <potager-link
        :to="{ name: 'mon-compte_orders_detail', params: { orderId: getLinkedOrder.id } }"
        class="underline font-bold">
        commande en cours
      </potager-link>&nbsp;!
    </span>

    <span v-else-if="isDifferentSubGdd">
      Votre panier diffère de votre abonnement actuel.<br>
      <potager-link
        class="underline font-bold"
        @click="openModal">
        En savoir plus
      </potager-link>
    </span>

    <span v-else-if="isNewOrder">
      Vous avez déjà une <potager-link
        :to="{ name: 'mon-compte_orders'}"
        class="underline font-bold">commande en cours</potager-link>.<br> Ce panier générera une nouvelle commande.
    </span>
  </status-banner>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  CONTEXT_BASKET,
  BASKET_HAVE_LINKED_ORDER,
  BASKET_HAVE_DIFFERENT_SUB_GDD,
  HAVE_ACTIVE_ORDER
} from 'Classes/Constants';
import WordingTools from 'Classes/WordingTools';
import ModalUnusualDeliveryPoint from 'Modals/ModalUnusualDeliveryPoint';
import StatusBanner from 'Components/statusBanner/StatusBanner';

export default {
  components: {
    StatusBanner,
  },

  computed: {
    ...mapGetters('basket', [
      'getSituation',
      'getLinkedOrder',
      'getSelectedGdd',
    ]),

    type() {
      switch (this.getSituation) {
        case BASKET_HAVE_LINKED_ORDER:
          return 'success';
        case BASKET_HAVE_DIFFERENT_SUB_GDD:
        case HAVE_ACTIVE_ORDER:
          return 'warning';
        default:
          if (this.errorMessage) {
            return 'error';
          } else {
            return null;
          }
      }
    },
    haveLinkedOrder() {
      return this.getSituation === BASKET_HAVE_LINKED_ORDER;
    },
    isDifferentSubGdd() {
      return this.getSituation === BASKET_HAVE_DIFFERENT_SUB_GDD;
    },
    isNewOrder() {
      return this.getSituation === HAVE_ACTIVE_ORDER;
    },
    errorMessage() {
      return this.getSelectedGdd ? WordingTools.gddError(CONTEXT_BASKET, this.getSelectedGdd) : null;
    },
  },

  methods: {
    openModal() {
      this.$modal.open(ModalUnusualDeliveryPoint);
    },
  },
};
</script>
