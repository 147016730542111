export const PLACEHOLDER_DASHBOARD_ITEMS = [];
const PLACEHOLDER_ITEM_THUMBNAIL = { itemType: 'thumbnail', loading: true };
const PLACEHOLDER_ITEM_PRODUCT = { itemType: 'product', loading: true };

export const PLACEHOLDER_DASHBOARD_SECTIONS = [
  {
    type: 'liste',
    loading: true,
    items: [
      PLACEHOLDER_ITEM_THUMBNAIL,
      PLACEHOLDER_ITEM_PRODUCT,
      PLACEHOLDER_ITEM_PRODUCT,
      PLACEHOLDER_ITEM_PRODUCT,
      PLACEHOLDER_ITEM_PRODUCT,
      PLACEHOLDER_ITEM_PRODUCT,
      PLACEHOLDER_ITEM_PRODUCT,
    ],
  },
  {
    type: 'liste',
    loading: true,
    items: [
      PLACEHOLDER_ITEM_THUMBNAIL,
      PLACEHOLDER_ITEM_PRODUCT,
      PLACEHOLDER_ITEM_PRODUCT,
      PLACEHOLDER_ITEM_PRODUCT,
      PLACEHOLDER_ITEM_PRODUCT,
      PLACEHOLDER_ITEM_PRODUCT,
      PLACEHOLDER_ITEM_PRODUCT,
    ],
  },
];

export const PLACEHOLDER_BONCOING_ITEMS = [
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
  { loading: true },
];
