import ORD0016Handler from 'Classes/ErrorHandler/handlers/ORD0016Handler';
import ORD0018Handler from 'Classes/ErrorHandler/handlers/ORD0018Handler';

import BT003Handler from 'Classes/ErrorHandler/handlers/BT003Handler';
import BT004Handler from 'Classes/ErrorHandler/handlers/BT004Handler';
import BT005Handler from 'Classes/ErrorHandler/handlers/BT005Handler';
import BT015Handler from 'Classes/ErrorHandler/handlers/BT015Handler';
import BT017Handler from 'Classes/ErrorHandler/handlers/BT017Handler';
import BT018Handler from 'Classes/ErrorHandler/handlers/BT018Handler';
import BT021Handler from 'Classes/ErrorHandler/handlers/BT021Handler';
import BT024Handler from 'Classes/ErrorHandler/handlers/BT024Handler';

import ORDBASK003Handler from 'Classes/ErrorHandler/handlers/ORDBASK003Handler';
import ORDBASK004Handler from 'Classes/ErrorHandler/handlers/ORDBASK004Handler';
import ORDBASK010Handler from 'Classes/ErrorHandler/handlers/ORDBASK010Handler';
import ORDBASK022Handler from 'Classes/ErrorHandler/handlers/ORDBASK022Handler';
import ORDBASK030Handler from 'Classes/ErrorHandler/handlers/ORDBASK030Handler';

import CUS005Handler from 'Classes/ErrorHandler/handlers/CUS005Handler';

import StripeErrorHandler from 'Classes/ErrorHandler/handlers/StripeErrorHandler';

import { DEFAULT_ERROR_EVENT } from 'Classes/Constants';
import WordingTools from 'Classes/WordingTools';

export const BT003 = 'BT003';
export const BT004 = 'BT004';
export const BT005 = 'BT005';
export const BT006 = 'BT006';
export const BT007 = 'BT007';
export const BT015 = 'BT015';
export const BT021 = 'BT021';
export const BT024 = 'BT024';
export const ORD0012 = 'ORD0012';
export const ORD0015 = 'ORD0015';
export const ORD0016 = 'ORD0016';
export const TEMPCUS007 = 'TEMPCUS007';
export const TEMPCUS008 = 'TEMPCUS008';
export const ORDBASK022 = 'ORDBASK022';
export const ORDBASK030 = 'ORDBASK030';

/**
 * Errors messages.
 *
 * For inline error message
 * exemple: "Ceci est un test de message erreur"
 *
 * For ErrorAlert error (by default, is trigger in axios response interceptor)
 * exemple: {
 *   type: 'popin',
 *   title: "Titre de l'alerte",
 *   text: "Ceci {text} est un test de message d'erreur",
 *   closeBtnText: 'Text du bouton pour fermer la popup',
 *   onCloseRedirect: { name: 'home' }
 * }
 * If trigger manually, you can have context var for your title and text ({text})
 *
 */
export default {
  BASK001: 'Votre panier n\'est pas valide.',
  BT001: 'La région associée à votre panier n\'est pas la bonne.',
  BT002: 'Votre panier ne respecte pas les règles de validations des coupons.',
  BT003: BT003Handler,
  BT004: BT004Handler,
  BT005: BT005Handler,
  BT006: 'Veuillez renseigner votre adresse postale ou un code groupe afin de garantir la livraison dans ce point de vente.',
  BT007: 'Vous ne pouvez pas (ou plus) commander sur ce créneau cette semaine.',
  BT008: 'Votre panier ne doit pas contenir des produits appartenant à l\'offre formules.',
  BT009: 'Vous ne pouvez pas commander sur ce jour de retrait car sa limite a été atteinte. Veuillez sélectionner un autre jour et/ou un autre point de retrait.',
  BT010: 'Votre panier doit avoir un jour de livraison.',
  BT012: 'Ce coupon n\'est utilisable que pour votre première commande.',
  BT013: 'Ce coupon a atteint sa limite de clients et ne peut plus être appliqué.',
  BT014: 'Ce coupon ne peut pas être appliqué car il a atteint le nombre maximum de commandes auquel il peut être associé.',
  BT015: BT015Handler,
  BT016: 'Vous n\'avez pas l\'autorisation d\'utiliser ce coupon.',
  BT017: BT017Handler,
  BT018: BT018Handler,
  BT019: 'Vous ne pouvez pas valider ce panier car il ne contient que des articles à l’unité et il n’est pas possible de commander des articles à l’unité seuls.',
  BT021: BT021Handler,
  BT022: 'Vous ne pouvez pas commander sur ce jour de retrait car sa limite a été atteinte. Veuillez sélectionner un autre jour et/ou un autre point de retrait.',
  BT023: 'Une erreur est survenue',
  BT024: BT024Handler,
  COUP001: 'Ce coupon ne peut pas être appliqué car il a atteint son nombre d\'utilisations.',
  COUP003: 'Ce coupon est invalide ou sa période de validité est dépassée.',
  COUP004: 'Ce coupon ne peut pas être appliqué car il n\'est valable que sur la première commande.',
  COUP005: 'Ce coupon ne peut pas être appliqué car il est déjà associé à un point de retrait ou à un autre client.',
  COUP006: 'Ce coupon a atteint sa limite de clients et ne peut plus être appliqué.',
  ORDBASK001: 'Une commande ne peut pas avoir plus de 2 titres restaurants.',
  ORDBASK002: 'Il n\'est pas possible de cumuler un coupon en euros (ex : -10€) avec un coupon en pourcentage (ex : -50%). Notre conseil : prenez celui qui vous offre la plus grande remise 😉',
  ORDBASK003: ORDBASK003Handler,
  ORDBASK004: ORDBASK004Handler,
  ORDBASK005: 'Une commande ne peut pas contenir plusieurs coupons de type pourcentage.',
  ORDBASK006: 'Le même coupon ne peut pas être appliqué deux fois sur une même commande.',
  ORDBASK007: 'Vous devez appartenir au point de retrait associé au coupon pour l\'ajouter à votre compte ou votre commande.',
  ORDBASK008: 'Le coupon que vous avez appliqué n\'est valable que sur votre première commande.',
  ORDBASK010: ORDBASK010Handler,
  ORDBASK009: 'Le coupon appliqué sur votre commande n\'est pas disponible pour votre région.',
  ORDBASK012: 'Nous n’avons pas appliqué le coupon sélectionné car nous n’autorisons qu’un seul coupon “valable uniquement pour une première commande”.',
  ORDBASK013: 'Désolé, ce coupon n’est valable que pour les clients avec un abonnement actif. Pour utiliser ce coupon, veuillez activer un abonnement puis l’appliquer sur la commande qui sera créée suite à l’activation de l’abonnement.',
  ORDBASK014: 'Vous ne pouvez pas appliquer de coupon de parrainage sur votre première commande. Effectuez une première commande, attendez qu’elle soit livrée et vous pourrez ensuite les appliquer sur vos commandes suivantes.',
  ORDBASK015: 'Désolé, un coupon de parrainage n’est valable que pour les clients avec un abonnement actif. Pour utiliser ce coupon, veuillez activer un abonnement puis l’appliquer sur la commande qui sera créée suite à l’activation de l’abonnement.',
  ORDBASK016: 'Nous n’avons pas pu appliquer ce coupon car nous n’autorisons qu’un seul coupon de type “parrainage” ou “bon de réduction” sur une même commande.',
  ORDBASK017: 'Nous n’avons pas pu appliquer ce coupon car nous n’autorisons qu’un seul coupon de type “bon de réduction” sur une même commande.',
  ORDBASK018: 'Nous n’avons pas pu appliquer ce coupon car nous n’autorisons qu’un seul coupon de type “parrainage” sur une même commande.',
  ORDBASK019: 'Ce coupon n\'est valable que pour les clients avec un abonnement actif. Pour utiliser ce coupon, veuillez l\'appliquer sur la commande qui sera créée suite à l\'activation de votre abonnement.',
  ORDBASK020: 'Ce coupon n\'est valable que pour une commande comportant au moins un produit à l\'unité. Ajoutez d\'abord un produit à l\'unité à votre commande puis appliquez le coupon.',
  ORDBASK021: 'Ce coupon ne peut pas être appliqué car il n\'est éligible que pour une liste restreinte de produits. Et aucun de ces produits n\'est présent dans votre commande. Veuillez ajouter un produit éligible à votre commande et réessayez.',
  ORDBASK022: ORDBASK022Handler,
  ORDBASK023: 'Ce coupon n’est pas utilisable pour une livraison sur le point de retrait sélectionné. Pour utiliser ce coupon, veuillez sélectionner un autre lieu de retrait.',
  ORDBASK024: 'Vous ne pouvez pas utiliser ce coupon car il est restreint à un domaine email spécifique et l’adresse email associée à votre compte client n’est pas éligible.',
  ORDBASK025: 'Ce coupon n\'est pas utilisable pour une livraison sur le point de retrait sélectionné. Pour utiliser ce coupon, veuillez sélectionner un autre lieu de retrait.',
  ORDBASK030: ORDBASK030Handler,
  ORD001: 'Votre commande ne doit pas contenir d\'abonnement.',
  ORD002: 'Votre commande doit être en cours de composition.',
  ORD003: 'La date limite de paiement de votre commande est dépassée.',
  ORD004: 'Votre commande ne doit pas être confirmée.',
  ORD005: 'Votre commande ne doit pas être livrée.',
  ORD006: 'Votre commande n\'est plus modifiable.',
  ORD007: 'Le paiement de votre commande est échoué.',
  ORD008: 'Votre commande doit posséder un produit de type abonnement.',
  ORD0013: 'Le coupon n\'est utilisable que pour la première commande.',
  ORD0014: 'Le coupon à atteint son nombre d’utilisations maximum',
  ORD0015: 'Nous avons détecté que vous essayez d’appliquer un coupon valable sur la première commande alors que vous possédez déjà plusieurs comptes utilisant la même carte de paiement. Nous sommes dans le regret de vous signaler que nous n’autorisons pas cette pratique.',
  ORD0016: ORD0016Handler,
  ORD0018: ORD0018Handler,
  TEMPCUS002: 'Vous devez choisir une région.',
  TEMPCUS003: 'Vous devez avoir un jour de livraison.',
  TEMPCUS004: 'Votre abonnement n\'existe pas.',
  TEMPCUS005: 'Votre numéro de téléphone mobile n\'est pas valide.',
  TEMPCUS006: 'Votre adresse email n\'est pas valide.',
  TEMPCUS009: 'Le code parrain n\'est pas valide ou le parrain n\'a pas été trouvé.',
  COUPAS001: 'Ce coupon ne peut plus être utilisé sur votre compte.',
  CS001: 'Le produit choisi n\'est pas éligible à l\'abonnement.',
  CS002: 'Le produit choisi n\'est pas disponible sur votre région.',
  CUS001: 'Vous devez posséder une carte bancaire principale pour être abonné.',
  CUS002: 'Vous devez posséder une carte bancaire principale.',
  CUS003: 'Vous devez êtes abonné.',
  CUS005: CUS005Handler,
  CB001: 'Votre carte bancaire est arrivée à expiration.',
  PRO001: 'Le produit n\'est pas disponible sur la semaine en cours.',
  PRO002: 'Oh nooooon… Le dernier élément vient d\'être vendu et n’est désormais plus disponible. Nous vous suggérons de choisir autre chose sur la boutique, il y a forcément quelque chose qui vous plaira 🙂',
  SB001: 'Veuillez indiquer la commande à modifier.',
  CUSBC001: 'La carte de paiement renseignée est invalide. Veuillez réessayer en vérifiant l’exactitude des informations.',
  CUSCOUP002: 'Ce coupon a atteint le nombre maximum d\'utilisations.',
  GDD001: WordingTools.gddFull(),
  SPAY001: 'Le montant du paiement autorisé est inférieur au montant de la commande à laquelle il est relié.',
  SPAY002: StripeErrorHandler,
  SPAY003: StripeErrorHandler,
  S3DS001: StripeErrorHandler,
  S3DS002: StripeErrorHandler,
  NonTemporaryEmail: 'Les adresses emails jetables ou temporaires ne sont pas admises.',
};

export const defaultErrorConfig = {
  title: 'Avertissement',
  text: 'Une erreur s\'est produite',
  eventType: DEFAULT_ERROR_EVENT,
  code: 'default',
  // Use pure function ! else bind this won't work

  onClose: () => {
  },
  handle: () => {
  },
};
