<template>
  <div
    class="panel-section mx-0">
    <p class="panel-section__subtitle">
      {{ isFirstOrder ? 'Prix de votre 1ère commande' : 'Prix de votre commande' }}
    </p>

    <template v-if="getCustomerSubscriptionProduct">
      <potager-receipt-details
        :lines="receiptLines"
        class="panel-section__line" />

      <subscriber-benefits-navigator
        :order="getSubscriptionBasket"
        no-navigate />

      <div class="panel-section__separator" />

      <p class="panel-section__subtitle flex justify-between">
        Code promo

        <potager-button
          class="underline not-italic"
          label="Ajouter"
          size="small"
          theme="link"
          @onClick="openCouponsModal">
          {{ getSubscriptionBasket?.coupons.length ? 'Modifier' : 'Ajouter' }}
        </potager-button>
      </p>

      <potager-receipt-details
        v-if="getSubscriptionBasket?.coupons.length"
        :lines="couponsLines" />

      <div class="panel-section__separator" />

      <potager-receipt-details
        :lines="[totalLine]" />
    </template>

    <template v-else>
      <div
        v-for="i in 3"
        :key="i"
        class="animate-pulse w-full h-8 bg-white-rock rounded-lg mb-4 last:mb-0" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerReceiptDetails from 'UI/PotagerReceiptDetails';
import PotagerButton from 'UI/PotagerButton';

import SubscriberBenefitsNavigator from 'Components/subscriberBenefits/SubscriberBenefitsNavigator';
import ModalSubscriptionCoupons from 'Components/modals/ModalSubscriptionCoupons';

import { getCouponsListLines, getReceiptLines, getTotalLine } from 'Classes/ReceiptDetailsTools';

export default {

  components: {
    PotagerButton,
    SubscriberBenefitsNavigator,
    PotagerReceiptDetails,
  },

  computed: {
    ...mapGetters('subscriptionBasket', [
      'getSubscriptionBasket',
      'getCustomerSubscriptionProduct',
    ]),
    ...mapGetters('user', [
      'getOrders',
      'getTotalPaidOrders',
    ]),
    isFirstOrder() {
      return !this.getOrders.length && !this.getTotalPaidOrders;
    },
    receiptLines() {
      return getReceiptLines(this.getSubscriptionBasket);
    },
    couponsLines() {
      return getCouponsListLines(this.getSubscriptionBasket);
    },
    totalLine() {
      return getTotalLine(this.getSubscriptionBasket);
    },
  },

  methods: {
    openCouponsModal() {
      this.$modal.open(ModalSubscriptionCoupons);
    },
  },
};
</script>
