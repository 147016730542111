import { useGtm } from '@gtm-support/vue-gtm';
const gtm = useGtm();

import { UPDATE_OAC_FLOW, UPDATE_OAC_RECOMMENDED_REF } from 'Stores/types/sessionMutationsTypes';
import { FETCH_PRODUCT_SELECTORS_V2_ACTION } from 'Stores/types/subscriptionBasketActionsTypes';
import Tree from 'Classes/OAC/Tree';
import Config from 'Classes/OAC/Config';

import { watch } from 'vue';
import router from 'Router';
import store from 'Stores';
import { useRoute } from 'vue-router';

const explodeHistoricalAnswersParam = (value) => value?.split(Config.questionSeparator).map((e) => {
  const a = e.split(Config.answersSeparator);
  return {
    question: a[0],
    answers: a[1],
  };
}).filter((e) => !!e.question) || [{ question: Tree[0].id }];

const joinHistoricalAnswersArray = (arr) => arr.map((e) => `${e.question}${e.answers ? `${Config.answersSeparator}${e.answers}` : ''}`).join(Config.questionSeparator);

const getRegionid = () => useRoute().params.regionId || store.getters['session/getRegionId'];

const routerPush = (answers) => {
  router.push({
    name: Config.routes.question,
    params: {
      answers: joinHistoricalAnswersArray(answers),
      regionId: getRegionid(),
    },
  });
};

const restart = () => {
  router.push({
    name: Config.routes.question,
    params: { regionId: getRegionid() },
  });
};

const cantAccessStep = (step) => !!step.constraints?.filter((e) => e()).length;

const aggregator = (values) => values.reduce((acc, val) => `${acc}${acc ? Config.answerSeparator : ''}${val.value}`, '');

const saveRecommendedBoxRef = (ref, isNotEnough = false) => {
  store.commit(`session/${UPDATE_OAC_RECOMMENDED_REF}`, { ref, isNotEnough });
};

class OAC {

  constructor(tree) {
    this.watchers = {};
    this.ready = false;
    this.tree = tree;
    this.savedAnswers = [];
    this.historicalSteps = [];
  }

  init() {
    const route = useRoute();
    this.ready = false;
    this.savedAnswers = [];
    this.setHistoricalStepsFromRouting();
    saveRecommendedBoxRef(null);

    this.watchers.routingName = watch(
      () => route.name,
      (e) => {
        if (e !== Config.routes.question) this.destroy();
      },
    );

    this.watchers.routingParams = watch(
      () => route.params.answers,
      () => {
        this.savedAnswers = [];
        this.setHistoricalStepsFromRouting();
      },
    );

    const regionId = getRegionid();
    store.dispatch(`subscriptionBasket/${FETCH_PRODUCT_SELECTORS_V2_ACTION}`, { regionId })
      .then(() => {
        if (cantAccessStep(this.getCurrentStep())) restart();
        this.ready = true;
      });
  }

  destroy() {
    Object.entries(this.watchers).forEach((unwatch) => {
      unwatch[1]();
    });
  }

  isReady() {
    return this.ready;
  }

  getRegionId() { // eslint-disable-line class-methods-use-this
    return getRegionid();
  }

  getHistoricalSteps() {
    return this.historicalSteps;
  }

  getCurrentStep() {
    const ID = this.getHistoricalSteps()[this.getHistoricalSteps().length - 1]?.question;
    return this.getStepById(ID) || this.tree[0];
  }

  getMessage() {
    return typeof this.getCurrentStep().message === 'function' ? this.getCurrentStep().message() : this.getCurrentStep().message;
  }

  getLegend() {
    return this.getCurrentStep().legend;
  }

  getStepById(id) {
    return this.tree.find((e) => e.id === id);
  }

  getSavedAnswers() {
    return this.savedAnswers;
  }

  canSubmit() {
    return this.getSavedAnswers().filter((e) => !!e).length === this.getCurrentStep().questions.length;
  }

  canGoBack() {
    return !!this.getHistoricalSteps()[this.getHistoricalSteps().length - 2];
  }

  isAnswerSelected(answer) {
    return !!this.getSavedAnswers().find((a) => a === answer);
  }

  setHistoricalStepsFromRouting() {
    const route = useRoute();
    this.historicalSteps = explodeHistoricalAnswersParam(route.params.answers);
    this.saveFlowToStore();
  }

  saveFlowToStore() {
    store.commit(`session/${UPDATE_OAC_FLOW}`, joinHistoricalAnswersArray(this.getHistoricalSteps()));
  }

  saveAnswer(answer) {
    this.getCurrentStep().questions.forEach((q, i) => {
      q.answers.forEach((a) => {
        if (a === answer) App.$set(this.savedAnswers, i, this.savedAnswers[i] === answer ? null : answer);
      });
    });
    this.goToNextStep();
  }

  goToPrevStep() {
    return new Promise((resolve, reject) => {
      if (this.canGoBack()) {
        const answers = [...this.getHistoricalSteps()];
        answers.pop();
        routerPush(answers);
      } else {
        reject();
      }
    });
  }

  goToNextStep() {
    if (this.canSubmit()) {
      const nextStep = this.getCurrentStep().resolver(this.getSavedAnswers());
      this.goToNextStepActions(nextStep);
    }
  }

  goToNextStepActions(nextStep, save = true) {
    if (save) {
      this.historicalSteps[this.historicalSteps.length - 1].answers = aggregator(this.getSavedAnswers());
      this.saveFlowToStore();
    }

    if (typeof nextStep === 'function') {
      nextStep();
    } else if (typeof nextStep === 'object') {
      saveRecommendedBoxRef(nextStep.ref, nextStep.isNotEnough);
      this.trackAnswer(nextStep.ref, nextStep.isNotEnough);
      router.push({
        name: Config.routes.answer,
        params: {
          ref: nextStep.ref,
          regionId: getRegionid(),
        },
      });
    } else {
      const nextStepObj = this.getStepById(nextStep);
      if (cantAccessStep(nextStepObj)) {
        const newNextStep = nextStepObj.resolver([]);
        this.goToNextStepActions(newNextStep, false);
      } else {
        this.goToNextQuestion(nextStep);
      }
    }
  }

  goToNextQuestion(nextStep) {
    const answers = [...this.getHistoricalSteps()];
    answers.push({ question: nextStep });
    this.trackQuestion();
    routerPush(answers);
  }

  trackQuestion() {
    try {
      gtm.trackEvent({
        event: 'OAC_Question',
        data: {
          id: this.getCurrentStep().id,
          questions: this.getSavedAnswers().map((e, i) => ({
            title: this.getCurrentStep().questions[i]?.title,
            answer: e.value,
          })),
        },
      });
    } catch (e) { /* Nothing */ }
  }

  trackAnswer(reference, isNotEnough = false) {
    try {
      gtm.trackEvent({
        event: 'OAC_Answer',
        data: {
          reference,
          isNotEnough,
          regionId: store.getters['session/getRegionId'],
          details: this.getHistoricalSteps().map((q) => ({
            id: this.getStepById(q.question).id,
            questions: this.getStepById(q.question).questions.map((e, j) => ({
              title: e.title,
              answer: e.answers.find((a) => String(a.value) === q.answers.split(Config.answerSeparator)[j])?.label,
            })),
          })),
          summary: joinHistoricalAnswersArray(this.getHistoricalSteps()),
          fromStart: this.getHistoricalSteps()[0].question === this.tree[0].id,
        },
      });
    } catch (e) { /* Nothing */ }
  }

}

export default new OAC(Tree);
