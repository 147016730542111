import { mapGetters } from 'vuex';

import OrderActionMixin from 'Mixins/OrderActionMixin';

import { ABONNEMENT, CANCELED_CAN_RESUME, CANCELLED, ONESHOT } from 'Classes/OrderStatus';
import Filters from 'Filters';

import { addWeeks, fromUnixTime, isThisWeek } from 'date-fns';
import ModalInfo from 'Modals/ModalInfo';
import ModalDeleteOrder from 'Modals/ModalDeleteOrder';

export default {

  mixins: [
    OrderActionMixin,
  ],

  computed: {
    ...mapGetters('user', [
      'getSubscription',
      'getNextDeliveredTimeslot',
      'isSubscriptionActive',
      'getNextGenerationDate',
    ]),
    isCancel() {
      const { order } = this;
      if (this.order) return order.frontStatus === CANCELED_CAN_RESUME || order.status === CANCELLED;
      return false;
    },
    isNextWeek() {
      if (this.order && !this.isCancel) {
        const orderDate = fromUnixTime(this.order.paymentDate);
        // Vérifie si la date de paiement est dans la semaine suivant la semaine actuelle
        // On ajoute une semaine à la date actuelle
        // et on vérifie si la date de paiement tombe cette semaine
        return !isThisWeek(orderDate) && isThisWeek(addWeeks(orderDate, -1));
      }
      return false;
    },
    isEditable() {
      const { order } = this;
      if (order) return order.isEditable;
      return false;
    },
    isResumable() {
      const { order } = this;
      if (order) return order.isResumable;
      return false;
    },
    isNextDeliveryAvailable() {
      const { order } = this;
      if (order && order.groupDeliveryDay) return order.groupDeliveryDay.isNextDeliveryAvailable;
      return false;
    },
    getNextDeliveryDate() {
      let ts = this.getNextDeliveredTimeslot;
      if (this.order?.groupDeliveryDay) {
        ts = this.isNextWeek ? this.order.groupDeliveryDay.timeSlot : this.order.groupDeliveryDay.currentWeekTimeSlot;
      }
      return ts ? Filters.tmsToFormat(ts.date, 'iiii dd MMMM') : null;
    },
  },

  methods: {
    deleteOrder(order) {
      if (order.canal === ONESHOT) {
        this.deleteOrderOneShot(order);
      } else if (order.canal === ABONNEMENT) {
        this.tryToDeleteOrderSubscription(order);
      }
    },
    deleteOrderOneShot({ id }) {
      this.$modal.open(ModalDeleteOrder, {
        title: 'Êtes-vous sûr ?',
        text: `Si vous avez payé cette commande avec
          des coupons, ils vous seront rendus. Si vous
          avez effectué un paiement par carte bancaire,
          vous serez remboursé sous forme de coupon.`,
        yesCallback: (callback) => {
          this.cancelOrder(id, () => {
            this.$modal.open(ModalInfo, {
              title: 'C\'est tout bon !',
              text: `L'annulation a bien été prise en compte. Si vous aviez payé cette commande
                avec des coupons, ils vous ont été rendus. Si vous aviez effectué un paiement par
                carte bancaire, vous avez été remboursé sous forme de coupon.`,
              closeBtnText: 'Ok',
              showCancel: true,
              cancelBtnText: 'Voir mes coupons',
              onCancelRedirect: { name: 'mon-compte_coupons' },
            });
            if (typeof callback === 'function') callback();
          }, this.showErrorCancelOrder);
        },
        noCallback: (callback) => {
          if (typeof callback === 'function') callback();
        },
      });
    },
    deleteOrderSubscription(order, config = {}) {
      const text = config.text || 'Êtes-vous sûr de vouloir annuler cette commande ?';
      const buttonYesText = config.confirm || 'Oui';
      const buttonNoText = config.cancel || 'Non';

      this.$modal.open(ModalDeleteOrder, {
        title: 'Avertissement',
        text,
        buttonYesText,
        buttonNoText,
        yesCallback: (callback) => {
          this.cancelOrder(order.id, () => {
            this.$notify({
              type: 'success',
              title: 'Commande annulée',
              text: `L'annulation a bien été prise en compte. Si vous aviez payé cette commande
                avec des coupons, ils vous ont été rendus. Si vous aviez effectué un paiement par
                carte bancaire, vous avez été remboursé sous forme de coupon.`,
            });
            if (typeof callback === 'function') callback();
          }, this.showErrorCancelOrder);
        },
        noCallback: (callback) => {
          if (typeof callback === 'function') callback();
        },
      });
    },
    tryToDeleteOrderSubscription(order) {
      if (this.canRedirectToSuspendForm(order)) {
        this.redirectToSuspendForm(order);
      } else {
        this.deleteOrderSubscription(order);
      }
    },
    showErrorCancelOrder() {
      this.$modal.open(ModalInfo, {
        title: 'Oups !',
        text: `Nous avons rencontré une erreur lors de l'annulation de votre commande. Vous pouvez
          ré-essayer. Si cela ne fonctionne toujours pas, contactez le service client.`,
      });
    },
    canRedirectToSuspendForm(order) {
      return order.boxes.length === 1 && order.containsSubscriptionBox;
    },
    redirectToSuspendForm(order) {
      this.$router.push({
        name: 'mon-compte_order_suspend_form',
        params: {
          orderId: order.id,
        },
      });
    },
  },
};
