<template>
  <blog-section
    v-if="sanitizeItems.length"
    :title="getTitle"
    class="print:hidden">
    <potager-row class="content-list__content">
      <potager-col
        v-for="(item, i) in sanitizeItems"
        :key="i"
        bp1024="12"
        class="content-list-page__item bp640:mb-0"
        col="4">
        <card-content
          :content="item"
          class="bp640:pb-4 bp640:mb-4" />
      </potager-col>
    </potager-row>

    <p
      v-if="getRedirect"
      class="cross-selling__see-more">
      <potager-button
        :full-width="$mq.bp1024"
        :label="getBtnLabel"
        :to="getRedirect"
        theme="stroke" />
    </p>
  </blog-section>
</template>

<script>
import PotagerButton from 'UI/PotagerButton';
import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import CardContent from 'Components/cards/CardContent';
import BlogSection from 'Components/blog/BlogSection';
import { toRaw } from 'vue';

export default {

  components: {
    PotagerRow,
    PotagerCol,
    PotagerButton,
    CardContent,
    BlogSection,
  },

  data: () => ({
    items: [],
  }),

  props: {
    type: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      required: false,
      default: 6,
    },
    data: {
      type: Array,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    exclude: {
      type: Number,
      required: false,
      default: -1,
    },
    research: {
      type: String,
      required: false,
      default: null,
    },
  },

  watch: {
    data: {
      handler() {
        this.resetItems();
        this.getItems(this.research);
      },
    },
    research: {
      handler(research) {
        this.getItems(research);
      },
    },
  },

  computed: {
    getTitle() {
      if (this.title) return this.title;

      switch (this.type) {
        case 'supplier-product':
          return 'Explorez plus de saveurs';
        case 'recipe':
          return 'Du potager à l\'assiette : découvrez nos recettes';
        case 'supplier':
          return 'Zoom sur nos producteurs partenaires';
        default:
          return null;
      }
    },
    getRedirect() {
      switch (this.type) {
        case 'supplier-product':
          return { name: 'products-list' };
        case 'recipe':
          return { name: 'recettes-list' };
        case 'supplier':
          return { name: 'suppliers-list' };
        default:
          return null;
      }
    },
    getBtnLabel() {
      switch (this.type) {
        case 'supplier-product':
          return 'Voir tous nos produits';
        case 'recipe':
          return 'Voir toutes nos recettes';
        case 'supplier':
          return 'Voir tous nos producteurs partenaires';
        default:
          return null;
      }
    },
    sanitizeItems() {
      return this.items
        .filter((e) => e.id !== this.exclude)
        .filter((e, i, a) => a.findIndex((t) => (t.id === e.id)) === i)
        .slice(0, this.max)
        .map((item) => ({
          type: this.type,
          title: item.name,
          ...item,
        }));
    },
  },

  methods: {
    getVarieties(research) {
      this.$api.supplierProducts.findAll({
        research,
        maxPerPage: 32
      })
        .then(({ data }) => {
          const { results } = data.data;
          this.onResult(results);
        });
    },
    getRecipes(research) {
      this.$api.recipe.findAll({
        research,
        maxPerPage: 32
      })
        .then(({ data }) => {
          const { results } = data.data;
          this.onResult(results);
        });
    },
    getSuppliers() {
      this.$api.supplier.findAll({ maxPerPage: 32 })
        .then(({ data }) => {
          const { results } = data.data;
          this.items = [...results, ...(this.data || [])];
        });
    },
    getItems(research) {
      switch (this.type) {
        case 'supplier-product':
          return this.getVarieties(research);
        case 'recipe':
          return this.getRecipes(research);
        case 'supplier':
          return this.getSuppliers();
        default:
          return null;
      }
    },
    resetItems() {
      this.items = this.data || [];
    },
    onResult(results) {
      this.items = [...(results || []), ...(this.data || [])];

      // If we have less than 3 items, we fetch more
      if (this.items < 3 && this.research) {
        this.getItems();
      }
    },
  },

  mounted() {
    this.getItems(this.research);
  },

};
</script>
