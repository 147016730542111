<template>
  <div
    v-if="skeleton || !box"
    class="card-box-price w-1/3 skeleton" />

  <div
    v-else
    class="card-box-price">
    <div class="leading-4 bp640:leading-4 pb-1 flex items-center">
      <span
        :class="{
          'card-box-price__price': true,
          'bg-promo/50 px-1 mr-1': isPromotion,
        }">
        {{ priceFilter(getPrice, { convert0toString: true }) }}
      </span>

      <span
        v-if="getBlockedOutPrice && isPromotion"
        class="card-box-price__blocked-out-price">
        {{ priceFilter(getBlockedOutPrice) }}€
      </span>
    </div>

    <div class="card-box-price__weight-and-price-by-weight">
      <span
        class="card-box-price__weight"
        v-html="getPackaging" />

      <span
        v-if="!box.isAssociableToVouchers"
        class="card-box-price__price-by-weight">
        -&nbsp;<span v-html="getPriceByWeight" />
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PriceTools from 'Classes/PricesTools';
import { getPackagingPriceByWeight, getPackagingWeightMessage } from 'Classes/BoxesTools';

import Filters from 'Filters';

export default {
  props: {
    box: {
      type: Object,
      required: false,
      default: undefined,
    },
    showSubPrice: {
      type: Boolean,
      required: false,
      default: false,
    },
    skeleton: {
      type: Boolean,
      required: false,
      default: false,
    },
    order: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    ...mapGetters('user', [
      'isSubscriptionActive',
    ]),
    ...mapGetters('basket', [
      'getPriceCategory',
    ]),
    publicPrice() {
      return this.box.price?.oneShot;
    },
    subPrice() {
      return this.box?.price?.subscription;
    },
    getPrice() {
      return this.showSubPrice ? this.subPrice : this.publicPrice;
    },
    isPromotion() {
      return this.box.isInPromotion || this.box.isAssociableToVouchers || this.box.highlightedPrice;
    },
    getBlockedOutPrice() {
      return PriceTools.getBlockedOutPrice(this.getPriceCategory, this.box.price);
    },
    getPackaging() {
      return getPackagingWeightMessage(
        this.box.productPackaging,
        true,
        this.box.categoryMentionWeight,
      );
    },
    getPriceByWeight() {
      return getPackagingPriceByWeight(this.box.productPackaging, this.getPriceCategory);
    },
  },

  methods: {
    priceFilter: Filters.priceFilter,
  },
};
</script>

<style lang="scss" scoped>
.card-box-price {
  $this: &;
  @apply min-h-[38px];

  &__price {
    @apply font-extrabold leading-tight py-0.5 inline-block text-base;
    .card-v2--small & {
      @apply text-base;
    }
  }

  &__blocked-out-price {
    @apply text-xs line-through text-warm-grey font-semibold;
  }

  &__weight-and-price-by-weight {
    @apply leading-[13px] text-xxs.5;
  }

  &__weight {
    @apply font-semibold;
  }

  &__price-by-weight {
    @apply font-medium text-warm-grey;
  }
}
</style>

<style lang="scss">
.card-box-price {
  .card-v2--landscape & {
    @apply flex flex-col justify-between;
  }

  &__price {
    @apply bp425:text-sm bp425:leading-tight;
  }
}
</style>
