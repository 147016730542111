<template>
  <span>
    <component
        :is="'script'"
        v-for="(json, index) in jsons"
        :key="index"
        type="application/ld+json"
        v-text="json"/>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';
import Date from 'Classes/Dates';
import isEmpty from 'lodash/isEmpty';
import { pluralize } from 'Filters';
import {
  getResolvedUrl,
  stripHtml,
  calcSlug,
} from 'Classes/Tools';

export default {

  props: {
    context: {
      type: String,
      required: false,
      default: '',
    },
    content: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },

  computed: {
    ...mapGetters('session', [
      'isLoggedIn',
    ]),
    jsons() {
      const types = {
        article: 'Article',
        product: 'Product',
        offer: 'Offer',
        webpage: 'WebPage',
        website: 'WebSite',
        image: 'ImageObject',
        organization: 'Organization',
        person: 'Person',
        recipe: 'Recipe',
        howToStep: 'HowToStep',
        breadcrumbList: 'BreadcrumbList',
        listItem: 'ListItem',
        brand: 'Brand',
        video: 'VideoObject',
      };

      const schema = 'http://schema.org/';
      const image = 'https://www.potagercity.fr/images/meta-default.jpg';
      const name = 'Potager City';
      const url = getResolvedUrl('home');
      const pageUrl = document.location.href;

      const logo = {
        '@type': types.image,
        url: 'https://www.potagercity.fr/images/meta-logo.png',
      };
      const publisher = {
        '@type': types.organization,
        name,
      };
      const publisherWithLogo = Object.assign(publisher, { logo });
      const author = {
        '@type': types.person,
        name,
      };

      if (!this.context) {
        return [
          {
            '@context': schema,
            '@type': types.website,
            url,
            name,
            image,
            inLanguage: 'fr',
            headline: 'Un panier avec les meilleurs fruits et légumes de saison pour cuisiner de bons repas !',
            publisher,
            keywords: 'fruits, légumes, produits frais, local, bio, circuit court',
            sameAs: [
              'https://www.facebook.com/potagercity/',
              'https://twitter.com/potagercity',
              'https://www.instagram.com/potagercity/',
              'https://www.linkedin.com/company/potagercity/',
              'https://www.pinterest.fr/potagercity/',
              'https://fr.wikipedia.org/wiki/Potager_City',
            ],
          },
        ];
      }

      const breadcrumbStep1 = {
        '@type': types.listItem,
        position: 1,
        item: {
          '@id': url,
          url,
          name: 'Accueil',
        },
      };

      if (!isEmpty(this.content) && !this.content.loading) {
        if (this.context === 'product') {
          let body = '';
          if (this.content.article) {
            this.content.article.forEach((article) => {
              body += `${article.question} ${article.answer} `;
            });
          }
          body += `Conseils de conservation et préparation ${this.content.advice} `;
          body += `Caractéristiques ${this.content.description} `;
          body += `Le saviez-vous ? ${this.content.anecdotes} `;
          const parentPageUrl = getResolvedUrl('products-list');
          return [
            {
              '@context': schema,
              '@type': types.article,
              mainEntityOfPage: {
                '@type': types.webpage,
                '@id': pageUrl,
              },
              headline: this.content.title,
              image: {
                '@type': types.image,
                url: this.getImgUrl(this.content.image),
              },
              author,
              publisher: publisherWithLogo,
              description: this.content.description,
              articleBody: stripHtml(body),
            },
            {
              '@context': schema,
              '@type': types.webpage,
              keywords: `${this.content.name}, fruit, légume`,
              about: `Caractéristiques de ${this.content.title.replace('Tout savoir sur ', '')}`,
              name: this.content.title,
              description: this.content.description,
              publisher,
            },
            {
              '@context': schema,
              '@type': types.breadcrumbList,
              itemListElement: [
                breadcrumbStep1,
                {
                  '@type': types.listItem,
                  position: 2,
                  item: {
                    '@id': parentPageUrl,
                    url: parentPageUrl,
                    name: 'Produits',
                  },
                },
              ],
            },
          ];
        }

        if (this.context === 'supplier') {
          let body = this.content.description;
          if (this.content.article) {
            this.content.article.forEach((article) => {
              body += `${article.question} ${article.answer} `;
            });
          }
          const parentPageUrl = getResolvedUrl('suppliers-list');
          const supplierUrl = getResolvedUrl('producteurs', {
            slug: calcSlug(this.content.title),
            id: this.content.id,
          });

          return [
            {
              '@context': schema,
              '@type': types.article,
              mainEntityOfPage: {
                '@type': types.webpage,
                '@id': supplierUrl,
              },
              headline: this.content.title,
              image: {
                '@type': types.image,
                url: this.getImgUrl(this.content.image),
              },
              author,
              publisher: publisherWithLogo,
              datePublished: Date.getDateFormatFromTimestamp(this.content.datePublished),
              dateModified: Date.getDateFormatFromTimestamp(this.content.dateModified),
              description: this.content.description,
              articleBody: stripHtml(body),
            },
            {
              '@context': schema,
              '@type': types.webpage,
              keywords: `${this.content.firstname}, producteur`,
              about: `Portrait producteur de ${this.content.firstname}`,
              name: this.content.title,
              description: this.content.description,
              publisher,
            },
            {
              '@context': schema,
              '@type': types.breadcrumbList,
              itemListElement: [
                breadcrumbStep1,
                {
                  '@type': types.listItem,
                  position: 2,
                  item: {
                    '@id': parentPageUrl,
                    url: parentPageUrl,
                    name: 'Producteurs',
                  },
                },
              ],
            },
          ];
        }

        if (this.context === 'recipe') {
          const ingredients = this.content.ingredients_v2;

          let formatedIngredients = '';
          let productsIngredients = '';

          if (ingredients) {
            formatedIngredients = ingredients.map((element) => {
              if (element.quantity && element.unit) {
                return `${element.product.trim()} : ${element.quantity} ${element.unit.trim()}`;
              }
              if (element.quantity) {
                return `${element.product.trim()} : ${element.quantity}`;
              }
              return element.product.trim();
            });
            if (ingredients) {
              ingredients.forEach((ingredient) => {
                productsIngredients += `, ${ingredient.product.toLowerCase()}`;
              });
            }
          }

          const { steps } = this.content;
          let formatedSteps = '';

          if (steps) {
            formatedSteps = steps.map((step, index) => ({
              '@type': types.howToStep,
              position: (index + 1).toString(10),
              text: stripHtml(step.step),
            }));
          }

          const parentPageUrl = getResolvedUrl('recettes-list');
          const recipeUrl = getResolvedUrl('recette', {
            slug: calcSlug(this.content.title),
            id: this.content.id,
          });

          const prepTime = Date.duration(this.content.preparationTime, 'minutes');
          const cookTime = Date.duration(this.content.cookTime, 'minutes');
          const totalTime = Date.duration(this.content.preparationTime + this.content.cookTime, 'minutes');

          const datePublished = Date.getDateFormatFromTimestamp(this.content.datePublished);
          const dateModified = Date.getDateFormatFromTimestamp(this.content.dateModified);

          const keywords = `recette, ${this.content.title}${productsIngredients}`;
          const video = this.content.youtube ? [{
            '@type': types.video,
            name: this.content.title,
            description: stripHtml(this.content.description),
            thumbnailUrl: this.getImgUrl(this.content.image),
            embedUrl: this.content.youtube,
            uploadDate: datePublished,
          }] : null;

          const recipeCategory = this.content.tag;

          return [
            {
              '@context': schema,
              '@type': types.recipe,
              name: this.content.title,
              author: name,
              prepTime,
              cookTime,
              totalTime,
              recipeCategory,
              keywords,
              recipeYield: pluralize(this.content.coverNumber, 'personne'),
              datePublished,
              dateModified,
              description: stripHtml(this.content.description),
              image: this.getImgUrl(this.content.image),
              url: recipeUrl,
              recipeIngredient: formatedIngredients,
              recipeInstructions: formatedSteps,
              video,
            },
            {
              '@context': schema,
              '@type': types.breadcrumbList,
              itemListElement: [
                breadcrumbStep1,
                {
                  '@type': types.listItem,
                  position: 2,
                  item: {
                    '@id': parentPageUrl,
                    url: parentPageUrl,
                    name: 'Recettes',
                  },
                },
              ],
            },
            {
              '@context': schema,
              '@type': types.webpage,
              keywords: `recette${productsIngredients}`,
              about: this.content.title,
              name: `Recette ${this.content.title}`,
              description: stripHtml(this.content.description),
              publisher: {
                '@type': types.website,
                name,
              },
            },
          ];
        }

        if (this.context === 'article') {
          const parentPageUrl = getResolvedUrl('reports-list');
          const articleUrl = getResolvedUrl('reports', {
            slug: calcSlug(this.content.title),
            id: this.content.id,
          });

          return [
            {
              '@context': schema,
              '@type': types.article,
              mainEntityOfPage: {
                '@type': types.webpage,
                '@id': articleUrl,
              },
              headline: this.content.title,
              image: {
                '@type': types.image,
                url: this.getImgUrl(this.content.image),
              },
              author,
              publisher: publisherWithLogo,
              datePublished: Date.getDateFormatFromTimestamp(this.content.datePublished),
              dateModified: Date.getDateFormatFromTimestamp(this.content.dateModified),
              description: this.content.description,
              articleBody: stripHtml(this.content.content),
            },
            {
              '@context': schema,
              '@type': types.webpage,
              name: this.content.title,
              description: this.content.description,
              publisher,
            },
            {
              '@context': schema,
              '@type': types.breadcrumbList,
              itemListElement: [
                breadcrumbStep1,
                {
                  '@type': types.listItem,
                  position: 2,
                  item: {
                    '@id': parentPageUrl,
                    url: parentPageUrl,
                    name: 'Articles',
                  },
                },
              ],
            },
          ];
        }

        if (this.context === 'oneshot') {
          const isRelatedTo = [];
          if (this.content.related && this.content.related.length) {
            this.content.related.forEach((related) => {
              const relatedUrl = getResolvedUrl('detail-product-item', {
                slug: calcSlug(related.name),
                id: related.id,
                idRegion: related.region.id,
              });
              isRelatedTo.push({
                '@type': types.product,
                url: relatedUrl,
              });
            });
          }
          const parentPageUrl = getResolvedUrl('dashboard');
          const oneShotUrl = getResolvedUrl('detail-product-item', {
            slug: calcSlug(this.content.name),
            id: this.content.id,
            idRegion: this.content.region ? this.content.region.id : null,
          });

          return [
            {
              '@context': schema,
              '@type': types.product,
              description: this.content.descriptionOfWeek,
              name: this.content.name,
              image: this.getImgUrl(this.content.image),
              brand: {
                '@type': types.brand,
                name,
              },
              offers: {
                '@type': types.offer,
                availability: this.content.isSoldout ? 'http://schema.org/OutOfStock' : 'http://schema.org/InStock',
                price: this.content.price.subscription,
                priceCurrency: 'EUR',
                priceValidUntil: Date.getMaximumValidityDate(this.content.deliveryDate),
              },
              isRelatedTo,
              url: oneShotUrl,
              potentialAction: 'http://schema.org/BuyAction',
            },
            {
              '@context': schema,
              '@type': types.webpage,
              keywords: 'produit, fruits, légumes',
              about: `Fiche produit : ${this.content.name}`,
              name: this.content.name,
              description: this.content.descriptionOfWeek,
              publisher,
            },
            {
              '@context': schema,
              '@type': types.breadcrumbList,
              itemListElement: [
                {
                  '@type': types.listItem,
                  position: 1,
                  item: {
                    '@id': parentPageUrl,
                    url: parentPageUrl,
                    name: 'Boutique',
                  },
                },
              ],
            },
          ];
        }

        if (this.context === 'customPages') {
          return [
            {
              '@context': schema,
              '@type': types.article,
              mainEntityOfPage: {
                '@type': types.webpage,
                '@id': pageUrl,
              },
              headline: this.content.title,
              author,
              publisher: publisherWithLogo,
              description: this.content.meta,
              articleBody: stripHtml(this.content.content),
            },
            {
              '@context': schema,
              '@type': types.webpage,
              name: this.content.title,
              description: this.content.meta,
              publisher: {
                '@type': types.website,
                name,
              },
            },
          ];
        }
      }

      return [];
    },
  },

  methods: {
    getImgUrl(imgObj) {
      return imgObj && imgObj.url ? imgObj.url : null;
    },
  },

};
</script>
