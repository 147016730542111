<template>
  <tunnel-body
    class="h-full border-t border-solid border-white-rock bp768:border-none bp768:mt-0"
    fullscreen
    size="wide">
    <div class="tunnel-dlp-map-layout__map h-full relative z-10 flex items-center justify-center">
      <dlp-map-private
        v-if="isPrivateDlp || isHomeDlp"
        :context="context" />

      <dlp-map-public
        v-else-if="isPublicDlp"
        :context="context"
        @onDlpSelect="onDlpSelect"
        @onSearch="onSearch" />

      <IconPtcyLoaderSpinner
        v-else
        class="text-4xl" />
    </div>

    <div
      :class="[
        'stack-panels h-full',
        'bp768:w-full bp768:absolute bp768:right-0 bp768:top-0 bp768:z-20 bp768:pointer-events-none',
      ]">
      <router-view
        :context="context"
        :has-header="isDetailsRoute && !$mq.bp768"
        :home-route="homeRoute"
        @onDlpSelect="onDlpSelect"
        @onGddSubmit="onGddSubmit"
        @onHomeDeliveryRedirect="onHomeDeliveryRedirect" />
    </div>
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { DELIVERY_PUBLIC_TYPE, DELIVERY_TYPE, DELIVERY_HOME_TYPE } from 'Classes/workflow/TunnelTypesConstants';
import { CONTEXT_SUBSCRIPTION_BASKET } from 'Classes/Constants';
import { stuckPage, unstuckPage } from 'Classes/Tools';

import DlpMapPublic from 'Components/deliveryPoint/map/DlpMapPublic';
import DlpMapPrivate from 'Components/deliveryPoint/map/DlpMapPrivate';
import TunnelBody from 'Components/tunnel/TunnelBody';

import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';

export default {

  components: {
    TunnelBody,
    DlpMapPrivate,
    DlpMapPublic,
    IconPtcyLoaderSpinner,
  },

  props: {
    context: {
      type: String,
      default: CONTEXT_SUBSCRIPTION_BASKET,
    },
  },

  computed: {
    ...mapGetters('map', [
      'getSelectedDeliveryPoint',
    ]),
    ...mapGetters('session', [
      'getDeliveryPoint',
    ]),
    ...mapGetters('tunnel', [
      'getTypeValue',
      'getStepRouteByName',
      'getNextStepRoute',
      'getPrevStepRoute',
    ]),
    isPrivateDlp() {
      return this.getSelectedDeliveryPoint?.private;
    },
    isHomeDlp() {
      return this.getTypeValue(DELIVERY_TYPE) === DELIVERY_HOME_TYPE;
    },
    isPublicDlp() {
      return this.getTypeValue(DELIVERY_TYPE) === DELIVERY_PUBLIC_TYPE;
    },
    isDetailsRoute() {
      return !!this.$route.params.deliveryPointId;
    },
    homeRoute() {
      return this.getStepRouteByName('deliveryPointHomeSearch');
    },
  },

  methods: {
    onSearch() {
      if (this.$route.params.deliveryPointId) {
        this.$router.push(this.getStepRouteByName('deliveryPointPublicList'));
      }
    },
    onDlpSelect(dlp) {
      if (dlp) {
        if (!this.$route.params.deliveryPointId) {
          this.$router.push(this.getNextStepRoute({
            params: {
              deliveryPointRegionId: dlp.regionId,
              deliveryPointId: dlp.id,
            },
          }));
        } else {
          this.$router.replace({
            ...this.$route,
            params: {
              ...this.$route.params,
              deliveryPointRegionId: dlp.regionId,
              deliveryPointId: dlp.id,
            },
          });
        }
      } else {
        this.onSearch();
      }
    },
    onGddSubmit() {
      this.$notify({
        type: 'success',
        title: 'Choix de livraison enregistrés',
        text: 'Votre choix de livraison a bien été enregistré.',
      });

      this.$router.push(this.getNextStepRoute());
    },
    onHomeDeliveryRedirect(regionId) {
      this.$router.push(this.getStepRouteByName('deliveryPointHomeSearch', {
        params: { regionId },
      }));
    },
  },

  mounted() {
    stuckPage();
  },

  beforeUnmount() {
    unstuckPage();
  },

  head: MetaInfosService.generate({
    title: 'Sélectionnez votre point de retrait',
  }),
};
</script>
