<template>
  <ul class="coupons-selector">
    <template v-for="(group, i) in getGroupedCoupons">
      <template v-if="group.coupons && group.coupons.length">
        <li :key="`title-${i}`">
          <p
            v-if="group.title"
            class="coupons-selector__title">
            {{ group.title }}
          </p>
          <p
            v-if="group.legend && group.coupons.length > 1"
            class="coupons-selector__legend">
            {{ group.legend }}
          </p>
        </li>
        <coupon-checkbox
          v-for="coupon in group.coupons"
          :key="`${i}-${coupon.id}-${resetKey}`"
          :coupon="coupon"
          :is-checkable="!isLoading"
          :is-checked="coupon.isChecked"
          :is-disabled="isCouponDisabled(coupon)"
          :is-loading="isCouponLoading(coupon)"
          @onChecked="onChecked" />
      </template>
    </template>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  LUNCHEON, getCouponsGroupedByType, getForbiddenTypes, PERCENT, PRODUCT, VALUE,
} from 'Classes/CouponsMapping';
import { CONTEXT_BASKET, CONTEXT_ORDER, CONTEXT_SUBSCRIPTION } from 'Classes/Constants';

import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';

import {
  ADD_COUPON_ON_BASKET_ACTION,
  ADD_COUPON_ON_ORDER_ACTION,
  ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION,
  REMOVE_COUPON_ON_BASKET_ACTION,
  REMOVE_COUPON_ON_ORDER_ACTION,
  REMOVE_COUPON_ON_SUBSCRIPTION_BASKET_ACTION,
} from 'Stores/types/userActionsTypes';

import CouponCheckbox from 'Components/coupons/CouponCheckbox';

export default {

  components: {
    CouponCheckbox,
  },

  props: {
    context: {
      type: String,
      required: true,
      default: CONTEXT_BASKET,
    },
    autoCheckNewCoupon: {
      type: Boolean,
      required: false,
      default: false,
    },
    order: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data: () => ({
    resetKey: 0,
  }),

  computed: {
    ...mapGetters('user', {
      userCoupons: 'getCoupons',
    }),
    getCoupons() {
      const coupons = uniqBy([
        ...this.userCoupons,
        ...this.getContextCoupons,
        ...(this.context === CONTEXT_BASKET ? this.$store.getters['basket/getRemovedCoupons'] : []),
      ], 'id');
      return coupons.map((coupon) => ({
        ...coupon,
        isChecked: this.isCouponChecked(coupon),
      }))
        .reverse();
    },
    getGroupedCoupons() {
      return getCouponsGroupedByType(this.getCoupons);
    },
    getContextCoupons() {
      switch (this.context) {
        case CONTEXT_SUBSCRIPTION:
          return this.$store.getters['subscriptionBasket/getCoupons'];
        case CONTEXT_ORDER:
          return this.order ? this.$store.getters['user/getOrderCoupons'](this.order.id) : [];
        default:
          return this.$store.getters['basket/getSimulatedOrderCoupons'];
      }
    },
    getAddAction() {
      switch (this.context) {
        case CONTEXT_SUBSCRIPTION:
          return ADD_COUPON_ON_SUBSCRIPTION_BASKET_ACTION;
        case CONTEXT_ORDER:
          return ADD_COUPON_ON_ORDER_ACTION;
        default:
          return ADD_COUPON_ON_BASKET_ACTION;
      }
    },
    getRemoveAction() {
      switch (this.context) {
        case CONTEXT_SUBSCRIPTION:
          return REMOVE_COUPON_ON_SUBSCRIPTION_BASKET_ACTION;
        case CONTEXT_ORDER:
          return REMOVE_COUPON_ON_ORDER_ACTION;
        default:
          return REMOVE_COUPON_ON_BASKET_ACTION;
      }
    },
    isLoading() {
      return this.$wait.is([
        this.getAddAction,
        this.getRemoveAction,
      ]);
    },
  },

  watch: {
    userCoupons(newVal, oldValue) {
      if (newVal !== oldValue && this.autoCheckNewCoupon) {
        const newCoupon = newVal.filter((obj) => !oldValue.some((obj2) => obj.id === obj2.id));
        if (newCoupon && newCoupon.length === 1 && !this.isCouponDisabled(newCoupon[0])) {
          this.onChecked({
            isChecked: true,
            coupon: newCoupon[0],
          });
        }
      }
    },
  },

  methods: {
    isCouponLoading(coupon) {
      return this.$wait.is([
        `${this.getAddAction}_${coupon.id}`,
        `${this.getAddAction}_${coupon.couponCode}`,
        `${this.getRemoveAction}_${coupon.id}`,
        `${this.getRemoveAction}_${coupon.couponCode}`,
      ]);
    },
    isCouponDisabled(coupon) {
      if (coupon.isChecked) return false;
      return this.isCouponDisabledByType(coupon)
          || this.isCouponDisabledByValueType(coupon)
          || this.isCouponDisabledByLimitLuncheonReached(coupon);
    },
    isCouponDisabledByType(coupon) {
      if (!this.getContextCoupons.length) return false;
      const forbiddenTypes = this.getContextCoupons.flatMap((c) => getForbiddenTypes(c.couponType));
      return forbiddenTypes.includes(coupon.couponType);
    },
    isCouponDisabledByValueType(coupon) {
      if (this.getContextCoupons.length) {
        const isForbidden = this.getContextCoupons.filter((e) => {
          // list of cases
          const isValueAndValue = (e.couponValueType === VALUE && coupon.couponValueType === VALUE);
          const isValueAndProduct = (e.couponValueType === VALUE && coupon.couponValueType === PRODUCT)
              || (e.couponValueType === PRODUCT && coupon.couponValueType === VALUE);
          const isDifferentTypes = e.couponType !== coupon.couponType;
          const isProductAndPercent = (e.couponValueType === PERCENT && coupon.couponValueType === PRODUCT)
              || (e.couponValueType === PRODUCT && coupon.couponValueType === PERCENT);

          // allowed cases
          const isCombinable = isValueAndValue || isValueAndProduct || (isDifferentTypes && isProductAndPercent);
          return !isCombinable;
        }).length;
        if (isForbidden) return true;
      }
      return false;
    },
    isCouponDisabledByLimitLuncheonReached(coupon) {
      if (this.getContextCoupons.length && coupon.couponType === LUNCHEON) {
        return filter(this.getContextCoupons, { couponType: LUNCHEON }).length > 1;
      }
      return false;
    },
    isCouponChecked(coupon) {
      return filter(this.getContextCoupons, ['id', coupon.id]).length > 0;
    },
    getAction(isChecked) {
      return isChecked ? this.getAddAction : this.getRemoveAction;
    },
    onChecked({
      isChecked,
      coupon,
    }) {
      const action = this.getAction(isChecked);

      const params = {
        couponId: coupon.id,
        couponCode: coupon.couponCode,
      };
      if (this.order) params.orderId = this.order.id;

      this.$store.dispatch(`user/${action}`, params)
        .catch((err) => {
          this.resetKey += 1;
          this.$notify({
            type: 'error',
            title: 'Une erreur est survenue',
            text: `<small>(${err?.globals && err.globals[0]?.message})</small>`,
          });
        });
    },
  },

};
</script>
