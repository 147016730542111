import ToolsMixin from 'Mixins/ToolsMixin';
import { getResolvedUrl, calcSlug } from 'Classes/Tools';

import Heading from 'Components/heading/Heading';
import Cms from 'Components/cms/Cms';
import BlogBody from 'Components/blog/BlogBody';
import BlogSection from 'Components/blog/BlogSection';
import BlogSocial from 'Components/blog/BlogSocial';
import BlogCrossContent from 'Components/blog/BlogCrossContent';
import MicroDatas from 'Components/microDatas/MicroDatas';
import NewsletterSubscriber from 'Components/newsletter/NewsletterSubscriber';
import PushSubscription from 'Components/pushs/PushSubscription';

import Filters from 'Filters';

import WordpressApiMixin, {
  GROWER_CONTENT_TYPE,
  PRODUCTS_CONTENT_TYPE,
  RECEIPTS_CONTENT_TYPE,
  REPORTS_CONTENT_TYPE,
} from 'Mixins/WordpressApiMixin';

export default {

  mixins: [
    ToolsMixin,
    WordpressApiMixin,
  ],

  components: {
    Heading,
    Cms,
    BlogBody,
    BlogSection,
    BlogSocial,
    BlogCrossContent,
    MicroDatas,
    NewsletterSubscriber,
    PushSubscription,
  },

  computed: {
    canonical() {
      if (this.$route.name && this.content.title) {
        return getResolvedUrl(this.$route.name, {
          slug: calcSlug(this.content.title),
          id: this.content.id,
        });
      }
      return document.location.href;
    },

    metaInfos() {
      if (!this.content || !this.content.id) return {};

      const {
        title,
        description,
        content,
        image
      } = this.content;
      const name = title ? title.replace('Tout savoir sur ', '') : '';
      const cleanDescription = description ? Filters.cleanWordpress(description) : '';

      const metaInfo = {
        title: '',
        description: '',
        image: image ? image.url : null,
        link: [{
          rel: 'canonical',
          href: this.canonical
        }]
      };

      switch (this.$route.name) {
        case RECEIPTS_CONTENT_TYPE:
          metaInfo.title = `Recette ${title}`;
          metaInfo.description = `Découvrez la recette ${title}. ${cleanDescription}`;
          break;
        case PRODUCTS_CONTENT_TYPE:
          metaInfo.title = title;
          metaInfo.description = `Comment conserver ${name}, préparation et association à d’autres ingrédients… Tous nos conseils pour manger ${name} et découvrir ses bienfaits.`;
          break;
        case GROWER_CONTENT_TYPE:
          metaInfo.title = title;
          metaInfo.description = content;
          break;
        case REPORTS_CONTENT_TYPE:
          metaInfo.title = title;
          metaInfo.description = description;
          break;
      }

      return this.generateMetaIfPanelClosed(metaInfo);
    }
  },
};
