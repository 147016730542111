import {
  CONTEXT_BASKET,
  CONTEXT_ORDER,
  CONTEXT_SUBSCRIPTION,
  DELIVERY_FUNCTIONAL_TYPE_PUBLIC_PATH,
  CONTEXT_USER_REGISTRATION,
  DELIVERY_FUNCTIONAL_TYPE_PRIVATE_PATH,
} from 'Classes/Constants';
import { DELIVERY_PRIVATE_TYPE } from 'Classes/workflow/TunnelTypesConstants';

export const getOrderId = (route) => {
  if (!route?.meta) return null;
  const { potagerRoute } = route.meta;
  return potagerRoute?.params?.orderId || route?.params?.orderId;
};

export const getOnlyGDD = (route) => {
  if (!route) return null;
  const { potagerRoute } = route.meta;
  const onlyGDDQuery = potagerRoute?.query?.onlyGDD || route?.query?.onlyGDD;
  return onlyGDDQuery === 'true';
};

export const getDlpSearchRoute = ({
  context = CONTEXT_BASKET,
  type,
  params,
  query,
  orderId,
} = {}, currentRoute) => {
  const oId = orderId || getOrderId(currentRoute);
  const ctx = context === CONTEXT_ORDER && !oId ? CONTEXT_BASKET : context;
  const typeParams = type === DELIVERY_PRIVATE_TYPE ? DELIVERY_FUNCTIONAL_TYPE_PRIVATE_PATH : DELIVERY_FUNCTIONAL_TYPE_PUBLIC_PATH;

  return {
    name: `account-dlp-search-step1-${ctx}${type ? `-${type}` : ''}`,
    params: {
      type: typeParams,
      ...oId ? { orderId: oId } : {},
      ...params,
    },
    query,
  };
};

export const getDlpListRoute = ({
  context = CONTEXT_BASKET,
  type,
  params = {},
  query,
  orderId,
} = {}, currentRoute) => {
  const oId = orderId || getOrderId(currentRoute);
  const ctx = context === CONTEXT_ORDER && !oId ? CONTEXT_BASKET : context;

  if (type === DELIVERY_PRIVATE_TYPE && !params.search) {
    return getDlpSearchRoute({
      context: ctx,
      params: {
        ...params,
        type: type === DELIVERY_PRIVATE_TYPE ? DELIVERY_FUNCTIONAL_TYPE_PRIVATE_PATH : DELIVERY_FUNCTIONAL_TYPE_PUBLIC_PATH,
      },
      query,
      orderId: oId,
    }, currentRoute);
  }

  return {
    name: `account-dlp-list-${type}-step3-${ctx || CONTEXT_BASKET}`,
    params: {
      ...oId ? { orderId: oId } : {},
      ...params,
    },
    query,
  };
};

export const getDlpDetailsRoute = ({
  context = CONTEXT_BASKET,
  type,
  params,
  query,
  deliveryPoint,
  orderId,
} = {}, currentRoute) => {
  const oId = orderId || getOrderId(currentRoute);
  // in this case public & private are same
  const dlpParams = deliveryPoint ? {
    deliveryPointRegionId: deliveryPoint.regionId,
    deliveryPointId: deliveryPoint.id,
  } : {};
  const ctx = context === CONTEXT_ORDER && !oId ? CONTEXT_BASKET : context;

  return {
    name: `account-dlp-details-${type}-step4-${ctx || CONTEXT_BASKET}`,
    params: {
      ...oId ? { orderId: oId } : {},
      ...params,
      ...dlpParams,
    },
    query,
  };
};

export const getDlpUpdateRoute = ({
  context = CONTEXT_BASKET,
  params,
  query,
  dlpId,
  gddId,
  orderId,
} = {}, currentRoute) => {
  const oId = orderId || getOrderId(currentRoute);
  const ctx = context === CONTEXT_ORDER && !oId ? CONTEXT_BASKET : context;

  return {
    name: `account-dlp-update-step5-${ctx}`,
    params: {
      ...oId ? { orderId: oId } : {},
      ...{
        dlpId,
        gddId
      },
      ...params,
    },
    query,
  };
};

export const getDlpBackRoute = ({
  context,
  params,
  query,
  orderId,
} = {}, currentRoute) => {
  const oId = orderId || getOrderId(currentRoute);
  const ctx = context === CONTEXT_ORDER && !oId ? CONTEXT_BASKET : context;

  const route = {
    params: {
      ...oId ? { orderId: oId } : {},
      ...params,
    },
    query,
  };

  switch (ctx) {
    case CONTEXT_BASKET:
      route.name = 'basket';
      break;
    case CONTEXT_ORDER:
      route.name = 'mon-compte_orders_detail';
      break;
    case CONTEXT_SUBSCRIPTION:
      route.name = 'mon-compte_subscription';
      break;
    case CONTEXT_USER_REGISTRATION:
      route.name = 'register';
      break;
    default:
      route.name = 'dashboard';
  }

  return route;
};
