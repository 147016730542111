import Store from 'Stores';
import { CONTEXT_BASKET, CONTEXT_ORDER, CONTEXT_SUBSCRIPTION, CONTEXT_SUBSCRIPTION_BASKET } from 'Classes/Constants';
import Dates from 'Classes/Dates';
import { ucfirst } from 'Filters';

// Methode qui permet de dire si on est dans un contexte d'abonnement
export const isSubscription = (context) => [CONTEXT_SUBSCRIPTION_BASKET, CONTEXT_SUBSCRIPTION].includes(context);

export const isInAnotherRegion = ({ item }) => Store.getters['session/isLoggedIn'] && item.regionId !== Store.getters['session/getRegionId'];

export const getSubscriptionTimeSlot = (gdd) => {
  // Si la date de début de la réservation est pleine (isFull),
  // mais que la réservation n'est pas autorisée sur toute la durée (isFullForSubscription)
  // et qu'un autre créneau horaire (notFullTimeslot) est disponible,
  // renvoyer le créneau horaire alternatif (notFullTimeslot).
  if (gdd.isFull && !gdd.isFullForSubscription && gdd.notFullTimeslot) {
    return gdd.notFullTimeslot;
  }

  // Sinon, renvoyer le créneau horaire initial (timeSlot).
  return gdd.timeSlot;
};

export const getNotSubscriptionTimeSlot = (gdd) => {
  const {
    timeSlot,
    currentWeekTheoricalTimeSlot,
    currentWeekTimeSlot
  } = gdd;

  // Si le créneau horaire théorique de la semaine en cours (`currentWeekTheoricalTimeSlot`) est défini
  // et qu'il correspond à la date du créneau horaire de la semaine en cours (`currentWeekTimeSlot`),
  // renvoyer le créneau horaire de la semaine en cours (`currentWeekTimeSlot`).
  if (currentWeekTimeSlot && currentWeekTheoricalTimeSlot && currentWeekTheoricalTimeSlot.date === currentWeekTimeSlot.date) {
    return currentWeekTimeSlot;
  }

  // Sinon,
  // si le créneau horaire initial (`timeSlot`) est défini
  // et correspond à la date du créneau horaire théorique de la semaine en cours (`currentWeekTheoricalTimeSlot`)
  // ou du créneau horaire de la semaine en cours (`currentWeekTimeSlot`),
  // renvoyer le créneau horaire initial (`timeSlot`).
  if (timeSlot && timeSlot.date === (currentWeekTheoricalTimeSlot || currentWeekTimeSlot).date) {
    return timeSlot;
  }

  // Sinon,
  // renvoyer le créneau horaire théorique de la semaine en cours (`currentWeekTheoricalTimeSlot`) s'il est défini,
  // sinon le créneau horaire de la semaine en cours (`currentWeekTimeSlot`) s'il est défini,
  // sinon le créneau horaire initial (`timeSlot`) s'il est défini.
  return currentWeekTheoricalTimeSlot || currentWeekTimeSlot || timeSlot;
};

export const isGDDDisabledNotForSubscription = ({ gdd }) => gdd.isGddDisabled
  || (gdd.isTooLate && !gdd.isNextDeliveryAvailable)
  || isInAnotherRegion({ item: gdd });

export const isGddDisabledForSubscription = ({ gdd }) => gdd.isGddDisabledForSubscription || isInAnotherRegion({ item: gdd });

export const isGDDDisabled = ({
  gdd,
  context
}) => context === CONTEXT_ORDER && (isSubscription(context) ? isGddDisabledForSubscription({ gdd }) : isGDDDisabledNotForSubscription({ gdd }));

// On ne souhaite plus empêcher un utilisateur de sélectionner un PR
// même s'il n'est pas de la semaine en cours
// Dans ce cas, il aura simplement un message d'erreur
// à l'ajout d'un produit à son panier
// On utilise donc uniquement timeSlot
// export const getTimeSlot = (gdd, context) => (isSubscription(context) ? getSubscriptionTimeSlot(gdd) : getNotSubscriptionTimeSlot(gdd));
export const getTimeSlot = (gdd, context) => isSubscription(context) ? gdd.currentWeekTheoricalTimeSlot : gdd.timeSlot;

export const isGDDFull = ({
  gdd,
  context
}) => context === CONTEXT_ORDER && (isSubscription(context) ? gdd.isFullForSubscription : gdd.isFull);

export const isGDDClosed = ({
  gdd,
  context,
}) => (isSubscription(context) ? getSubscriptionTimeSlot(gdd) === null : getNotSubscriptionTimeSlot(gdd) === null);

export const getGddLabel = ({
  gdd,
  context
}) => {
  if (isGDDFull({
    gdd,
    context
  })) {
    return 'Complet';
  }

  if (isInAnotherRegion({ item: gdd })) {
    return 'Hors région';
  }

  if (isGDDClosed({
    gdd,
    context
  }) || isGDDDisabled({
    gdd,
    context
  })) {
    return 'Indisponible';
  }

  return 'Choisir';
};

export const getFormattedDay = (gdd, context, condensed = false, showHours = false) => {
  const timeslot = getTimeSlot(gdd, context);

  // Gère le remplacement des créneaux de livraison lors des jours fériés.
  const replacement = gdd?.currentWeekTheoricalTimeSlot?.date !== gdd.timeSlot?.date && !condensed && gdd.isNextDeliveryAvailable
    ? `<br>(en remplacement du ${Dates.formattedTimeSlot(gdd.currentWeekTheoricalTimeSlot, true, false)})`
    : '';

  return isSubscription(context)
    ? Dates.allDayWithSlot(gdd, condensed || !showHours)
    : Dates.formattedTimeSlot(timeslot, condensed, showHours) + replacement;
};

export const getGddErrorMessage = ({
  gdd,
  context
}) => {
  if (gdd.isTooLate) {
    return `Trop tard pour choisir ce jour 😕
          Revenez à partir de jeudi.`;
  }

  if (isGDDFull({
    gdd,
    context
  })) {
    return 'Ce point de retrait est complet 😕';
  }

  if (isInAnotherRegion({ item: gdd })) {
    return 'Ce point de retrait n\'est pas dans votre région 😕';
  }

  if (!gdd.isNextDeliveryAvailable) {
    return 'Le prochain jour de retrait n\'est pas disponible 😕';
  }

  if (isGDDClosed({
    gdd,
    context
  })) {
    return 'Ce point de retrait est actuellement fermé 😕';
  }

  return 'message';
};

export const isDlpDisabled = (dlp, context) => {
  const isAnotherRegion = isInAnotherRegion({ item: dlp });
  const hasGddAvailable = dlp?.groupDeliveryDay
    .filter((gdd) => !isGDDDisabled({
      gdd,
      context
    })).length;
  const isDisabled = isSubscription(context) ? dlp.isDisabledForSubscription : dlp.isDisabled;

  return isAnotherRegion || !hasGddAvailable || isDisabled;
};

export const isDlpFull = (dlp, context) => (dlp.groupDeliveryDay ? !dlp.groupDeliveryDay
  .filter((gdd) => !isGDDFull({
    gdd,
    context
  })).length : false);

export const isDlpSelected = (dlp) => Store.getters['map/getSelectedDeliveryPoint']?.id === dlp.id;

export const isLocation = (item) => item.type === 'gmap';

export const getNote = (deliveryPoint, groupDeliveryDay, context = CONTEXT_BASKET) => {
  const dlpNote = deliveryPoint?.note;
  const gddNote = getTimeSlot(groupDeliveryDay, context)?.note;
  return dlpNote || gddNote;
};

export const getNoteTitle = (deliveryPoint, groupDeliveryDay, context = CONTEXT_BASKET) => {
  const prefix = deliveryPoint?.isHomeDelivery ? 'Message de notre partenaire' : 'Message du point relais';
  const date = getFormattedDay(groupDeliveryDay, context) ? `pour le</span> ${ucfirst(getFormattedDay(groupDeliveryDay, context))}` : '';
  return `<span class="font-normal">${prefix} ${date}`;
};
