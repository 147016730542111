<template>
  <panel
    :back-route="{ name: 'mon-compte_payment-methods' }"
    :is-loading="!isUserLoaded"
    :title="paymentMethod.title">
    <div
      class="panel-section">
      <template v-if="getSortedPaygreenCards?.length > 0">
        <transition-group
          class="panel-section__line"
          name="bluecard--transition"
          tag="div">
          <payment-card
            v-for="card in getSortedPaygreenCards"
            :key="card.id"
            :ref="`card_${card.id}`"
            :card="card"
            actions />
        </transition-group>
      </template>

      <template v-else>
        <p class="panel-section__subtitle">
          Vous n'avez pas encore ajouté de {{ paymentMethod?.label }}.
        </p>
      </template>

      <potager-navigator @click="addCard">
        Ajouter une {{ paymentMethod?.label }}
      </potager-navigator>
    </div>
  </panel>
</template>

<script>

import Panel from 'Components/panel/Panel';
import PaymentCard from 'Components/paymentCard/PaymentCard';
import PotagerNavigator from 'UI/PotagerNavigator';

import { mapGetters } from 'vuex';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { getPMByName, getPaymentMethod } from 'Classes/paymentMethods';
import { PAYMENT_METHODS_TYPES, PAYMENT_METHODS_INTEGRATIONS } from 'Classes/paymentMethods/Constants';

export default {

  components: {
    Panel,
    PaymentCard,
    PotagerNavigator,
  },

  computed: {
    ...mapGetters('user', [
      'isUserLoaded',
      'getBankCards',
      'getPaygreenCards',
      'getSubscription',
      'getPaymentGateway',
    ]),
    name() {
      return this.$potagerRoute.params.name;
    },
    paymentMethod() {
      return getPMByName(this.name);
    },
    getSortedPaygreenCards() {
      return this.paymentMethod.type === PAYMENT_METHODS_TYPES.RESTAURANT_CARD
        ? this.getPaygreenCards
          .filter((card) => getPaymentMethod(card)?.name === this.name)
        : this.getBankCards;
    },
  },

  methods: {
    addCard() {
      if (this.paymentMethod.type === PAYMENT_METHODS_TYPES.RESTAURANT_CARD) {
        this.paymentMethod.actions.openAddModal(this);
      } else {
        const route = this.getPaymentGateway === PAYMENT_METHODS_INTEGRATIONS.PAYGREEN
          ? 'mon-compte_payment-methods_paygreen_add'
          : 'mon-compte_payment-methods_credit-card_add';
        this.$router.push({
          name: route,
          params: { name: this.name }
        });
      }
    },
  },

  mounted() {
    if (this.getSortedPaygreenCards?.length === 0 && this.paymentMethod.type === PAYMENT_METHODS_TYPES.RESTAURANT_CARD) {
      this.paymentMethod.actions.openAddModal(this);
    }
  },

  head() {
    return MetaInfosService.generate({
      title: `Mes ${this.paymentMethod?.label}`,
    });
  },
};
</script>
