import { POPIN_ERROR_EVENT } from 'Classes/Constants';
import { UPDATE_BASKET_ACTION } from 'Stores/types/basketActionsTypes';
import { UPDATE_USER } from 'Stores/types/userMutationsTypes';
import store from 'Stores';

export const BT015_PAY_BASKET_ERROR = {
  eventType: POPIN_ERROR_EVENT,
  text: 'Le temps que vous validiez votre panier, la vente d\'un élément s’est totalement écoulée. Il est indisponible et a donc été supprimé.',
  handle: (app, response) => {
    let { data: { customer } } = response;

    store.dispatch('basket/' + UPDATE_BASKET_ACTION, customer.basket);
    store.commit('user/' + UPDATE_USER, customer);
  }
};

export default {
  default: {
    text: 'Le temps que vous validiez votre panier, la vente d\'un élément s’est totalement écoulée. Il est indisponible et a donc été supprimé.',
  },
  'PaymentApi.payBasketByPaiementMethods': BT015_PAY_BASKET_ERROR,
}
