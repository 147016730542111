export default {
  bp375: 375, // iPhone 12 mini
  bp425: 425, // wider iPhone portrait
  bp480: 480, // nouveau breakpoint
  bp640: 640, // Mobiles
  bp768: 768, // iPad vertical
  bp840: 840, // iPad vertical
  bp1024: 1024, // iPad horizontal
  bp1168: 1168, // iPad horizontal
  bp1280: 1280, // Petit écran desktop
  bp1920: 1920, // grand écran desktop
};
