import IconPluxee from 'Icons/bold/IconPluxee';
import IconPluxeeFull from 'Icons/bold/IconPluxeeFull';

import ModalPedagogyTicketResto from 'Components/modals/ModalPedagogyTicketResto';

import { SET_DEFAULT_CARD_ACTION } from 'Stores/types/userActionsTypes';

import PAYMENT_METHODS_CONSTANTS from 'Classes/paymentMethods/Constants';

import { openModal } from 'Plugins/potagerModals';

import store from 'Stores';
import router from 'Router';

export const {
  type,
  integration,
  issuer,
  colors,
} = PAYMENT_METHODS_CONSTANTS.PLUXEE;

export default {
  name: PAYMENT_METHODS_CONSTANTS.PLUXEE.name,
  alias: PAYMENT_METHODS_CONSTANTS.PLUXEE.alias,
  type,
  integration,
  issuer,
  label: 'carte Pluxee (ex Sodexo)',
  title: 'Carte Pluxee (ex Sodexo)',
  readmoreLink: undefined,
  route: {
    name: 'mon-compte_payment-methods_cards',
    params: { name: PAYMENT_METHODS_CONSTANTS.PLUXEE.name },
  },

  defaultIcon: IconPluxee,
  icons: [IconPluxee],
  alternativeIcons: [IconPluxeeFull],
  colors,

  loaders: {
    editSettings: SET_DEFAULT_CARD_ACTION,
    editSettingsByCardId: (cardId) => `${SET_DEFAULT_CARD_ACTION}_${cardId}`,
  },

  actions: {
    openAddModal(app, onSuccess, onClose) {
      openModal(ModalPedagogyTicketResto,
        {
          name: PAYMENT_METHODS_CONSTANTS.PLUXEE.name,
          cardIconComponent: IconPluxee,
          cardIconFullComponent: IconPluxeeFull,
          cardIconColor: colors.primary,
        },
        undefined,
        (e) => {
          if (typeof onClose === 'function') onClose();
          if (e.params?.type === 'success' && typeof onSuccess === 'function') onSuccess();
        });
    },

    redirect() {
      router.push({
        name: 'mon-compte_payment-methods_paygreen_add',
        params: { name: PAYMENT_METHODS_CONSTANTS.PLUXEE.name },
      });
    },

    setDefault(cardId, isDefault) {
      store.dispatch(`user/${SET_DEFAULT_CARD_ACTION}`, {
        cardId,
        isDefault
      });
    },
  },
};
