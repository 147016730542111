<template>
  <div
    class="dashboard__sidebar overflow-auto flex flex-col">
    <template
      v-for="(section, index) in getDashboardSections">
      <template
        v-if="section.loading">
        <div
          v-for="i in 3"
          :key="`${section.type}-${index}-${i}`"
          class="dashboard__section__title w-full h-10 skeleton mb-2" />
      </template>

      <potager-button
        v-else
        :key="`${section.type}-${index}`"
        :ref="`sidebar-item-${index}`"
        :label="section.categoryTitle || section.title"
        :theme="intersectingSection === index ? 'beryl-green' : 'transparent'"
        :to="`#${calcSlug(section.title)}`"
        class="text-left justify-start hover:bg-white-rock focus:bg-white-rock"
        @click="onclick(section, index)">
        <template
          #icon>
          <potager-picture
            :src="section.image"
            :title="section.categoryTitle || section.title"
            background
            class="w-5 h-5" />
        </template>

        {{ section.categoryTitle || section.title }}
      </potager-button>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerButton from 'UI/PotagerButton';
import PotagerPicture from 'UI/PotagerPicture';

import { calcSlug } from 'Classes/Tools';

export default {
  name: 'DashboardSideBar',
  components: {
    PotagerPicture,
    PotagerButton
  },

  props: {
    intersectingSection: {
      type: Number,
      default: 0
    },
  },

  computed: {
    ...mapGetters('dashboard', [
      'getDashboardSections',
    ]),
  },

  methods: {
    calcSlug,
    onclick(section, index) {
      // useful to scroll to an already intersecting section
      if (this.intersectingSection === index) {
        const sectionHash = calcSlug(section.title);
        if (this.$route.hash === `#${sectionHash}`) {
          this.$scrollTo(`#${sectionHash}`, 500, { offset: -100 });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  &__sidebar {
    max-height: calc(100vh - #{$height_header} - (40px * 2));

    @include bp640() {
      max-height: calc(100vh - #{$height_header--mobile} - (40px * 2));
    }
  }
}
</style>
