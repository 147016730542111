<template>
  <tunnel-body class="text-center">
    <h1 class="text-xl font-extrabold mb-3 mx-10">
      Choisissez un mode de livraison
    </h1>

    <p class="mb-10">
      Vous avez déjà enregistré votre mode de livraison.<br>
      Vous pouvez le modifier ou passer directement à l’étape suivante.
    </p>

    <potager-row centered>
      <potager-col
        bp640="12"
        col="6">
        <tunnel-choice-block>
          <template #icon>
            <icon-home v-if="getDeliveryPoint.isHomeDelivery" />
            <icon-shop v-else />
          </template>

          <template #title>
            {{ isHomeDelivery ? 'Mon adresse de livraison' : 'Ma livraison' }}
          </template>

          {{ deliveryDisplayedName }}<br>
          {{ deliveryDisplayedAddress }}

          <potager-info
            v-if="getWarningDlpMessage"
            class="mt-4"
            status="warning">
            {{ getWarningDlpMessage }}
          </potager-info>

          <template #action>
            <potager-button
              :to="dlpHubRoute"
              full-width
              theme="stroke">
              Changer
            </potager-button>
          </template>
        </tunnel-choice-block>
      </potager-col>

      <potager-col
        bp640="12"
        col="6">
        <tunnel-choice-block>
          <template #icon>
            <icon-calendar-check />
          </template>

          <template #title>
            Livraison de ma prochaine commande
          </template>

          {{ ucfirst(getNextDeliveryAvailable) }}

          <span class="text-xs.5 leading-tight text-warm-grey block mt-2">
            {{ getNextDeliveryLegend }}
          </span>

          <template #action>
            <potager-button
              :is-disabled="isDlpFull(getDeliveryPoint, context)"
              :to="dlpGddRoute"
              full-width
              theme="stroke">
              Changer
            </potager-button>
          </template>
        </tunnel-choice-block>
      </potager-col>
    </potager-row>

    <potager-row
      centered
      class="mt-4 mb-8 w-100%">
      <potager-col class="flex">
        <potager-button
          :is-disabled="isGDDFull({gdd: getGroupDeliveryDay, context})"
          :is-loading="isLoading"
          :to="nextStepRoute"
          class="potager-button--tunnel block mx-auto"
          theme="go-green">
          Je continue
        </potager-button>
      </potager-col>
    </potager-row>
  </tunnel-body>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import IconShop from 'Icons/regular/IconShop';
import IconHome from 'Icons/regular/IconHome';
import IconCalendarCheck from 'Icons/regular/IconCalendarCheck';

import PotagerRow from 'UI/PotagerRow';
import PotagerCol from 'UI/PotagerCol';
import PotagerButton from 'UI/PotagerButton';
import PotagerInfo from 'UI/PotagerInfo';

import TunnelBody from 'Components/tunnel/TunnelBody';
import TunnelChoiceBlock from 'Components/tunnel/TunnelChoiceBlock';

import MetaInfosService from 'Classes/services/MetaInfoService';
import WordingTools from 'Classes/WordingTools';
import Dates from 'Classes/Dates';

import Filters from 'Filters';
import { isGDDFull, getTimeSlot, isDlpFull, getFormattedDay } from 'Classes/DlpTools';
import { CONTEXT_SUBSCRIPTION_BASKET } from 'Classes/Constants';

import GtmMixin from 'Mixins/GtmMixin';

import { UPDATE_DELIVERY_POINT, UPDATE_GROUP_DELIVERY_DAY } from 'Stores/types/homeDeliveryMutationsTypes';
import { CONFIRM_SUBSCRIPTION_BASKET_ACTION } from 'Stores/types/subscriptionBasketActionsTypes';
import {
  UPDATE_SUBSCRIPTION_BASKET_TEMP_DELIVERY_POINT,
  UPDATE_SUBSCRIPTION_BASKET_TEMP_GROUP_DELIVERY_DAY,
} from 'Stores/types/subscriptionBasketMutationsTypes';
import { SET_TYPE } from 'Stores/types/tunnelMutationsTypes.js';

import {
  DELIVERY_HOME_TYPE,
  DELIVERY_PRIVATE_TYPE,
  DELIVERY_PUBLIC_TYPE,
  DELIVERY_TYPE,
  DELIVERY_SKIP_TYPE,
} from 'Classes/workflow/TunnelTypesConstants';

export default {
  mixins: [
    GtmMixin,
  ],

  components: {
    IconHome,
    IconShop,
    IconCalendarCheck,
    PotagerRow,
    PotagerCol,
    PotagerButton,
    PotagerInfo,
    TunnelBody,
    TunnelChoiceBlock,
  },

  props: {
    context: {
      type: String,
      default: CONTEXT_SUBSCRIPTION_BASKET,
    },
  },

  computed: {
    ...mapGetters('subscriptionBasket', [
      'getDeliveryPoint',
      'getGroupDeliveryDay',
    ]),
    ...mapGetters('session', [
      'getAddress',
    ]),
    ...mapGetters('tunnel', [
      'getStepRouteByName',
      'getNextStepRoute',
    ]),
    isLoading() {
      return this.$wait.is(CONFIRM_SUBSCRIPTION_BASKET_ACTION);
    },
    getWarningDlpMessage() {
      return isGDDFull({
        gdd: this.getGroupDeliveryDay,
        context: this.context
      }) ? WordingTools.dlpFull() : null;
    },
    getNextDeliveryAvailable() {
      return getFormattedDay(this.getGroupDeliveryDay);
    },
    getNextDeliveryLegend() {
      const gdd = this.getGroupDeliveryDay;
      const timeSlot = getTimeSlot(gdd, this.context);
      if (gdd.startHour === timeSlot.startHour && gdd.endHour === timeSlot.endHour) {
        return `Puis tous les ${gdd.dayLabel}s aux mêmes horaires`;
      }
      return `Puis ${Dates.allDayWithSlot(gdd)}`;
    },
    isHomeDelivery() {
      return this.getDeliveryPoint.isHomeDelivery;
    },
    homeDeliveryAddress() {
      return this.getAddress(this.context);
    },
    deliveryDisplayedName() {
      const {
        isHomeDelivery,
        groupName
      } = this.getDeliveryPoint;
      return isHomeDelivery ? `${this.homeDeliveryAddress.firstname} ${this.homeDeliveryAddress.lastname}` : groupName;
    },
    deliveryDisplayedAddress() {
      const {
        isHomeDelivery,
        groupDeliveryAddress
      } = this.getDeliveryPoint;

      if (isHomeDelivery) {
        if (this.homeDeliveryAddress) {
          return `${this.homeDeliveryAddress.streetNumber || ''} ${this.homeDeliveryAddress.streetName} - ${this.homeDeliveryAddress.postalCode} ${this.homeDeliveryAddress.city}`;
        }
        return 'Veuillez compléter votre adresse';
      }

      return groupDeliveryAddress;
    },
    dlpHubRoute() {
      return this.getNextStepRoute({ newTypes: [{ [DELIVERY_TYPE]: undefined }] });
    },
    dlpGddRoute() {
      let type,
        stepName;
      if (this.getDeliveryPoint.isHomeDelivery) {
        type = DELIVERY_HOME_TYPE;
        stepName = 'deliveryPointHomeGdd';
      } else if (this.getDeliveryPoint.private) {
        type = DELIVERY_PRIVATE_TYPE;
        stepName = 'deliveryPointPrivateGdd';
      } else {
        type = DELIVERY_PUBLIC_TYPE;
        stepName = 'deliveryPointPublicGdd';
      }

      this.$store.commit(`tunnel/${SET_TYPE}`, {
        name: DELIVERY_TYPE,
        value: type
      });

      return this.getStepRouteByName(stepName, {
        params: {
          deliveryPointRegionId: this.getDeliveryPoint.regionId,
          deliveryPointId: this.getDeliveryPoint.id,
        },
        query: {
          onlyGDD: true,
        },
      });
    },
    nextStepRoute() {
      return this.getNextStepRoute({ newTypes: [{ [DELIVERY_TYPE]: DELIVERY_SKIP_TYPE }] });
    },
  },

  methods: {
    ucfirst: Filters.ucfirst,
    ...mapMutations('homeDelivery', {
      setHomeDeliveryPoint: UPDATE_DELIVERY_POINT,
      setHomeDeliveryGroupDeliveryDay: UPDATE_GROUP_DELIVERY_DAY,
    }),
    ...mapMutations('subscriptionBasket', {
      setSubscriptionBasketDeliveryPoint: UPDATE_SUBSCRIPTION_BASKET_TEMP_DELIVERY_POINT,
      setSubscriptionBasketGroupDeliveryDay: UPDATE_SUBSCRIPTION_BASKET_TEMP_GROUP_DELIVERY_DAY,
    }),
    isGDDFull,
    isDlpFull,
  },

  head() {
    return MetaInfosService.generate({
      title: 'Validez votre point et jour de retrait',
    });
  },

  created() {
    if (this.getDeliveryPoint.isHomeDelivery) {
      this.setHomeDeliveryPoint(this.getDeliveryPoint);
      this.setHomeDeliveryGroupDeliveryDay(this.getGroupDeliveryDay);
    }
  },
};
</script>
