export const LUNCHEON = 'LUNCHEON';
export const FIDELITY = 'FIDELITY';
export const VOUCHER = 'VOUCHER';
export const SPONSORSHIP = 'SPONSORSHIP';
export const REFUND = 'REFUND';
export const PARTICIPATION = 'PARTICIPATION';
export const PERCENT = 'PERCENT';
export const VALUE = 'VALUE';
export const PRODUCT = 'PRODUCT';

export function getCouponTypeMsg(coupon) {
  const mapping = {
    [SPONSORSHIP]: 'parrainage',
    [LUNCHEON]: 'titre restaurant',
    [PARTICIPATION]: 'carte de participation',
    [REFUND]: 'avoir',
    [FIDELITY]: 'fidélité',
    [VOUCHER]: coupon.couponValueType === PRODUCT ? 'ajout produit' : 'bon de réduction',
  };
  return coupon.couponType ? mapping[coupon.couponType] : null;
}

export function getForbiddenTypes(type) {
  const mapping = {
    [SPONSORSHIP]: [VOUCHER, SPONSORSHIP],
    [VOUCHER]: [SPONSORSHIP],
  };
  return mapping[type] || [];
}

export function getCouponsGroupedByType(coupons) {
  const sortedCoupons = coupons.sort((a, b) => a.id - b.id);
  return [
    {
      title: 'Titres restaurant',
      legend: 'Vous pouvez utiliser jusqu\'à 2 titres restaurant par commande.',
      coupons: sortedCoupons.filter((c) => c.couponType === LUNCHEON),
    },
    {
      title: 'Cartes de participation',
      coupons: sortedCoupons.filter((c) => c.couponType === PARTICIPATION),
    },
    {
      title: 'Avoirs',
      coupons: sortedCoupons.filter((c) => c.couponType === REFUND),
    },
    {
      title: 'Bons de réduction et parrainage',
      legend: 'Ces coupons ne sont pas cumulables à l\'exception des bons d\'achat Potager City. Faites votre choix parmi vos coupons disponibles :',
      coupons: [
        ...sortedCoupons.filter((c) => c.couponType === SPONSORSHIP),
        ...sortedCoupons.filter((c) => c.couponType === VOUCHER),
      ],
    },
    {
      title: 'Fidélité',
      coupons: sortedCoupons.filter((c) => c.couponType === FIDELITY),
    },
  ];
}

export const couponUnit = (coupon) => {
  switch (coupon.couponValueType) {
    case PERCENT:
      return `-${coupon.couponValue}%`;
    case PRODUCT:
      return `x${coupon.couponValue} ${coupon.giftedProductName}`;
    default:
      return `-${coupon.couponValue}€`;
  }
};
