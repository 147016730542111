<template>
  <div
    :class="[
      'card-v2__tools z-20 text-sm font-bold',
      'flex rounded-full',
      disabled && 'cursor-not-allowed',
      skeleton || !canEditQuantity ? 'bg-white-rock' : quantity > 0 ? 'bg-go-green' : 'bg-beryl-green',
      { 'animate-pulse': skeleton },
    ]"
    @click.stop.prevent>
    <potager-button
      v-if="!hideMinus"
      key="minus"
      :size="$mq.bp375 ? 'small' : undefined"
      :theme="buttonTheme"
      class="card-v2__tools__btn"
      is-circle
      prevent
      @onClick="$emit('decrement')">
      <template #icon>
        <icon-subtract class="text-sm" />
      </template>
    </potager-button>

    <component
      :is="(quantity === 0 || isLock) ? 'potager-button' : 'span'"
      v-if="isLoading || skeleton"
      key="loader"
      :class="[
        'card-v2__tools__loader card-v2__tools__btn',
        { 'text-sm card-v2__tools__quantity': !(quantity === 0 || isLock) }
      ]"
      :size="$mq.bp375 ? 'small' : undefined"
      :theme="buttonTheme"
      is-circle
      prevent>
      <icon-ptcy-loader-spinner
        v-if="!skeleton"
        :class="[
          'leading-0',
          !(quantity === 0 || isLock) ? 'text-sm' : 'text-lg.5',
        ]" />
    </component>

    <div
      v-else-if="!canEditQuantity && quantity"
      class="px-4 py-2 font-bold text-sm text-charcoal leading-none">
      x{{ quantity }}
    </div>

    <potager-button
      v-else-if="isLock"
      :class="[
        'card-v2__tools__btn card-v2__tools__btn--lock',
        {'cursor-not-allowed': quantity === 0},
      ]"
      :size="$mq.bp375 ? 'small' : undefined"
      :tooltip="getLockMessage(box)"
      is-circle
      @onClick="onLockClick">
      <template #icon>
        <icon-bin1 v-if="quantity && quantity > 0" />
        <icon-lock6 v-else />
      </template>
    </potager-button>

    <component
      :is="editable ? 'button' : 'span'"
      v-else-if="!hideMinus"
      key="quantity"
      :class="{
        'card-v2__tools__quantity': true,
        'card-v2__tools__quantity--edit': editable,
      }"
      @click="editQuantity">
      {{ quantity }}
    </component>

    <potager-button
      v-if="!hidePlus"
      key="plus"
      :is-disabled="disabled || isLoading"
      :size="$mq.bp375 ? 'small' : undefined"
      :theme="buttonTheme"
      class="card-v2__tools__btn"
      is-circle
      prevent
      @onClick="$emit('increment')">
      <template #icon>
        <icon-add class="text-sm" />
      </template>
    </potager-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PotagerButton from 'UI/PotagerButton';

import IconPtcyLoaderSpinner from 'Icons/regular/IconPtcyLoaderSpinner';
import IconLock6 from 'Icons/bold/IconLock6';
import IconAdd from 'Icons/bold/IconAdd';
import IconSubtract from 'Icons/bold/IconSubtract';
import IconBin1 from 'Icons/bold/IconBin1';

import { getLockMessage } from 'Classes/BoxesTools';

import ModalEditProductQuantity from 'Modals/ModalEditProductQuantity';
import ModalInfo from 'Modals/ModalInfo';

export default {

  components: {
    PotagerButton,
    IconBin1,
    IconLock6,
    IconAdd,
    IconSubtract,
    IconPtcyLoaderSpinner,
  },

  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    quantity: {
      type: Number,
      required: false,
      default: 0,
    },
    canEditQuantity: {
      type: Boolean,
      required: false,
      default: false,
    },
    box: {
      type: Object,
      required: false,
      default: null,
    },
    skeleton: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters('basket', [
      'canContainMiniBox',
    ]),
    isNotAvailable() {
      return !this.box?.deliveryDate?.isCurrentWeek;
    },
    isLock() {
      return !this.canContainMiniBox || this.box?.isSoldOut || this.isNotAvailable;
    },
    editable() {
      return this.canEditQuantity && !this.disabled;
    },
    hideMinus() {
      return !this.editable || this.quantity <= 0 || this.disabled || (this.isLoading && this.quantity <= 0) || this.isLock || this.skeleton;
    },
    hidePlus() {
      return !this.editable || (this.quantity <= 0 && this.isLoading) || this.isLock || this.skeleton;
    },
    buttonTheme() {
      if (this.skeleton) {
        return 'white-rock';
      }
      if (this.isLock) {
        return undefined;
      }
      if (this.quantity > 0) {
        return 'go-green';
      }
      if (this.isLock) {
        return undefined;
      }

      return 'beryl-green';
    },
  },

  methods: {
    getLockMessage,
    editQuantity(e) {
      if (this.editable) {
        e.preventDefault();

        this.$modal.open(ModalEditProductQuantity,
          {
            initialQuantity: this.quantity,
            onSubmit: (newQuantity) => this.$emit('edit', newQuantity),
          });
      }
    },
    onLockClick() {
      if (this.quantity > 0) {
        this.$emit('edit', 0);
      } else if (this.$mq.bp768) {
        this.$modal.open(ModalInfo, {
          title: 'Psst !',
          text: this.getLockMessage(this.box),
        });
      }
    },
  },

};
</script>

<style lang="scss" scoped>
.card-v2__tools {
  @apply flex justify-end relative;

  :deep(.potager-button--disabled) {
    opacity: 0.5;
  }

  &__loader {
    display: flex;
    align-items: center;
    justify-content: center;
    @apply text-xl.5;
  }

  &__quantity {
    position: relative;
    line-height: 1em;
    @apply z-30 h-5 w-5 rounded-full text-white bg-go-green/80;
    @apply flex justify-center items-center;
    @apply absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2;

    &--edit {
      &:before {
        content: '';
        height: 2.5rem;
        width: 2.5rem;
        background: rgba($charcoal, .1);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(.5);
        border-radius: 50%;
        transition: transform .3s, opacity .3s;
        opacity: 0;
      }

      @include hover {
        &:before {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }
  }
}
</style>
