<template>
  <dlp-search-input
    ref="input"
    :constraints="[
      { constraint: 'required', field: 'search' },
      { constraint: 'length', field: 'search', options: { min: 3 } },
    ]"
    :context="context"
    delivery-choice-tracking
    locate
    looking-for="publics"
    @onSearch="onSearch" />
</template>

<script>
import { CONTEXT_BASKET } from 'Classes/Constants';

import DlpSearchInput from 'Components/deliveryPoint/DlpSearchInput';

export default {
  props: {
    context: {
      type: String,
      required: false,
      default: CONTEXT_BASKET,
    },
  },

  components: {
    DlpSearchInput,
  },

  computed: {
    getParams() {
      return this.$potagerRoute?.params || this.$route.params;
    },
  },

  methods: {
    onSearch(value) {
      this.$emit('onSearch', value);
    },
  },
};
</script>
