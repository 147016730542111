import filter from 'lodash/filter';

export default class ErrorsTools {
  static getBackEndErrorMsg(response, code) {
    if (response.errors && response.errors.globals) {
      const { globals } = response.errors;
      const msg = filter(globals, err => err.code === code);
      if (msg && msg.length && msg[0].message) return msg[0].message;
    }
    return null;
  }
}
