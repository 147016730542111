import TunnelConstraints from 'Classes/workflow/TunnelConstraints';
import { inAppWebView, isProcessEnvProd } from 'Classes/Tools';

// set to true to enable debug mode
const logInitiation = !isProcessEnvProd; // log tunnel steps initiate
const logGuard = !isProcessEnvProd; // log tunnel guard
const logStore = !isProcessEnvProd; // mainly used for debugging next/prev step calculation
if (logInitiation || logGuard || logStore) console.info('Tunnel debug mode: ', 'initiate:', logInitiation, '| guard:', logGuard, '| store:', logStore);

/* A recursive function that is flattening the steps. */
export default (stepsTree) => {
  const finalSection = {
    name: 'final',
    steps: [
      {
        name: 'dashboard',
        route: 'dashboard',
        constraints: [{
          name: 'isLoggedIn',
          value: true,
        }],
      },
      {
        name: 'home',
        route: 'home',
        constraints: [{
          name: 'isLoggedIn',
          value: false,
        }],
      },
    ],
  };

  const steps = [];

  const getSteps = (section, depthLevel) => {
    section.steps.forEach((step) => {
      if (logInitiation) step.log = true;
      if (logGuard) step.debugGuard = true;
      if (logStore) step.debugStore = true;

      const stepConstraints = [
        ...section.constraints || [],
        ...step.constraints || [],
      ]
        .filter((constraint) => {
          if (!TunnelConstraints[constraint.name]) {
            console.error(`Tunnel constraint ${constraint.name} does not exist or is not registered in TunnelConstraints.js`);
          }
          return !!TunnelConstraints[constraint.name];
        })
        .map((constraint) => ({
          ...constraint,
          type: TunnelConstraints[constraint.name].type,
        }));

      const newStep = {
        ...step,
        section,
        depthLevel,
      };

      if (stepConstraints.length > 0) {
        newStep.constraints = stepConstraints;
      }

      if (newStep.steps) {
        getSteps(newStep, depthLevel + 1);
      } else {
        steps.push(newStep);
      }
    });
  };

  /* START calculate each steps percentage */
  const mapPercent = (section, index, parent) => {
    const stepNumber = parent.steps ? parent.steps.length : parent.length;
    const totalStepPercent = parent.totalStepPercent ? parent.totalStepPercent * (1 / stepNumber) : 1 / stepNumber;
    const mapSection = {
      ...section,
      totalStepPercent,
      percent: parent.percent ? parent.percent + (totalStepPercent * index) : totalStepPercent * index,
    };

    mapSection.steps = mapSection.steps
      .map((step, stepIndex) => {
        const mapStep = step.steps ? mapPercent(step, stepIndex, mapSection) : step;
        return {
          ...mapStep,
          percent: mapSection.percent + (mapSection.totalStepPercent * ((stepIndex + 1) / mapSection.steps.length)),
        };
      });
    return mapSection;
  };

  const mappedSteps = [...stepsTree, finalSection]
    .map((section, sIndex) => mapPercent(section, sIndex, stepsTree)); // set progress of each step
  /* END calculate each steps percentage */

  // loop through each section to prepare the steps
  mappedSteps.forEach((section) => {
    getSteps(section, 1);
  });

  return steps.map((step, index) => ({ index, ...step }));
};
