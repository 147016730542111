import store from 'Stores';
import Filters from 'Filters';

export default function (to, from, next) {
  if (to.params?.provider) {
    const id = store.getters[`temporaryCustomer/get${Filters.ucfirst(to.params.provider)}Id`];
    const email = store.getters['temporaryCustomer/getEmail'];

    if (!id || !email) {
      console.info('socialProviderGuard > Redirecting to tunnel-registration-register');
      next({ name: 'tunnel-registration-register' });
    }
  }
  next();
}
