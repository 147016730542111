import {
  ERROR_MESSAGES,
  authentication_required,
  generic_decline,
  call_issuer,
} from 'Components/stripe/StripeErrors';

import * as Sentry from '@sentry/vue';

/* Item peut être :
 * - un objet order
 * - un objet error de potager
 * - un objet error de stripe
 */
export function getPaymentErrorMessage(item) {
  const errorCode = item.errorCode || (item.stripePayment ? item.stripePayment.errorCode : null) || item.code;
  const declineCode = item.declineCode || (item.stripePayment ? item.stripePayment.declineCode : null) || item.decline_code;
  const neededAction = item.stripePayment ? item.stripePayment.neededAction : null;
  const { message } = item;
  let mappedMessage = message || generic_decline;

  if (ERROR_MESSAGES[declineCode]) {
    mappedMessage = ERROR_MESSAGES[declineCode];
  }

  if (ERROR_MESSAGES[errorCode]) {
    mappedMessage = ERROR_MESSAGES[errorCode];
  }

  if (neededAction === '3ds' || neededAction === 'front_validation') {
    mappedMessage = authentication_required;
  }

  if ((message && message === 'Votre carte a été refusée.')
    || (item.stripePayment && !item.stripePayment.currentStripePayment)) {
    mappedMessage = call_issuer;
  }

  Sentry.captureException(new Error(`Payment error || code:${errorCode} - declineCode:${declineCode} - neededAction:${neededAction} - message:${mappedMessage}`));
  return mappedMessage;
}
