import { inAppWebView } from 'Classes/Tools';

export const postMessage = (data) => {
  const mockData = typeof data === 'object'
    ? data.token ? {
      ...data,
      token: '***'
    } : data
    : data;
  if (window.ReactNativeWebView) {
    console.info('ReactNativeWebView postMessage', mockData);
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  } else if (inAppWebView()) {
    console.info('postMessage', mockData);
    window.postMessage(JSON.stringify(data), '*');
  }
};

export const redirectWebApp = (options = {}, closeWebView = true) => {
  const token = localStorage.getItem('token');
  const data = { token, ...options };

  console.info('> quit webApp');
  postMessage(data);
  if (closeWebView) postMessage('closeWebView');
};

export const loginInApp = () => {
  if (window.ReactNativeWebView) {
    const token = localStorage.getItem('token');
    window.ReactNativeWebView.postMessage(JSON.stringify({ token }));
  }
};

export default {
  computed: {
    isInAppWebView() {
      return inAppWebView();
    },
    isInFacebookApp() {
      const ua = navigator.userAgent || navigator.vendor;
      return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
    },
  },

  methods: {
    postMessage,
    redirectWebApp,
    loginInApp,
  },
};
