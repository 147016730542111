import { isProd } from 'Classes/Tools';

import { REGISTER_TEST_BY_NAME } from 'Stores/types/ABTestActionsTypes';
import { UPDATE_TEST_BY_NAME } from 'Stores/types/ABTestMutationsTypes';

window.dataLayer = window.dataLayer || [];

function gtag() {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
  window.dataLayer.push({ event: 'optimize.activate' });
}

export const PTCY_3863 = 'PTCY-3863-faire-un-ab-test-parcours-insc';
export const PTCY_3910 = 'PTCY-3910-a-b-test-mode-de-commande-dans';
export default {

  namespaced: true,

  state: {
    // 'PTCY-1234 - sample': {
    //   id: isProd ? 'prod-key' : 'test-key',
    //   value: 0, // default variante
    // },
    // then add it to ABTestMixin and use it
    [PTCY_3863]: {
      id: isProd ? 'infbC4rCQDqXMljZj8HSiA' : 'EQ7XKU6sQx63zgrgIsGA5g',
      value: 0, // default variante = redirect tunnel // 1 = redirect to /boutique
    },
    [PTCY_3910]: {
      id: isProd ? '3Pbd4ZMVSJWNQv55neLfKA' : 'JdxbI7ATQZmE62SLKCg1Rg',
      value: 0, // default variante = 2 blocs alc & subscription // 1 = uniquement bloc subscription
    },
  },

  getters: {
    getABTestValueByName: (state) => (name) => state[name]?.value,
  },

  mutations: {
    [UPDATE_TEST_BY_NAME](state, { name, value }) {
      state[name].value = value;
    },
  },

  actions: {
    getVariant(store, { name }) {
      return new Promise((resolve) => {
        // Si window.google_optimize.get(testId) retourne une valeur,
        // le test a déjà été initialisé et optimize est loader,
        // on peut donc renvoyer directement ça
        resolve(window.google_optimize?.get?.(store.state[name].id) || null);
      });
    },
    register({ dispatch, state }, name, defaultValue = 0) {
      return new Promise((resolve, reject) => {
        const testId = state[name]?.id;

        if (!testId) reject();

        dispatch('getVariant', { name, defaultValue })
          .then((value) => {
            // Si on récupère une variante, le test est déjà initialisé
            // On retourne donc directement cette valeur
            if (value !== null) resolve(value);

            // Sinon, on tente de trigger google optimize.callback
            if (window.dataLayer.find((item) => item.event === 'optimize.callback')) {
              gtag('event', 'optimize.callback', {
                name: testId,
                callback: (variant, id) => {
                  if (id === testId) {
                    resolve(variant);
                  }
                },
              });
            } else {
              resolve(defaultValue);
            }
          });
      });
    },
    [REGISTER_TEST_BY_NAME]({ dispatch, commit }, name) {
      return new Promise((resolve) => {
        dispatch('register', name)
          .then((value) => {
            commit(UPDATE_TEST_BY_NAME, { name, value });
            resolve(value);
          });
      });
    },
  },
};
