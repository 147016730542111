<template>
  <tunnel-body class="text-center">
    <h1 class="text-xl font-extrabold mb-3 mx-10">
      <template v-if="$route.params?.provider">
        Une dernière petite chose
      </template>
      <template v-else>
        Créez votre compte
      </template>
    </h1>

    <p class="mb-10">
      ✏️&nbsp;Vous me signez un autographe ?
    </p>

    <div class="max-w-640 w-full mx-auto">
      <registration-form
        @onEmailClick="$router.push(getPrevStepRoute())"
        @onSuccess="onSuccess" />
    </div>
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';

import MetaInfosService from 'Classes/services/MetaInfoService';
import { ORDER_ALC_TYPE, ORDER_TYPE } from 'Classes/workflow/TunnelTypesConstants';

import MobileAppMixin from 'Mixins/MobileAppMixin';

import TunnelBody from 'Components/tunnel/TunnelBody';
import RegistrationForm from 'Components/registration/RegistrationForm';

export default {
  mixins: [
    MobileAppMixin,
  ],

  components: {
    RegistrationForm,
    TunnelBody,
  },

  computed: {
    ...mapGetters('tunnel', [
      'getPrevStepRoute',
      'getNextStepRoute',
      'getTypeValue',
    ]),
  },

  methods: {
    onSuccess() {
      if (this.getTypeValue(ORDER_TYPE) === ORDER_ALC_TYPE) {
        this.$router.push(this.getNextStepRoute());
      } else {
        if (this.isInAppWebView) {
          this.loginInApp();
        } else {
          this.$notify({
            type: 'success',
            title: 'Bienvenue chez Potager City !',
            text: 'Votre compte a bien été créé.',
          });
        }

        this.$router.push(this.getNextStepRoute());
      }
    },
  },

  head: MetaInfosService.generate({
    title: 'Créez votre compte',
  }),
};
</script>
