import Filters from 'Filters';
import Dates from 'Classes/Dates';

export const getOrderTimeSlot = (order) => {
  return Dates.isNextWeek(order?.date)
    ? order?.groupDeliveryDay?.timeSlot
    : order?.groupDeliveryDay?.currentWeekTimeSlot;
};

export const getOrderNote = (order) => {
  const dlpNote = order?.deliveryPoint?.note || '';
  const gddNote = getOrderTimeSlot(order)?.note || '';
  return dlpNote || gddNote ? `${dlpNote}${dlpNote && gddNote ? '<br>' : ''}${gddNote}` : null;
};

export const getOrderDeliveryDate = (order) => {
  return order?.timeSlot?.date
    ? Filters.tmsToFormat(order.timeSlot.date, 'iiii dd MMMM')
    : null;
};

export const getOrderNextDeliveryDate = (order, format = 'iiii dd MMMM') => {
  const gdd = order?.groupDeliveryDay;
  if (!gdd) return null;
  return format ? Filters.tmsToFormat(gdd.timeSlot.date, format) : gdd.timeSlot.date;
};

export const getOrderNextDeliveryHours = (order) => {
  const ts = order?.groupDeliveryDay?.timeSlot;
  if (!ts || Dates.isNextWeek(ts.date)) return null;
  return `Dès ${Filters.tmsToHour(ts.startHour)} jusqu'à ${Filters.tmsToHour(ts.endHour)}`;
};

export const getOrderResumableDate = (order) => {
  return order?.limitDate
    ? Filters.tmsToFormat(order.limitDate, 'iiii dd MMMM, HH\'h\'mm')
    : null;
};
