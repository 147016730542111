<template>
  <transition name="fade">
    <potager-navigator
      v-if="subscriberReduction > 0 && !isOrderCanceledStatus(order)"
      :theme="isSubscriber ? 'beryl-green' : 'sandy-beach'"
      :to="noNavigate ? undefined : { name: 'basket_subscription' }">
      <span class="flex justify-between items-center text-left">
        <span class="mr-auto font-medium truncate">
          {{
            isSubscriber ? '⚡️&nbsp;Vos avantages abonné&nbsp;:&nbsp;' : '⚡️&nbsp;Économisez&nbsp;'
          }}<b>{{ priceFilter(subscriberReduction) }}€</b> {{ isSubscriber ? 'économisés' : 'avec l’abonnement' }}
        </span>
      </span>
    </potager-navigator>
  </transition>
</template>

<script>
import PotagerNavigator from 'UI/PotagerNavigator';

import PricesTools from 'Classes/PricesTools';

import { priceFilter } from 'Filters';
import { isOrderCanceledStatus } from 'potagerlogic/dist/Order/Front';

export default {
  name: 'SubscriberBenefitsNavigator',
  methods: {
    isOrderCanceledStatus,
    priceFilter
  },

  components: {
    PotagerNavigator,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
    noNavigate: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isSubscriber() {
      return PricesTools.isSubscription(this.order?.priceCategory);
    },
    subscriberReduction() {
      return PricesTools.getSubscriberReduction(this.order) - PricesTools.getSubscriptionEnterpriseDiscount(this.order);
    },
  },
};
</script>
