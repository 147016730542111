import { mapGetters } from 'vuex';

import {
  REMOVE_PRODUCT_SHADOW_BASKET_ACTION,
  CLEAR_SHADOW_BASKET_ACTION,
  CONFIRM_SHADOW_BASKET_ACTION,
  REPLACE_SUBSCRIPTION_PRODUCT,
} from 'Stores/types/shadowBasketActionsTypes';

import {
  UPDATE_DELIVERY_POINT_BASKET_ACTION,
  EMPTY_BASKET_ACTION,
} from 'Stores/types/basketActionsTypes';

import ModalInfo from 'Modals/ModalInfo';

import PaymentMethodsMixin from 'Mixins/payments/PaymentMethodsMixin';

export default {
  mixins: [
    PaymentMethodsMixin,
  ],

  computed: {
    ...mapGetters('basket', {
      basketGroupDeliveryDay: 'getGroupDeliveryDay',
      basketProducts: 'getProducts',
    }),
  },

  methods: {
    removeProduct(product, order) {
      return this.$store.dispatch(`shadowBasket/${REMOVE_PRODUCT_SHADOW_BASKET_ACTION}`, {
        productId: product.id,
        orderId: order.id,
      });
    },
    replaceSubscriptionProduct(product, order) {
      return this.$store.dispatch(`shadowBasket/${REPLACE_SUBSCRIPTION_PRODUCT}`, {
        productId: product.id,
        orderId: order.id,
      })
        .then(() => {
          this.submit(order, true);
        });
    },
    clearShadowBasket() {
      return this.$store.dispatch(`shadowBasket/${CLEAR_SHADOW_BASKET_ACTION}`);
    },
    deleteProductFromOrder(product, order) {
      this.$modal.open(ModalInfo, {
        title: 'Êtes-vous sûr ?',
        text: 'Cette élement va être supprimé de votre commande.',
        closeBtnText: 'Confirmer',
        showCancel: true,
        immediateClosure: false,
        isLoading: [
          REMOVE_PRODUCT_SHADOW_BASKET_ACTION,
          CLEAR_SHADOW_BASKET_ACTION,
          CONFIRM_SHADOW_BASKET_ACTION,
        ],
        onClose: this.deleteProductFromOrderAction.bind(this, product, order),
      });
    },
    deleteProductFromOrderAction(product, order) {
      return new Promise((resolve, reject) => {
        // On clear le shadowbasket, juste au cas ou
        this.clearShadowBasket(order)
          .then(() => {
            // On ajoute les produits ciblés en négatif dans le shadowbasket
            this.removeProduct(product, order)
              .then(() => {
                // On valide le shadowbasket
                this.submit(order)
                  .then((resp) => resolve(resp))
                  .catch((err) => reject(err));
              })
              .catch((err) => reject(err));
          })
          .catch((err) => reject(err));
      });
    },
    submit(order, replaceNotification = false) {
      const prevOrder = { ...order };
      return this.$store.dispatch(`shadowBasket/${CONFIRM_SHADOW_BASKET_ACTION}`)
        .then((response) => {
          const { id: orderId } = order;
          const orderStillExist = response.find((o) => o.id === orderId);

          if (order?.stripePayment?.isPayable) {
            this.notifyOrderStatus(order);
          } else if (order && replaceNotification) {
            this.$notify({
              type: 'success',
              title: 'Panier remplacé !',
              text: 'Votre panier a bien été remplacé.',
              data: {
                buttons: [
                  {
                    label: 'Voir ma commande',
                    redirect: orderStillExist ? {
                      name: 'mon-compte_orders_detail',
                      params: { orderId },
                    } : { name: 'mon-compte_orders' },
                  },
                ],
              },
            });

            this.$router.push({
              name: 'dashboard',
              hash: '#offre-de-la-semaine',
            });
          } else {
            this.$notify({
              type: 'success',
              title: 'Panier modifié !',
              text: 'Votre panier a bien été modifié.',
              data: {
                buttons: [
                  {
                    label: 'Voir ma commande',
                    redirect: orderStillExist ? {
                      name: 'mon-compte_orders_detail',
                      params: { orderId },
                    } : { name: 'mon-compte_orders' },
                  },
                ],
              },
            });
          }
        });
    },
    migrateBasketToOrderDlp(order) {
      const {
        regionId,
        id: groupDeliveryDayId
      } = order.groupDeliveryDay;

      if (this.basketGroupDeliveryDay.id === groupDeliveryDayId) {
        return this.redirectToDashboard();
      }

      return new Promise((resolve, reject) => {
        const emptyBasket = () => this.$store.dispatch(`basket/${EMPTY_BASKET_ACTION}`);

        const updateBasketDlp = () => this.$store.dispatch(`basket/${UPDATE_DELIVERY_POINT_BASKET_ACTION}`, {
          regionId,
          groupDeliveryDayId,
        })
          .then(() => {
            this.redirectToDashboard();
            resolve();
          })
          .catch((err) => reject(err));

        // Si le panier est vide, on le change juste de point de retrait
        if (!this.basketProducts.length) {
          updateBasketDlp();
          // sinon, on le vide afin d'éviter des erreurs de stock
          // puis on le change de point de retrait
        } else {
          emptyBasket()
            .then(() => updateBasketDlp());
        }
      });
    },
    redirectToDashboard() {
      this.$router.push({
        name: 'dashboard',
        hash: '#offre-de-la-semaine',
      })
        .catch(() => {
        });
    },
  },
};
