import { RESET_USER_ACTION, UPDATE_USER_ACTION, UPDATE_USER_DATA, } from 'Stores/types/userActionsTypes';

import {
  GET_TEMPORARY_CUSTOMER_ACTION,
  UPDATE_TEMPORARY_CUSTOMER_DATA,
} from 'Stores/types/temporaryCustomerActionsTypes';

import {
  ADD_REDIRECTED_PAGE,
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  RESET_SESSION,
  UPDATE_OAC_FLOW,
  UPDATE_OAC_RECOMMENDED_REF,
  UPDATE_OPTANON_ACTIVE_GROUPS,
  UPDATE_OPTANON_CHOICE_SAVED,
  UPDATE_REQUEST_URL,
  UPDATE_TEST_MODE,
  UPDATE_TEST_MODE_DATE,
} from 'Stores/types/sessionMutationsTypes';

import {
  LOGIN_USER_ACTION,
  LOGOUT_USER_ACTION,
  ON_LOGIN_ERROR,
  ON_LOGIN_SUCCESS,
  REQUEST_FORGOT_PASSWORD_EMAIL_ACTION,
  RESET_PASSWORD_ACTION,
  RESET_SESSION_ACTION,
  SOCIAL_LOGIN_USER_ACTION,
  UPDATE_REQUEST_URL_ACTION,
  UPDATE_SESSION_USER_DATA_ACTION,
} from 'Stores/types/sessionActionsTypes';

import { getUrlParameter } from 'Classes/Tools';

import {
  CLOSED_REGIONS,
  CONTEXT_BASKET,
  CONTEXT_ORDER,
  CONTEXT_SUBSCRIPTION,
  CONTEXT_SUBSCRIPTION_BASKET,
  CONTEXT_USER,
  TEMPORARY_CLOSED_REGIONS,
} from 'Classes/Constants';

import { api } from 'Plugins/potagerApiClient';
import { getOrderId } from 'Classes/DlpRouteTools.js';
import { isWithinInterval } from 'date-fns';

// Si on est dans une webview ou si on essaye de se login via un param token
// Alors on réinitialise tout les storages
if (getUrlParameter('utm_source') === 'app' || getUrlParameter('token')) {
  const apiswitcher = window.localStorage.getItem('api-switcher');
  window.sessionStorage.clear();
  window.localStorage.clear();
  if (apiswitcher) window.localStorage.setItem('api-switcher', apiswitcher);
}

// Si le param ?token= est présent dans l'URL
// On set ce token dans le localstorage afin
// de connecter automatiquement l'utilisateur
const tokenFromUrl = getUrlParameter('token');
if (tokenFromUrl) {
  localStorage.setItem('token', tokenFromUrl);
}
const isInitialLoggedIn = !!localStorage.getItem('token');

export default {

  namespaced: true,

  state: {
    isLoggedIn: isInitialLoggedIn,
    pending: false,
    requestUrl: null,
    OptanonActiveGroups: [],
    optanonChoiceSaved: false,
    testMode: false,
    testModeDate: null,
    redirectedPages: [],
    oacFlow: null,
    oacRecommendedRef: null,
    oacRecommendedRefNotEnough: null,
  },

  getters: {
    isLoggedIn: (state) => state.isLoggedIn,
    getRequestUrl: (state) => state.requestUrl,
    getOptanonActiveGroups: (state) => state.OptanonActiveGroups,
    getOptanonChoiceSaved: (state) => state.optanonChoiceSaved,
    isTestModeActivated: (state, g, rs, rootGetters) => rootGetters['user/canTest'] && state.testMode,
    getTestModeDate: (state, getters) => getters.isTestModeActivated && state.testModeDate,
    getRedirectedPages: (state) => state.redirectedPages,
    getRedirectedPageByName: (state, getters) => (name) => getters.getRedirectedPages.filter((e) => e.from === name)?.[0],
    getRegionId: (s, g, rs, rg) => {
      const userRegion = g.isLoggedIn ? rg['user/getRegionId'] : rg['temporaryCustomer/getRegionId'];
      const subscriptionRegion = rg['subscriptionBasket/getRegionId'];
      return userRegion || subscriptionRegion;
    },
    getOacRecommendedRef: (state) => state.oacRecommendedRef,
    getOacRecommendedBox: (s, g, rs, rg) => {
      if (g.getOacRecommendedRef) {
        const isRef = (format) => format.product.productReference === g.getOacRecommendedRef;
        return rg['subscriptionBasket/getProductSelectorsV2']
          .find((cat) => cat.variants.find((format) => isRef(format)))
          ?.variants
          .find((format) => isRef(format))?.product;
      }
      return null;
    },
    isOacRecommendedRefNotEnough: (state) => !!state.oacRecommendedRefNotEnough,
    getDeliveryPoint: (s, g, rs, rg) => (context) => {
      const route = rg['routeHistory/getCurrentRoute'];
      const orderId = getOrderId(route);
      const getBasketSubscriptionDeliveryPoint = rg['subscriptionBasket/getDeliveryPoint'];
      const getSubscriptionDeliveryPoint = rg['user/getSubscriptionDeliveryPoint'];
      const getOrderDeliveryPoint = rg['user/getOrderById'](orderId)?.deliveryPoint;
      const getBasketDeliveryPoint = rg['basket/getDeliveryPoint'];
      const getHomeDeliveryPoint = rg['homeDelivery/getDeliveryPoint'];

      switch (context) {
        case CONTEXT_SUBSCRIPTION_BASKET:
          return getBasketSubscriptionDeliveryPoint || getHomeDeliveryPoint;
        case CONTEXT_SUBSCRIPTION:
          return getSubscriptionDeliveryPoint || getBasketSubscriptionDeliveryPoint || getHomeDeliveryPoint;
        case CONTEXT_ORDER:
          return getOrderDeliveryPoint || getHomeDeliveryPoint;
        case CONTEXT_BASKET:
          return getBasketDeliveryPoint || getHomeDeliveryPoint;
        default:
          return undefined;
      }
    },
    getGroupDeliveryDay: (s, g, rs, rg) => (context) => {
      const route = rg['routeHistory/getCurrentRoute'];
      const orderId = getOrderId(route);

      const getBasketSubscriptionGroupDeliveryDay = rg['subscriptionBasket/getGroupDeliveryDay'];
      const getSubscriptionGroupDeliveryDay = rg['user/getSubscriptionGroupDeliveryDay'];
      const getOrderGroupDeliveryDay = rg['user/getOrderById'](orderId)?.groupDeliveryDay;
      const getBasketGroupDeliveryDay = rg['basket/getGroupDeliveryDay'];

      switch (context) {
        case CONTEXT_SUBSCRIPTION_BASKET:
          return getBasketSubscriptionGroupDeliveryDay;
        case CONTEXT_SUBSCRIPTION:
          return getSubscriptionGroupDeliveryDay || getBasketSubscriptionGroupDeliveryDay;
        case CONTEXT_ORDER:
          return getOrderGroupDeliveryDay;
        case CONTEXT_BASKET:
          return getBasketGroupDeliveryDay;
        default:
          return undefined;
      }
    },
    getAddress: (s, g, rs, rg) => (context) => {
      const route = rg['routeHistory/getCurrentRoute'];
      const orderId = getOrderId(route);

      const getBasketSubscriptionAddress = rg['subscriptionBasket/getAddress'];
      const getSubscriptionAddress = rg['user/getSubscriptionAddress'];
      const getBasketAddress = rg['basket/getAddress'];
      const getOrderAddress = rg['user/getOrderById'](orderId)?.address;
      const getHomeDeliveryAddress = rg['homeDelivery/getAddress'];

      switch (context) {
        case CONTEXT_SUBSCRIPTION_BASKET:
          return getBasketSubscriptionAddress || getHomeDeliveryAddress;
        case CONTEXT_SUBSCRIPTION:
          return getSubscriptionAddress || getHomeDeliveryAddress;
        case CONTEXT_ORDER:
          return getOrderAddress || getHomeDeliveryAddress;
        case CONTEXT_BASKET:
        case CONTEXT_USER:
          return getBasketAddress || getHomeDeliveryAddress;
        default:
          return getHomeDeliveryAddress;
      }
    },
    getClosedRegionInfos: (state, getters) => {
      const temporaryClosed = TEMPORARY_CLOSED_REGIONS
        .find((e) => e.regionId === getters.getRegionId);
      const definitiveClosed = CLOSED_REGIONS
        .find((r) => r.regionId === getters.getRegionId);
      return definitiveClosed || temporaryClosed;
    },
    isClosedRegion: (state, getters) => CLOSED_REGIONS
      .find((r) => r.regionId === (getters.getRegionId)),
    isClosedRegionByRegionId: () => (regionId) => CLOSED_REGIONS
      .find((r) => r.regionId === regionId),
    isTemporaryClosedRegion: (state, getters) => {
      if (!getters.getClosedRegionInfos) return false;

      const interval = {
        start: new Date(getters.getClosedRegionInfos.startDate),
        end: new Date(getters.getClosedRegionInfos.endDate),
      };

      // Vérifier si la date actuelle est dans l'intervalle
      return isWithinInterval(new Date(), interval);
    },
  },

  mutations: {
    [LOGIN](state) {
      state.pending = true;
    },
    [LOGIN_SUCCESS](state) {
      state.isLoggedIn = true;
      state.pending = false;
    },
    [LOGIN_ERROR](state) {
      state.isLoggedIn = false;
      state.pending = false;
    },
    [LOGOUT](state) {
      state.isLoggedIn = false;
      state.testMode = false;
      state.testModeDate = null;
    },
    [UPDATE_REQUEST_URL](state, url) {
      state.requestUrl = url;
    },
    [RESET_SESSION](state) {
      state.isLoggedIn = !!localStorage.getItem('token');
      state.idTemporaryCustomer = localStorage.getItem('temporaryCustomerId');
    },
    [UPDATE_OPTANON_ACTIVE_GROUPS](state) {
      state.OptanonActiveGroups = window.OptanonActiveGroups ? window.OptanonActiveGroups.split(',')
        .filter((e) => e !== '') : [];
    },
    [UPDATE_OPTANON_CHOICE_SAVED](state, choice) {
      state.optanonChoiceSaved = choice;
    },
    [UPDATE_TEST_MODE](state, value) {
      state.testMode = value;
    },
    [UPDATE_TEST_MODE_DATE](state, formatedDate = null) {
      state.testModeDate = formatedDate;
    },
    [ADD_REDIRECTED_PAGE](state, {
      from,
      to,
    }) {
      state.redirectedPages = [
        ...state.redirectedPages.filter((e) => e.from !== from),
        {
          from,
          to,
        },
      ];
    },
    [UPDATE_OAC_FLOW](state, flow) {
      state.oacFlow = flow;
    },
    [UPDATE_OAC_RECOMMENDED_REF](state, payload) {
      state.oacRecommendedRef = payload?.ref || null;
      state.oacRecommendedRefNotEnough = payload?.isNotEnough || null;
    },
  },

  actions: {
    [LOGIN_USER_ACTION]({
      commit,
      dispatch,
    }, {
      username,
      password,
      temporaryCustomerId,
    }) {
      return new Promise((resolve, reject) => {
        commit(LOGIN);
        dispatch('wait/start', LOGIN_USER_ACTION, { root: true });

        api.login.login(username, password, temporaryCustomerId)
          .then((resp) => {
            dispatch(ON_LOGIN_SUCCESS, resp)
              .then((response) => resolve(response))
              .catch((err) => reject(err))
              .finally(() => {
                dispatch('wait/end', LOGIN_USER_ACTION, { root: true });
              });
          })
          .catch((err) => {
            dispatch(ON_LOGIN_ERROR, err)
              .catch((error) => reject(error))
              .finally(() => {
                dispatch('wait/end', LOGIN_USER_ACTION, { root: true });
              });
          });
      });
    },
    [SOCIAL_LOGIN_USER_ACTION]({
      commit,
      dispatch,
    }, param) {
      return new Promise((resolve, reject) => {
        const {
          provider,
          ...params
        } = param;
        commit(LOGIN);
        dispatch('wait/start', SOCIAL_LOGIN_USER_ACTION, { root: true });
        dispatch('wait/start', `${SOCIAL_LOGIN_USER_ACTION}_${provider}`, { root: true });
        api.login.loginWithSocial(provider, params)
          .then((resp) => {
            dispatch(ON_LOGIN_SUCCESS, resp)
              .then((response) => resolve(response))
              .finally(() => {
                dispatch('wait/end', SOCIAL_LOGIN_USER_ACTION, { root: true });
                dispatch('wait/end', `${SOCIAL_LOGIN_USER_ACTION}_${provider}`, { root: true });
              });
          })
          .catch((err) => {
            dispatch(ON_LOGIN_ERROR, err)
              .catch((error) => reject(error))
              .finally(() => {
                dispatch('wait/end', SOCIAL_LOGIN_USER_ACTION, { root: true });
                dispatch('wait/end', `${SOCIAL_LOGIN_USER_ACTION}_${provider}`, { root: true });
              });
          });
      });
    },
    [ON_LOGIN_SUCCESS]({
      commit,
      dispatch,
    }, resp) {
      return new Promise((resolve, reject) => {
        commit(LOGIN_SUCCESS);

        const {
          token,
          refresh_token,
        } = resp.data;
        localStorage.setItem('token', token); // store the token in localstorage
        localStorage.setItem('refresh_token', refresh_token); // store the refresh_token in localstorage

        // On update l'utilisateur courant
        dispatch(`user/${UPDATE_USER_ACTION}`, null, { root: true })
          .then(() => resolve(resp))
          .catch((err) => reject(err));
      });
    },
    [ON_LOGIN_ERROR]({ commit }, err) {
      return new Promise((resolve, reject) => {
        commit(LOGIN_ERROR);
        reject(err);
      });
    },
    [RESET_PASSWORD_ACTION]({
      commit,
      dispatch,
    }, {
      token,
      password,
    }) {
      commit(LOGIN);
      dispatch('wait/start', RESET_PASSWORD_ACTION, { root: true });

      return new Promise((resolve, reject) => {
        api.login.resetPassword(token, password)
          .then((resp) => {
            resolve(resp);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => dispatch('wait/end', RESET_PASSWORD_ACTION, { root: true }));
      });
    },
    [LOGOUT_USER_ACTION]({
      commit,
      dispatch,
    }) {
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      commit(LOGOUT);
      dispatch(`temporaryCustomer/${GET_TEMPORARY_CUSTOMER_ACTION}`, null, { root: true });
      dispatch(`user/${RESET_USER_ACTION}`, null, { root: true });
    },
    [REQUEST_FORGOT_PASSWORD_EMAIL_ACTION]({ dispatch }, {
      emailPassword,
      temporaryCustomerId,
      from,
      resetPasswordUrl,
    }) {
      dispatch('wait/start', REQUEST_FORGOT_PASSWORD_EMAIL_ACTION, { root: true });

      return api.login.sendEmailPassword(emailPassword, temporaryCustomerId, from, resetPasswordUrl)
        .finally(() => dispatch('wait/end', REQUEST_FORGOT_PASSWORD_EMAIL_ACTION, { root: true }));
    },
    [RESET_SESSION_ACTION]({ commit }) {
      commit(RESET_SESSION);
    },
    [UPDATE_REQUEST_URL_ACTION]({ commit }, to) {
      commit(UPDATE_REQUEST_URL, to?.fullPath || to);
    },
    [UPDATE_SESSION_USER_DATA_ACTION]({
      dispatch,
      rootGetters
    }, payload) {
      const data = payload.data || payload;
      if (data?.success) {
        if (rootGetters['user/getUserId']) {
          dispatch(`user/${UPDATE_USER_DATA}`, payload, { root: true });
        } else {
          dispatch(`temporaryCustomer/${UPDATE_TEMPORARY_CUSTOMER_DATA}`, payload, { root: true });
        }
      }
    },
  },
};
