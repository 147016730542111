import COLORS from 'Settings/colors';
import { isSubscription } from 'Classes/DlpTools';
import ConfigurationManager from 'Classes/ConfigurationManager';
import markerEnable from 'potagerassets/images/marker-enable.svg';
import markerEnableActive from 'potagerassets/images/marker-enable-active.svg';
import markerUser from 'potagerassets/images/marker-user.svg';

const { forestGreen } = COLORS;

export const COUNTRY_CENTER = {
  lat: 46.227638,
  lng: 2.213749,
};
export const PARIS_CENTER = {
  lat: 48.856614,
  lng: 2.3522219,
};

export const DEFAULT_ZOOM_LEVEL = 14;
export const DEFAULT_FOCUS_ZOOM_LEVEL = 16;
export const DEFAULT_MIN_ZOOM_LEVEL = 6;
export const DEFAULT_MAX_ZOOM_LEVEL = 22;
export const DEFAULT_MAP_OPTIONS = {
  zoom: DEFAULT_MIN_ZOOM_LEVEL,
  center: COUNTRY_CENTER,
  styles: [
    {
      featureType: 'poi',
      stylers: [
        { visibility: 'off' },
      ],
    },
  ],
  clickableIcons: false,
  fullscreenControl: false,
  mapTypeControl: false,
  mapTypeId: 'roadmap',
  maxZoom: DEFAULT_MAX_ZOOM_LEVEL,
  minZoom: DEFAULT_MIN_ZOOM_LEVEL,
  scrollwheel: true,
  streetViewControl: false,
  zoomControl: !ConfigurationManager.getByName('isMobileTablet'),
  gestureHandling: 'greedy',
};

export const getLatLng = ({
  lat,
  lng
}) => ({
  lat: parseFloat(lat),
  lng: parseFloat(lng),
});
const getPoint = (x, y) => ({
  x,
  y
});
const getSize = (width, height) => ({
  height,
  width
});

export const getDistanceBetweenTwoPoints = (a, b) => {
  if (a.lat === b.lat && a.lng === b.lng) return 0;
  const R = 6378.137; // Radius of earth in KM
  const dLat = ((b.lat * Math.PI) / 180) - ((a.lat * Math.PI) / 180);
  const dLon = ((b.lng * Math.PI) / 180) - ((a.lng * Math.PI) / 180);
  const A = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos((a.lat * Math.PI) / 180) * Math.cos((b.lat * Math.PI) / 180)
    * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(A), Math.sqrt(1 - A));
  const d = R * c;
  return d * 1000; // meters
};

export const isDeliveryPointFiltered = (dlp, filters) => {
  if (filters.filter((d) => !d.value).length === filters.length) return false;
  if (filters.filter((d) => d.value).length === filters.length) return false;
  return !Object.entries(dlp.groupDeliveryDay)
    .filter((e) => !!filters.filter((d) => d.dayIndex === e[1].day && d.value).length)
    .length;
};

const markerSize = {
  width: 37,
  height: 41.2,
};
const MARKERS_CONFIG = {
  size: getSize(markerSize.width, markerSize.height),
  anchor: getPoint(markerSize.width / 2, markerSize.height),
};

export const MARKER_DEFAULT = {
  url: markerEnable,
  ...MARKERS_CONFIG,
};

export const MARKER_DEFAULT_SELECTED = {
  url: markerEnableActive,
  ...MARKERS_CONFIG,
};

export const MARKER_DISABLED = {
  // url: '/images/marker-disable.svg',
  // ...MARKERS_CONFIG,
  ...MARKER_DEFAULT,
};

export const MARKER_DISABLED_SELECTED = {
  // url: '/images/marker-disable-active.svg',
  // ...MARKERS_CONFIG,
  ...MARKER_DEFAULT_SELECTED,
};

export const MARKER_USER = {
  url: markerUser,
  size: getSize(64, 64),
  scaledSize: getSize(64, 64),
  origin: getPoint(0, 0),
  anchor: getPoint(32, 32),
};

export const WOOSMAP_DISABLED_DLP_TYPE = (context) => (isSubscription(context) ? 'isGddDisabledForSubscription' : 'isGddDisabled');
export const woosmapStyle = (context) => ({
  breakPoint: DEFAULT_ZOOM_LEVEL - 1,
  default: {
    color: forestGreen,
    icon: MARKER_DEFAULT,
  },
  rules: [
    // {
    //   color: warm-grey,
    //   type: WOOSMAP_DISABLED_DLP_TYPE(context),
    //   icon: MARKER_DISABLED,
    // },
  ],
});

export const TRESHOLD_DIFF_TRIGGER_BUTTON_LAT = 1;
export const TRESHOLD_DIFF_TRIGGER_BUTTON_LNG = 1;

export const SUGGEST_PUBLIC_FORM_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSdiyo5hLipLAFi4pIPMFS6b-Vb-EuZ8q0gI9cvlVPjmFMwfNQ/viewform';
export const SUGGEST_PRIVATE_FORM_URL = 'https://docs.google.com/forms/d/e/1FAIpQLScxcpDxXaNoMixzxT_qAzkoVMchtXIwZxn2ENCSSaJf_D1C2Q/viewform';

export const MAX_CARDS_IN_LIST = 20;
