<template>
  <blog-section class="print:hidden">
    <social-media
      :facebook="facebook"
      :mail="mail"
      :pinterest="pinterest"
      :twitter="twitter" />
  </blog-section>
</template>

<script>
/* eslint-disable object-curly-newline */
import SocialMedia from 'Components/widget/SocialMedia';
import BlogSection from 'Components/blog/BlogSection';
import { getResolvedUrl, calcSlug } from 'Classes/Tools';

export default {

  components: {
    SocialMedia,
    BlogSection,
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
  },

  computed: {
    id() {
      return this.item.id;
    },
    subject() {
      switch (this.context) {
        case 'recipe':
          return `Découvre la recette : ${this.item.title} - Potager City`;
        default:
          return this.item.title ? `${this.item.title} - Potager City` : 'Potager City';
      }
    },
    routeName() {
      switch (this.context) {
        case 'recipe':
          return 'recette';
        case 'supplier':
          return 'producteurs';
        case 'report':
          return 'reports';
        default:
          return 'produits';
      }
    },
    url() {
      return getResolvedUrl(this.routeName, {
        slug: calcSlug(this.item.title),
        id: this.id,
      });
    },
    body() {
      switch (this.context) {
        case 'recipe':
          return `Découvre la recette : ${this.item.title}\n\n${this.url} sur Potager City`;
        default:
          return `${this.item.content || this.item.description}\n\n${this.url} sur Potager City`;
      }
    },
    image() {
      return this.item.image ? this.item.image.url : null;
    },

    mail() {
      const { id, subject, body, url } = this;
      return { id, subject, body, url };
    },
    twitter() {
      return {
        url: this.url,
        text: this.subject,
      };
    },
    facebook() {
      return {
        url: this.url,
      };
    },
    pinterest() {
      return {
        url: this.url,
        image: this.image,
        description: this.subject,
      };
    },
  },

};
</script>
