<template>
  <div class="pb-6 bg-white">
    <heading
      background="background-delivery-points-page"
      background-position="center 33%"
      title="Devenir point relais de paniers de fruits et légumes">
      <template #title>
        Devenez point relais <br>de paniers de fruits <br>et légumes
      </template>
      <template #baseline>
        Attirez de nouveaux clients dans votre commerce en devenant point relais pour Potager City
      </template>
      <potager-button
        :size="$mq.bp1024 ? 'small' : undefined"
        :full-width="$mq.bp768"
        theme="white"
        @onClick="goToContact">
        Devenir Point Relais
      </potager-button>
    </heading>

    <tabs
      :tabs="tabs"
      :offset="$mq.bp768 ? -16 : -120" />

    <why-become-delivery-point />

    <how-to
      id="comment-ca-marche"
      :items="howto"
      class="px-10"
      subtitle="C’est facile, vous n'avez simplement qu'à remettre les commandes aux clients"
      svg-icons />

    <video-frame />

    <why-become-delivery-point-advantages @onClick="goToContact" />

    <rassurance-they-trust-us />

    <i class="block text-center">
      *sous réserve de conditions contractuelles particulières
    </i>
  </div>
</template>

<script>
import SellsyMixin from 'Mixins/SellsyMixin';
import ToolsMixin from 'Mixins/ToolsMixin';
import { getResolvedUrl } from 'Classes/Tools';

import IconBusinessDealHandshake from 'Icons/regular/IconBusinessDealHandshake';
import IconWarehouseCartPackageRibbon1 from 'Icons/regular/IconWarehouseCartPackageRibbon1';
import IconDeliveryTruck2 from 'Icons/regular/IconDeliveryTruck2';
import IconShop from 'Icons/regular/IconShop';

import Heading from 'Components/heading/Heading';
import PotagerButton from 'UI/PotagerButton';
import Tabs from 'Components/tabs/Tabs';
import HowTo from 'Components/howto/Howto';
import WhyBecomeDeliveryPoint from 'Components/whyBecomeDeliveryPoint/WhyBecomeDeliveryPoint';
import WhyBecomeDeliveryPointAdvantages from 'Components/whyBecomeDeliveryPoint/WhyBecomeDeliveryPointAdvantages';
import RassuranceTheyTrustUs from 'Components/rassurance/RassuranceTheyTrustUs';
import VideoFrame from 'Components/videoFrame/VideoFrame';

export default {

  mixins: [
    ToolsMixin,
    SellsyMixin,
  ],

  components: {
    Heading,
    PotagerButton,
    Tabs,
    HowTo,
    WhyBecomeDeliveryPoint,
    WhyBecomeDeliveryPointAdvantages,
    RassuranceTheyTrustUs,
    VideoFrame,
  },

  data() {
    return {
      tabs: [{
        label: 'Pourquoi devenir partenaire ?',
        anchor: '#pourquoi-devenir-partenaire',
      }, {
        label: 'Comment ça marche ?',
        anchor: '#comment-ca-marche',
      }, {
        label: 'Vos avantages',
        anchor: '#vos-avantages',
      }, {
        label: 'Ils nous font confiance',
        anchor: '#ils-nous-font-confiance',
      }],

      howto: [{
        icon: IconBusinessDealHandshake,
        title: 'Contact',
        desc: 'Vous nous contactez pour manifester votre intérêt',
        button: {
          action: () => window.SellsySnippet.toggle(),
          label: 'Nous contacter',
        },
      }, {
        icon: IconWarehouseCartPackageRibbon1,
        title: 'Installation',
        desc: 'Nous vous fournissons les supports de présentation',
      }, {
        icon: IconDeliveryTruck2,
        title: 'Livraison',
        desc: 'Nous vous livrons une ou deux fois par semaine',
      }, {
        icon: IconShop,
        title: 'Clients',
        desc: 'Les clients viennent chercher leurs commandes dans votre boutique',
      }],
    };
  },

  created() {
    this.createSellsyWidget('SyVGNyU3Q0UlMTklQUUlOTklRTYlOTVzJTEzJThFJTlGdlAlODgrJUIyJTFBJTlEJTA4JURBJUU4JUI3JUExciUyOEElMTYlMTFvQiUwNiVBMCVFMSUwMCU3RFElN0QlMjklQUQlQUFGJUEwJUFFJUQ4JUE3JUVCJUQ5JTNFJTNEJUVFTyU2MFklREElQTAlMDZ6JTA0JTFBJTI0Lnc=');
  },

  beforeUnmount() {
    this.removeSellsyWidget();
  },

  computed: {
    canonical() {
      return getResolvedUrl('devenir-point-de-retrait');
    },
  },

  methods: {
    goToContact() {
      window.SellsySnippet.toggle();
    },
  },

  head() {
    return this.generateMetaIfPanelClosed({
      title: ' Devenir point relais de paniers de fruits et légumes',
      description: 'Devenez point relais de paniers de fruits et légumes et attirez de nouveaux clients dans votre commerce. Installation gratuite et partenariat sans engagement !',
      image: 'https://potagercity.fr/images/meta_landing_page_point_de_retrait_partenaire_1200_628.png',
      link: [{
        rel: 'canonical',
        href: this.canonical,
      }],
    });
  },
};
</script>
