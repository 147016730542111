import { POPIN_ERROR_EVENT } from 'Classes/Constants';
import WordingTools from 'Classes/WordingTools';
import { getDlpSearchRoute } from 'Classes/DlpRouteTools';

import store from 'Stores';

export const BT003_DEFAULT_ERROR = {
  eventType: POPIN_ERROR_EVENT,
  title: 'Avertissement',
  closeBtnText: 'Modifier mon point de retrait',
  onCloseRedirect: getDlpSearchRoute(),
  showCancel: true,
  handle(app, response, error) {
    const gdd = store.getters['basket/getGroupDeliveryDay'];
    error.text = WordingTools.gddFull('BT003', gdd);
  },
};

export const BT003_SUBSCRIPTION_BASKET_ERROR = {
  eventType: POPIN_ERROR_EVENT,
  title: 'Avertissement',
  closeBtnText: 'Modifier mon point de retrait',
  onCloseRedirect: { name: 'tunnel-delivery-type-step1' },
  showCancel: false,
  canBeClosed: false,
  handle(app, response, error) {
    const gdd = store.getters['basket/getGroupDeliveryDay'];
    error.text = WordingTools.gddFull('BT003', gdd);
  },
};

export const BT003_BASKET_ERROR = {
  eventType: POPIN_ERROR_EVENT,
  title: 'Avertissement',
  handle(app, response, error) {
    const gdd = store.getters['basket/getGroupDeliveryDay'];
    error.text = WordingTools.gddFull('BT003', gdd);
  },
};

export default {
  default: BT003_DEFAULT_ERROR,
  'TemporaryCustomerApi.addBasketSubscription': BT003_SUBSCRIPTION_BASKET_ERROR,
  'UserApi.addSubscriptionToSubscriptionBasket': BT003_SUBSCRIPTION_BASKET_ERROR,
  'TemporaryCustomerApi.setBasketDeliveryPoint': BT003_BASKET_ERROR,
  'UserApi.setBasketDeliveryPoint': BT003_BASKET_ERROR,
};
