import { bioConstraint } from 'Classes/OAC/Constraints';

import PotagerBadge from 'UI/PotagerBadge';
import IconPtcyBio from 'Icons/regular/IconPtcyBio';
import store from 'Stores';

const answerBuilder = (value, label) => ({
  value,
  label: label || value,
});

const BioCpt = {
  components: {
    PotagerBadge,
    IconPtcyBio,
  },
  template: `
    <potager-badge
      theme="forest"
      size="small">
      <template #icon>
        <icon-ptcy-bio />
      </template>
    </potager-badge>
  `,
};

export default {

  family: {
    message: () => (store.getters['session/isLoggedIn'] ? `
        Bonjour, je m’appelle Framboise&nbsp;!&nbsp;👋<br>
        Je vais vous poser quelques questions afin de vous proposer
        le panier le plus adapté à vos besoins. Et s’il y a un pépin
        dans ma proposition personnalisée et qu’elle ne vous plaît pas,
        vous pourrez ensuite choisir autre chose&nbsp;!
      `
        : 'Combien êtes-vous à la maison ?'
    ),
    questions: [
      {
        title: 'Combien d’adulte(s) à la maison ?',
        icon: '🌳',
        answers: [
          answerBuilder(1),
          answerBuilder(2),
          answerBuilder(3),
          answerBuilder(4, '4 et +'),
        ],
      },
      {
        title: 'Combien d’enfant(s) à la maison ?',
        icon: '🌱',
        answers: [
          answerBuilder(0),
          answerBuilder(1),
          answerBuilder(2),
          answerBuilder(3, '3 et +'),
        ],
      },
    ],
  },

  fruitsAndVegetables: {
    message: 'Ok ! Qu’y a-t-il dans votre panier Potager City idéal ?',
    questions: [
      {
        title: 'Fruits, légumes, ou les deux ?',
        icon: '🍓🥒',
        answers: [
          answerBuilder('f', '100% fruits'),
          answerBuilder('l', '100% légumes'),
          answerBuilder('fl', 'Fruits & légumes'),
        ],
      },
    ],
  },

  bio: {
    message: 'Super ! Votre panier idéal doit-il être 100% Bio ?',
    legend: 'Tous les paniers Potager City contiennent fréquemment des produits issus de l’agriculture biologique.',
    questions: [
      {
        title: 'Le Bio dans votre panier ?',
        icon: BioCpt,
        answers: [
          answerBuilder('n', 'Pas nécessairement'),
          answerBuilder('y', 'Exclusivement'),
        ],
      },
    ],
    constraints: [
      bioConstraint,
    ],
  },

  exclusivities: {
    message: 'Pour quelques radis de plus, seriez-vous intéressé par une sélection coup de cœur de l’équipe avec les nouvelles récoltes en primeur à chaque début de saison ?',
    legend: 'C’est l’occasion de retrouver toutes les variétés en tout début de saison quand les quantités sont encore limitées. À vous les fraises et les clémentines avant tout le monde !',
    questions: [
      {
        title: 'Sélection coup de coeur ?',
        icon: '💚🥰',
        answers: [
          answerBuilder('n', 'Non merci'),
          answerBuilder('y', 'Avec plaisir'),
        ],
      },
    ],
  },

};
