<template>
  <transition name="appear-from-bottom">
    <div
      v-if="$slots.default"
      :class="[
        'py-4 px-6 text-center font-medium text-xs.5',
        {
          'bg-champagne': type === 'warning' || type === 'error',
          'bg-beryl-green': type === 'success',
        }
      ]">
      <slot />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'StatusBanner',

  props: {
    type: {
      type: String,
      default: 'success',
      values: ['warning', 'success', 'error'],
    },
  },
};
</script>
