<template>
  <potager-receipt-total
    :label="getLabel"
    :value="getValue"
    :sub-totals="getRealTotal" />
</template>

<script>
import { mapGetters } from 'vuex';
import Filters from 'Filters';
import PriceTools from 'Classes/PricesTools';

import PotagerReceiptTotal from 'UI/PotagerReceiptTotal';

export default {

  components: {
    PotagerReceiptTotal,
  },

  props: {
    totalOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    ...mapGetters('basket', [
      'getTotal',
      'getLinkedOrder',
      'getSimulatedOrder',
    ]),
    getLabel() {
      if (this.totalOnly) return undefined;
      // eslint-disable-next-line no-nested-ternary
      return this.getRealTotal.length
        ? this.getSubtotal >= 0 ? 'MONTANT AJOUTÉ' : 'MONTANT SOUSTRAIT'
        : undefined;
    },
    getSubtotal() {
      return PriceTools.getTotalOrder(this.getSimulatedOrder) - PriceTools.getTotalOrder(this.getLinkedOrder);
    },
    getValue() {
      if (this.totalOnly) return `${Filters.priceFilter(PriceTools.getTotalOrder(this.getSimulatedOrder))}€`;
      if (this.getSimulatedOrder && this.getLinkedOrder) {
        return `${Filters.priceFilter(this.getSubtotal)}€`;
      }
      return `${Filters.priceFilter(this.getTotal)}€`;
    },
    getRealTotal() {
      if (this.totalOnly) return null;
      const { getLinkedOrder, getSimulatedOrder } = this;
      const total = getLinkedOrder && getSimulatedOrder && PriceTools.getTotalOrder(getSimulatedOrder);
      return [
        ...(total ? [{
          label: 'Nouveau total de la commande',
          value: `${Filters.priceFilter(total)}€`,
        }] : []),
      ];
    },
  },

};
</script>
