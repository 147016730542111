import ErrorsTools from 'Classes/ErrorHandler/ErrorsTools';
import router from 'Router';

export const ADD_COUPON_ERROR = {
  text: 'Le coupon a une valeur minimale.',
  closeBtnText: 'Ok, j\'ai compris',
  cancelBtnText: 'Passer au paiement',
  onCancelRedirect: { name: 'basket_payment' },
  showCancel: true,
  handle(app, response, error) {
    error.onCancelRedirect = router.currentRoute.value.name.includes('tunnel')
      ? { name: 'tunnel-subscription-payment' }
      : { name: 'basket_payment' };

    const msg = ErrorsTools.getBackEndErrorMsg(response, 'ORDBASK004');
    if (msg) error.text = msg;
  },
};

export default {
  default: {
    text: 'Le coupon a une valeur minimale.',
    handle(response, error) {
      const msg = ErrorsTools.getBackEndErrorMsg(response, 'ORDBASK004');
      if (msg) error.text = msg;
    },
  },
  'TemporaryCustomerApi.addCouponBasketByCode': ADD_COUPON_ERROR,
  'UserApi.addCouponBasketByCode': ADD_COUPON_ERROR,
};
