<template>
  <div
    class="bg-beryl-green text-charcoal overflow-hidden banner-slider-homepage__wrapper -z-10">
    <potager-container
      ref="container"
      :class="[
        'banner-slider-homepage',
        slider?.autoplay?.running ? 'banner-slider-homepage--running' : 'banner-slider-homepage--stopped',
        { 'banner-slider-homepage--loaded': onLoaded },
      ]"
      size="wide">
      <potager-slider
        v-if="!isLoading && slides.length > 0"
        :items="slides"
        :settings="sliderSettings"
        :slides-per-view="1"
        class="banner-slider-homepage__slider h-full w-full"
        name="homepage-banner-slider"
        pagination
        @onInit="onSliderInit"
        @onSlideChange="onSlideChange">
        <template #default="{item}">
          <component
            :is="item.link ? 'potager-link' : 'div'"
            :to="item.link"
            class="h-full">
            <potager-picture
              :src="item.image"
              background
              class="relative"
              height="100%"
              no-lazy-load
              placeholder="bg-british-racing"
              position="center"
              title="Potagercity - Choisissez un panier ou faites votre marché et composez le vôtre !"
              width="100%"
              @onLoaded="onPictureLoaded">
              <div class="px-20 h-full bp1024:px-[50px] bp640:px-1">
                <div class="banner-slider-homepage__slide-content">
                  <div
                    :class="[
                      'banner-slider-homepage__slide-content-inner',
                      item.highContrast ? 'text-charcoal' : 'text-white',
                    ]">
                    <span
                      class="inline-block p-1.5 border border-current rounded-md leading-none text-xxs.5 font-bold uppercase"
                      v-html="item.subtitle" />

                    <p
                      class="font-extrabold text-3xl.5 bp1024:text-2xl.5"
                      v-html="item.title" />

                    <p
                      class="font-medium text-base bp1024:text-xs.5"
                      v-html="item.content" />

                    <potager-button
                      :href="item.link"
                      :label="item.ctaText"
                      :theme="item.ctaColor"
                      class="mt-3" />
                  </div>
                </div>
              </div>
            </potager-picture>
          </component>
        </template>

        <template #pagination>
          <div
            :class="['banner-slider-homepage__pagination', {
              'banner-slider-homepage__pagination--contrasted': contrast,
            }]" />
        </template>
      </potager-slider>

      <div
        v-else
        class="h-full w-full bg-british-racing flex items-center justify-center flex-col">
        <potager-logo
          :height="72"
          class="text-bianca/50 animate-pulse"
          minimal />
      </div>
    </potager-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FETCH_HP_SLIDES } from 'Stores/types/appActionTypes';

import PotagerContainer from 'UI/PotagerContainer';
import PotagerButton from 'UI/PotagerButton';
import PotagerPicture from 'UI/PotagerPicture';
import PotagerSlider from 'UI/PotagerSlider';
import PotagerLogo from 'UI/PotagerLogo';

import SeasonalCouponMixin from 'Mixins/SeasonalCouponMixin';

export default {
  mixins: [SeasonalCouponMixin],

  data() {
    return {
      pictureLoaded: false,
      sliderInit: false,
      contrast: false,
      slider: null,
    };
  },

  components: {
    PotagerLogo,
    PotagerContainer,
    PotagerButton,
    PotagerSlider,
    PotagerPicture,
  },

  computed: {
    ...mapGetters('app', ['getHpSlides']),
    onLoaded() {
      return this.pictureLoaded && this.sliderInit;
    },
    slides() {
      // mock
      return this.getHpSlides
          .map((slide) => {
            let imageVersion;

            if (this.$mq.bp640) {
              imageVersion = 'mobileImage';
            } else if (this.$mq.bp1024) {
              imageVersion = 'tabletImage';
            } else {
              imageVersion = 'desktopImage';
            }

            return {
              ...slide,
              image: slide[imageVersion],
            };
          });
    },
    sliderSettings() {
      // with fade effect and custom pagination
      return {
        effect: 'fade',
        centeredSlides: true,
        autoplay: {
          delay: 6000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        },
        pagination: {
          el: '.banner-slider-homepage__pagination',
          bulletClass: 'banner-slider-homepage__pagination-bullet',
          bulletActiveClass: 'banner-slider-homepage__pagination-bullet--active',
          clickable: true,
          renderBullet: (index, className) => `<span class="${className}"></span>`,
        },
        speed: 1000,
      };
    },
    isLoading() {
      return this.$wait.is[`app/${FETCH_HP_SLIDES}`];
    },
  },

  watch: {
    slides: {
      handler() {
        if (this.slider) {
          this.$nextTick(() => {
            this.slider.update();
            this.contrast = this.slides[0].highContrast;
          });
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('app', {
      fetchHpSlides: FETCH_HP_SLIDES,
    }),
    // on attend que les images soient chargées pour lancer l'autoplay du slider
    // sinon l'animation de progression des bullets n'est pas synchro
    onSliderInit({ slider }) {
      this.slider = slider;
      this.sliderInit = true;

      if (!this.pictureLoaded && slider) {
        this.$nextTick(() => {
          slider.autoplay.stop();
        });
      }
    },
    onPictureLoaded() {
      this.pictureLoaded = true;
      if (this.sliderInit && this.slider) {
        this.slider.autoplay.start();
      }
    },
    onSlideChange({ slide }) {
      this.contrast = slide.highContrast;
    },
  },

  created() {
    this.fetchHpSlides();
  },
};
</script>

<style lang="scss" scoped>
@keyframes progressBar {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.banner-slider-homepage {
  $this: &;
  @apply relative h-full w-full;

  &__wrapper {
    //@apply sticky left-0; uncomment this line to turn the banner sticky = create a parallax effect
    @apply w-full;
    top: $height_header;
    @apply h-[#{$hp-slider-height}];
    @apply bp1024:h-[#{$hp-slider-height--tablet}];
    @apply bp640:h-[#{$hp-slider-height--mobile}];

    @include bp1024() {
      top: $height_header_secondary--mobile;
    }
  }

  &__img {
    @apply w-fit relative;

    :deep() .potager-picture__img {
      @apply h-full w-auto min-w-full object-cover object-center;
    }
  }

  &__slide-content {
    @apply flex flex-col mx-auto pb-10;
    @apply max-w-[1064px] w-full h-full;
    @apply bp1024:max-w-[668px];
    @apply bp640:pb-[120px];

    &-inner {
      @apply my-auto max-w-[380px] flex flex-col items-start gap-3;
      @apply bp640:items-center bp640:text-center bp640:mx-auto;
    }
  }

  &__pagination {
    $pagination: &;

    @apply absolute bottom-10 left-1/2 transform z-20 -translate-x-1/2 translate-y-1/2;
    @apply flex justify-center items-center gap-2;
    @apply w-full;
    max-width: min(calc(100vw - (80px * 2)), 1064px);

    @include bp1024() {
      max-width: min(calc(100vw - (50px * 2)), 668px);
    }

    @include bp640() {
      max-width: calc(100vw - (24px * 2));
      @apply bottom-6;
    }

    :deep() &-bullet {
      @apply inline-block cursor-pointer z-10 w-full relative;
      @apply h-10 -my-2.5;
      @apply before:absolute before:top-1/2 before:left-0 before:rounded-[1px] before:bg-white-rock/30 before:h-[2px] before:w-full before:transition-all;
      @apply after:absolute after:top-1/2 after:rounded-[1px] after:bg-white-rock after:h-[2px] after:left-0 after:transition-all;

      &::after {
        width: 0;
      }

      &--active {
        &::after {
          transition: width 0.2s ease-in-out;
        }
      }
    }

    &--contrasted {
      :deep() #{$pagination}-bullet {
        @apply before:bg-charcoal/30;
        @apply after:bg-charcoal;
      }
    }
  }

  &--loaded#{&}--running {
    #{$this} {
      &__pagination {
        :deep() &-bullet {
          &--active {
            &::after {
              animation-name: progressBar;
              animation-timing-function: ease-in-out;
              animation-iteration-count: 1;
              animation-direction: alternate;
              animation-fill-mode: forwards;
              animation-duration: 6s;
            }
          }
        }
      }
    }
  }

  &--loaded#{&}--stopped {
    #{$this} {
      &__pagination {
        :deep() &-bullet {
          &--active {
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
  }

  @include hover() {
    #{$this} {
      &__pagination {
        :deep() &-bullet {
          &--active {
            &::after {
              animation-play-state: paused;
            }
          }
        }
      }
    }
  }
}
</style>
