/* jshint camelcase: false */
/* jshint maxlen: 300 */
export const CARD_DECLINED = 'card_declined';

export const incorrect_number = 'Le numéro de carte n\'est pas valide. Veuillez réessayer et si le problème persiste, contactez <a href="https://potagercity.force.com/serviceclient/s/contactsupport">notre service client</a>.';
export const invalid_number = incorrect_number;
export const processing_error = 'Une erreur s’est produite lors du traitement de la carte bancaire. Veuillez réessayer. Si le problème persiste, réessayez plus tard ou contactez <a href="https://potagercity.force.com/serviceclient/s/contactsupport">notre service client</a>.';
export const incorrect_cvc = 'Le numéro de sécurité (CVC) de la carte est invalide. Veuillez réessayer et si le problème persiste, contactez <a href="https://potagercity.force.com/serviceclient/s/contactsupport">notre service client</a>.';
export const invalid_cvc = incorrect_cvc;
export const expired_card = 'La date d\'expiration de la carte est dépassée. Veuillez réessayer avec une autre carte et si le problème persiste, contactez <a href="https://potagercity.force.com/serviceclient/s/contactsupport">notre service client</a>.';
export const invalid_expiry_month = 'Le mois d\'expiration n\'est pas valide. Veuillez réessayer avec la bonne date d\'expiration.';
export const invalid_expiry_year = 'L\'année d\'expiration n\'est pas valide. Veuillez réessayer avec la bonne date d\'expiration.';
export const stolen_card = 'Le processus a échoué car la carte est considérée comme volée. Veuillez contacter votre émetteur de carte pour corriger le problème.';
export const lost_card = 'Le processus a échoué car la carte est considérée comme perdue. Veuillez contacter votre émetteur de carte pour corriger le problème.';
export const insufficient_funds = 'Le processus a échoué car il y a une insuffisance de fonds sur le compte rattaché à la carte. Veuillez contacter votre émetteur de carte pour corriger le problème.';
export const card_not_supported = 'Le processus a échoué car le modèle de carte n\'est pas accepté par notre service. Veuillez contacter votre émetteur de carte pour corriger le problème.';
export const authentication_required = 'Le processus a échoué car la carte nécessite une authentification.';
export const approve_with_id = 'Le processus a échoué car le paiement ne peut pas être autorisé. Veuillez contacter votre émetteur de carte pour corriger le problème.';
export const call_issuer = 'Le processus a échoué pour une raison inconnue (votre émetteur de carte ne nous donne pas la raison). Veuillez réessayer plus tard ou utiliser une autre carte bancaire.';
export const do_not_try_again = call_issuer;
export const generic_decline = call_issuer;
export const do_not_honor = call_issuer;
export const merchant_blacklist = call_issuer;
export const no_action_taken = call_issuer;
export const reenter_transaction = call_issuer;
export const revocation_of_all_authorizations = call_issuer;
export const security_violation = call_issuer;
export const service_not_allowed = call_issuer;
export const stop_payment_order = call_issuer;
export const transaction_not_allowed = call_issuer;
export const try_again_later = call_issuer;
export const card_velocity_exceeded = 'Le processus a échoué car vous avez dépassé la limite de crédits autorisés sur votre carte. Veuillez contacter votre émetteur de carte pour corriger le problème.';
export const withdrawal_count_limit_exceeded = card_velocity_exceeded;
export const currency_not_supported = 'Le processus a échoué car la devise (€) n\'est pas acceptée par votre carte. Veuillez contacter votre émetteur de carte pour corriger le problème.';
export const duplicate_transaction = 'Le processus a échoué car une transaction identique avec le même montant a été déjà acceptée il y a quelques secondes.';
export const fraudulent = 'Le processus a échoué car il a été considéré comme frauduleux. Veuillez contacter votre émetteur de carte pour corriger le problème.';
export const incorrect_pin = 'Le processus a échoué car le code PIN est incorrect.';
export const invalid_pin = incorrect_pin;
export const incorrect_zip = 'Le processus a échoué car le code postal est incorrect.';
export const invalid_account = 'Le processus a échoué car le compte rattaché à la carte est invalide. Veuillez contacter votre émetteur de carte pour corriger le problème.';
export const new_account_information_available = invalid_account;
export const invalid_amount = 'Le processus a échoué car le montant du paiement est supérieur au montant maximum autorisé par la carte. Veuillez contacter votre émetteur de carte pour corriger le problème.';
export const issuer_not_available = 'Le processus a échoué car nous rencontrons un problème technique avec l\'émetteur de votre carte. Veuillez vous renseigner auprès de votre émetteur de carte pour avoir plus d\'information.';
export const not_permitted = generic_decline;
export const pickup_card = 'La carte ne peut pas être utilisée pour effectuer ce paiement (il est possible qu\'elle ait été déclarée perdue ou volée). Veuillez contacter votre émetteur de carte pour corriger le problème.';
export const restricted_card = pickup_card;
export const offline_pin_required = 'Le processus a échoué car il requiert la validation d\'un code PIN. Veuillez réessayer et si le problème persiste, contactez <a href="https://potagercity.force.com/serviceclient/s/contactsupport">notre service client</a>.';
export const online_or_offline_pin_required = offline_pin_required;
export const pin_try_exceeded = 'Le processus a échoué car la limite d\'essais de validation du code PIN a été dépassée. Veuillez réessayer et si le problème persiste, contactez <a href="https://potagercity.force.com/serviceclient/s/contactsupport">notre service client</a>.';
export const testmode_decline = 'La carte utilisée est une carte de test Stripe. Utilisez une véritable carte bancaire pour effectuer le paiement.';
export const payment_intent_authentication_failure = 'Le processus d\'authentification de la carte a échoué. Veuillez réessayer avec un autre moyen de paiement ou contactez <a href="https://potagercity.force.com/serviceclient/s/contactsupport">notre service client</a> si le problème persiste.';
export const already_added = 'Le processus a échoué car la carte a déjà été ajoutée. Veuillez réessayer avec une autre carte ou contactez <a href="https://potagercity.force.com/serviceclient/s/contactsupport">notre service client</a>.';
export const setup_intent_authentication_failure = payment_intent_authentication_failure;
export const resource_missing = processing_error;

export const ERROR_MESSAGES = {
  incorrect_number,
  invalid_number,
  processing_error,
  incorrect_cvc,
  invalid_cvc,
  expired_card,
  invalid_expiry_month,
  invalid_expiry_year,
  stolen_card,
  lost_card,
  insufficient_funds,
  card_not_supported,
  authentication_required,
  approve_with_id,
  call_issuer,
  do_not_try_again,
  generic_decline,
  do_not_honor,
  merchant_blacklist,
  no_action_taken,
  reenter_transaction,
  revocation_of_all_authorizations,
  security_violation,
  service_not_allowed,
  stop_payment_order,
  transaction_not_allowed,
  try_again_later,
  card_velocity_exceeded,
  withdrawal_count_limit_exceeded,
  currency_not_supported,
  duplicate_transaction,
  fraudulent,
  incorrect_pin,
  invalid_pin,
  incorrect_zip,
  invalid_account,
  new_account_information_available,
  invalid_amount,
  issuer_not_available,
  not_permitted,
  pickup_card,
  restricted_card,
  offline_pin_required,
  online_or_offline_pin_required,
  pin_try_exceeded,
  testmode_decline,
  payment_intent_authentication_failure,
  already_added,
  setup_intent_authentication_failure,
  resource_missing,
};
