import { inAppWebView } from 'Classes/Tools';
import { postMessage, redirectWebApp } from 'Mixins/MobileAppMixin';

export default function (_to, _from, next) {
  if (inAppWebView) {
    const from = _from && _from.name ? _from.fullPath : null;
    const to = _to && _to.name ? _to.fullPath : null;
    postMessage({
      from,
      to
    });
  }

  if (!_to.meta.webview) {
    setTimeout(() => {
      redirectWebApp();
    });
  }

  next();
}
