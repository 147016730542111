<template>
  <template
    v-for="(benefit, k) in subscriptionBenefits"
    :key="`benefit-${k}`">
    <h2
      class="text-zest font-bold text-base">
      {{ benefit.title }}
    </h2>

    <ul class="text-sm">
      <li
        v-for="(item, key) in benefit.list"
        :key="`benefit-item-${k}-${key}`"
        class="mt-1.5 flex">
        <icon-check-circle1 class="text-go-green text-lg min-w-[18px] min-h-[18px] mr-2 mt-[2px]" />
        <span v-html="item" />
      </li>
    </ul>

    <div
      v-if="separators && k !== subscriptionBenefits.length - 1"
      :key="`separator-${k}`"
      class="panel-section__separator mx-0" />
  </template>
</template>
<script>
import { subscriptionBenefits } from 'Classes/Wordings';

import IconCheckCircle1 from 'Icons/bold/IconCheckCircle1';

export default {
  name: 'SubscriberBenefitsList',

  components: {
    IconCheckCircle1,
  },

  props: {
    separators: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      subscriptionBenefits,
    };
  },
};
</script>
