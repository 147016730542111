<template>
  <span>
      <component
          :is="icon.component"
          v-for="(icon, index) in icons"
          :key="`${icon.PM.name}-${index}`"
          :class="[
          'inline-block align-middle mx-2',
          `text-${icon.PM.name}`,
        ]"
          :style="{ color: icon.PM.colors?.primary }"/>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';

import { getPMsByType } from 'Classes/paymentMethods';
import PAYMENT_METHODS_CONSTANTS, {
  PAYMENT_METHODS_TYPES,
  PAYMENT_METHODS_INTEGRATIONS
} from 'Classes/paymentMethods/Constants';

export default {
  props: {
    types: {
      type: Array,
      required: false,
      default: () => [PAYMENT_METHODS_TYPES.BANK_CARD],
      validator: (val) => {
        const types = Object.values(PAYMENT_METHODS_TYPES);
        return val.every((type) => types.includes(type));
      },
    },
    alternativeIcons: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    getPMsByType,
  },

  computed: {
    ...mapGetters('user', [
      'getPaymentGateway',
    ]),
    icons() {
      const iconsAttr = this.alternativeIcons ? 'alternativeIcons' : 'icons';

      // Déterminer les PM à filtrer en fonction de la gateway
      const filteredGateway = this.getPaymentGateway === PAYMENT_METHODS_INTEGRATIONS.PAYGREEN
          ? PAYMENT_METHODS_CONSTANTS.STRIPE.name
          : PAYMENT_METHODS_CONSTANTS.PAYGREEN_BANK_CARD.name;

      // Transformer chaque type de paiement en une liste d'icônes associées.
      return this.types.flatMap(type => this.getPMsByType(type)
          .flatMap((PM) => {
            const icons = PM[iconsAttr] || []; // Assurer que 'icons' n'est jamais 'undefined'.
            return icons.map((icon) => ({
              PM,
              component: icon
            }));
          }))
          // Filtrer les icônes en fonction de la gateway
          .filter((icon) => icon.PM.name !== filteredGateway);
    },
  },
};
</script>
