import {
  DELIVERY_FUNCTIONAL_TYPE_HOME,
  DELIVERY_FUNCTIONAL_TYPE_PRIVATE,
  DELIVERY_FUNCTIONAL_TYPE_PUBLIC,
} from 'Classes/Constants';

export const ORDER_TYPE = 'orderType';
export const ORDER_SUBSCRIPTION_TYPE = 'subscription';
export const ORDER_ALC_TYPE = 'alc';
export const ORDER_TYPES = [ORDER_SUBSCRIPTION_TYPE, ORDER_ALC_TYPE];

// type only use in dlp change to navigate
// else (like for api call) use functional types directly
export const DELIVERY_TYPE = 'deliveryType';
export const DELIVERY_SKIP_TYPE = 'skip';
export const DELIVERY_HOME_TYPE = DELIVERY_FUNCTIONAL_TYPE_HOME;
export const DELIVERY_PRIVATE_TYPE = DELIVERY_FUNCTIONAL_TYPE_PRIVATE;
export const DELIVERY_PUBLIC_TYPE = DELIVERY_FUNCTIONAL_TYPE_PUBLIC;
export const DELIVERY_TYPES = [DELIVERY_PUBLIC_TYPE, DELIVERY_PRIVATE_TYPE, DELIVERY_HOME_TYPE, DELIVERY_SKIP_TYPE];

export const REGISTRATION_TYPE = 'registrationType';
export const REGISTRATION_REGISTER_TYPE = 'register';
export const REGISTRATION_LOGIN_TYPE = 'login';
export const REGISTRATION_TYPES = [REGISTRATION_REGISTER_TYPE, REGISTRATION_LOGIN_TYPE];

export default {
  [ORDER_TYPE]: {
    types: ORDER_TYPES,
    default: ORDER_SUBSCRIPTION_TYPE,
  },
  [DELIVERY_TYPE]: {
    types: DELIVERY_TYPES,
    default: DELIVERY_PUBLIC_TYPE,
  },
  [REGISTRATION_TYPE]: {
    types: REGISTRATION_TYPES,
    default: REGISTRATION_REGISTER_TYPE,
  },
};
