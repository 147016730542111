import { POPIN_ERROR_EVENT } from 'Classes/Constants';
import WordingTools from 'Classes/WordingTools';
import { getDlpSearchRoute } from 'Classes/DlpRouteTools';
import store from 'Stores';

export const BT004_ADD_BOX_ERROR = {
  eventType: POPIN_ERROR_EVENT,
  title: 'Avertissement',
  text: WordingTools.gddDelete('BT004'),
  closeBtnText: 'Modifier mon point de retrait',
  onCloseRedirect: getDlpSearchRoute(),
  showCancel: true,
  handle(app, response, error) {
    const gdd = store.getters['basket/getGroupDeliveryDay'];
    error.text = WordingTools.gddDelete('BT004', gdd);
  },
};

export default {
  default: {
    text: WordingTools.gddDelete('BT004'),
  },
  'UserApi.addBox': BT004_ADD_BOX_ERROR,
  'TemporaryCustomerApi.addBox': BT004_ADD_BOX_ERROR,
};
