<template>
  <tunnel-body
    class="text-center">
    <h1 class="text-xl font-extrabold mb-3 mx-10">
      Le concept
    </h1>

    <p class="mb-10">
      Laissez-vous guider en choisissant l'un de nos 5 paniers composés et bénéficiez de
      <potager-button
        class="underline text-mango-tango font-bold"
        theme="reset"
        @onClick="showCouponPolicy(getPromotedCoupon)">
        € notre offre de bienvenue
      </potager-button>&nbsp;🧡
    </p>

    <div class="bg-white rounded-lg overflow-hidden w-full mb-7 flex bp768:flex-col">
      <div class="flex-1 px-6 py-5 bp768:order-2">
        <h2 class="text-lg font-bold mb-1">
          Je choisis un panier hebdomadaire
        </h2>

        <p
          v-if="getMinPrice"
          class="mb-3">
          À partir de <span class="font-extrabold text-xl.5 text-forest-green not-italic">
            {{ priceFilter(getMinPrice) }}€
          </span>
        </p>

        <potager-badge
          class="mb-5"
          clickable
          theme="sandy-beach"
          @onClick="showCouponPolicy(getPromotedCoupon)">
          👋 -50% sur votre 1er panier
        </potager-badge>

        <ul>
          <li class="mb-2 flex">
            <div>
              <icon-check-circle1 class="text-forest-green text-xs mr-2 leading-normal block mt-0.5" />
            </div>

            <div class="text-xs.5">
              <p>
                <b>
                  Gagnez du temps
                </b>

                et ne vous prenez plus le chou à choisir vos fruits et légumes.
              </p>
            </div>
          </li>

          <li class="mb-2 flex">
            <div>
              <icon-check-circle1 class="text-forest-green text-xs mr-2 leading-normal block mt-0.5" />
            </div>

            <div class="text-xs.5">
              <p>
                <b>
                  Découvrez des variétés
                </b>

                que vous n’auriez pas choisies d’habitude, et laissez-vous accompagner en cuisine&nbsp;!
              </p>
            </div>
          </li>

          <li class="mb-2 flex">
            <div>
              <icon-check-circle1 class="text-forest-green text-xs mr-2 leading-normal block mt-0.5" />
            </div>

            <div class="text-xs.5">
              <p>
                <b>
                  Annulez vos livraisons
                </b>

                et gérez vos commandes en 1 clic : zéro engagement&nbsp;!
              </p>
            </div>
          </li>
        </ul>

        <potager-button
          class="underline mb-5"
          theme="reset"
          @onClick="openModal(subscriptionModal)">
          En savoir plus
        </potager-button>

        <potager-button
          :to="subscriptionRoute"
          full-width
          theme="go-green">
          Choisir mon panier
        </potager-button>
      </div>

      <div>
        <potager-picture
          :height="!$mq.bp768 ? '100%' : '140px'"
          :position="$mq.bp768 ? '50% 75%' : undefined"
          :width="$mq.bp768 ? '100%' : '240px'"
          class="bp768:order-1"
          placeholder
          src="tunnel-order-type-subscription" />
      </div>
    </div>

    <div
      v-if="variante === 0"
      class="bg-white rounded-lg overflow-hidden w-full mb-7 flex bp768:flex-col">
      <div class="flex-1 px-6 py-5 bp768:order-2">
        <h2 class="text-lg font-bold mb-2">
          Je m’inscris
        </h2>

        <p class="mb-2">
          Min. d’achat <span class="font-extrabold text-xl.5 text-forest-green not-italic">
            13,50€
          </span>
        </p>

        <p class="mb-5">
          <b>
            Rejoignez la communauté Potager City sans choisir de panier.
          </b>
        </p>

        <potager-button
          class="underline mb-5"
          theme="reset"
          @onClick="openModal(alcModal)">
          En savoir plus
        </potager-button>

        <potager-button
          :to="alcRoute"
          full-width
          theme="bianca">
          M’inscrire et commander
        </potager-button>
      </div>
    </div>
  </tunnel-body>
</template>

<script>
import { mapGetters } from 'vuex';
import { PTCY_3910 } from 'Stores/ABTestStore';
import { FETCH_PRODUCT_SELECTORS_V2_ACTION } from 'Stores/types/subscriptionBasketActionsTypes';

import { priceFilter } from 'Filters';

import GtmMixin from 'Mixins/GtmMixin';

import TunnelBody from 'Components/tunnel/TunnelBody';
import ModalList from 'Components/modals/ModalList';

import { ORDER_ALC_TYPE, ORDER_SUBSCRIPTION_TYPE, ORDER_TYPE } from 'Classes/workflow/TunnelTypesConstants';
import MetaInfosService from 'Classes/services/MetaInfoService';
import { PMEL } from 'Classes/Constants';

import PotagerPicture from 'UI/PotagerPicture';
import PotagerBadge from 'UI/PotagerBadge';
import PotagerButton from 'UI/PotagerButton';

import IconCheckCircle1 from 'Icons/bold/IconCheckCircle1';

import SeasonalCouponMixin from 'Mixins/SeasonalCouponMixin';
import ABTestMixin from 'Mixins/ABTestMixin';

export default {
  mixins: [
    GtmMixin,
    SeasonalCouponMixin,
    ABTestMixin,
  ],

  components: {
    PotagerBadge,
    PotagerPicture,
    IconCheckCircle1,
    PotagerButton,
    TunnelBody,
  },

  data() {
    return {
      ORDER_ALC_TYPE,
      ORDER_SUBSCRIPTION_TYPE,
      subscriptionModal: {
        title: '<span class="font-normal">Potager City</span> <br> avec abonnement',
        items: [
          {
            title: 'Sans aucun engagement : ',
            description: 'Chez nous, l’abonnement est juste une récurrence : <b class="font-bold">pas de conditions cachées écrites en tout petit.</b> <br>Chaque semaine, nous créons votre commande, vous pouvez l’annuler ou tout mettre en pause à tout moment.<br>Fastoche ! ',
            icon: '🙏',
          }, {
            title: '+ économique :',
            description: 'À chaque commande, <b class="font-bold">économisez 1€ sur tous les paniers.</b>',
            icon: '👍',
          }, {
            title: '+ pratique :',
            description: 'Choisissez le format de panier le plus adapté à votre appétit. Chaque semaine, son contenu change, mais jamais son prix ! ',
            icon: '👌',
          }, {
            title: 'Super bon :',
            description: 'Pas un avantage mais une évidence, les fruits et légumes de vos paniers sont super bons.',
            icon: '😋',
          },
        ],
        btnLabel: 'Choisir mon panier',
        btnRoute: () => this.subscriptionRoute,
      },
      alcModal: {
        title: '<span class="font-normal">Potager City</span> <br> sans abonnement',
        items: [
          {
            title: '+ flexible :',
            description: 'Commander sans abonnement, c’est surtout ne pas avoir de récurrence par défaut. Vous commandez quand vous voulez ! (mais c’est aussi prendre le risque d’oublier et de louper les pépites de la semaine. La tuile.)',
            icon: '🙏',
          },
          {
            title: 'Toujours super bon :',
            description: 'Avec ou sans abonnement, ça ne changera pas le goût. Toujours des fruits et légumes super bons et de saison.',
            icon: '😋',
          },
        ],
        btnLabel: 'M’inscrire et commander',
        btnRoute: () => this.alcRoute,
      },
    };
  },

  computed: {
    ...mapGetters('tunnel', [
      'getNextStepRoute',
    ]),
    ...mapGetters('session', [
      'getRegionId',
    ]),
    ...mapGetters('subscriptionBasket', [
      'getProductSelectorsV2',
    ]),
    getMinPrice() {
      const pricesArray = this.getProductSelectorsV2
        .map((cat) => cat.variants
          .map((format) => format.product.price.subscription))
        .flat();
      if (pricesArray.length === 0) return undefined;
      return pricesArray
        .reduce((acc, curr) => Math.min(acc, curr), Infinity);
    },
    alcRoute() {
      return this.getNextStepRoute({ newTypes: [{ [ORDER_TYPE]: ORDER_ALC_TYPE }] });
    },
    subscriptionRoute() {
      return this.getNextStepRoute({
        newTypes: [{ orderType: ORDER_SUBSCRIPTION_TYPE }],
        params: {
          regionId: this.getRegionId,
          ref: PMEL,
        },
      });
    },
    variante() {
      return this.getABTestVariante(PTCY_3910);
    },
  },

  watch: {
    getProductSelectorsV2: {
      handler(products) {
        if (!products.length) {
          this.$store.dispatch(`subscriptionBasket/${FETCH_PRODUCT_SELECTORS_V2_ACTION}`, {});
        }
      },
      immediate: true,
    },
  },

  methods: {
    priceFilter,
    openModal(modal) {
      const {
        title,
        items,
        btnTheme = 'go-green',
        btnLabel,
        btnCallback,
        btnRoute,
      } = modal;

      this.$modal.open(ModalList, {
        title,
        items,
        btnLabel,
        btnTheme,
        btnCallback,
        btnRoute: typeof btnRoute === 'function' ? btnRoute() : btnRoute,
      });
    },
  },

  head: MetaInfosService.generate({
    title: 'Choix du mode de commande en abonnement ou à la carte',
  }),
};
</script>
